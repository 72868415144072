import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import * as Constants from '../constants'

import { Header, Footer, ScrollToTop } from '../components'

import { Website_Home, Website_Imprint, Website_TaC, Website_Privacypolicy, Website_Offline, Website_Contact, Website_FAQ, Website_About, Website_Customerinfo, Website_Benefits, Website_Reviews } from '../pages'
import { Admin_Dashboard, Admin_Purchase, Admin_Purchases } from '../pages'
import { Article_Grid, Article_List_Ski, Article_Insert, Article_Update, Article_View } from '../pages'
import { Auth_Login, Auth_Reset, Auth_Signup, Auth_Change_Password, Auth_Change_Email, Auth_Logout, Auth_Registered, Auth_Verify } from '../pages'
import { User_Profile, User_Marks, User_Adverts, User_Purchases, User_Noseller } from '../pages'
import { Purchase_Checkout, Purchase_Success } from '../pages'

import 'bootstrap/dist/css/bootstrap.min.css'
import Userfront from "@userfront/react";

const AppContainer = styled.div.attrs({
})`
    background-color: #EBEBEB;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`

const HeaderContainer = styled.div.attrs({
})`
flex: 0 1 auto;
`
const ContentContainer = styled.div.attrs({
})`
flex: 1 1 auto;
`
const FooterContainer = styled.div.attrs({
})`
flex: 0 1 auto;
`

function App() {

    //Website maintainence
    var maintainence = '';
    if (process.env.NODE_ENV !== 'production') {
        maintainence = Constants.DEV_MAINTAINENCE;
    } else {
        maintainence = process.env.REACT_APP_MAINTAINENCE;
    }


    var tenant = '';
    if (process.env.NODE_ENV !== 'production') {
        tenant = Constants.DEV_USERFRONT_TENANT;
    } else {
        tenant = process.env.REACT_APP_USERFRONT_TENANT;
    }
    Userfront.init(tenant);
    var token = Userfront.accessToken();
    if (token) {
        var token_json = JSON.parse(atob(token.split('.')[1]));
        var date_now = new Date().getTime()

        if (date_now > (token_json.exp * 1000)) {
            Userfront.logout({ redirect: false });
        } else {
            Userfront.tokens.refresh();
        }

    }

    //Website maintainence developer & test key
    const dev_key = localStorage.getItem("dev_key");
    const test_key = localStorage.getItem("test_key");

    return (
        <AppContainer >
            {maintainence != 'true' || dev_key === Constants.DEVELOPER_KEY || test_key === Constants.TESTER_KEY ?
                <Router>
                    <HeaderContainer>
                        <Header />
                    </HeaderContainer>
                    <ContentContainer>
                        <ScrollToTop />
                        <Routes>
                            <Route path="" element={<Website_Home />} />
                            <Route path="/info/home" element={<Website_Home />} />
                            <Route path="/info/imprint" element={<Website_Imprint />} />
                            <Route path="/info/termsandconditions" element={<Website_TaC />} />
                            <Route path="/info/privacypolicy" element={<Website_Privacypolicy />} />
                            <Route path="/info/contact" element={<Website_Contact />} />
                            <Route path="/info/contact/:order_no" element={<Website_Contact />} />
                            <Route path="/info/faq" element={<Website_FAQ />} />
                            <Route path="/info/about" element={<Website_About />} />
                            <Route path="/info/customerinfo" element={<Website_Customerinfo />} />
                            <Route path="/info/benefits" element={<Website_Benefits />} />
                            <Route path="/info/reviews" element={<Website_Reviews />} />
                            <Route path="/info/reviews/:limit" element={<Website_Reviews />} />


                            <Route path="/advert/all" element={<Article_Grid />} />
                            <Route path="/advert/all/:limit" element={<Article_Grid />} />
                            <Route path="/advert/ski" element={<Article_List_Ski />} />
                            <Route path="/advert/ski/:limit/:filter/:sort" element={<Article_List_Ski />} />
                            <Route path="/advert/insert" element={<Article_Insert />} />
                            <Route path="/advert/view/:id" element={<Article_View />} />
                            <Route path="/advert/update/:id" element={<Article_Update />} />


                            <Route path="/user/profile" element={<User_Profile />} />
                            <Route path="/user/marks" element={<User_Marks />} />
                            <Route path="/user/purchases" element={<User_Purchases />} />
                            <Route path="/user/adverts" element={<User_Adverts />} />
                            <Route path="/user/seller" element={<User_Noseller />} />


                            <Route path="/login" element={<Auth_Login />} />
                            <Route path="/auth/login" element={<Auth_Login />} />
                            <Route path="/auth/reset" element={<Auth_Reset />} />
                            <Route path="/auth/change_password" element={<Auth_Change_Password />} />
                            <Route path="/auth/change_email" element={<Auth_Change_Email />} />
                            <Route path="/auth/verify" element={<Auth_Verify />} />
                            <Route path="/auth/signup" element={<Auth_Signup />} />
                            <Route path="/auth/logout" element={<Auth_Logout />} />
                            <Route path="/auth/registered" element={<Auth_Registered />} />


                            <Route path="/purchase/checkout/:id" element={<Purchase_Checkout />} />
                            <Route path="/purchase/success" element={<Purchase_Success />} />


                            <Route path="/admin/dashboard" element={<Admin_Dashboard />} />
                            <Route path="/admin/purchase/:id" element={<Admin_Purchase />} />
                            <Route path="/admin/purchases" element={<Admin_Purchases />} />
                            <Route path="/admin/purchases/:filter/:sort" element={<Admin_Purchases />} />
                        </Routes>
                    </ContentContainer>
                    <FooterContainer>
                        <Footer />
                    </FooterContainer>
                </Router>
                :
                <ContentContainer>
                    <Website_Offline />
                </ContentContainer>
            }
        </AppContainer>
    )
}

export default App