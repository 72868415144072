import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { Container, WrapperCenter } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnHoloBlue } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Drawer from '@mui/material/Drawer';


import icon_newa from '../res/icons/icon_newa_g_v1.png'
import icon_user from '../res/icons/icon_user_g_v1.png'
import icon_admin from '../res/icons/icon_admin_g_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_menu from '../res/icons/icon_menu_g_v1.png'
import icon_grid from '../res/icons/icon_grid_g_v1.png'
import icon_ski from '../res/icons/icon_cat_ski_g_v1.png'

import logo from '../res/images/reski_logo_3_v3.webp'
const logo_backup = 'https://ik.imagekit.io/reski/admin/reski_logo_3_v4.png'

const style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const Collapse = styled.div.attrs({
    className: 'collpase navbar-collapse',
})`
`

const List = styled.div.attrs({
    className: 'navbar-nav ms-auto',
})``

const Item = styled.div.attrs({
    className: 'collpase navbar-collapse',
})`
  position: relative;
  display: inline-block;
  &:hover .item-dropdown{ 
    display: block;
  }
`
const ItemDropdown = styled.div.attrs({
    className: 'item-dropdown',
})`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 45px;
  background-color: #212529;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
`


const Wrapper = styled.a.attrs({
    className: 'navbar-brand',
})`
`

const WrapperLeft = styled.div.attrs({
})`
display: flex;
width: 100%;
flex-direction: column;
justify-content: left;
align-items: left;
`

const Icon = styled.img.attrs({
    hight: '30',
    width: '30'
})`
margin: 0 10px;
`

const Splitter = styled.hr.attrs({
    color: 'white',
    hight: 'auto',
    width: '1'
})`
margin: 0 10px;
`

const SplitterH = styled.hr.attrs({
    color: 'white',
    hight: '1',
    width: 'auto'
})`
margin: 10px;
`

const BtnToLogin = styled(BtnBlue)`
    width: 50%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class LinksNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalLoginOpen: false,
            sb_left: false,
            isMobile
        }
    }

    componentDidMount = async () => {
        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);
    }

    doLogout = async () => {
        Userfront.logout({ redirect: false }).then(function (response) {
            window.location.reload();
        }
        );
    }

    handleModalLoginOpen = async event => {
        event.preventDefault();

        const modalLoginOpen = true;
        this.setState({ modalLoginOpen })
    }

    handleModalLoginClose = async event => {
        const modalLoginOpen = false
        this.setState({ modalLoginOpen })

    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ sb_left: open });
    }

    render() {
        const { modalLoginOpen, isMobile, sb_left } = this.state
        const { t } = this.props

        return (
            <React.Fragment>
                <Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalLoginOpen}
                        onClose={this.handleModalLoginClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalLoginOpen}>
                            <Box sx={isMobile === true ? m_style_login : style_login}>
                                <Container>
                                    <WrapperModalHeader>
                                        <H5TitleModal>{t("ui.view.tx_mod_login_title")}</H5TitleModal>
                                        <BtnModalClose onClick={this.handleModalLoginClose}>
                                            <IconModalClose src={icon_close} alt={'...'} />
                                        </BtnModalClose>
                                    </WrapperModalHeader>
                                    <Paragraph>{t("ui.view.tx_mod_login_text")}</Paragraph>
                                    <StyledLink to={"/auth/login/"} state={{ from: '/advert/insert' }}>
                                        <WrapperCenter>
                                            <BtnToLogin onClick={this.handleModalLoginClose}>{t("ui.view.bt_mod_toLogin")}</BtnToLogin>
                                        </WrapperCenter>
                                    </StyledLink>
                                </Container>
                            </Box>
                        </Fade>
                    </Modal>
                </Container>

                {isMobile === false ?
                    <React.Fragment>

                        <Link to="/" className="navbar-brand">
                            <img src={logo} style={{ marginLeft: "15px" }} height="35"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = logo_backup;
                                }}
                            />
                        </Link>

                        {Userfront.accessToken() && Userfront.user.hasRole("admin") &&
                            <List>
                                <Item>
                                    <Link to="/admin/dashboard" className="navbar-brand">
                                        <Icon src={icon_admin} alt={'...'} />
                                        {t("ui.admin.tx_title")}
                                    </Link>
                                </Item>
                                <Item>
                                    <Link to="/admin/purchases" className="navbar-brand">
                                        <Icon src={icon_admin} alt={'...'} />
                                        {t("ui.admin_purchases.tx_title")}
                                    </Link>
                                </Item>
                            </List>
                        }

                        <List>
                            <Item>
                                <Link onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : null} to="/advert/insert" className="nav-link" >
                                    <Icon src={icon_newa} alt={'...'} />
                                    <font size="2">{t("ui.header.tx_newarticle")}</font>
                                </Link>
                            </Item>
                            <Splitter />
                            {!Userfront.accessToken() ?
                                <Item>
                                    <Link to="/auth/login" className="nav-link" >
                                        <Icon src={icon_user} alt={'...'} />
                                        <font size="2">{t("ui.header.tx_user_login")}</font>
                                    </Link>
                                </Item>
                                :
                                <Item>
                                    <Link to="/user/profile" className="nav-link" >
                                        <Icon src={icon_user} alt={'...'} />

                                        <font size="2">{decodeURIComponent(Userfront.user.data.name)}</font>
                                    </Link>
                                    <ItemDropdown>
                                        <Link to="/user/profile" className="nav-link"><font size="2">{t("ui.header.tx_user_profile")}</font></Link>
                                        <Link to="/user/adverts" className="nav-link"><font size="2">{t("ui.header.tx_user_adverts")}</font></Link>
                                        <Link to="/user/purchases" className="nav-link"><font size="2">{t("ui.header.tx_user_purchases")}</font></Link>
                                        <Link to="/user/marks" className="nav-link"><font size="2">{t("ui.header.tx_user_marks")}</font></Link>
                                        <Link className="nav-link" onClick={this.doLogout}><font size="2">{t("ui.header.tx_user_logout")}</font></Link>
                                    </ItemDropdown>
                                </Item>
                            }
                        </List>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <WrapperLeft>
                            <Icon alt={'...'} src={icon_menu} onClick={this.toggleDrawer(true)} />
                            <Drawer
                                anchor={'left'}
                                open={sb_left}
                                onClose={this.toggleDrawer(false)}
                            >
                                <Box
                                    height={'100%'}
                                    bgcolor={'#212529'}
                                    role="presentation"
                                    onClick={this.toggleDrawer(false)}
                                    onKeyDown={this.toggleDrawer(false)}
                                >
                                    <Link to="/" className="navbar-brand">
                                        <img src={logo} style={{ margin: "15px" }} height="35"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = logo_backup;
                                            }}
                                        />
                                    </Link>
                                    <List
                                        style={{ margin: "10px" }}
                                    >
                                        {Userfront.accessToken() && Userfront.user.hasRole("admin") &&
                                            <Item>
                                                <Link to="/admin/dashboard" className="navbar-brand">
                                                    <Icon src={icon_admin} alt={'...'} />
                                                    <font color="#999999" size="2">{t("ui.admin.tx_title")}</font>
                                                </Link>
                                            </Item>
                                        }
                                        {Userfront.accessToken() && Userfront.user.hasRole("admin") &&
                                            <Item>
                                                <Link to="/admin/purchases" className="navbar-brand">
                                                    <Icon src={icon_admin} alt={'...'} />
                                                    <font color="#999999" size="2">{t("ui.admin_purchases.tx_title")}</font>
                                                </Link>
                                            </Item>
                                        }
                                        {Userfront.accessToken() && Userfront.user.hasRole("admin") &&
                                            <SplitterH />
                                        }
                                        <Item>
                                            <Link onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : null} to="/advert/insert" className="nav-link" >
                                                <Icon src={icon_newa} alt={'...'} />
                                                <font color="#999999" size="2">{t("ui.header.tx_newarticle")}</font>
                                            </Link>
                                        </Item>
                                        <SplitterH />
                                        {!Userfront.accessToken() ?
                                            <Item>
                                                <Link to="/auth/login" className="nav-link" >
                                                    <Icon src={icon_user} alt={'...'} />
                                                    <font color="#999999" size="2">{t("ui.header.tx_user_login")}</font>
                                                </Link>
                                            </Item>
                                            :
                                            <Item>
                                                <Link to="/user/profile" className="nav-link" >
                                                    <Icon src={icon_user} alt={'...'} />

                                                    <font color="#999999" size="2">{decodeURIComponent(Userfront.user.data.name)}</font>
                                                </Link>
                                                <Container
                                                    style={{ marginLeft: "50px" }}
                                                >
                                                    <List>
                                                        <Item>
                                                            <Link to="/user/profile" className="nav-link"><font color="#999999" size="2">{t("ui.header.tx_user_profile")}</font></Link>
                                                        </Item>
                                                        <Item>
                                                            <Link to="/user/adverts" className="nav-link"><font color="#999999" size="2">{t("ui.header.tx_user_adverts")}</font></Link>
                                                        </Item>
                                                        <Item>
                                                            <Link to="/user/purchases" className="nav-link"><font color="#999999" size="2">{t("ui.header.tx_user_purchases")}</font></Link>
                                                        </Item>
                                                        <Item>
                                                            <Link to="/user/marks" className="nav-link"><font color="#999999" size="2">{t("ui.header.tx_user_marks")}</font></Link>
                                                        </Item>
                                                        <Item>
                                                            <Link className="nav-link" onClick={this.doLogout}><font color="#999999" size="2">{t("ui.header.tx_user_logout")}</font></Link>
                                                        </Item>
                                                    </List>
                                                </Container>
                                            </Item>
                                        }
                                        <SplitterH />
                                        <Item>
                                            <Link to="/advert/all" aria-label="Alle" className="navbar-brand">
                                                <Icon alt={'...'} src={icon_grid} />
                                                <font color="#999999" size="2"> {t("label_all")}</font>
                                            </Link>
                                        </Item>
                                        <Item>
                                            <Link to="/advert/ski" className="nav-link">
                                                <Icon alt={'...'} src={icon_ski} />
                                                <font color="#999999" size="2"> {t("label_ski")}</font>
                                            </Link>
                                        </Item>
                                    </List>
                                </Box>
                            </Drawer>
                        </WrapperLeft>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
export default withTranslation()(LinksNav)