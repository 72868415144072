import React, { Component } from 'react'
import { useParams, Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import axios from 'axios'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MultilineTooltip from '../components/MultilineTooltip';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';

import icon_del from '../res/icons/icon_del_d_v1.png';
import icon_err from '../res/icons/icon_err_r_v1.png';
import icon_main from '../res/icons/icon_main_d_v1.png';
import icon_left from '../res/icons/icon_dl_d_v1.png';
import icon_right from '../res/icons/icon_dr_d_v1.png';
import icon_info from '../res/icons/icon_info_d_v1.png';
import icon_close from '../res/icons/icon_close_d_v1.png';

const style_photo = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    height: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_photo = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_loading = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_cancel = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_cancel = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};


const ContainerTextarea = styled.div.attrs({
})`
    width: 100%;
`

const ContainerExtra = styled.div.attrs({
})`
width:55%;
`
const ContainerPhoto = styled.div.attrs({
})`
&:hover {
    cursor: pointer;
  }
`
const WrapperTextLine = styled.div.attrs({
})`
    margin-left: 5px;
    white-space: pre-wrap;

`

const WrapperLR = styled.div.attrs({
})`
    padding: 0 15px;
`
const WrapperLRc = styled.div.attrs({
})`
    padding: 5px 3%;
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
`

const WrapperLineProp = styled.div.attrs({
})`
display: flex;
flex-direction: row;
`

const WrapperLineExtras = styled.div.attrs({
})`

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
`

const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10%;
`
const WrapperLineExtraItem = styled.div.attrs({
})`
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemFlex = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 15%;
`

const WrapperLineItemLabel = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 35%;
`
const WrapperLineItemSplit2Label = styled.div.attrs({
})`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`
const WrapperLineItemSplit2Value = styled.div.attrs({
})`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemSplit2Comment = styled.div.attrs({
})`
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`

const WrapperLineItemComment = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 15%;
`
const WrapperLineItemLabelTop = styled.div.attrs({
})`
  margin-top: 5px;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`
const WrapperLineItemText = styled.div.attrs({
})`
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`
const WrapperLineItemTextarea = styled.div.attrs({
})`
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`

const WrapperLineItemValue = styled.div.attrs({
})`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemNumberP = styled.div.attrs({
})`
width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`
const WrapperLineExtraItemCC = styled.div.attrs({
})`
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`
const WrapperLineExtraItemPC = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`

const WrapperLineExtraItemC = styled.div.attrs({
})`
  width: 46%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`

const WrapperLineItemCC = styled.div.attrs({
})`
margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`

const WrapperLineItemExtrasNumber = styled.div.attrs({
})`
  margin-left: 5px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemExtrasSpace = styled.div.attrs({
})`
  width: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemExtrasQuarters = styled.div.attrs({
})`
  margin-left: 5px;
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemExtrasQuartersSmall = styled.div.attrs({
})`
  margin-left: 5px;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemExtrasQuartersLarge = styled.div.attrs({
})`
  margin-left: 5px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemPhotos = styled.div.attrs({
})`
margin-top: 15px;
width: 75%;
display: flex;
flexWrap: wrap;
justifyContent: space-around;
overflow: hidden;
`
const WrapperLineItemPriceBuffer = styled.div.attrs({
})`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`

const WrapperLineItemErrors = styled.div.attrs({
})`
margin-top: 15px;
width: 75%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
flexWrap: wrap;
justifyContent: space-around;
overflow: hidden;
`

const WrapperRadiogroup = styled.div.attrs({
})`
    margin: 5px;
`

const IconRemove = styled.img.attrs({
    hight: '20',
    width: '20'
})``

const IconD = styled.img.attrs({
    hight: '15',
    width: '15'
})``


const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
    background-color: #EBEBEB;
`
const InputTextarea = styled.textarea.attrs({
    className: 'form-control',
})`
    margin: 5px;
    background-color: #EBEBEB;
`

const InputSelect = styled.select.attrs({
    className: 'form-control',
})`
    margin: 5px;
    background-color: #EBEBEB;
    &:hover {
        cursor: pointer;
      }
`
const SelectOption = styled.option.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const WrapperGrid = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`

const BtnViewArticle = styled(BtnBlue)`
    margin: 5px 0px;
    width: 100%;
`
const BtnBackOnError = styled(BtnBlue)`
    margin: 5px;
`
const BtnHome = styled(BtnGrey)`
    margin: 5px 0px;
    width: 100%;
`
const BtnPublish = styled(BtnBlue)`
`

const BtnBack = styled(BtnWhite)`
margin-left: 15px;
`


const WrapperButtonLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const BtnDiscard = styled(BtnRed)`
margin: 5px 10px;
justify-content: right;
`

const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class Article_Update extends Component {
    constructor(props) {
        super(props)
        this.state = {
            placeholder: '',
            isMobile,

            requiredFieldsError: false,
            valueFieldsError: false,
            priceRangeExceeded: false,

            pageState: Constants.STATE_INITIAL,
            uploadingArticle: false,

            modalPhotoOpen: false,
            modalPhoto: '',
            modalCancelOpen: false,

            openUploads: [],
            photo_errors: [],
            photo_elements: [],

            baseOfTitlephotoId: '',
            photo_elements_to_delete: [],
            showInProcess: false,

            id: this.props.params.id,
            seller_type: Constants.USER_SELLER_TYPE_1,
            seller_name: '',

            confirmedUser: true,
            sellerUser: true,

            //Valuehelp
            vhbrands_ski: [],
            vhbrands_binding: [],
            vhbrands_climbingskin: [],
            vhbrands_skicrampon: [],


            //ArticleBase
            title: '',
            description: '',
            char_left: 2000,
            photo_items: [],
            price: '',
            price_original: '',
            category: Constants.ART_CAT_SKI,
            delivery: Constants.ART_DEL_1,
            delivery_country: '',
            delivery_postalcode: '',
            delivery_city: '',
            titlephoto_url: 'null',
            titlephoto_id: 'null',
            //searchString: '',

            //Article
            article_weight: '',
            article_weight_unit: Constants.UNIT_KILOGRAMM,
            article_age: '',
            article_daysontour: '',

            //Ski
            ski_brand: '',
            ski_model: '',
            ski_condition: '',
            ski_type: Constants.SKI_TYPE_1, //-> Focus Tourenarticel
            ski_aptitude: '',
            ski_length: '',
            ski_width: '',
            ski_radius: '',
            ski_rocker: '',
            ski_climbingskin: Constants.GEN_FALSE,

            //Binding
            binding_brand: '',
            binding_model: '',
            binding_condition: '',
            binding_type: Constants.BINDING_TYPE_1, //-> Focus Tourenarticel
            binding_aptitude: '',
            binding_pin: Constants.GEN_TRUE,
            binding_stopper: Constants.GEN_TRUE,
            binding_length: '',
            binding_length_adjustable: Constants.GEN_FALSE,
            binding_length_min: '',
            binding_length_max: '',
            binding_release_front: Constants.GEN_FALSE,
            binding_release_front_min: '',
            binding_release_front_max: '',
            binding_release_rear: Constants.GEN_TRUE,
            binding_release_rear_min: '',
            binding_release_rear_max: '',
            binding_skicrampon: Constants.GEN_FALSE,

            //Climbingskin
            climbingskin_brand: '',
            climbingskin_model: '',
            climbingskin_condition: '',
            climbingskin_adhesion: '',
            climbingskin_material: '',

            //Skicrampon
            skicrampon_brand: '',
            skicrampon_model: '',
            skicrampon_condition: '',
            skicrampon_width: '',

        }
    }

    //ArticleBase
    handleChangeInputTitle = async event => {
        const title = event.target.value
        document.getElementById("ipTitle").style.border = ''
        this.setState({ title })
    }
    handleChangeInputDescription = async event => {
        const description = event.target.value
        document.getElementById("ipDescription").style.border = ''
        this.setState({ description })
        const char_left = 2000 - description.length
        this.setState({ char_left })
    }

    handleChangeInputPhotos = async event => {
        const anchor = this;

        this.setState({ photo_errors: [] })

        var errors = [];

        var photo_count = new Number(this.state.photo_items.length);

        for (let i = 0; i < event.target.files.length; i++) {

            var img_file = event.target.files[i];
            var file_name = img_file.name.split(".");
            var img_name = file_name[file_name.length - 2];

            var img_tag = img_name + '_';
            let charset = "abcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0, n = charset.length; i < 6; ++i) {
                img_tag += charset.charAt(Math.floor(Math.random() * n));
            }

            if (photo_count < Constants.PHOTO_AMOUNT_LIMIT) {
                if (event.target.files[i].size < Constants.PHOTO_SIZE_LIMIT) {

                    photo_count += 1;

                    this.cropImage(anchor, img_name, img_tag, img_file);

                } else {
                    errors.push({ tag: img_tag, name: img_name + '.' + file_name[file_name.length - 1], error: Constants.ERROR_IMAGE_SIZE });
                }
            } else {
                errors.push({ tag: img_tag, name: img_name + '.' + file_name[file_name.length - 1], error: Constants.ERROR_IMAGE_AMOUNT });
            }
        }

        this.setState({ photo_errors: errors })
    }

    async cropImage(anchor, name, tag, file) {

        var image = new Image();
        image.onload = function () {
            if (typeof (name) != "undefined") {
                var c = document.createElement('canvas'),
                    ctx = c.getContext("2d"),
                    oc = document.createElement('canvas'),
                    octx = oc.getContext('2d');

                var org_height = this.height;
                var org_width = this.width;

                let new_width = this.width;
                let new_height = this.height;
                if (org_width > org_height) {
                    new_height = new_width / Constants.PHOTO_WIDTH * Constants.PHOTO_HEIGHT
                } else {
                    new_width = new_height / Constants.PHOTO_HEIGHT * Constants.PHOTO_WIDTH
                }
                c.width = new_width
                c.height = new_height

                let offset_width = 0
                let offset_hight = 0
                if (this.width > this.height) {
                    offset_hight = (new_height - this.height) / 2
                } else {
                    offset_width = (new_width - this.width) / 2
                }

                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, new_width, new_height);
                ctx.drawImage(image, offset_width, offset_hight);

                let target_width = Constants.PHOTO_WIDTH;
                let target_height = Constants.PHOTO_HEIGHT;
                oc.width = target_width;
                oc.height = target_height;
                octx.imageSmoothingQuality = 'high';
                octx.drawImage(c, 0, 0, target_width, target_height);

                var oc_url = oc.toDataURL("image/jpeg", Constants.PHOTO_QUALITY);

                anchor.state.photo_items.push({
                    name: name, tag: tag, path: oc_url, url: oc_url, org_height: org_height, org_width: org_width, new: true
                });
                anchor.setState({ placeholder: 'holding' })
            }
        }
        image.src = URL.createObjectURL(file);
    }

    handleChangeInputPrice = async event => {
        const price = event.target.value
        document.getElementById("ipPrice").style.border = ''
        this.setState({ price })
        if (!isNaN(Number(price))) {
            if (price > Constants.ART_PRICE_MAX) {
                this.setState({ priceRangeExceeded: true })
            } else {
                this.setState({ priceRangeExceeded: false })
            }
        }
    }

    handleChangeInputPriceOriginal = async event => {
        const price_original = event.target.value
        document.getElementById("ipPriceOriginal").style.border = ''
        this.setState({ price_original })
    }
    handleChangeInputCategory = async event => {
        const category = event.target.value
        document.getElementById("ipCategory").style.border = ''
        this.setState({ category })
    }
    handleChangeInputDelivery = async event => {
        const delivery = event.target.value
        this.setState({ delivery })
    }
    handleChangeInputDeliveryCountry = async event => {
        const delivery_country = event.target.value
        this.setState({ delivery_country })
    }
    handleChangeInputDeliveryPostalcode = async event => {
        const delivery_postalcode = event.target.value
        document.getElementById("ipDeliveryPostalcode").style.border = ''
        this.setState({ delivery_postalcode })
    }
    handleChangeInputDeliveryCity = async event => {
        const delivery_city = event.target.value
        this.setState({ delivery_city })
    }

    //Article
    handleChangeInputArticle_weight = async event => {
        const article_weight = event.target.value
        document.getElementById("ipArticleWeight").style.border = ''
        this.setState({ article_weight })
    }
    handleChangeInputArticle_weight_unit = async event => {
        const article_weight_unit = event.target.value
        this.setState({ article_weight_unit })
    }
    handleChangeInputArticle_age = async event => {
        const article_age = event.target.value
        document.getElementById("ipArticleAge").style.border = ''
        this.setState({ article_age })
    }
    handleChangeInputArticle_daysontour = async event => {
        const article_daysontour = event.target.value
        document.getElementById("ipArticleDaysontour").style.border = ''
        this.setState({ article_daysontour })
    }

    //Ski
    handleChangeInputSki_brand = async event => {
        const ski_brand = event.target.value
        document.getElementById("ipSkiBrand").style.border = ''
        this.setState({ ski_brand })
    }
    handleChangeInputSki_model = async event => {
        const ski_model = event.target.value
        document.getElementById("ipSkiModel").style.border = ''
        this.setState({ ski_model })
    }
    handleChangeInputSki_condition = async event => {
        const ski_condition = event.target.value
        document.getElementById("ipSkiCondition").style.border = ''
        this.setState({ ski_condition })
    }
    handleChangeInputSki_aptitude = async event => {
        const ski_aptitude = event.target.value
        this.setState({ ski_aptitude })
    }
    handleChangeInputSki_length = async event => {
        const ski_length = event.target.value
        document.getElementById("ipSkiLength").style.border = ''
        this.setState({ ski_length })
    }
    handleChangeInputSki_width = async event => {
        const ski_width = event.target.value
        document.getElementById("ipSkiWidth").style.border = ''
        this.setState({ ski_width })
    }
    handleChangeInputSki_radius = async event => {
        const ski_radius = event.target.value
        this.setState({ ski_radius })
    }
    handleChangeInputSki_rocker = async event => {
        const ski_rocker = event.target.value
        this.setState({ ski_rocker })
    }
    handleChangeInputSki_climbingskin = async event => {
        const ski_climbingskin = event.target.value
        this.setState({ ski_climbingskin })
    }

    //Binding
    handleChangeInputBinding_brand = async event => {
        const binding_brand = event.target.value
        document.getElementById("ipBindingBrand").style.border = ''
        this.setState({ binding_brand })
    }
    handleChangeInputBinding_model = async event => {
        const binding_model = event.target.value
        document.getElementById("ipBindingModel").style.border = ''
        this.setState({ binding_model })
    }
    handleChangeInputBinding_condition = async event => {
        const binding_condition = event.target.value
        document.getElementById("ipBindingCondition").style.border = ''
        this.setState({ binding_condition })
    }
    handleChangeInputBinding_aptitude = async event => {
        const binding_aptitude = event.target.value
        this.setState({ binding_aptitude })
    }
    handleChangeInputBinding_pin = async event => {
        const binding_pin = event.target.value
        this.setState({ binding_pin })
    }
    handleChangeInputBinding_stopper = async event => {
        const binding_stopper = event.target.value
        this.setState({ binding_stopper })
    }
    handleChangeInputBinding_length = async event => {
        const binding_length = event.target.value
        document.getElementById("ipBindingLength").style.border = ''
        this.setState({ binding_length })
    }
    handleChangeInputbinding_length_min = async event => {
        const binding_length_min = event.target.value
        document.getElementById("ipBindingLengthPos").style.border = ''
        this.setState({ binding_length_min })
    }
    handleChangeInputbinding_length_max = async event => {
        const binding_length_max = event.target.value
        document.getElementById("ipBindingLengthNeg").style.border = ''
        this.setState({ binding_length_max })
    }


    handleChangeInputBinding_release_front = async event => {
        const binding_release_front = event.target.value
        this.setState({ binding_release_front })
    }
    handleChangeInputBinding_release_front_min = async event => {
        const binding_release_front_min = event.target.value
        document.getElementById("ipBindingReleaseFrontMin").style.border = ''
        this.setState({ binding_release_front_min })
    }
    handleChangeInputBinding_release_front_max = async event => {
        const binding_release_front_max = event.target.value
        document.getElementById("ipBindingReleaseFrontMax").style.border = ''
        this.setState({ binding_release_front_max })
    }
    handleChangeInputBinding_release_rear = async event => {
        const binding_release_rear = event.target.value
        this.setState({ binding_release_rear })
    }
    handleChangeInputBinding_release_rear_min = async event => {
        const binding_release_rear_min = event.target.value
        document.getElementById("ipBindingReleaseRearMin").style.border = ''
        this.setState({ binding_release_rear_min })
    }
    handleChangeInputBinding_release_rear_max = async event => {
        const binding_release_rear_max = event.target.value
        document.getElementById("ipBindingReleaseRearMax").style.border = ''
        this.setState({ binding_release_rear_max })
    }

    handleChangeInputBinding_skicrampon = async event => {
        const binding_skicrampon = event.target.value
        this.setState({ binding_skicrampon })
    }

    //Climbingskin
    handleChangeInputClimbingskin_brand = async event => {
        const climbingskin_brand = event.target.value
        document.getElementById("ipClimbingskinBrand").style.border = ''
        this.setState({ climbingskin_brand })
    }
    handleChangeInputClimbingskin_model = async event => {
        const climbingskin_model = event.target.value
        this.setState({ climbingskin_model })
    }
    handleChangeInputClimbingskin_condition = async event => {
        const climbingskin_condition = event.target.value
        document.getElementById("ipClimbingskinCondition").style.border = ''
        this.setState({ climbingskin_condition })
    }
    handleChangeInputClimbingskin_adhesion = async event => {
        const climbingskin_adhesion = event.target.value
        this.setState({ climbingskin_adhesion })
    }
    handleChangeInputClimbingskin_material = async event => {
        const climbingskin_material = event.target.value
        this.setState({ climbingskin_material })
    }


    //Skicrampon
    handleChangeInputSkicrampon_brand = async event => {
        const skicrampon_brand = event.target.value
        document.getElementById("ipSkicramponBrand").style.border = ''
        this.setState({ skicrampon_brand })
    }
    handleChangeInputSkicrampon_model = async event => {
        const skicrampon_model = event.target.value
        this.setState({ skicrampon_model })
    }
    handleChangeInputSkicrampon_condition = async event => {
        const skicrampon_condition = event.target.value
        document.getElementById("ipSkicramponCondition").style.border = ''
        this.setState({ skicrampon_condition })
    }
    handleChangeInputSkicrampon_width = async event => {
        const skicrampon_width = event.target.value
        document.getElementById("ipSkicramponWidth").style.border = ''
        this.setState({ skicrampon_width })
    }

    //Seller
    handleChangeInputSeller_name = async event => {
        const seller_name = event.target.value
        document.getElementById("ipSellerName").style.border = ''
        this.setState({ seller_name })
    }
    handleChangeInputSeller_type = async event => {
        const seller_type = event.target.value
        this.setState({ seller_type })
    }

    cropUploadTitlephoto = async (anchor, uuid, item, payload_data) => {

        const uploadToken = {}

        await api.getUploadToken().then(res => {
            uploadToken.signature = res.data.signature;
            uploadToken.expire = res.data.expire;
            uploadToken.token = res.data.token;
        }).catch(error => {
            //Todo handle Error
        })

        var file = item;
        var image = new Image();
        if (item.new === false) {
            image.crossOrigin = "anonymous"
        }

        image.onload = function () {
            var c = document.createElement('canvas'),
                ctx = c.getContext("2d"),
                oc = document.createElement('canvas'),
                octx = oc.getContext('2d');

            let org_width = file.org_width;
            let org_height = file.org_height;

            let new_width = org_width;
            let new_height = org_height;

            let scale = 0;

            if (org_width > org_height) {
                scale = Constants.PHOTO_WIDTH / org_width
            } else {
                scale = Constants.PHOTO_HEIGHT / org_height
            }

            if (org_width > org_height) {
                new_width = org_height * scale
                new_height = new_width
            } else {
                new_height = org_width * scale
                new_width = new_height
            }

            c.width = new_width
            c.height = new_height

            let offset_width = - (Constants.PHOTO_WIDTH - new_width) / 2
            let offset_hight = - (Constants.PHOTO_HEIGHT - new_height) / 2

            ctx.drawImage(image, offset_width, offset_hight);

            let target_width = Constants.TITLEPHOTO_WIDTH;
            let target_height = Constants.TITLEPHOTO_HEIGHT;
            oc.width = target_width;
            oc.height = target_height;
            octx.imageSmoothingQuality = 'high';
            octx.drawImage(c, 0, 0, target_width, target_height);

            var oc_url = oc.toDataURL("image/jpeg", Constants.PHOTO_QUALITY);
            var oc_blob = atob(oc_url.split(',')[1]);
            var oc_array = [];
            for (var i = 0; i < oc_blob.length; i++) {
                oc_array.push(oc_blob.charCodeAt(i));
            }
            var oc_file = new Blob([new Uint8Array(oc_array)], { type: 'image/jpeg' });

            var folder = '';
            var publicKey = '';
            if (process.env.NODE_ENV !== 'production') {
                folder = Constants.DEV_IMAGEKIT_FOLDER;
                publicKey = Constants.DEV_IMAGEKIT_PUBLIC_KEY;
            } else {
                folder = process.env.REACT_APP_IMAGEKIT_FOLDER;
                publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
            }

            let formData = new FormData();
            formData.append("file", oc_file);
            formData.append("publicKey", publicKey);
            formData.append("signature", uploadToken.signature);
            formData.append("expire", uploadToken.expire);
            formData.append("token", uploadToken.token);
            formData.append("fileName", 'titlephoto');
            formData.append("folder", folder + '/' + uuid + '/title');

            var payload = formData;

            api.uploadPhoto(payload).then(res => {
                anchor.state.openUploads.pop();

                const titlephoto_url = res.data.url;
                const titlephoto_id = res.data.fileId;
                anchor.state.titlephoto_url = titlephoto_url;
                anchor.state.titlephoto_id = titlephoto_id;

                if (anchor.state.openUploads.length === 0) {
                    anchor.prepUploadArticle(anchor, payload_data);
                }

            }).catch(error => {
                //Todo handle Error
            })

        }
        if (item.new === false) {
            image.src = file.url;
        } else {
            image.src = file.path;
        }

    }

    uploadPhoto = async (anchor, uuid, item, index, payload_data) => {
        const uploadToken = {}

        await api.getUploadToken().then(res => {
            uploadToken.signature = res.data.signature;
            uploadToken.expire = res.data.expire;
            uploadToken.token = res.data.token;
        }).catch(error => {
            //Todo handle Error
        })

        var oc_url = item.path;
        var oc_blob = atob(oc_url.split(',')[1]);
        var oc_array = [];
        for (var i = 0; i < oc_blob.length; i++) {
            oc_array.push(oc_blob.charCodeAt(i));
        }
        var oc_file = new Blob([new Uint8Array(oc_array)], { type: 'image/jpeg' });

        var folder = '';
        var publicKey = '';
        if (process.env.NODE_ENV !== 'production') {
            folder = Constants.DEV_IMAGEKIT_FOLDER;
            publicKey = Constants.DEV_IMAGEKIT_PUBLIC_KEY;
        } else {
            folder = process.env.REACT_APP_IMAGEKIT_FOLDER;
            publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY;
        }

        let formData = new FormData();
        formData.append("file", oc_file);
        formData.append("publicKey", publicKey);
        formData.append("signature", uploadToken.signature);
        formData.append("expire", uploadToken.expire);
        formData.append("token", uploadToken.token);
        formData.append("fileName", item.name);
        formData.append("folder", folder + '/' + uuid);

        var payload = formData;

        await api.uploadPhoto(payload).then(res => {
            anchor.state.openUploads.pop();

            anchor.state.photo_elements.forEach(function (element) {
                if (element.index === index) {
                    element.url = res.data.url;
                    element.id = res.data.fileId;
                    element.path = res.data.filePath;
                    element.name = res.data.name;
                    element.orginal_height = item.org_height;
                    element.orginal_width = item.org_width;
                }
            });

            if (anchor.state.openUploads.length === 0) {
                anchor.prepUploadArticle(anchor, payload_data);
            }

        }).catch(error => {

        })
    }

    prepUploadArticle = async (anchor, payload) => {
        payload.titlephoto_url = this.state.titlephoto_url;
        payload.titlephoto_id = this.state.titlephoto_id;

        payload.photo_elements = this.state.photo_elements;

        anchor.uploadArticle(anchor, payload);
    }

    doUpdate = async () => {
        const anchor = this;

        var {
            //ArticleBase
            title, description, price, price_original, photo_items, category, delivery, delivery_country, delivery_postalcode, delivery_city,
            //Article
            article_weight, article_age, article_weight_unit, article_daysontour,
            //Ski
            ski_brand, ski_model, ski_condition, ski_type, ski_aptitude, ski_length, ski_width, ski_radius, ski_rocker, ski_climbingskin,
            //Binding
            binding_brand, binding_model, binding_condition, binding_type, binding_aptitude, binding_pin, binding_stopper, binding_length, binding_length_adjustable, binding_length_min, binding_length_max,
            binding_release_front, binding_release_front_min, binding_release_front_max, binding_release_rear, binding_release_rear_min, binding_release_rear_max,
            binding_skicrampon,
            //Climbingskin
            climbingskin_brand, climbingskin_model, climbingskin_condition, climbingskin_adhesion, climbingskin_material,
            //Skicrampon
            skicrampon_brand, skicrampon_model, skicrampon_condition, skicrampon_width,

            //Seller
            seller_type, seller_name
        } = this.state

        //Check required fields
        var skip = false;
        if (title === '') { document.getElementById("ipTitle").style.border = '2px solid red'; skip = true; }
        if (category === '') { document.getElementById("ipCategory").style.border = '2px solid red'; skip = true; }
        if (description === '') { document.getElementById("ipDescription").style.border = '2px solid red'; skip = true; }
        if (price === '') { document.getElementById("ipPrice").style.border = '2px solid red'; skip = true; }
        if (delivery === Constants.ART_DEL_1 || delivery === Constants.ART_DEL_3) { if (delivery_postalcode === '') { document.getElementById("ipDeliveryPostalcode").style.border = '2px solid red'; skip = true; } }
        if (seller_name === '') { document.getElementById("ipSellerName").style.border = '2px solid red'; skip = true; }
        switch (category) {
            case Constants.ART_CAT_SKI:
                if (ski_brand === '') { document.getElementById("ipSkiBrand").style.border = '2px solid red'; skip = true; }
                if (ski_model === '') { document.getElementById("ipSkiModel").style.border = '2px solid red'; skip = true; }
                if (ski_length === '') { document.getElementById("ipSkiLength").style.border = '2px solid red'; skip = true; }
                if (ski_width === '') { document.getElementById("ipSkiWidth").style.border = '2px solid red'; skip = true; }
                if (ski_condition === '') { document.getElementById("ipSkiCondition").style.border = '2px solid red'; skip = true; }
                if (binding_brand === '') { document.getElementById("ipBindingBrand").style.border = '2px solid red'; skip = true; }
                if (binding_model === '') { document.getElementById("ipBindingModel").style.border = '2px solid red'; skip = true; }
                if (binding_condition === '') { document.getElementById("ipBindingCondition").style.border = '2px solid red'; skip = true; }
                if (binding_length === '') { document.getElementById("ipBindingLength").style.border = '2px solid red'; skip = true; }
                if (ski_climbingskin === Constants.GEN_TRUE && climbingskin_condition === '') { document.getElementById("ipClimbingskinCondition").style.border = '2px solid red'; skip = true; }
                if (binding_skicrampon === Constants.GEN_TRUE && skicrampon_condition === '') { document.getElementById("ipSkicramponCondition").style.border = '2px solid red'; skip = true; }
                if (ski_climbingskin === Constants.GEN_TRUE && climbingskin_brand === '') { document.getElementById("ipClimbingskinBrand").style.border = '2px solid red'; skip = true; }
                if (binding_skicrampon === Constants.GEN_TRUE && skicrampon_brand === '') { document.getElementById("ipSkicramponBrand").style.border = '2px solid red'; skip = true; }
                break;
            default:
                break;
        }
        if (skip) {
            this.setState({ requiredFieldsError: true });
            return;
        }
        this.setState({ requiredFieldsError: false });


        //Check fields types
        if (price === '') { price = -1 } else {
            price = price.replace(',', '.')
            if (isNaN(Number(price))) { document.getElementById("ipPrice").style.border = '2px solid red'; var skip = true; } else {
                if (!Number.isSafeInteger(Number(price))) { document.getElementById("ipPrice").style.border = '2px solid red'; var skip = true; }
            }
        }
        if (price_original === '') { price_original = -1 } else {
            price_original = price_original.replace(',', '.')
            if (isNaN(Number(price_original))) { document.getElementById("ipPriceOriginal").style.border = '2px solid red'; var skip = true; } else {
                if (!Number.isSafeInteger(Number(price_original))) { document.getElementById("ipPriceOriginal").style.border = '2px solid red'; var skip = true; }
            }
        }
        if (article_age === '') { article_age = -1 } else {
            article_age = article_age.replace(',', '.')
            if (isNaN(Number(article_age))) { document.getElementById("ipArticleAge").style.border = '2px solid red'; var skip = true; }
        }
        if (article_daysontour === '') { article_daysontour = -1 } else {
            article_daysontour = article_daysontour.replace(',', '.')
            if (isNaN(Number(article_daysontour))) { document.getElementById("ipArticleDaysontour").style.border = '2px solid red'; var skip = true; } else {
                if (!Number.isSafeInteger(Number(article_daysontour))) { document.getElementById("ipArticleDaysontour").style.border = '2px solid red'; var skip = true; }
            }
        }
        if (article_weight === '') { article_weight = -1 } else {
            article_weight = article_weight.replace(',', '.')
            if (isNaN(Number(article_weight))) { document.getElementById("ipArticleWeight").style.border = '2px solid red'; var skip = true; }
        }
        if (ski_length === '') { ski_length = -1 } else {
            ski_length = ski_length.replace(',', '.')
            if (isNaN(Number(ski_length))) { document.getElementById("ipSkiLength").style.border = '2px solid red'; var skip = true; }
        }
        if (ski_width === '') { ski_width = -1 } else {
            ski_width = ski_width.replace(',', '.')
            if (isNaN(Number(ski_width))) { document.getElementById("ipSkiWidth").style.border = '2px solid red'; var skip = true; }
        }
        if (ski_radius === '') { ski_radius = -1 } else {
            ski_radius = ski_radius.replace(',', '.')
            if (isNaN(Number(ski_radius))) { document.getElementById("ipSkiRadius").style.border = '2px solid red'; var skip = true; }
        }
        if (binding_length === '') { binding_length = -1 } else {
            binding_length = binding_length.replace(',', '.')
            if (isNaN(Number(binding_length))) { document.getElementById("ipBindingLength").style.border = '2px solid red'; var skip = true; }
        }
        if (binding_length_min === '') { binding_length_min = -1 } else {
            binding_length_min = binding_length_min.replace(',', '.')
            if (isNaN(Number(binding_length_min))) { document.getElementById("ipBindingLengthPos").style.border = '2px solid red'; var skip = true; }
        }
        if (binding_length_max === '') { binding_length_max = -1 } else {
            binding_length_max = binding_length_max.replace(',', '.')
            if (isNaN(Number(binding_length_max))) { document.getElementById("ipBindingLengthNeg").style.border = '2px solid red'; var skip = true; }
        }

        if (binding_release_rear === Constants.GEN_TRUE) {
            if (binding_release_rear_min === '') { binding_release_rear_min = -1 } else {
                binding_release_rear_min = binding_release_rear_min.replace(',', '.')
                if (isNaN(Number(binding_release_rear_min))) { document.getElementById("ipBindingReleaseRearMin").style.border = '2px solid red'; var skip = true; } else {
                    if (!Number.isSafeInteger(Number(binding_release_rear_min))) { document.getElementById("ipBindingReleaseRearMin").style.border = '2px solid red'; var skip = true; }
                }
            }
            if (binding_release_rear_max === '') { binding_release_rear_max = -1 } else {
                binding_release_rear_max = binding_release_rear_max.replace(',', '.')
                if (isNaN(Number(binding_release_rear_max))) { document.getElementById("ipBindingReleaseRearMax").style.border = '2px solid red'; var skip = true; } else {
                    if (!Number.isSafeInteger(Number(binding_release_rear_max))) { document.getElementById("ipBindingReleaseRearMax").style.border = '2px solid red'; var skip = true; }
                }
            }
        } else {
            binding_release_rear_min = -1;
            binding_release_rear_max = -1;
        }

        if (binding_release_front === Constants.GEN_TRUE) {
            if (binding_release_front_min === '') { binding_release_front_min = -1 } else {
                binding_release_front_min = binding_release_front_min.replace(',', '.')
                if (isNaN(Number(binding_release_front_min))) { document.getElementById("ipBindingReleaseFrontMin").style.border = '2px solid red'; var skip = true; } else {
                    if (!Number.isSafeInteger(Number(binding_release_front_min))) { document.getElementById("ipBindingReleaseFrontMin").style.border = '2px solid red'; var skip = true; }
                }
            }
            if (binding_release_front_max === '') { binding_release_front_max = -1 } else {
                binding_release_front_max = binding_release_front_max.replace(',', '.')
                if (isNaN(Number(binding_release_front_max))) { document.getElementById("ipBindingReleaseFrontMax").style.border = '2px solid red'; var skip = true; } else {
                    if (!Number.isSafeInteger(Number(binding_release_front_max))) { document.getElementById("ipBindingReleaseFrontMax").style.border = '2px solid red'; var skip = true; }
                }
            }
        } else {
            binding_release_front_min = -1;
            binding_release_front_max = -1;
        }

        if (ski_climbingskin === Constants.GEN_TRUE) {
            //Room for isNaN from cast Number checks
        } else {
            climbingskin_brand = '';
            climbingskin_model = '';
            climbingskin_condition = '';
            climbingskin_adhesion = '';
        }

        if (binding_skicrampon === Constants.GEN_TRUE) {
            if (skicrampon_width === '') { skicrampon_width = -1 } else {
                skicrampon_width = skicrampon_width.replace(',', '.')
                if (isNaN(Number(skicrampon_width))) { document.getElementById("ipSkicramponWidth").style.border = '2px solid red'; var skip = true; }
            }
        } else {
            skicrampon_brand = '';
            skicrampon_model = '';
            skicrampon_condition = '';
            skicrampon_width = -1;
        }

        if (skip) {
            this.setState({ valueFieldsError: true });
            return;
        }

        //Check hard limits 
        if (Number(price) > Constants.ART_PRICE_MAX) {
            document.getElementById("ipPrice").style.border = '2px solid red';
            skip = true;
        }
        if (skip) {
            this.setState({ valueFieldsError: true });
            return;
        }
        this.setState({ valueFieldsError: false });

        //Conversions
        if (article_weight !== -1 && article_weight_unit === Constants.UNIT_KILOGRAMM) {
            article_weight = article_weight * 1000;
        }

        if (Number(binding_release_front_min) > Number(binding_release_front_max) && Number(binding_release_front_max) !== -1) {
            let x = binding_release_front_max
            binding_release_front_max = binding_release_front_min
            binding_release_front_min = x
        }
        if (Number(binding_release_rear_min) > Number(binding_release_rear_max) && Number(binding_release_rear_max) !== -1) {
            let x = binding_release_rear_max
            binding_release_rear_max = binding_release_rear_min
            binding_release_rear_min = x
        }

        if (binding_length_min === binding_length) {
            binding_length_min = -1
        }
        if (binding_length_max === binding_length) {
            binding_length_max = -1
        }

        if (binding_length_min === -1 && binding_length_max !== -1) {
            binding_length_min = binding_length
        }
        if (binding_length_min !== -1 && binding_length_max === -1) {
            binding_length_max = binding_length
        }
        if (binding_length_min !== -1 && binding_length_max !== -1) {
            binding_length_adjustable = Constants.GEN_TRUE
        }

        this.setState({ uploadingArticle: true });
        while (this.state.photo_elements.length > 0) {
            this.state.photo_elements.pop();
        }

        var titlephoto_url = this.state.titlephoto_url;
        var titlephoto_id = this.state.titlephoto_id;
        var photo_elements = [];
        var photo_ids_to_delete = []
        this.state.photo_elements_to_delete.forEach(photo_element_to_delete => {
            photo_ids_to_delete.push(photo_element_to_delete.id);
        });

        var payload = {}

        switch (category) {
            case Constants.ART_CAT_SKI:
                var searchString = title + " " + description + " " + ski_brand + " " + ski_model + " " + binding_brand + " " + binding_model;
                payload = {
                    title, description, titlephoto_url, titlephoto_id, photo_elements, photo_ids_to_delete, price, price_original, category, delivery, delivery_country, delivery_postalcode, delivery_city, searchString,  //tags,
                    article_weight, article_age, article_daysontour,
                    ski_brand, ski_model, ski_condition, ski_type, ski_aptitude, ski_length, ski_width, ski_radius, ski_rocker, ski_climbingskin,
                    binding_brand, binding_model, binding_condition, binding_type, binding_aptitude, binding_pin, binding_stopper, binding_length, binding_length_adjustable, binding_length_min, binding_length_max, binding_skicrampon,
                    binding_release_front, binding_release_front_min, binding_release_front_max, binding_release_rear, binding_release_rear_min, binding_release_rear_max,
                    climbingskin_brand, climbingskin_model, climbingskin_condition, climbingskin_adhesion, climbingskin_material,
                    skicrampon_brand, skicrampon_model, skicrampon_condition, skicrampon_width,
                    seller_name, seller_type
                }
                break;
            default:
                break;
        }

        const uuid = Userfront.user.userUuid;

        this.setState({ uploadingArticle: true });

        if (photo_items.length === 0) {
            //Update Payload; No Photos, reset Titlephoto

            this.state.titlephoto_url = 'null';
            this.state.titlephoto_id = 'null';
            this.state.photo_elements = []; //not needed

            this.prepUploadArticle(anchor, payload);

            return;
        }

        var newPhotos = false;
        photo_items.forEach(item => {
            if (item.new) {
                newPhotos = true;
            }
        });

        var newTitlephoto = false;
        if (this.state.baseOfTitlephotoId !== photo_items[0].tag) {
            newTitlephoto = true;
        }

        if (!newTitlephoto && !newPhotos) {
            //Update Payload; Photos may have been reorderd or deleted

            photo_items.forEach(item => {
                this.state.photo_elements.push({
                    url: item.url,
                    id: item.tag,
                    path: item.path,
                    name: item.name,
                    orginal_height: item.org_height,
                    orginal_width: item.org_width
                });
            });

            this.prepUploadArticle(anchor, payload);

            return;
        }

        if (newTitlephoto && !newPhotos) {
            //Update Payload; Photos may have been reorderd or deleted /W new Titlephoto

            photo_items.forEach(item => {
                this.state.photo_elements.push({
                    url: item.url,
                    id: item.tag,
                    path: item.path,
                    name: item.name,
                    orginal_height: item.org_height,
                    orginal_width: item.org_width
                });
            });

            payload.photo_ids_to_delete.push(payload.titlephoto_id);
            this.state.openUploads.push({ a: 1 });
            this.cropUploadTitlephoto(anchor, uuid, photo_items[0], payload);
            return;
        }


        if (!newTitlephoto && newPhotos) {
            //Update Payload; New Photos need to be uploaded

            for (let i = 0; i < photo_items.length; i++) {
                if (photo_items[i].new === true) {
                    this.state.openUploads.push({ a: 1 });
                    this.state.photo_elements.push({ index: i });
                    this.uploadPhoto(anchor, uuid, photo_items[i], i, payload);
                } else {
                    this.state.photo_elements.push({
                        index: i,
                        url: photo_items[i].url,
                        id: photo_items[i].tag,
                        path: photo_items[i].path,
                        name: photo_items[i].name,
                        orginal_height: photo_items[i].org_height,
                        orginal_width: photo_items[i].org_width
                    });
                }
            }

            return;
        }

        if (newTitlephoto && newPhotos) {
            //Update payload and rm all del add all new photos and sort order of photos ++ new titlephoto!

            payload.photo_ids_to_delete.push(payload.titlephoto_id);
            this.state.openUploads.push({ a: 1 });
            this.cropUploadTitlephoto(anchor, uuid, photo_items[0], payload);

            for (let i = 0; i < photo_items.length; i++) {
                if (photo_items[i].new === true) {
                    this.state.openUploads.push({ a: 1 });
                    this.state.photo_elements.push({ index: i });
                    this.uploadPhoto(anchor, uuid, photo_items[i], i, payload);
                } else {
                    this.state.photo_elements.push({
                        index: i,
                        url: photo_items[i].url,
                        id: photo_items[i].tag,
                        path: photo_items[i].path,
                        name: photo_items[i].name,
                        orginal_height: photo_items[i].org_height,
                        orginal_width: photo_items[i].org_width
                    });
                }
            }

            return;
        }


        //Error 
        this.setState({
            uploadingArticle: false,
            pageState: Constants.STATE_ERROR
        })

    }

    uploadArticle = async (anchor, payload) => {
        const { id } = this.state;
        await api.updateArticleById(id, payload).then(res => {
            anchor.setState({
                uploadingArticle: false,
                pageState: Constants.STATE_SUCCESS,
                new_id: res.data.id

            })

        }).catch(error => {
            anchor.setState({
                uploadingArticle: false,
                pageState: Constants.STATE_ERROR
            })
        })
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        const anchor = this;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        try {
            await this.load_vhbrands_ski();
            await this.load_vhbrands_binding();
            await this.load_vhbrands_climbingskin();
            await this.load_vhbrands_skicrampon();
        } catch {
            //Todo Error haneling
        }

        try { document.getElementById('ipCategory').disabled = true } catch { }

        if (!Userfront.accessToken()) {
            return;
        }

        const { id } = this.state;
        var view = 'false';
        var uuid = 'anonymous';
        const article = await api.getArticleById(id, view, uuid)
            .catch(error => {
                anchor.setState({
                    pageState: Constants.STATE_NODATA
                })
            })

        this.setState({
            //ArticleBase
            title: article.data.data.title,
            description: article.data.data.description,
            char_left: 2000 - article.data.data.description.length,
            price: article.data.data.price.toString(),
            category: article.data.data.category,
            delivery: article.data.data.delivery,

            //Seller
            seller_name: article.data.data.seller_name,
            seller_type: article.data.data.seller_type
        })

        document.getElementById('ipCategory').value = article.data.data.category
        document.getElementById('ipCategory').disabled = true
        document.getElementById('ipDelivery').value = article.data.data.delivery;

        if (typeof (article.data.data.price_original) != "undefined" && article.data.data.price_original != -1) {
            this.setState({ price_original: article.data.data.price_original.toString() })
        }

        var itemsArray = []
        let titlephoto = true;
        article.data.data.photo_elements.forEach(element => {
            if (titlephoto) {
                titlephoto = false;
                this.setState({ baseOfTitlephotoId: element.id })
            }

            itemsArray.push({
                name: element.name,
                tag: element.id,
                url: element.url,
                path: element.path,
                org_height: element.orginal_height,
                org_width: element.orginal_width,
                new: false
            });
        });

        this.setState({ photo_items: itemsArray })

        if (typeof (article.data.data.titlephoto_url) != "undefined" && article.data.data.titlephoto_url != 'null') {
            this.setState({ titlephoto_url: article.data.data.titlephoto_url })
        }
        if (typeof (article.data.data.titlephoto_id) != "undefined" && article.data.data.titlephoto_id != 'null') {
            this.setState({ titlephoto_id: article.data.data.titlephoto_id })
        }


        //Delivery
        if (article.data.data.delivery === Constants.ART_DEL_1 || article.data.data.delivery === Constants.ART_DEL_3) {
            if (typeof (article.data.data.delivery_country) != "undefined" && article.data.data.delivery_country != '') {
                this.setState({ delivery_country: article.data.data.delivery_country })
                document.getElementById('ipDeliveryCountry').value = article.data.data.delivery_country;
            }
            if (typeof (article.data.data.delivery_postalcode) != "undefined" && article.data.data.delivery_postalcode != '') {
                this.setState({ delivery_postalcode: article.data.data.delivery_postalcode })
            }
            if (typeof (article.data.data.delivery_city) != "undefined" && article.data.data.delivery_city != '') {
                this.setState({ delivery_city: article.data.data.delivery_city })
            }
        }

        //Details
        if (typeof (article.data.data.article_weight) != "undefined" && article.data.data.article_weight != -1) {
            this.setState({ article_weight: article.data.data.article_weight.toString() })
            this.setState({ article_weight_unit: Constants.UNIT_GRAMM })
            document.getElementById('ipArticleWeightUnit').value = Constants.UNIT_GRAMM;
        }
        if (typeof (article.data.data.article_age) != "undefined" && article.data.data.article_age != -1) {
            this.setState({ article_age: article.data.data.article_age.toString() })
        }
        if (typeof (article.data.data.article_daysontour) != "undefined" && article.data.data.article_daysontour != -1) {
            this.setState({ article_daysontour: article.data.data.article_daysontour.toString() })
        }

        switch (article.data.data.category) {
            case Constants.ART_CAT_SKI:
                //Ski
                if (typeof (article.data.data.ski_brand) != "undefined" && article.data.data.ski_brand != '') {
                    this.setState({ ski_brand: article.data.data.ski_brand })
                    document.getElementById('ipSkiBrand').value = article.data.data.ski_brand;
                }
                if (typeof (article.data.data.ski_model) != "undefined" && article.data.data.ski_model != '') {
                    this.setState({ ski_model: article.data.data.ski_model })
                }
                if (typeof (article.data.data.ski_condition) != "undefined" && article.data.data.ski_condition != '') {
                    this.setState({ ski_condition: article.data.data.ski_condition })
                    document.getElementById('ipSkiCondition').value = article.data.data.ski_condition;
                }
                if (typeof (article.data.data.ski_aptitude) != "undefined" && article.data.data.ski_aptitude != '') {
                    this.setState({ ski_aptitude: article.data.data.ski_aptitude })
                    document.getElementById('ipSkiAptitude').value = article.data.data.ski_aptitude;
                }
                if (typeof (article.data.data.ski_length) != "undefined" && article.data.data.ski_length != -1) {
                    this.setState({ ski_length: article.data.data.ski_length.toString() })
                }
                if (typeof (article.data.data.ski_width) != "undefined" && article.data.data.ski_width != -1) {
                    this.setState({ ski_width: article.data.data.ski_width.toString() })
                }
                if (typeof (article.data.data.ski_radius) != "undefined" && article.data.data.ski_radius != -1) {
                    this.setState({ ski_radius: article.data.data.ski_radius.toString() })
                }
                if (typeof (article.data.data.ski_rocker) != "undefined" && article.data.data.ski_rocker != -1) {
                    this.setState({ ski_rocker: article.data.data.ski_rocker })
                    document.getElementById('ipSkiRocker').value = article.data.data.ski_rocker;
                }
                if (typeof (article.data.data.ski_climbingskin) != "undefined" && article.data.data.ski_climbingskin != '') {
                    this.setState({ ski_climbingskin: article.data.data.ski_climbingskin })
                    document.getElementById('ipSkiClimbingskin').value = article.data.data.ski_climbingskin;
                }


                //Climbingskin
                this.setState({ showInProcess: true }, () => {
                    this.setState({
                        showInProcess: false,
                    });
                    if (typeof (article.data.data.climbingskin_brand) != "undefined" && article.data.data.climbingskin_brand != '') {
                        this.setState({ climbingskin_brand: article.data.data.climbingskin_brand })
                        document.getElementById('ipClimbingskinBrand').value = article.data.data.climbingskin_brand;
                    }
                    if (typeof (article.data.data.climbingskin_condition) != "undefined" && article.data.data.climbingskin_condition != '') {
                        this.setState({ climbingskin_condition: article.data.data.climbingskin_condition })
                        document.getElementById('ipClimbingskinCondition').value = article.data.data.climbingskin_condition;
                    }
                    if (typeof (article.data.data.climbingskin_material) != "undefined" && article.data.data.climbingskin_material != '') {
                        this.setState({ climbingskin_material: article.data.data.climbingskin_material })
                    }
                    if (typeof (article.data.data.climbingskin_adhesion) != "undefined" && article.data.data.climbingskin_adhesion != '') {
                        this.setState({ climbingskin_adhesion: article.data.data.climbingskin_adhesion })
                        document.getElementById('ipClimbingskinAdhesion').value = article.data.data.climbingskin_adhesion;
                    }
                });

                //Binding
                if (typeof (article.data.data.binding_brand) != "undefined" && article.data.data.binding_brand != '') {
                    this.setState({ binding_brand: article.data.data.binding_brand })
                    document.getElementById('ipBindingBrand').value = article.data.data.binding_brand;
                }
                if (typeof (article.data.data.binding_condition) != "undefined" && article.data.data.binding_condition != '') {
                    this.setState({ binding_condition: article.data.data.binding_condition })
                    document.getElementById('ipBindingCondition').value = article.data.data.binding_condition;
                }
                if (typeof (article.data.data.binding_model) != "undefined" && article.data.data.binding_model != '') {
                    this.setState({ binding_model: article.data.data.binding_model })
                }
                if (typeof (article.data.data.binding_aptitude) != "undefined" && article.data.data.binding_aptitude != '') {
                    this.setState({ binding_aptitude: article.data.data.binding_aptitude })
                    document.getElementById('ipBindingAptitude').value = article.data.data.binding_aptitude;
                }
                if (typeof (article.data.data.binding_pin) != "undefined" && article.data.data.binding_pin != '') {
                    this.setState({ binding_pin: article.data.data.binding_pin })
                    document.getElementById('ipBindingPin').value = article.data.data.binding_pin;
                }
                if (typeof (article.data.data.binding_stopper) != "undefined" && article.data.data.binding_stopper != '') {
                    this.setState({ binding_stopper: article.data.data.binding_stopper })
                    document.getElementById('ipBindingStopper').value = article.data.data.binding_stopper;
                }
                if (typeof (article.data.data.binding_length) != "undefined" && article.data.data.binding_length != -1) {
                    this.setState({ binding_length: article.data.data.binding_length.toString() })
                }
                if (typeof (article.data.data.binding_length_min) != "undefined" && article.data.data.binding_length_min != -1) {
                    this.setState({ binding_length_min: article.data.data.binding_length_min.toString() })
                }
                if (typeof (article.data.data.binding_length_max) != "undefined" && article.data.data.binding_length_max != -1) {
                    this.setState({ binding_length_max: article.data.data.binding_length_max.toString() })
                }
                if (typeof (article.data.data.binding_release_rear) != "undefined" && article.data.data.binding_release_rear != '') {
                    this.setState({ binding_release_rear: article.data.data.binding_release_rear.toString() })
                    document.getElementById('ipBindingReleaseRear').value = article.data.data.binding_release_rear;
                }
                if (typeof (article.data.data.binding_release_front) != "undefined" && article.data.data.binding_release_front != '') {
                    this.setState({ binding_release_front: article.data.data.binding_release_front.toString() })
                    document.getElementById('ipBindingReleaseFront').value = article.data.data.binding_release_front;
                }
                if (typeof (article.data.data.binding_release_front_min) != "undefined" && article.data.data.binding_release_front_min != -1) {
                    this.setState({ binding_release_front_min: article.data.data.binding_release_front_min.toString() })
                }
                if (typeof (article.data.data.binding_release_front_max) != "undefined" && article.data.data.binding_release_front_max != -1) {
                    this.setState({ binding_release_front_max: article.data.data.binding_release_front_max.toString() })
                }
                if (typeof (article.data.data.binding_release_rear_min) != "undefined" && article.data.data.binding_release_rear_min != -1) {
                    this.setState({ binding_release_rear_min: article.data.data.binding_release_rear_min.toString() })
                }
                if (typeof (article.data.data.binding_release_rear_max) != "undefined" && article.data.data.binding_release_rear_max != -1) {
                    this.setState({ binding_release_rear_max: article.data.data.binding_release_rear_max.toString() })
                }
                if (typeof (article.data.data.binding_skicrampon) != "undefined" && article.data.data.binding_skicrampon != '') {
                    this.setState({ binding_skicrampon: article.data.data.binding_skicrampon })
                    document.getElementById('ipBindingSkicrampon').value = article.data.data.binding_skicrampon;
                }

                //Skicrampon
                this.setState({ showInProcess: true }, () => {
                    this.setState({
                        showInProcess: false,
                    });
                    if (typeof (article.data.data.skicrampon_brand) != "undefined" && article.data.data.skicrampon_brand != '') {
                        this.setState({ skicrampon_brand: article.data.data.skicrampon_brand })
                        document.getElementById('ipSkicramponBrand').value = article.data.data.skicrampon_brand;
                    }
                    if (typeof (article.data.data.skicrampon_condition) != "undefined" && article.data.data.skicrampon_condition != '') {
                        this.setState({ skicrampon_condition: article.data.data.skicrampon_condition })
                        document.getElementById('ipSkicramponCondition').value = article.data.data.skicrampon_condition;
                    }
                    if (typeof (article.data.data.skicrampon_width) != "undefined" && article.data.data.skicrampon_width != -1) {
                        this.setState({ skicrampon_width: article.data.data.skicrampon_width.toString() })
                    }
                });


                break;
            /*case Constants.ART_CAT_BOOT:
                break;*/
            default:
                break;
        }

    }

    load_vhbrands_ski = async event => {
        const vhbrands_ski = await api.getBrandsByCategory('ski')
        this.setState({
            vhbrands_ski: vhbrands_ski.data.data
        })
    }
    load_vhbrands_binding = async event => {
        const vhbrands_binding = await api.getBrandsByCategory('binding')
        this.setState({
            vhbrands_binding: vhbrands_binding.data.data
        })
    }
    load_vhbrands_climbingskin = async event => {
        const vhbrands_climbingskin = await api.getBrandsByCategory('climbingskin')
        this.setState({
            vhbrands_climbingskin: vhbrands_climbingskin.data.data
        })
    }
    load_vhbrands_skicrampon = async event => {
        const vhbrands_skicrampon = await api.getBrandsByCategory('skicrampon')
        this.setState({
            vhbrands_skicrampon: vhbrands_skicrampon.data.data
        })
    }

    handleBtnNextArticle = async event => {
        event.preventDefault()
        window.location.href = `/advert/insertv2`
    }
    handleBtnBackOnError = async event => {
        const pageState = Constants.STATE_INITIAL
        await this.setState({ pageState });

        //Delivery
        document.getElementById('ipDelivery').value = this.state.delivery;
        document.getElementById('ipDeliveryCountry').value = this.state.delivery_country;

        //Details
        document.getElementById('ipArticleWeightUnit').value = this.state.article_weight_unit;

        switch (this.state.category) {
            case Constants.ART_CAT_SKI:
                //Ski
                document.getElementById('ipSkiBrand').value = this.state.ski_brand;
                document.getElementById('ipSkiCondition').value = this.state.ski_condition;
                document.getElementById('ipSkiAptitude').value = this.state.ski_aptitude;
                document.getElementById('ipSkiRocker').value = this.state.ski_rocker;
                document.getElementById('ipSkiClimbingskin').value = this.state.ski_climbingskin;

                //Climbingskin
                if (this.state.ski_climbingskin === Constants.GEN_TRUE) {
                    this.setState({ showInProcess: true }, () => {
                        this.setState({
                            showInProcess: false,
                        });
                        document.getElementById('ipClimbingskinBrand').value = this.state.climbingskin_brand;
                        document.getElementById('ipClimbingskinCondition').value = this.state.climbingskin_condition;
                        document.getElementById('ipClimbingskinAdhesion').value = this.state.climbingskin_adhesion;

                    });
                }

                //Binding
                document.getElementById('ipBindingBrand').value = this.state.binding_brand;
                document.getElementById('ipBindingCondition').value = this.state.binding_condition;
                document.getElementById('ipBindingAptitude').value = this.state.binding_aptitude;
                document.getElementById('ipBindingPin').value = this.state.binding_pin;
                document.getElementById('ipBindingStopper').value = this.state.binding_stopper;
                document.getElementById('ipBindingReleaseRear').value = this.state.binding_release_rear;
                document.getElementById('ipBindingReleaseFront').value = this.state.binding_release_front;
                document.getElementById('ipBindingSkicrampon').value = this.state.binding_skicrampon;


                //Skicrampon
                if (this.state.binding_skicrampon === Constants.GEN_TRUE) {
                    this.setState({ showInProcess: true }, () => {
                        this.setState({
                            showInProcess: false,
                        });
                        document.getElementById('ipSkicramponBrand').value = this.state.skicrampon_brand;
                        document.getElementById('ipSkicramponCondition').value = this.state.skicrampon_condition;
                    });
                }

                break;
            /*case Constants.ART_CAT_BOOT:
                break;*/
            default:
                break;
        }
    }

    handleOnClickPhotoX = tag => () => {
        for (var i = 0; i < this.state.photo_items.length; i++) {
            if (this.state.photo_items[i].tag === tag) {
                if (this.state.photo_items[i].new === false) {
                    this.state.photo_elements_to_delete.push({
                        url: this.state.photo_items[i].url,
                        id: this.state.photo_items[i].tag,
                        path: this.state.photo_items[i].path,
                        name: this.state.photo_items[i].name
                    });
                }
                this.state.photo_items.splice(i, 1);
                break;
            }
        }
        this.setState({ placeholder: 'holding' })
    }
    handleOnClickPhotoL = value => () => {
        var photosArray = this.state.photo_items;
        for (var i = 0; i < photosArray.length; i++) {
            if (photosArray[i].tag === value) {
                var img_holder = photosArray[i];
                photosArray[i] = photosArray[i - 1];
                photosArray[i - 1] = img_holder;
                break;
            }
        }
        this.setState({ photo_items: photosArray })
    }
    handleOnClickPhotoR = value => () => {
        var photosArray = this.state.photo_items;
        for (var i = 0; i < photosArray.length; i++) {
            if (photosArray[i].tag === value) {
                var img_holder = photosArray[i];
                photosArray[i] = photosArray[i + 1];
                photosArray[i + 1] = img_holder;
                break;
            }
        }
        this.setState({ photo_items: photosArray })
    }

    handleModalPhotoOpen = async event => {
        const modalPhotoOpen = true
        var photosArray = this.state.photo_items;
        const modalPhoto = photosArray[Number(event.currentTarget.dataset.index)].url;
        this.setState({ modalPhoto })
        this.setState({ modalPhotoOpen })
    }

    handleModalPhotoClose = async event => {
        const modalPhotoOpen = false
        this.setState({ modalPhotoOpen })

    }

    handleModalCancelOpen = async event => {
        const modalCancelOpen = true
        this.setState({ modalCancelOpen })
    }

    handleModalCancelClose = async event => {
        const modalCancelOpen = false
        this.setState({ modalCancelOpen })

    }


    render() {
        const { pageState, uploadingArticle, modalCancelOpen, requiredFieldsError, valueFieldsError, priceRangeExceeded, vhbrands_ski, vhbrands_binding, vhbrands_climbingskin, vhbrands_skicrampon, modalPhotoOpen, modalPhoto, photo_errors, isMobile } = this.state
        const { title, description, char_left, photo_items, price, price_original, category, delivery, delivery_country, delivery_postalcode, delivery_city } = this.state
        const { article_weight, article_weight_unit, article_age, article_daysontour } = this.state
        const { ski_brand, ski_model, ski_condition, ski_aptitude, ski_length, ski_width, ski_radius, ski_rocker, ski_climbingskin } = this.state
        const { binding_brand, binding_model, binding_condition, binding_aptitude, binding_pin, binding_stopper, binding_length, binding_length_min, binding_length_max,
            binding_release_front, binding_release_front_min, binding_release_front_max, binding_release_rear, binding_release_rear_min, binding_release_rear_max,
            binding_skicrampon } = this.state
        const { climbingskin_brand, climbingskin_model, climbingskin_condition, climbingskin_adhesion, climbingskin_material } = this.state
        const { skicrampon_brand, skicrampon_model, skicrampon_condition, skicrampon_width } = this.state

        const { seller_type, seller_name } = this.state

        const { confirmedUser, sellerUser } = this.state

        const { t } = this.props

        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <Container>
                    {pageState === Constants.STATE_INITIAL &&
                        <ContainerMain>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={modalPhotoOpen}
                                onClose={this.handleModalPhotoClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={modalPhotoOpen}>
                                    <Box sx={isMobile === true ? m_style_photo : style_photo}>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.insert.tx_photo")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalPhotoClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <img style={{ border: '2px solid #EBEBEB' }} height='95%' src={modalPhoto} alt={'...'} />
                                    </Box>
                                </Fade>
                            </Modal>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={modalCancelOpen}
                                onClose={this.handleModalCancelClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={modalCancelOpen}>
                                    <Box sx={isMobile === true ? m_style_cancel : style_cancel}>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.update.tx_mod_discard_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalCancelClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.update.tx_mod_discard_text")}</Paragraph>
                                        <WrapperButtonLine>
                                            <Container>
                                                <StyledLink to={"/user/adverts"} >
                                                    <BtnDiscard > {t('ui.update.bt_discard')}</BtnDiscard>
                                                </StyledLink>
                                            </Container>
                                            <BtnCancel onClick={this.handleModalCancelClose}> {t('ui.update.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Box>
                                </Fade>
                            </Modal>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={uploadingArticle}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={uploadingArticle}>
                                    <Box sx={style_loading}>
                                        <WrapperCenter>
                                            <Paragraph>{t('ui.insert.tx_loading')}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Box>
                                </Fade>
                            </Modal>
                            <WrapperText>
                                <WrapperLR>
                                    <WrapperCenter>
                                        <Title>{t('ui.insert.tx_title')}</Title>
                                        <Paragraph></Paragraph>
                                    </WrapperCenter>
                                    <H5Title>{t('ui.insert.tx_articleDetails')}</H5Title>
                                    <WrapperLRc>
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('ui.insert.tx_ipTitle')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemText>
                                                <InputText
                                                    id="ipTitle"
                                                    type="text"
                                                    maxLength={40}
                                                    value={title}
                                                    onChange={this.handleChangeInputTitle}
                                                />
                                            </WrapperLineItemText>
                                        </WrapperLine>
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('ui.insert.tx_ipCategory')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemText>
                                                <InputSelect id="ipCategory" onChange={this.handleChangeInputCategory} defaultValue={Constants.ART_CAT_SKI}>
                                                    <SelectOption type="text" value={Constants.ART_CAT_SKI} > {t('label_ski')} </SelectOption>
                                                </InputSelect>
                                            </WrapperLineItemText>
                                        </WrapperLine>

                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('ui.insert.tx_ipPrice')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemNumberP>
                                                <InputText
                                                    id="ipPrice"
                                                    type="text"
                                                    maxLength={4}
                                                    value={price}
                                                    onChange={this.handleChangeInputPrice}
                                                />
                                            </WrapperLineItemNumberP>
                                            <WrapperLineItemComment>
                                                ,00 €
                                            </WrapperLineItemComment>
                                            {priceRangeExceeded &&
                                                <WrapperLineItemPriceBuffer>
                                                    <font color={Style.COLOUR_RED}>{t('ui.insert.tx_price_max') + ' ' + Constants.ART_PRICE_MAX + '€'} </font>
                                                </WrapperLineItemPriceBuffer>
                                            }
                                        </WrapperLine>
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('ui.insert.tx_ipPriceOriginal')}
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemNumberP>
                                                <InputText
                                                    id="ipPriceOriginal"
                                                    type="text"
                                                    maxLength={4}
                                                    value={price_original}
                                                    onChange={this.handleChangeInputPriceOriginal}
                                                />
                                            </WrapperLineItemNumberP>
                                            <WrapperLineItemComment>
                                                ,00 €
                                            </WrapperLineItemComment>
                                        </WrapperLine>
                                        <WrapperLine>
                                            <WrapperLineItemLabelTop>
                                                {t('ui.insert.tx_ipPhotos')}
                                            </WrapperLineItemLabelTop>
                                            <WrapperLineItemText>
                                                <ContainerTextarea>
                                                    <InputText
                                                        id="ipPhotos"
                                                        type="file"
                                                        accept="image/*"
                                                        name="files[]"
                                                        multiple
                                                        onChange={this.handleChangeInputPhotos}
                                                    />
                                                    <WrapperTextLine>
                                                        {t('ui.insert.tx_ipPhotosT')} <MultilineTooltip type={Constants.ML_TT_PHOTO_HELP} />
                                                    </WrapperTextLine>
                                                </ContainerTextarea>
                                            </WrapperLineItemText>
                                        </WrapperLine>

                                        <WrapperLine>
                                            <WrapperLineItemLabel></WrapperLineItemLabel>
                                            <WrapperLineItemPhotos>
                                                <ImageList sx={{ width: '100%', height: '100%' }} cols={4} >
                                                    {photo_items.map((obj, index) => {
                                                        return (
                                                            <ContainerPhoto key={photo_items[index].tag}>
                                                                <ImageListItem >
                                                                    <img style={{ border: '2px solid #EBEBEB' }} src={photo_items[index].url} alt={'...'} data-index={index} onClick={this.handleModalPhotoOpen} />
                                                                    {index === 0 &&
                                                                        <ImageListItemBar
                                                                            sx={{ background: 'rgba(0,0,0,0) 0%', }}
                                                                            position="top"
                                                                            actionIcon={
                                                                                <IconButton >
                                                                                    <IconRemove src={icon_main} />
                                                                                </IconButton>
                                                                            }
                                                                            actionPosition="left"
                                                                        />
                                                                    }
                                                                    <ImageListItemBar
                                                                        sx={{
                                                                            background: 'rgba(0,0,0,0) 0%',
                                                                        }}
                                                                        position="top"
                                                                        actionIcon={
                                                                            <IconButton onClick={this.handleOnClickPhotoX(photo_items[index].tag)}>
                                                                                <IconRemove src={icon_del} />
                                                                            </IconButton>
                                                                        }
                                                                        actionPosition="right"
                                                                    />
                                                                    {photo_items.length > 1 &&
                                                                        <Container>
                                                                            {index === 0 &&
                                                                                <ImageListItemBar
                                                                                    sx={{ background: 'rgba(0,0,0,0) 0%', }}
                                                                                    position="bottom"
                                                                                    actionIcon={
                                                                                        <Container>
                                                                                            <IconD onClick={this.handleOnClickPhotoR(photo_items[index].tag)} src={icon_right} />
                                                                                        </Container>
                                                                                    }
                                                                                    actionPosition="right"
                                                                                />
                                                                            }
                                                                            {index > 0 && index < (photo_items.length - 1) &&
                                                                                < ImageListItemBar
                                                                                    sx={{ background: 'rgba(0,0,0,0) 0%', }}
                                                                                    position="bottom"
                                                                                    actionIcon={
                                                                                        <Container>
                                                                                            <IconD onClick={this.handleOnClickPhotoL(photo_items[index].tag)} src={icon_left} />
                                                                                            <IconD onClick={this.handleOnClickPhotoR(photo_items[index].tag)} src={icon_right} />
                                                                                        </Container>
                                                                                    }
                                                                                    actionPosition="right"
                                                                                />
                                                                            }
                                                                            {index === (photo_items.length - 1) &&
                                                                                <ImageListItemBar
                                                                                    sx={{ background: 'rgba(0,0,0,0) 0%', }}
                                                                                    position="bottom"
                                                                                    actionIcon={
                                                                                        <Container>
                                                                                            <IconD onClick={this.handleOnClickPhotoL(photo_items[index].tag)} src={icon_left} />
                                                                                            <IconD alt='' />
                                                                                        </Container>
                                                                                    }
                                                                                    actionPosition="right"
                                                                                />
                                                                            }
                                                                        </Container>
                                                                    }
                                                                </ImageListItem>
                                                            </ContainerPhoto>
                                                        )
                                                    })}
                                                </ImageList>
                                            </WrapperLineItemPhotos>
                                        </WrapperLine>
                                        {photo_errors.length > 0 &&
                                            <WrapperLine>
                                                <WrapperLineItemLabelTop>
                                                    {t('ui.insert.tx_ipPhotosError')}
                                                </WrapperLineItemLabelTop>
                                                <WrapperLineItemErrors>
                                                    {photo_errors.map((obj, index) => {
                                                        return (
                                                            <Container key={photo_errors[index].tag}>
                                                                {photo_errors[index].error === Constants.ERROR_IMAGE_SIZE &&
                                                                    <Line>{photo_errors[index].name}  <font color={Style.COLOUR_RED}>{t('ui.insert.tx_ipPhotosErrorS')}</font></Line>
                                                                }
                                                                {photo_errors[index].error === Constants.ERROR_IMAGE_AMOUNT &&
                                                                    <Line>{photo_errors[index].name}  <font color={Style.COLOUR_RED}>{t('ui.insert.tx_ipPhotosErrorC')}</font></Line>
                                                                }
                                                            </Container>
                                                        )
                                                    })}
                                                </WrapperLineItemErrors>
                                            </WrapperLine>
                                        }
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('ui.insert.tx_ipDelivery')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemText>
                                                <InputSelect id="ipDelivery" onChange={this.handleChangeInputDelivery} defaultValue={Constants.ART_DEL_1}>
                                                    <SelectOption type="text" value={Constants.ART_DEL_1} > {t('art_del_1')} </SelectOption>
                                                    <SelectOption type="text" value={Constants.ART_DEL_2} > {t('art_del_2')} </SelectOption>
                                                    <SelectOption type="text" value={Constants.ART_DEL_3} > {t('art_del_3')} </SelectOption>
                                                </InputSelect>
                                            </WrapperLineItemText>
                                        </WrapperLine>

                                        {(delivery === Constants.ART_DEL_1 || delivery === Constants.ART_DEL_3) &&
                                            <WrapperLine>
                                                <WrapperLineItemLabel>
                                                </WrapperLineItemLabel>
                                                <WrapperLineItemValue>
                                                    <WrapperGrid>
                                                        <Grid container
                                                            width={1}
                                                            height={1}
                                                            direction="row"
                                                            justifyContent="space-evenly"
                                                            alignItems="center"
                                                            spacing={{ sm: 0, md: 0 }} >
                                                            <Grid sm={10} md={4} key={'g-up-DeliveryCountry'}
                                                                sx={{
                                                                    boxShadow: 0,
                                                                    width: '100%',
                                                                    p: 0,
                                                                    m: 0,
                                                                    borderRadius: 0
                                                                }}>
                                                                <WrapperPanel>
                                                                    <WrapperLineProp>
                                                                        <WrapperLineItemLabel>
                                                                            {t('ui.insert.tx_ipDeliveryCountry')} *
                                                                        </WrapperLineItemLabel>
                                                                        <WrapperLineItemCC>
                                                                            <InputSelect id="ipDeliveryCountry" onChange={this.handleChangeInputDeliveryCountry} >
                                                                                <SelectOption type="text" value={Constants.COUNTRYCODE_GERMANY} > {Constants.COUNTRYCODE_GERMANY} </SelectOption>
                                                                                <SelectOption type="text" value={Constants.COUNTRYCODE_AUSTRIA} > {Constants.COUNTRYCODE_AUSTRIA} </SelectOption>
                                                                            </InputSelect>
                                                                        </WrapperLineItemCC>
                                                                    </WrapperLineProp>
                                                                </WrapperPanel>
                                                            </Grid>
                                                            <Grid sm={10} md={4} key={'g-up-DeliveryPostalcode'}
                                                                sx={{
                                                                    boxShadow: 0,
                                                                    width: '100%',
                                                                    p: 0,
                                                                    m: 0,
                                                                    borderRadius: 0
                                                                }}>
                                                                <WrapperPanel>
                                                                    <WrapperLineProp>
                                                                        <WrapperLineItemLabel>
                                                                            {t('ui.insert.tx_ipDeliveryPostalcode')} *
                                                                        </WrapperLineItemLabel>
                                                                        <WrapperLineItemValue>
                                                                            <InputText
                                                                                id="ipDeliveryPostalcode"
                                                                                type="text"
                                                                                maxLength={5}
                                                                                value={delivery_postalcode}
                                                                                onChange={this.handleChangeInputDeliveryPostalcode}
                                                                            />
                                                                        </WrapperLineItemValue>
                                                                    </WrapperLineProp>
                                                                </WrapperPanel>
                                                            </Grid>
                                                            <Grid sm={10} md={4} key={'g-up-DeliveryCity'}
                                                                sx={{
                                                                    boxShadow: 0,
                                                                    width: '100%',
                                                                    p: 0,
                                                                    m: 0,
                                                                    borderRadius: 0
                                                                }}>
                                                                <WrapperPanel>
                                                                    <WrapperLineProp>
                                                                        <WrapperLineItemLabel>
                                                                            {t('ui.insert.tx_ipDeliveryCity')}
                                                                        </WrapperLineItemLabel>
                                                                        <WrapperLineItemText>
                                                                            <InputText
                                                                                id="ipDeliveryCity"
                                                                                type="text"
                                                                                maxLength={40}
                                                                                value={delivery_city}
                                                                                onChange={this.handleChangeInputDeliveryCity}
                                                                            />
                                                                        </WrapperLineItemText>
                                                                    </WrapperLineProp>
                                                                </WrapperPanel>
                                                            </Grid>
                                                        </Grid>
                                                    </WrapperGrid>
                                                </WrapperLineItemValue>
                                            </WrapperLine>
                                        }

                                    </WrapperLRc>
                                </WrapperLR>
                            </WrapperText>

                            <WrapperText>
                                <WrapperLR>
                                    <H5Title>{t('label_properties') + " - " + t('label_article')}</H5Title>
                                    <WrapperLRc>
                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-up-age'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperLineProp>
                                                            <WrapperLineItemLabel>
                                                                {t('label_article_age')}
                                                            </WrapperLineItemLabel>
                                                            <WrapperLineItemValue>
                                                                <InputText
                                                                    id="ipArticleAge"
                                                                    type="text"
                                                                    maxLength={10}
                                                                    value={article_age}
                                                                    onChange={this.handleChangeInputArticle_age}
                                                                />
                                                            </WrapperLineItemValue>
                                                            <WrapperLineItemComment>
                                                                {t('label_article_age_unit')}
                                                            </WrapperLineItemComment>
                                                        </WrapperLineProp>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-up-daysontour'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperLineProp>
                                                            <WrapperLineItemLabel>
                                                                {t('label_article_daysontour')}
                                                            </WrapperLineItemLabel>
                                                            <WrapperLineItemValue>
                                                                <InputText
                                                                    id="ipArticleDaysontour"
                                                                    type="text"
                                                                    maxLength={10}
                                                                    value={article_daysontour}
                                                                    onChange={this.handleChangeInputArticle_daysontour}
                                                                />
                                                            </WrapperLineItemValue>
                                                        </WrapperLineProp>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-up-weight'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperLineProp>
                                                            <WrapperLineItemLabel>
                                                                {t('label_article_weight')}
                                                            </WrapperLineItemLabel>
                                                            <WrapperLineItemValue>
                                                                <InputText
                                                                    id="ipArticleWeight"
                                                                    type="text"
                                                                    maxLength={10}
                                                                    value={article_weight}
                                                                    onChange={this.handleChangeInputArticle_weight}
                                                                />
                                                                <InputSelect id="ipArticleWeightUnit" onChange={this.handleChangeInputArticle_weight_unit} defaultValue={Constants.UNIT_KILOGRAMM}>
                                                                    <SelectOption type="text" value={Constants.UNIT_KILOGRAMM} > {t('label_article_weight_unit_alt')} </SelectOption>
                                                                    <SelectOption type="text" value={Constants.UNIT_GRAMM} > {t('label_article_weight_unit')} </SelectOption>
                                                                </InputSelect>
                                                            </WrapperLineItemValue>
                                                            <WrapperLineItemComment>
                                                                <MultilineTooltip type={Constants.ML_TT_WEIGHT_HELP} />
                                                            </WrapperLineItemComment>
                                                        </WrapperLineProp>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-up-prop_fill'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>
                                    </WrapperLRc>
                                </WrapperLR>
                            </WrapperText>
                            {category === Constants.ART_CAT_SKI &&
                                <WrapperText>
                                    <WrapperLR>
                                        <H5Title>{t('label_properties') + " - " + t('label_ski')}</H5Title>
                                        <WrapperLRc>





                                            <WrapperGrid>
                                                <Grid container
                                                    width={1}
                                                    height={1}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    spacing={{ sm: 0, md: 1 }} >
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_brand'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_brand')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkiBrand" onChange={this.handleChangeInputSki_brand} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        {vhbrands_ski.map((obj, index) => {
                                                                            return (
                                                                                <SelectOption type="text" value={vhbrands_ski[index].brand} > {vhbrands_ski[index].brand} </SelectOption>
                                                                            )
                                                                        })}
                                                                        <SelectOption type="text" value={Constants.GEN_NULL} > {t('other_brand')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_model'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_model')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipSkiModel"
                                                                        type="text"
                                                                        maxLength={35}
                                                                        value={ski_model}
                                                                        onChange={this.handleChangeInputSki_model}
                                                                    />
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_condition'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_condition')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkiCondition" onChange={this.handleChangeInputSki_condition} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_1} > {t('art_con_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_2} > {t('art_con_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_3} > {t('art_con_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_4} > {t('art_con_4')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_5} > {t('art_con_5')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    <MultilineTooltip type={Constants.ML_TT_SKI_CONDITION} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_aptitude'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_aptitude')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkiAptitude" onChange={this.handleChangeInputSki_aptitude} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.SKI_APTITUDE_1} > {t('ski_aptitude_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.SKI_APTITUDE_2} > {t('ski_aptitude_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.SKI_APTITUDE_3} > {t('ski_aptitude_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.SKI_APTITUDE_4} > {t('ski_aptitude_4')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_length'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_length')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipSkiLength"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        value={ski_length}
                                                                        onChange={this.handleChangeInputSki_length}
                                                                    />
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_ski_length_unit')}
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_width'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_width')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipSkiWidth"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        value={ski_width}
                                                                        onChange={this.handleChangeInputSki_width}
                                                                    />
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_ski_width_unit')}
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_radius'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_radius')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipSkiRadius"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        value={ski_radius}
                                                                        onChange={this.handleChangeInputSki_radius}
                                                                    />
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_ski_radius_unit')}
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_rocker'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_rocker')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkiRocker" onChange={this.handleChangeInputSki_rocker} defaultValue={""}>
                                                                        <SelectOption type="text" value=""> {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ROCKER_TYPE_1} > {t('rocker_type_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ROCKER_TYPE_2} > {t('rocker_type_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ROCKER_TYPE_3} > {t('rocker_type_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ROCKER_TYPE_4} > {t('rocker_type_4')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ROCKER_TYPE_5} > {t('rocker_type_5')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    <MultilineTooltip type={Constants.ML_TT_ROCKER_HELP} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-ski_climbingskin'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_ski_climbingskin')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkiClimbingskin" onChange={this.handleChangeInputSki_climbingskin} defaultValue={Constants.GEN_FALSE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>

                                                    <Grid sm={10} md={5.5} key={'g-up-ski_fill'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>

                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                </Grid>
                                            </WrapperGrid>

                                        </WrapperLRc>
                                    </WrapperLR>
                                </WrapperText>
                            }
                            {category === Constants.ART_CAT_SKI && ski_climbingskin === Constants.GEN_TRUE &&
                                <WrapperText>
                                    <WrapperLR>
                                        <H5Title>{t('label_properties') + " - " + t('label_climbingskin')}</H5Title>
                                        <WrapperLRc>
                                            <WrapperGrid>
                                                <Grid container
                                                    width={1}
                                                    height={1}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    spacing={{ sm: 0, md: 1 }} >
                                                    <Grid sm={10} md={5.5} key={'g-up-climbingskin_brand'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_climbingskin_brand')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipClimbingskinBrand" onChange={this.handleChangeInputClimbingskin_brand} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        {vhbrands_climbingskin.map((obj, index) => {
                                                                            return (
                                                                                <SelectOption type="text" value={vhbrands_climbingskin[index].brand} > {vhbrands_climbingskin[index].brand} </SelectOption>
                                                                            )
                                                                        })}
                                                                        <SelectOption type="text" value={Constants.GEN_NULL} > {t('other_brand')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-climbingskin_material'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_climbingskin_material')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipClimbingskinMaterial"
                                                                        type="text"
                                                                        maxLength={35}
                                                                        value={climbingskin_material}
                                                                        onChange={this.handleChangeInputClimbingskin_material}
                                                                    />
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-climbingskin_condition'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_climbingskin_condition')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipClimbingskinCondition" onChange={this.handleChangeInputClimbingskin_condition} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_1} > {t('art_con_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_2} > {t('art_con_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_3} > {t('art_con_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_4} > {t('art_con_4')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_5} > {t('art_con_5')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    <MultilineTooltip type={Constants.ML_TT_CLIMBINGSKIN_CONDITION} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-climbingskin_adhesion'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_climbingskin_adhesion')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipClimbingskinAdhesion" onChange={this.handleChangeInputClimbingskin_adhesion} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ADHESION_TYPE_1} > {t('adhesion_type_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ADHESION_TYPE_2} > {t('adhesion_type_2')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                </Grid>
                                            </WrapperGrid>
                                        </WrapperLRc>
                                    </WrapperLR>
                                </WrapperText>
                            }
                            {category === Constants.ART_CAT_SKI &&
                                <WrapperText>
                                    <WrapperLR>
                                        <H5Title>{t('label_properties') + " - " + t('label_binding')}</H5Title>
                                        <WrapperLRc>

                                            <WrapperGrid>
                                                <Grid container
                                                    width={1}
                                                    height={1}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    spacing={{ sm: 0, md: 1 }} >
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_brand'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_brand')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingBrand" onChange={this.handleChangeInputBinding_brand} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        {vhbrands_binding.map((obj, index) => {
                                                                            return (
                                                                                <SelectOption type="text" value={vhbrands_binding[index].brand} > {vhbrands_binding[index].brand} </SelectOption>
                                                                            )
                                                                        })}
                                                                        <SelectOption type="text" value={Constants.GEN_NULL} > {t('other_brand')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_model'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_model')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipBindingModel"
                                                                        type="text"
                                                                        maxLength={35}
                                                                        value={binding_model}
                                                                        onChange={this.handleChangeInputBinding_model}
                                                                    />
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_condition'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_condition')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingCondition" onChange={this.handleChangeInputBinding_condition} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_1} > {t('art_con_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_2} > {t('art_con_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_3} > {t('art_con_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_4} > {t('art_con_4')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_5} > {t('art_con_5')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    <MultilineTooltip type={Constants.ML_TT_BINDING_CONDITION} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_aptitude'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_aptitude')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingAptitude" onChange={this.handleChangeInputBinding_aptitude} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.BINDING_APTITUDE_1} > {t('binding_aptitude_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.BINDING_APTITUDE_2} > {t('binding_aptitude_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.BINDING_APTITUDE_3} > {t('binding_aptitude_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.BINDING_APTITUDE_4} > {t('binding_aptitude_4')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_pin'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_pin')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingPin" onChange={this.handleChangeInputBinding_pin} defaultValue={Constants.GEN_TRUE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_stopper'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_stopper')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingStopper" onChange={this.handleChangeInputBinding_stopper} defaultValue={Constants.GEN_TRUE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_length'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_length')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipBindingLength"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        value={binding_length}
                                                                        onChange={this.handleChangeInputBinding_length}
                                                                    />
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_binding_length_unit')}
                                                                    <MultilineTooltip type={Constants.ML_TT_BINDING_LENGTH_HELP} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_length_adjust'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_length_adjust')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <WrapperGrid>
                                                                        <Grid container
                                                                            width={1}
                                                                            height={1}
                                                                            direction="row"
                                                                            justifyContent="space-evenly"
                                                                            alignItems="center"
                                                                            spacing={{ sm: 0, lg: 1 }} >
                                                                            <Grid sm={10} lg={5.5} key={'g-up-binding_length_min'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperLineProp>
                                                                                        <WrapperLineItemExtrasQuartersSmall>
                                                                                            {t('label_binding_length_min')}
                                                                                        </WrapperLineItemExtrasQuartersSmall>
                                                                                        <WrapperLineItemExtrasQuartersLarge>
                                                                                            <InputText
                                                                                                id="ipBindingLengthPos"
                                                                                                type="text"
                                                                                                maxLength={3}
                                                                                                value={binding_length_min}
                                                                                                onChange={this.handleChangeInputbinding_length_min}
                                                                                            />
                                                                                        </WrapperLineItemExtrasQuartersLarge>
                                                                                    </WrapperLineProp>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid sm={10} lg={5.5} key={'g-up-binding_length_max'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperLineProp>
                                                                                        <WrapperLineItemExtrasQuartersSmall>
                                                                                            {t('label_binding_length_max')}
                                                                                        </WrapperLineItemExtrasQuartersSmall>
                                                                                        <WrapperLineItemExtrasQuartersLarge>
                                                                                            <InputText
                                                                                                id="ipBindingLengthNeg"
                                                                                                type="text"
                                                                                                maxLength={3}
                                                                                                value={binding_length_max}
                                                                                                onChange={this.handleChangeInputbinding_length_max}
                                                                                            />
                                                                                        </WrapperLineItemExtrasQuartersLarge>
                                                                                    </WrapperLineProp>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </WrapperGrid>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_binding_length_unit')}
                                                                    <MultilineTooltip type={Constants.ML_TT_BINDING_LENGTH_HELP} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_release_rear'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_release_rear')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingReleaseRear" onChange={this.handleChangeInputBinding_release_rear} defaultValue={Constants.GEN_TRUE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>

                                                    <Grid sm={10} md={5.5} key={'g-up-binding_release_rear_adjust'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_release_rear_adjust')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <WrapperGrid>
                                                                        <Grid container
                                                                            width={1}
                                                                            height={1}
                                                                            direction="row"
                                                                            justifyContent="space-evenly"
                                                                            alignItems="center"
                                                                            spacing={{ sm: 0, lg: 1 }} >
                                                                            <Grid sm={10} lg={5.5} key={'g-up-binding_release_rear_min'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperLineProp>
                                                                                        <WrapperLineItemExtrasQuartersSmall>
                                                                                            {t('label_binding_release_rear_min')}
                                                                                        </WrapperLineItemExtrasQuartersSmall>
                                                                                        <WrapperLineItemExtrasQuartersLarge>
                                                                                            <InputText
                                                                                                id="ipBindingReleaseRearMin"
                                                                                                type="text"
                                                                                                maxLength={3}
                                                                                                value={binding_release_rear_min}
                                                                                                onChange={this.handleChangeInputBinding_release_rear_min}
                                                                                            />
                                                                                        </WrapperLineItemExtrasQuartersLarge>
                                                                                    </WrapperLineProp>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid sm={10} lg={5.5} key={'g-up-binding_release_rear_max'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperLineProp>
                                                                                        <WrapperLineItemExtrasQuartersSmall>
                                                                                            {t('label_binding_release_rear_max')}
                                                                                        </WrapperLineItemExtrasQuartersSmall>
                                                                                        <WrapperLineItemExtrasQuartersLarge>
                                                                                            <InputText
                                                                                                id="ipBindingReleaseRearMax"
                                                                                                type="text"
                                                                                                maxLength={3}
                                                                                                value={binding_release_rear_max}
                                                                                                onChange={this.handleChangeInputBinding_release_rear_max}
                                                                                            />
                                                                                        </WrapperLineItemExtrasQuartersLarge>
                                                                                    </WrapperLineProp>
                                                                                </WrapperPanel>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </WrapperGrid>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_release_front'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_release_front')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingReleaseFront" onChange={this.handleChangeInputBinding_release_front} defaultValue={Constants.GEN_FALSE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    {binding_release_front === Constants.GEN_TRUE &&
                                                        <Grid sm={10} md={5.5} key={'g-up-binding_release_front_adjust'}
                                                            sx={{
                                                                boxShadow: 0,
                                                                width: '100%',
                                                                p: 0,
                                                                m: 0,
                                                                borderRadius: 0
                                                            }}>
                                                            <WrapperPanel>
                                                                <WrapperLineProp>
                                                                    <WrapperLineItemLabel>
                                                                        {t('label_binding_release_front_adjust')}
                                                                    </WrapperLineItemLabel>
                                                                    <WrapperLineItemValue>
                                                                        <WrapperGrid>
                                                                            <Grid container
                                                                                width={1}
                                                                                height={1}
                                                                                direction="row"
                                                                                justifyContent="space-evenly"
                                                                                alignItems="center"
                                                                                spacing={{ sm: 0, lg: 1 }} >
                                                                                <Grid sm={10} lg={5.5} key={'g-up-binding_release_front_min'}
                                                                                    sx={{
                                                                                        boxShadow: 0,
                                                                                        width: '100%',
                                                                                        p: 0,
                                                                                        m: 0,
                                                                                        borderRadius: 0
                                                                                    }}>
                                                                                    <WrapperPanel>
                                                                                        <WrapperLineProp>
                                                                                            <WrapperLineItemExtrasQuartersSmall>
                                                                                                {t('label_binding_release_front_min')}
                                                                                            </WrapperLineItemExtrasQuartersSmall>
                                                                                            <WrapperLineItemExtrasQuartersLarge>
                                                                                                <InputText
                                                                                                    id="ipBindingReleaseFrontMin"
                                                                                                    type="text"
                                                                                                    maxLength={3}
                                                                                                    value={binding_release_front_min}
                                                                                                    onChange={this.handleChangeInputBinding_release_front_min}
                                                                                                />
                                                                                            </WrapperLineItemExtrasQuartersLarge>
                                                                                        </WrapperLineProp>
                                                                                    </WrapperPanel>
                                                                                </Grid>
                                                                                <Grid sm={10} lg={5.5} key={'g-up-binding_release_front_max'}
                                                                                    sx={{
                                                                                        boxShadow: 0,
                                                                                        width: '100%',
                                                                                        p: 0,
                                                                                        m: 0,
                                                                                        borderRadius: 0
                                                                                    }}>
                                                                                    <WrapperPanel>
                                                                                        <WrapperLineProp>
                                                                                            <WrapperLineItemExtrasQuartersSmall>
                                                                                                {t('label_binding_release_front_max')}
                                                                                            </WrapperLineItemExtrasQuartersSmall>
                                                                                            <WrapperLineItemExtrasQuartersLarge>
                                                                                                <InputText
                                                                                                    id="ipBindingReleaseFrontMax"
                                                                                                    type="text"
                                                                                                    maxLength={3}
                                                                                                    value={binding_release_front_max}
                                                                                                    onChange={this.handleChangeInputBinding_release_front_max}
                                                                                                />
                                                                                            </WrapperLineItemExtrasQuartersLarge>
                                                                                        </WrapperLineProp>
                                                                                    </WrapperPanel>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </WrapperGrid>
                                                                    </WrapperLineItemValue>
                                                                </WrapperLineProp>
                                                            </WrapperPanel>
                                                        </Grid>
                                                    }
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_skicrampon'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_binding_skicrampon')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipBindingSkicrampon" onChange={this.handleChangeInputBinding_skicrampon} defaultValue={Constants.GEN_FALSE}>
                                                                        <SelectOption type="text" value={Constants.GEN_TRUE}  > {t('gen_true')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.GEN_FALSE} > {t('gen_false')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-binding_fill'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                </Grid>
                                            </WrapperGrid>
                                        </WrapperLRc>
                                    </WrapperLR>
                                </WrapperText>
                            }
                            {category === Constants.ART_CAT_SKI && binding_skicrampon === Constants.GEN_TRUE &&
                                <WrapperText>
                                    <WrapperLR>
                                        <H5Title>{t('label_properties') + " - " + t('label_skicrampon')}</H5Title>
                                        <WrapperLRc>
                                            <WrapperGrid>
                                                <Grid container
                                                    width={1}
                                                    height={1}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    spacing={{ sm: 0, md: 1 }} >
                                                    <Grid sm={10} md={5.5} key={'g-up-skicrampon_brand'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_skicrampon_brand')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkicramponBrand" onChange={this.handleChangeInputSkicrampon_brand} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        {vhbrands_skicrampon.map((obj, index) => {
                                                                            return (
                                                                                <SelectOption type="text" value={vhbrands_skicrampon[index].brand} > {vhbrands_skicrampon[index].brand} </SelectOption>
                                                                            )
                                                                        })}
                                                                        <SelectOption type="text" value={Constants.GEN_NULL} > {t('other_brand')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment></WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-skicrampon_width'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_skicrampon_width')}
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputText
                                                                        id="ipSkicramponWidth"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        value={skicrampon_width}
                                                                        onChange={this.handleChangeInputSkicrampon_width}
                                                                    />
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    {t('label_skicrampon_width_unit')}
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-skicrampon_condition'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperLineProp>
                                                                <WrapperLineItemLabel>
                                                                    {t('label_skicrampon_condition')} *
                                                                </WrapperLineItemLabel>
                                                                <WrapperLineItemValue>
                                                                    <InputSelect id="ipSkicramponCondition" onChange={this.handleChangeInputSkicrampon_condition} defaultValue={""}>
                                                                        <SelectOption type="text" value="" > {t('pls_choose')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_1} > {t('art_con_1')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_2} > {t('art_con_2')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_3} > {t('art_con_3')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_4} > {t('art_con_4')} </SelectOption>
                                                                        <SelectOption type="text" value={Constants.ART_CON_5} > {t('art_con_5')} </SelectOption>
                                                                    </InputSelect>
                                                                </WrapperLineItemValue>
                                                                <WrapperLineItemComment>
                                                                    <MultilineTooltip type={Constants.ML_TT_SKICRAMPON_CONDITION} />
                                                                </WrapperLineItemComment>
                                                            </WrapperLineProp>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-up-skicrampon_fill'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                </Grid>
                                            </WrapperGrid>
                                        </WrapperLRc>
                                    </WrapperLR>
                                </WrapperText>
                            }
                            <WrapperText>
                                <WrapperLR>
                                    <H5Title>{t('ui.update.tx_description')}</H5Title>
                                    <WrapperLRc>
                                        <WrapperLine>
                                            <WrapperLineItemLabelTop>
                                                {t('ui.insert.tx_ipDescription')} *
                                            </WrapperLineItemLabelTop>
                                            <WrapperLineItemTextarea>
                                                <ContainerTextarea>
                                                    <InputTextarea
                                                        id="ipDescription"
                                                        type="text"
                                                        rows="5"
                                                        wrap="soft"
                                                        maxLength={2000}
                                                        value={description}
                                                        onChange={this.handleChangeInputDescription}
                                                    />
                                                </ContainerTextarea>
                                            </WrapperLineItemTextarea>
                                        </WrapperLine>
                                        <WrapperTextLine>
                                            {t('ui.insert.tx_ipDescriptionTS')} <Italic>{char_left}</Italic> {t('ui.insert.tx_ipDescriptionTE')}
                                        </WrapperTextLine>
                                    </WrapperLRc>
                                </WrapperLR>
                            </WrapperText>


                            <WrapperText>

                                <WrapperLR>
                                    <H5Title>{t('ui.update.tx_publishTitle')}</H5Title>

                                    <WrapperLRc>
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('label_seller_type')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemText>
                                                <WrapperRadiogroup>
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="controlled-radio-buttons-group"
                                                            row={true}
                                                            value={seller_type}
                                                            onChange={this.handleChangeInputSeller_type}
                                                        >
                                                            <FormControlLabel value={Constants.USER_SELLER_TYPE_1} control={<Radio />} label={t("seller_type_1")} />
                                                            {/* Only private seller; onInclude check for fill on backOnError
                                                            <FormControlLabel value={Constants.USER_SELLER_TYPE_2} control={<Radio />} label={t("seller_type_2")} />
                                                            */}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </WrapperRadiogroup>
                                            </WrapperLineItemText>
                                            {/* Only private seller; onInclude check for fill on backOnError
                                            <WrapperLineItemComment>
                                                <Tooltip title={t('label_seller_type_help')} arrow>
                                                    <IconButton>
                                                        <Icon src={icon_info} />
                                                    </IconButton>
                                                </Tooltip>
                                            </WrapperLineItemComment>
                                            */}
                                        </WrapperLine>
                                        <WrapperLine>
                                            <WrapperLineItemLabel>
                                                {t('label_seller_name')} *
                                            </WrapperLineItemLabel>
                                            <WrapperLineItemValue>
                                                <InputText
                                                    id="ipSellerName"
                                                    type="text"
                                                    maxLength={35}
                                                    value={seller_name}
                                                    onChange={this.handleChangeInputSeller_name}
                                                />
                                            </WrapperLineItemValue>
                                            <WrapperLineItemComment>
                                                <MultilineTooltip type={Constants.ML_TT_SELLER_NAME_HELP} />
                                            </WrapperLineItemComment>
                                        </WrapperLine>
                                    </WrapperLRc>

                                    <WrapperLRc>
                                        <Paragraph>{t('ui.insert.tx_publishText1')} <a href={'/info/termsandconditions'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText2')}</a>. {t('ui.insert.tx_publishText3')} <a href={'/info/privacypolicy'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText4')}</a>.</Paragraph>
                                        {requiredFieldsError &&
                                            <Paragraph>
                                                <font color={Style.COLOUR_RED}>* {t('ui.insert.tx_requiredFieldsError')}!</font>
                                            </Paragraph>
                                        }
                                        {valueFieldsError &&
                                            <Paragraph>
                                                <font color={Style.COLOUR_RED}>* {t('ui.insert.tx_valueFieldsError')}!</font>
                                            </Paragraph>
                                        }
                                        <BtnPublish onClick={this.doUpdate}>{t('ui.update.bt_publish')}</BtnPublish>
                                        <BtnBack onClick={this.handleModalCancelOpen}>{t('ui.update.bt_back')}</BtnBack>
                                    </WrapperLRc>
                                </WrapperLR>
                            </WrapperText>


                        </ContainerMain >
                    }
                    {
                        pageState === Constants.STATE_SUCCESS &&
                        <ContainerMain>
                            <WrapperMain>
                                <WrapperCenter>
                                    <H5Title>{t('ui.update.tx_successTitle')}!</H5Title>
                                </WrapperCenter>
                                <WrapperText>{t('ui.update.tx_success_text')}</WrapperText>
                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/advert/view/" + this.state.id} >
                                        <BtnViewArticle >{t('ui.insert.bt_viewArticle')}</BtnViewArticle>
                                    </StyledLink>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/"} >
                                        <BtnHome >{t('ui.insert.bt_home')}</BtnHome>
                                    </StyledLink>
                                </WrapperCenter>
                            </WrapperMain>
                        </ContainerMain >
                    }
                    {
                        pageState === Constants.STATE_ERROR &&
                        <ContainerMain>
                            <WrapperMain>
                                <WrapperCenter>
                                    <H5Title>
                                        {t('ui.update.tx_errorTitle')}!
                                    </H5Title>
                                </WrapperCenter>
                                <WrapperText>{t('ui.update.tx_error_text')}</WrapperText>
                                <WrapperCenter>
                                    <BtnBackOnError style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleBtnBackOnError}>{t('ui.insert.bt_backOnError')}</BtnBackOnError>
                                </WrapperCenter>
                            </WrapperMain>
                        </ContainerMain >

                    }
                    {
                        pageState === Constants.STATE_NODATA &&
                        <ContainerMain>
                            <WrapperMain>
                                <WrapperCenter>
                                    <H5Title>{t('ui.update.tx_nodataTitle')}</H5Title>
                                </WrapperCenter>
                                <WrapperText>{t('ui.update.tx_nodata_text')}</WrapperText>
                            </WrapperMain>
                        </ContainerMain >

                    }
                </Container >
        )
    }

}

export default withTranslation()(withParams(Article_Update));