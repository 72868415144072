import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client'
import App from './app';
import './i18n/config';


const root = createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
