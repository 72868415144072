import React, { Component } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
min-width: 300px;
max-width: 600px;
width: 30%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnReset = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnProfile = styled(BtnWhite)`
margin: 5px 0px;
width: 100%;
`
const BtnHome = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

class Auth_Change_Email extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            emailVerify: '',
            emailError: false,
            pageState: Constants.STATE_INITIAL,
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);
    }

    handleChangeInputEmail = async event => {
        const email = event.target.value
        this.setState({ email })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    handleChangeInputEmailVerify = async event => {
        const emailVerify = event.target.value
        this.setState({ emailVerify })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    doChange = async () => {
        const anchor = this;

        const userData = Userfront.user;

        if (this.state.email !== this.state.emailVerify) {
            this.setState({ emailError: true })
            return;
        }

        if (!this.validateEmail(this.state.email)) {
            this.setState({ emailError: true })
            return;
        }

        const payload = {
            email: this.state.email,
            userId: userData.userId,
            userUuid: userData.userUuid,
            tenantId: userData.tenantId,
            options: {}
        };

        var verify_email_url = '';
        if (process.env.NODE_ENV !== 'production') {
            verify_email_url = Constants.DEV_USERFRONT_VERIFY_EMAIL_URL;
        } else {
            verify_email_url = process.env.REACT_APP_USERFRONT_VERIFY_EMAIL_URL;
        }

        const response = await fetch(verify_email_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Userfront.accessToken()}`
            },
            body: JSON.stringify(payload)
        })

        if (response.status === 400) {
            anchor.setState({ emailError: true })
        } else {
            anchor.setState({ pageState: Constants.STATE_SUCCESS });
            await new Promise(r => setTimeout(r, 1000));
            Userfront.logout({ redirect: false }).then(function (response) {
                window.location.href = '/auth/login';
            }
            );
        }
    }

    render() {
        const { t } = this.props

        const { email, emailVerify, emailError, pageState, isMobile } = this.state

        /* Prebuild Login
        const EmailResetForm = Userfront.build({
            toolId: "kobobk"
        });
        */

        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.change_email.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        {pageState === Constants.STATE_INITIAL &&
                            <Container>
                                <WrapperCenter>
                                    <WrapperText>
                                        {t('ui.change_email.tx_text_sndml')}
                                    </WrapperText>
                                    <ContainerFields>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-email"
                                                    label={t('ui.change_email.tx_email')}
                                                    value={email}
                                                    error={emailError}
                                                    required={true}
                                                    onChange={this.handleChangeInputEmail}
                                                    size={'small'}
                                                    autoComplete={'new-email'}
                                                    inputProps={{ maxLength: 50 }}
                                                />
                                            </FormControl>
                                        </WrapperField>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-email_verify"
                                                    label={t('ui.change_email.tx_email_verify')}
                                                    value={emailVerify}
                                                    error={emailError}
                                                    required={true}
                                                    onChange={this.handleChangeInputEmailVerify}
                                                    size={'small'}
                                                    autoComplete={'new-email'}
                                                    inputProps={{ maxLength: 50 }}
                                                />
                                            </FormControl>
                                        </WrapperField>
                                    </ContainerFields>
                                </WrapperCenter>

                                <WrapperCenter>
                                    <BtnReset style={{ "width": getButtonWidth((isMobile)) }} onClick={this.doChange}>{t('ui.change_email.bt_change')}</BtnReset>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth((isMobile)) }} to={"/user/profile"}>
                                        <BtnProfile>{t('ui.change_email.bt_toprofile')}</BtnProfile>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }
                        {pageState === Constants.STATE_SUCCESS &&
                            <Container>
                                <WrapperCenter>
                                    <WrapperText>
                                        {t('ui.change_email.tx_text_emchg_success')}
                                    </WrapperText>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth((isMobile)) }} to={"/user/profile"}>
                                        <BtnHome>{t('ui.change_email.bt_toprofile')}</BtnHome>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }

                        {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                        <EmailResetForm />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}
                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Change_Email));