function getButtonWidth (isMobile) {

    if (isMobile === true) {
        return "80%";
    }
    return "30%";

}

function getContainerFieldWidth (isMobile) {

    if (isMobile === true) {
        return "75%";
    }
    return "60%";

}

function getContentWidth (isMobile) {

    if (isMobile === true) {
        return "90%";
    }
    return "60%";

}

function getListWidth (isMobile) {

    if (isMobile === true) {
        return "95%";
    }
    return "80%";

}


module.exports = {
    getButtonWidth,
    getContainerFieldWidth,
    getContentWidth,
    getListWidth
}