import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import{ getButtonWidth } from '../util/index.js';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnHome = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

const BtnTest = styled(BtnBlue)`
margin: 5px 0px;
`


class Auth_Registered extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

    }

    //Dev only
    doCreate = async () => {
        const userData = Userfront.user;
        var payload = {
            mode: "test",
            action: "create",
            model: "user",
            record: {
                uuid: userData.userUuid
            }
        };
        await api.simulateWebhookCreateUser(payload).then(res => {
            console.log('Success creating user')
        }).catch(error => {
            console.log('Error creating user')
        })
    }

    render() {
        const { t } = this.props

        const { isMobile } = this.state

        //Website maintainence developer key
        const dev_key = localStorage.getItem("dev_key");



        return (
            <ContainerMain>
                <WrapperMain>
                    <WrapperCenter>
                        <Title>{t('ui.registered.tx_title')}</Title>
                        <Paragraph></Paragraph>
                    </WrapperCenter>
                    <WrapperCenter>
                        <WrapperText>{t('ui.registered.tx_text')}</WrapperText>
                    </WrapperCenter>
                    <WrapperCenter>
                        <StyledLink style={{ "width": getButtonWidth((isMobile) ) }} to={Constants.PATH_TO_ARTIKLES}>
                            <BtnHome >{t('ui.registered.bt_articles')}</BtnHome>
                        </StyledLink>
                    </WrapperCenter>
                    {dev_key === Constants.DEVELOPER_KEY &&
                        <WrapperCenter>
                            <BtnTest style={{ "width": getButtonWidth((isMobile) ) }} onClick={this.doCreate}>{"Dev: SIMULATE CREATE USER WEBHOOK"}</BtnTest>
                        </WrapperCenter>
                    }
                </WrapperMain>
            </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Registered));