import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed, BtnGreen } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth, getListWidth } from '../util/index.js';

import { format } from 'date-fns'
import Grid from '@mui/material/Unstable_Grid2';
import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import HelpView from '../components/HelpView';

import altimage from '../res/images/reski_altimage_sq_v1.png'
import icon_cal from '../res/icons/icon_cal_d_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_box from '../res/icons/icon_box_d_v1.png'


const style_dispute = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_dispute = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};


const style_accept = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_accept = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const StyledLinkHelp = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;
const ContainerText = styled.div.attrs({
})`
    width: 90%;
    display: flex;
    flex-flow: column;
    margin: 10px 15px;
`

const ContainerItem = styled.div.attrs({

})`
border-radius: 5px;
background-color: #FFFFFF;
`

const ContainerItemImage = styled.div.attrs({

})`
position: relative;
margin: 15px;
`

const WrapperField = styled.div.attrs({

})`
    width: 100%;
`


const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
    margin: 0px 10px 10px 0px ;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
    margin: 0px 0px 10px 0px ;
`
const WrapperSplit2LeftSM = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 10%;
    margin: 0px 10px 10px 0px ;
`

const WrapperPrice = styled.div.attrs({
})`
margin: 5px 0px;
    background-color: #FFFFFF;
    white-space: nowrap;
`
const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemPhoto = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemDetails = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemStatus = styled.div.attrs({
})`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemButtons = styled.div.attrs({
})`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperStatusDescription = styled.div.attrs({
})`
display: flex;
flex-direction: column;
justify-content: left;
align-items: left;
white-space: normal;
overflow-wrap: break-word;
`

const WrapperFlexTop = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperFlexMid = styled.div.attrs({
})`
flex: 1 1 auto;
`
const WrapperFlexBottom = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`
const ContainerFields = styled.div.attrs({
})`
`
const ContainerBtn = styled.div.attrs({
})`
width: 100%

`

const WrapperGridList = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const WrapperButtonLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const BtnView = styled(BtnBlue)`
    margin: 5px;
    width: 100%;
`
const BtnEdit = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`
const BtnDelete = styled(BtnWhite)`
    margin: 5px;
    width: 100%;
`
const BtnConfirm = styled(BtnGreen)`
    margin: 5px;
    width: 100%;
`

const BtnTrack = styled(BtnGrey)`
    margin: 5px;
width: 100%;
`

const BtnDispute = styled(BtnWhite)`
    margin: 5px;
    width: 100%;
`
const BtnHelp = styled(BtnWhite)`
    margin: 5px;
    width: 100%;
`

const BtnBack = styled(BtnGrey)`
`

const BtnBackOnError = styled(BtnGrey)`
`

const BtnDelete2 = styled(BtnRed)`
margin: 5px 10px;
justify-content: right;
`

const BtnAccept = styled(BtnGreen)`
margin: 5px 10px;
justify-content: right;
`

const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const Icon = styled.img.attrs({
    hight: '15',
    width: '15'
})`
margin-right: 5px;
`


class User_Purchases extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            modalAcceptId: '',
            modalAcceptOpen: false,
            modalDisputeId: '',
            modalDisputeOpen: false,
            modalDisputeState: Constants.STATE_INITIAL,
            disputeMessage: '',
            disputeMessageError: false,
            contactEmail: '',
            contactNumber: '',
            char_left: 500,
            isMobile
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const userData = Userfront.user;

        this.setState({
            contactEmail: userData.email,
            contactNumber: userData.data.phonenumber
        })

        await api.getPurchasesByUser().then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })
    }

    doConfirm = async () => {

        const confirmed = true;
        const id = this.state.modalAcceptId;

        const payload = { id, confirmed }

        await api.answerPurchaseDelivery(payload).then(res => {

            this.handleModalAcceptClose();

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })

    }

    doNavExt = url => async event => {
        event.preventDefault();
        window.open(url, "_blank");
    }


    doRefresh = async event => {
        await api.getPurchasesByUser().then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })
    }

    handleBtnBackOnError = async () => {
        this.setState({
            modalDisputeState: Constants.STATE_INITIAL
        })
    }

    handleChangeInputContactNumber = async event => {
        const contactNumber = event.target.value
        this.setState({ contactNumber })
    }

    handleBtnDispute = async () => {
        const anchor = this;

        const disputeMessage = this.state.disputeMessage;
        const contactEmail = this.state.contactEmail;
        const contactNumber = this.state.contactNumber;

        if (disputeMessage === '') {
            this.setState({
                disputeMessageError: true
            })
            return;
        }

        this.setState({
            modalDisputeState: Constants.STATE_PENDING
        })

        const confirmed = false;
        const id = this.state.modalDisputeId;
        const payload = { id, confirmed, disputeMessage, contactEmail, contactNumber }

        await api.answerPurchaseDelivery(payload).then(res => {

            anchor.setState({
                modalDisputeState: Constants.STATE_SUCCESS
            })

            this.doRefresh();

        }).catch(error => {
            anchor.setState({
                modalDisputeState: Constants.STATE_ERROR
            })
        })
    }


    handleDisputeMessageChange = async event => {
        const disputeMessage = event.target.value
        this.setState({ disputeMessage })
        const char_left = 500 - disputeMessage.length
        this.setState({ char_left })
        this.setState({ disputeMessageError: false })
    }

    handleModalDisputeOpen = id => async event => {
        event.preventDefault()

        const modalDisputeOpen = true;
        const modalDisputeId = id;
        this.setState({ modalDisputeOpen, modalDisputeId })
    }

    handleModalDisputeClose = async event => {
        const modalDisputeOpen = false
        this.setState({ modalDisputeOpen })
        await this.delay(250);
        this.setState({
            modalDisputeState: Constants.STATE_INITIAL
        })
    }


    handleModalAcceptOpen = id => async event => {
        event.preventDefault()

        const modalAcceptOpen = true;
        const modalAcceptId = id;
        this.setState({ modalAcceptOpen, modalAcceptId })
    }

    handleModalAcceptClose = async event => {
        const modalAcceptOpen = false
        this.setState({ modalAcceptOpen })
    }

    allowNav = (isRefund) => event => {
        if (isRefund === true) {
            event.preventDefault()
        }
    }

    delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    render() {
        const { articles, modalDisputeOpen, modalDisputeState, modalAcceptOpen, disputeMessage, disputeMessageError, char_left, contactNumber, contactEmail, isMobile } = this.state
        const { t } = this.props
        return (
            !Userfront.accessToken() ?
                <Navigate
                    to={{
                        pathname: "/auth/login",
                    }}
                />
                :
                <Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalDisputeOpen}
                        onClose={this.handleModalDisputeClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalDisputeOpen}>
                            <Box sx={isMobile === true ? m_style_dispute : style_dispute}>
                                {modalDisputeState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.purchases.tx_mod_dispute_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDisputeClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.purchases.tx_mod_dispute_text")}</Paragraph>
                                        <WrapperSplit>
                                            <WrapperSplit2Left>
                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-d_email"
                                                            label={t('ui.profile.tx_email')}
                                                            value={contactEmail}
                                                            disabled={true}
                                                            required={true}
                                                            inputProps={{ maxLength: 50 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>
                                            </WrapperSplit2Left>
                                            <WrapperSplit2Right>
                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-d_number"
                                                            label={t('ui.profile.tx_phonenumber')}
                                                            value={contactNumber}
                                                            onChange={this.handleChangeInputContactNumber}
                                                            inputProps={{ maxLength: 25 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>
                                            </WrapperSplit2Right>
                                        </WrapperSplit>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-message_d"
                                                    label={t('ui.view.tx_mod_report_message')}
                                                    value={disputeMessage}
                                                    onChange={this.handleDisputeMessageChange}
                                                    inputProps={{ maxLength: 500 }}
                                                    multiline
                                                    error={disputeMessageError}
                                                    required={true}
                                                    rows={5}
                                                    size={'small'}
                                                />

                                            </FormControl>
                                        </WrapperField>
                                        <Paragraph>
                                            {t('ui.contact.tx_message_ts')} <Italic>{char_left}</Italic> {t('ui.contact.tx_message_te')}
                                        </Paragraph>
                                        <WrapperButtonLine>
                                            <BtnDelete2 onClick={this.handleBtnDispute}> {t('ui.purchases.bt_dispute')}</BtnDelete2>
                                            <BtnCancel onClick={this.handleModalDisputeClose}> {t('ui.purchases.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalDisputeState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.purchases.tx_mod_dispute_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDisputeState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.purchases.tx_mod_dispute_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDisputeClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.purchases.tx_mod_dispute_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleModalDisputeClose}> {t('ui.purchases.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDisputeState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.purchases.tx_mod_dispute_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDisputeClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.purchases.tx_mod_dispute_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleBtnBackOnError}> {t('ui.purchases.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalAcceptOpen}
                        onClose={this.handleModalAcceptClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalAcceptOpen}>
                            <Box sx={isMobile === true ? m_style_accept : style_accept}>
                                <Container>
                                    <WrapperModalHeader>
                                        <H5TitleModal>{t("ui.purchases.tx_mod_accept_title")}</H5TitleModal>
                                        <BtnModalClose onClick={this.handleModalAcceptClose}>
                                            <IconModalClose src={icon_close} />
                                        </BtnModalClose>
                                    </WrapperModalHeader>
                                    <Paragraph>{t("ui.purchases.tx_mod_accept_text")}</Paragraph>
                                    <WrapperButtonLine>
                                        <BtnAccept onClick={this.doConfirm}> {t('ui.purchases.bt_accept')}</BtnAccept>
                                        <BtnCancel onClick={this.handleModalAcceptClose}> {t('ui.purchases.bt_cancel')}</BtnCancel>
                                    </WrapperButtonLine>
                                </Container>
                            </Box>
                        </Fade>
                    </Modal>
                    <ContainerMain>
                        <WrapperMain>
                            <WrapperCenter>
                                <Title>{t('ui.purchases.tx_title')}</Title>
                                <Paragraph></Paragraph>
                            </WrapperCenter>
                        </WrapperMain>
                        <WrapperCenter>
                            <ContainerFields style={{ "width": getListWidth(isMobile) }}>
                                {articles.length > 0 ?
                                    <Container>
                                        <WrapperGrid>
                                            <Grid container spacing={{ xs: 0, md: 0 }} >
                                                {articles.map((obj, index) => {
                                                    return (
                                                        <Grid xs={12} key={index}
                                                            sx={{
                                                                boxShadow: 3,
                                                                width: '100%',
                                                                height: '100%',
                                                                p: 0,
                                                                m: 2,
                                                                borderRadius: 0,
                                                            }}>

                                                            <ContainerItem key={articles[index]._id}>
                                                                <StyledLink to={"/advert/view/" + articles[index]._id} onClick={this.allowNav(articles[index].isRefund)} >

                                                                    <WrapperGridList>
                                                                        <Grid container
                                                                            width={1}
                                                                            height={1}
                                                                            direction="row"
                                                                            justifyContent="space-evenly"
                                                                            alignItems="center"
                                                                            spacing={{ xs: 0, sm: 0.5, lg: 1 }} >
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-purchase-photo'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerItemImage>
                                                                                        {articles[index].photo_elements[0] ?
                                                                                            <img src={articles[index].titlephoto_url} loading="lazy" alt={'...'} width="100%" />
                                                                                            :
                                                                                            <img src={altimage} alt={'...'} width="100%" />
                                                                                        }
                                                                                    </ContainerItemImage>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-purchase-details'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{articles[index].title}</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>
                                                                                            <Container>
                                                                                                Nr. {articles[index].purchase_order_no}
                                                                                            </Container>
                                                                                            <Container>
                                                                                                <Icon src={icon_cal} />{format(new Date(articles[index].datePurchased), 'dd.MM.yyyy')}
                                                                                            </Container>
                                                                                            <Container>
                                                                                                <Icon src={icon_box} />{t(articles[index].used_delivery)}
                                                                                            </Container>
                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>
                                                                                            <WrapperPrice>
                                                                                                {articles[index].status != Constants.STATUS_RESOLVED &&
                                                                                                    <WrapperLine>
                                                                                                        <WrapperLineItem>
                                                                                                            {articles[index].price > 0 &&
                                                                                                                <SubsubTitle><font color={Style.COLOUR_GREEN}>{articles[index].price} €</font></SubsubTitle>
                                                                                                            }
                                                                                                            {articles[index].price < 0 &&
                                                                                                                <SubsubTitle><font color={Style.COLOUR_RED}>{articles[index].price} €</font></SubsubTitle>
                                                                                                            }
                                                                                                            {articles[index].price === 0 &&
                                                                                                                <SubsubTitle><font color={Style.COLOUR_GREEN}>- €</font></SubsubTitle>
                                                                                                            }
                                                                                                        </WrapperLineItem>
                                                                                                        <WrapperLineItem>
                                                                                                            {articles[index].price_original != '-1' &&
                                                                                                                <H5Title><font color="#DB0000"><s><font color="#000000">{articles[index].price_original} €</font></s></font></H5Title>
                                                                                                            }
                                                                                                        </WrapperLineItem>
                                                                                                    </WrapperLine>
                                                                                                }
                                                                                            </WrapperPrice>
                                                                                        </WrapperFlexBottom>

                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-purchase-status'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{t('ui.marks.tx_list_status')}:</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>
                                                                                            <WrapperPrice>
                                                                                                {articles[index].status === Constants.STATUS_RESERVED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_BLUE}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_1 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_2 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_HANDOVER &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_SOLD &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_DISPUTED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_RED}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_RESOLVED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREY}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_TIMEOUT &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREY}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATE_REFUND_PENDING &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_ORANGE}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATE_PURCHASE_REFUNDED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("purchase_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("purchase_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }

                                                                                            </WrapperPrice>

                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>
                                                                                            {articles[index].status != Constants.STATUS_SOLD &&
                                                                                                <WrapperLine>
                                                                                                    <WrapperSplit2LeftSM>
                                                                                                    </WrapperSplit2LeftSM>
                                                                                                    <Container>
                                                                                                        <StyledLinkHelp to={"/info/contact/" + articles[index].purchase_order_no} >
                                                                                                            <BtnHelp>
                                                                                                                <HelpView />
                                                                                                            </BtnHelp>
                                                                                                        </StyledLinkHelp>
                                                                                                    </Container>
                                                                                                </WrapperLine>
                                                                                            }
                                                                                        </WrapperFlexBottom>
                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-purchase-actions'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperCenter>
                                                                                        <ContainerText>
                                                                                            <WrapperFlexTop>
                                                                                                {articles[index].isRefund === false &&
                                                                                                    <StyledLink to={"/advert/view/" + articles[index]._id} >
                                                                                                        <BtnView>{t('ui.purchases.bt_list_view')}</BtnView>
                                                                                                    </StyledLink>
                                                                                                }
                                                                                            </WrapperFlexTop>
                                                                                            <WrapperFlexMid>
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <ContainerBtn>
                                                                                                        <BtnConfirm onClick={this.handleModalAcceptOpen(articles[index]._id)}>{t('ui.purchases.bt_list_confirm')}</BtnConfirm>
                                                                                                    </ContainerBtn>

                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <ContainerBtn>
                                                                                                        <BtnTrack onClick={this.doNavExt(articles[index].shipping_tracking_article)}>{t('ui.adverts.bt_list_track_article')}</BtnTrack>
                                                                                                    </ContainerBtn>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_HANDOVER &&
                                                                                                    <ContainerBtn>
                                                                                                        <BtnConfirm onClick={this.handleModalAcceptOpen(articles[index]._id)}>{t('ui.purchases.bt_list_confirm')}</BtnConfirm>
                                                                                                    </ContainerBtn>

                                                                                                }
                                                                                            </WrapperFlexMid>
                                                                                            <WrapperFlexBottom>
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <ContainerBtn>
                                                                                                        <BtnDispute onClick={this.handleModalDisputeOpen(articles[index]._id)}>{t('ui.purchases.bt_list_dispute')}</BtnDispute>
                                                                                                    </ContainerBtn>

                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_HANDOVER &&
                                                                                                    <ContainerBtn>
                                                                                                        <BtnDispute onClick={this.handleModalDisputeOpen(articles[index]._id)}>{t('ui.purchases.bt_list_dispute')}</BtnDispute>
                                                                                                    </ContainerBtn>

                                                                                                }
                                                                                            </WrapperFlexBottom>
                                                                                        </ContainerText>
                                                                                    </WrapperCenter>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </WrapperGridList>

                                                                </StyledLink>
                                                            </ContainerItem>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </WrapperGrid>
                                    </Container>
                                    :
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph></Paragraph>
                                            <Paragraph>{t('ui.adverts.tx_noarticles')}</Paragraph>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </ContainerFields>
                        </WrapperCenter>
                    </ContainerMain>
                </Container>
        )
    }
}

export default withTranslation()(withParams(User_Purchases));