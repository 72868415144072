import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from '../components/Captcha';

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';

import { format } from 'date-fns'
import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


const style_loading = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`
const WrapperFieldarea = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
    padding-right: 20px;
`
const MWrapperFieldarea = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
width: 60%;
`

const MContainerFields = styled.div.attrs({
})`
width: 75%;
`

const WrapperRight = styled.div.attrs({
})`
display: flex;
width: 80%;
flex-direction: row;
justify-content: right;
align-items: center;
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const BtnSend = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnHome = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`
const BtnBackOnError = styled(BtnGrey)`
margin: 5px 0px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class Website_Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageState: Constants.STATE_INITIAL,
            sendingContact: false,
            isMobile,

            uuid: '',
            name: '',
            nameError: false,
            email: '',
            emailError: false,
            company: '',
            companyError: false,
            subject: '',
            subjectError: false,
            subjectDisabled: false,
            order_no: this.props.params.order_no,
            order_noError: false,
            order_noDisabled: false,
            message: '',
            messageError: false,
            char_left: 2500,

            captcha: '',
            captchaError: false,
            captchaRequired: true,

        }
    }

    handleChangeInputEmail = async event => {
        const email = event.target.value
        this.setState({ email })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
        if (this.state.nameError) {
            this.setState({ nameError: false })
        }
    }

    handleChangeInputSubject = async event => {
        const subject = event.target.value
        this.setState({ subject })
        if (this.state.subjectError) {
            this.setState({ subjectError: false })
        }
    }

    handleChangeInputCompany = async event => {
        const company = event.target.value
        this.setState({ company })
        if (this.state.companyError) {
            this.setState({ companyError: false })
        }
    }

    handleChangeInputOrder_no = async event => {
        const order_no = event.target.value
        this.setState({ order_no })
        if (this.state.order_noError) {
            this.setState({ order_noError: false })
        }
    }

    handleChangeInputMessage = async event => {
        const message = event.target.value
        this.setState({ message })
        const char_left = 2500 - message.length
        this.setState({ char_left })
        if (this.state.messageError) {
            this.setState({ messageError: false })
        }
    }

    handleChangeInputCaptcha = async event => {
        const captcha = event.target.value
        this.setState({ captcha })
        if (this.state.captchaError) {
            this.setState({ captchaError: false })
        }
    }

    handleBackOnError = async event => {
        const pageState = Constants.STATE_INITIAL
        this.setState({ pageState })
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const { t } = this.props

        if (typeof (this.props.params.order_no) != 'undefined') {
            this.setState({
                subject: t('ui.contact.subject_order'),
                subjectDisabled: true,
                order_noDisabled: true,
                order_no: ' - '
            })
        }



        if (Userfront.accessToken()) {
            const userData = Userfront.user;
            this.setState({
                email: userData.email,
                uuid: userData.userUuid,
                name: decodeURIComponent(userData.data.name) + ' ' + decodeURIComponent(userData.data.sirname),
                captchaRequired: false
            })
        } else {
            loadCaptchaEnginge(Constants.CAPTCHA_LENGTH, t('ui.contact.bt_reload_captcha'));
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    doSend = async () => {

        if (this.state.captchaRequired === true && validateCaptcha(this.state.captcha) === false) {
            this.setState({
                captchaError: true,
                captcha: ''
            })
            return;
        }

        const {
            uuid,
            email,
            name,
            subject,
            order_no,
            company,
            message
        } = this.state

        const anchor = this;

        var missingFields = false;
        if (email === '') {
            this.setState({ emailError: true })
            missingFields = true;
        }
        if (name === '') {
            this.setState({ nameError: true })
            missingFields = true;
        }
        if (subject === '') {
            this.setState({ subjectError: true })
            missingFields = true;
        }
        if (message === '') {
            this.setState({ messageError: true })
            missingFields = true;
        }

        if (missingFields) {
            return;
        }

        //Check rules
        if (!this.validateEmail(email)) {
            this.setState({ emailError: true })
            return;
        }

        this.setState({ sendingContact: true })

        const payload = {
            uuid,
            email,
            name,
            subject,
            order_no,
            company,
            message,
        }

        await api.sendContact(payload).then(res => {
            anchor.setState({
                pageState: Constants.STATE_SUCCESS,
                sendingContact: false
            })
        }).catch(error => {
            anchor.setState({
                pageState: Constants.STATE_ERROR,
                sendingContact: false
            })
        })
    }

    render() {
        const { pageState, sendingContact, captchaRequired, isMobile } = this.state
        const { name, nameError, email, emailError, company, companyError, subject, subjectError, subjectDisabled, message, messageError, char_left, captcha, captchaError, order_no, order_noError, order_noDisabled } = this.state
        const { t } = this.props

        return (
            <Container>
                <ContainerMain>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={sendingContact}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={sendingContact}>
                            <Box sx={style_loading}>
                                <WrapperCenter>
                                    <Paragraph>{t('ui.contact.tx_loading')}</Paragraph>
                                    <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                </WrapperCenter>
                            </Box>
                        </Fade>
                    </Modal>
                    <WrapperMain>
                        {pageState === Constants.STATE_INITIAL &&
                            <Container>
                                {isMobile == false ?
                                    <Container>
                                        <WrapperCenter>
                                            <Title>{t('ui.contact.tx_title')}</Title>
                                            <Paragraph></Paragraph>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <ContainerFields>
                                                <Paragraph>{t('ui.contact.tx_text')}</Paragraph>
                                            </ContainerFields>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <ContainerFields>
                                                <H5Title>{t('ui.contact.tx_contactdata')}</H5Title>
                                                <WrapperSplit>
                                                    <WrapperSplit2Left>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-name"
                                                                    label={t('ui.contact.tx_name')}
                                                                    value={name}
                                                                    error={nameError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputName}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size={'small'}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperSplit2Left>
                                                    <WrapperSplit2Right>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-email"
                                                                    label={t('ui.contact.tx_email')}
                                                                    value={email}
                                                                    error={emailError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputEmail}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size={'small'}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperSplit2Right>
                                                </WrapperSplit>
                                                <WrapperSplit>
                                                    <WrapperSplit2Left>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-company"
                                                                    label={t('ui.contact.tx_company')}
                                                                    value={company}
                                                                    error={companyError}
                                                                    required={false}
                                                                    onChange={this.handleChangeInputCompany}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size={'small'}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperSplit2Left>
                                                    <WrapperSplit2Right>
                                                    </WrapperSplit2Right>
                                                </WrapperSplit>
                                            </ContainerFields>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <ContainerFields>
                                                <H5Title>{t('ui.contact.tx_subjectdata')}</H5Title>
                                                <WrapperSplit>
                                                    <WrapperSplit2Left>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-subject"
                                                                    label={t('ui.contact.tx_subject')}
                                                                    value={subject}
                                                                    error={subjectError}
                                                                    required={true}
                                                                    disabled={subjectDisabled}
                                                                    onChange={this.handleChangeInputSubject}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size={'small'}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperSplit2Left>
                                                    <WrapperSplit2Right>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-order_no"
                                                                    label={t('ui.contact.tx_order_no')}
                                                                    value={order_no}
                                                                    error={order_noError}
                                                                    disabled={order_noDisabled}
                                                                    required={false}
                                                                    onChange={this.handleChangeInputOrder_no}
                                                                    inputProps={{ maxLength: 10 }}
                                                                    size={'small'}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperSplit2Right>
                                                </WrapperSplit>
                                                <WrapperFieldarea>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-message"
                                                            label={t('ui.contact.tx_message')}
                                                            value={message}
                                                            error={messageError}
                                                            required={true}
                                                            onChange={this.handleChangeInputMessage}
                                                            inputProps={{ maxLength: 2500 }}
                                                            multiline
                                                            rows={7}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperFieldarea>
                                                <Paragraph>
                                                    {t('ui.contact.tx_message_ts')} <Italic>{char_left}</Italic> {t('ui.contact.tx_message_te')}
                                                </Paragraph>
                                            </ContainerFields>
                                        </WrapperCenter>
                                        {captchaRequired === true &&
                                            <WrapperCenter>
                                                <ContainerFields>
                                                    <H5Title>{t('ui.contact.tx_nobot')}</H5Title>
                                                    <WrapperSplit>
                                                        <WrapperSplit2Left>
                                                            <WrapperCenter>
                                                                <LoadCanvasTemplate />
                                                            </WrapperCenter>
                                                        </WrapperSplit2Left>
                                                        <WrapperSplit2Right>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip-captcha"
                                                                        label={t('ui.contact.tx_captcha')}
                                                                        value={captcha}
                                                                        error={captchaError}
                                                                        required={false}
                                                                        onChange={this.handleChangeInputCaptcha}
                                                                        inputProps={{ maxLength: Constants.CAPTCHA_LENGTH }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperSplit2Right>
                                                    </WrapperSplit>
                                                </ContainerFields>
                                            </WrapperCenter>
                                        }
                                        <Paragraph></Paragraph>
                                        <WrapperCenter>
                                            <ContainerFields>
                                                <Paragraph>
                                                    <Paragraph>{t('ui.insert.tx_publishText1')} <a href={'/info/termsandconditions'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText2')}</a>. {t('ui.insert.tx_publishText3')} <a href={'/info/privacypolicy'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText4')}</a>.</Paragraph>
                                                </Paragraph>
                                            </ContainerFields>
                                        </WrapperCenter>
                                        <WrapperRight>
                                            <Container style={{ "width": "20%" }}>
                                                <BtnSend onClick={this.doSend}>{t('ui.contact.bt_send')}</BtnSend>
                                            </Container>
                                        </WrapperRight>
                                    </Container>

                                    :

                                    <Container>
                                        <WrapperCenter>
                                            <Title>{t('ui.contact.tx_title')}</Title>
                                            <Paragraph></Paragraph>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <MContainerFields>
                                                <Paragraph>{t('ui.contact.tx_text')}</Paragraph>
                                            </MContainerFields>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <MContainerFields>
                                                <H5Title>{t('ui.contact.tx_contactdata')}</H5Title>
                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-name"
                                                            label={t('ui.contact.tx_name')}
                                                            value={name}
                                                            error={nameError}
                                                            required={true}
                                                            onChange={this.handleChangeInputName}
                                                            inputProps={{ maxLength: 50 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>
                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-email"
                                                            label={t('ui.contact.tx_email')}
                                                            value={email}
                                                            error={emailError}
                                                            required={true}
                                                            onChange={this.handleChangeInputEmail}
                                                            inputProps={{ maxLength: 50 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>

                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-company"
                                                            label={t('ui.contact.tx_company')}
                                                            value={company}
                                                            error={companyError}
                                                            required={false}
                                                            onChange={this.handleChangeInputCompany}
                                                            inputProps={{ maxLength: 50 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>
                                            </MContainerFields>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <MContainerFields>
                                                <H5Title>{t('ui.contact.tx_subjectdata')}</H5Title>

                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-subject"
                                                            label={t('ui.contact.tx_subject')}
                                                            value={subject}
                                                            error={subjectError}
                                                            required={true}
                                                            disabled={subjectDisabled}
                                                            onChange={this.handleChangeInputSubject}
                                                            inputProps={{ maxLength: 50 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>

                                                <WrapperField>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-order_no"
                                                            label={t('ui.contact.tx_order_no')}
                                                            value={order_no}
                                                            error={order_noError}
                                                            disabled={order_noDisabled}
                                                            required={false}
                                                            onChange={this.handleChangeInputOrder_no}
                                                            inputProps={{ maxLength: 10 }}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </WrapperField>
                                                <MWrapperFieldarea>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ip-message"
                                                            label={t('ui.contact.tx_message')}
                                                            value={message}
                                                            error={messageError}
                                                            required={true}
                                                            onChange={this.handleChangeInputMessage}
                                                            inputProps={{ maxLength: 2500 }}
                                                            multiline
                                                            rows={7}
                                                            size={'small'}
                                                        />
                                                    </FormControl>
                                                </MWrapperFieldarea>
                                                <Paragraph>
                                                    {t('ui.contact.tx_message_ts')} <Italic>{char_left}</Italic> {t('ui.contact.tx_message_te')}
                                                </Paragraph>
                                            </MContainerFields>
                                        </WrapperCenter>
                                        {captchaRequired === true &&
                                            <WrapperCenter>
                                                <MContainerFields>
                                                    <H5Title>{t('ui.contact.tx_nobot')}</H5Title>

                                                    <WrapperCenter>
                                                        <LoadCanvasTemplate />
                                                    </WrapperCenter>

                                                    <WrapperField>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="ip-captcha"
                                                                label={t('ui.contact.tx_captcha')}
                                                                value={captcha}
                                                                error={captchaError}
                                                                required={false}
                                                                onChange={this.handleChangeInputCaptcha}
                                                                inputProps={{ maxLength: Constants.CAPTCHA_LENGTH }}
                                                                size={'small'}
                                                            />
                                                        </FormControl>
                                                    </WrapperField>

                                                </MContainerFields>
                                            </WrapperCenter>
                                        }
                                        <Paragraph></Paragraph>
                                        <WrapperCenter>
                                            <MContainerFields>
                                                <Paragraph>
                                                    <Paragraph>{t('ui.insert.tx_publishText1')} <a href={'/info/termsandconditions'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText2')}</a>. {t('ui.insert.tx_publishText3')} <a href={'/info/privacypolicy'} target="_blank" rel="noreferrer noopener">{t('ui.insert.tx_publishText4')}</a>.</Paragraph>
                                                </Paragraph>
                                            </MContainerFields>
                                            <Container style={{ "width": "80%" }}>
                                                <WrapperCenter>
                                                    <BtnSend onClick={this.doSend}>{t('ui.contact.bt_send')}</BtnSend>
                                                </WrapperCenter>
                                            </Container>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Container>
                        }
                        {pageState === Constants.STATE_SUCCESS &&
                            <Container>
                                <WrapperCenter>
                                    <WrapperText>
                                        <SubTitle>{t('ui.contact.tx_success_title')}!</SubTitle>
                                    </WrapperText>
                                    <Paragraph>{t('ui.contact.tx_success_text')}</Paragraph>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <StyledLink to={"/"}>
                                        <BtnHome >{t('ui.contact.bt_home')}</BtnHome>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }
                        {pageState === Constants.STATE_ERROR &&
                            <Container>
                                <WrapperCenter>
                                    <WrapperText>
                                        <SubTitle>{t('ui.contact.tx_error_title')}!</SubTitle>
                                    </WrapperText>
                                    <Paragraph>{t('ui.contact.tx_error_text')}</Paragraph>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <BtnBackOnError onClick={this.handleBackOnError}>{t('ui.contact.bt_tocontact')}</BtnBackOnError>
                                </WrapperCenter>
                            </Container>
                        }
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_Contact));