import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'

import icon_grid from '../res/icons/icon_grid_d_v1.png'
import icon_ski from '../res/icons/icon_cat_ski_d_v1.png'
import { Col } from 'react-bootstrap';

const List = styled.div.attrs({
    className: 'navbar navbar-expand-lg',
})`
`

const Item = styled.div.attrs({
    className: 'nav-item',
})`
    margin: 0px 15px;
`

const WrapperGrid = styled.div.attrs({
})`
    margin-left: 15px;
`
const Icon = styled.img.attrs({
    hight: '40',
    width: '40'
})`
margin-right: 5px;
`

const Splitter = styled.hr.attrs({
    color: 'black',
    hight: 'auto',
    width: '1'
})`
margin: 0 0px;
`

class LinksArticle extends Component {
    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                <List>
                    <Item>
                        <Link to="/advert/all" aria-label="Alle" className="navbar-brand">
                            <Icon alt={'...'} src={icon_grid} />
                        </Link>
                    </Item>
                    <Item>
                        <Link to="/advert/ski" className="nav-link">
                            <Icon alt={'...'} src={icon_ski} />
                            <b>{t("label_ski")}</b>
                        </Link>
                    </Item>
                </List>
            </React.Fragment>
        )
    }
}

export default withTranslation()(LinksArticle)