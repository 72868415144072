import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'

const Collapse = styled.div.attrs({
    className: 'collpase navbar-collapse',
})``

const List = styled.div.attrs({
    className: 'navbar-nav mx-auto',
})``

const Item = styled.div.attrs({
    className: 'collpase navbar-collapse',
})`
`

const Splitter = styled.div.attrs({
   
})`
margin: 0 10px;
`

class LinksInfo extends Component {
    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                <Collapse                  
                     style={{ marginLeft: "10px" }}>
                    <List >
                    <Item>
                            <Link to="/" className="nav-link">
                                {t("ui.footer.tx_home")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/termsandconditions" className="nav-link">
                                {t("ui.footer.tx_termsandconditions")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/imprint" className="nav-link">
                                {t("ui.footer.tx_imprint")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/privacypolicy" className="nav-link">
                                {t("ui.footer.tx_privacypolicy")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/contact" className="nav-link">
                                {t("ui.footer.tx_contact")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/customerinfo" className="nav-link">
                                {t("ui.footer.tx_customerinfo")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/about" className="nav-link">
                                {t("ui.footer.tx_about")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/reviews" className="nav-link">
                                {t("ui.footer.tx_reviews")}
                            </Link>
                        </Item>
                        <Splitter />
                        <Item>
                            <Link to="/info/faq" className="nav-link">
                                {t("ui.footer.tx_faq")}
                            </Link>
                        </Item>
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }
}

export default withTranslation()(LinksInfo)