import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI.jsx'


import * as Constants from '../constants/index.js'

import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import icon_help from '../res/icons/icon_help_d_v1.png'
import altimage from '../res/images/reski_altimage_sq_v1.png'

const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ContainerTooltip = styled.div.attrs({
})`
margin: 10px;
`

const IconHelp = styled(Icon)`
margin-left: 5px;
`
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        maxWidth: 1200,
        fontSize: '15px',
        border: '2px solid #D1D1D1',
        borderRadius: '5px',
    },
}));

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class HelpView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: this.props.type,
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
    }

    render() {
        const { type } = this.state
        const { t } = this.props

        return (
            <HtmlTooltip title={
                <ContainerTooltip >
                    <React.Fragment>
                        {t('tt_help_text')}
                    </React.Fragment>
                </ContainerTooltip>
            } arrow>
                <Container>
                    <WrapperLine>
                        {t('tt_help')}
                        <IconHelp src={icon_help} />
                    </WrapperLine>
                </Container>
            </HtmlTooltip >
        )
    };
}

export default withTranslation()(withParams(HelpView));