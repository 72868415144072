import React, { Component } from 'react'

import styled from 'styled-components'
import LinksInfo from './LinksInfo'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

const Nav = styled.nav.attrs({
    className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
    margin-bottom: 20 px;
`

class BarInfo extends Component {
    render() {
        return (
            <Container>
                <Nav id="infobar">
                    <LinksInfo />
                </Nav>
            </Container>
        )
    }
}

export default BarInfo