import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnHoloBlue } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'
import PhotoCarousel from '../components/PhotoCarousel';
import MultilineTooltip from '../components/MultilineTooltip';

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import { format } from 'date-fns'
import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import BottomNavigation from '@mui/material/BottomNavigation';
import Grid from '@mui/material/Unstable_Grid2';

import icon_cal from '../res/icons/icon_cal_d_v1.png'
import icon_star from '../res/icons/icon_star_d_v1.png'
import icon_star_f from '../res/icons/icon_star_d_f_v1.png'
import icon_warn from '../res/icons/icon_warn_d_v1.png'
import icon_pos from '../res/icons/icon_pos_d_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_view from '../res/icons/icon_view_d_v1.png'
import icon_edit from '../res/icons/icon_edit_d_v1.png'


const style_report = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_report = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const Container34 = styled.div.attrs({
    className: 'container',
})`
   width: 100%;
   padding-top: 75%;
   position: relative;
`

const ContainerBtn = styled.div.attrs({
})`
width: 100%    
`
const ContainerBottomNavigation = styled.div.attrs({
})`
width: 100%;
`

const WrapperProperties = styled.div.attrs({
})`
    width: 30%;
    white-space: nowrap;
`
const WrapperSpace = styled.div.attrs({
})`
    width: 10%;
`

const WrapperArticle = styled.div.attrs({
})`
`

const WrapperActions = styled.div.attrs({
})`
`

const WrapperButtons = styled.div.attrs({
})`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const WrapperBtnReport = styled.div.attrs({
})`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
`


const WrapperRadiogroup = styled.div.attrs({
})`
    margin: 5px;
`

const Wrapper34 = styled.div.attrs({
})`
    padding: 15px;
    margin: 15px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 5px;
`
const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperMainLayout = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WrapperBtnTextLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperBtnTextLineItem = styled.div.attrs({
})`
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperButtonLine = styled.div.attrs({
})`
margin-bottom: 5px;
display: flex;
flex-direction: row;
justify-content: right;
align-items: center;
white-space: nowrap;
width: 100%;
`
const SpacerVert15 = styled.div.attrs({
})`
margin-bottom: 15px;
width: 100%;
`
const SpacerHori5 = styled.div.attrs({
})`
margin-left: 15px;
`

const WrapperGrid = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`


const BtnMark = styled(BtnWhite)`
    width: 100%;
`
const BtnCheckout = styled(BtnBlue)`
    width: 100%;
`
const BtnNoCheckout = styled(BtnHoloBlue)`
    width: 100%;    
`

const BtnToLogin = styled(BtnBlue)`
    width: 50%;
`

const BtnReport = styled(BtnGrey)`
    margin-bottom: 15px;
    width: 100%;
`
const BtnSendReport = styled(BtnBlue)`
    justify-content: right;
`

const BtnBack = styled(BtnGrey)`
`

const BtnBackOnError = styled(BtnGrey)`
`

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function getConditionColor(condition) {
    var color = '#000000'
    switch (condition) {
        case 'art_con_1':
            color = '#397F3B'
            break;
        case 'art_con_2':
            color = '#388e3c'
            break;
        case 'art_con_3':
            color = '#689f38'
            break;
        case 'art_con_4':
            color = '#afb42b'
            break;
        case 'art_con_5':
            color = '#fbc02d'
            break;
        case 'art_con_6':
            color = '#ffa000'
            break;
        default:
            break;
    }
    return color;
}

class Article_View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageState: Constants.STATE_INITIAL,
            modalReportOpen: false,
            modalLoginOpen: false,
            modalReportState: Constants.STATE_INITIAL,
            report_reason: Constants.REPORT_TYPE_1,
            report_message: '',
            valueReportMessageError: false,
            valueReportMessageRequired: false,
            char_left: 500,
            marked: false,
            status: Constants.STATUS_AVAILABLE,
            status_display: Constants.STATUS_AVAILABLE,
            isMobile,

            id: this.props.params.id,
            seller_name: '',
            seller_type: '',
            owner_uuid: '',
            taker_uuid: '',
            current_uuid: '',
            views: 0,
            title: '',
            description: '',
            price: '',
            price_original: '',
            category: '',
            delivery: '',
            delivery_location: '',
            dateCreated: '',
            properties1Title: '',
            properties2Title: '',
            properties3Title: '',
            properties4Title: '',
            properties1: [],
            properties2: [],
            properties3: [],
            properties4: [],
            condition1: '',
            condition2: '',
            condition3: '',
            condition4: '',
            condition1_tooltip: '',
            condition2_tooltip: '',
            condition3_tooltip: '',
            condition4_tooltip: '',
            condition_color1: '',
            condition_color2: '',
            condition_color3: '',
            condition_color4: ''
        }

    }

    handleModalReportOpen = async event => {
        const modalReportOpen = true;
        this.setState({ modalReportOpen })
    }

    handleModalReportClose = async event => {
        const modalReportOpen = false
        this.setState({ modalReportOpen })

    }

    handleModalLoginOpen = async event => {
        const modalLoginOpen = true;
        this.setState({ modalLoginOpen })
    }

    handleModalLoginClose = async event => {
        const modalLoginOpen = false
        this.setState({ modalLoginOpen })

    }

    handleDelivreyOptionChange = (event) => {
        const delivery_option = event.target.value
        this.setState({ delivery_option })
    }

    handleReportChange = (event) => {
        const report_reason = event.target.value
        if (report_reason === Constants.REPORT_TYPE_4) {
            this.setState({ valueReportMessageRequired: true })
        } else {
            this.setState({ valueReportMessageRequired: false })
            this.setState({ valueReportMessageError: false })
        }
        this.setState({ report_reason })
    }


    handleReportMessageChange = async event => {
        const report_message = event.target.value
        this.setState({ report_message })
        const char_left = 500 - report_message.length
        this.setState({ char_left })
        this.setState({ valueReportMessageError: false })
    }

    doMark = async event => {
        const anchor = this;

        const { id } = this.state
        const payload = { id }

        if (this.state.marked) {
            //demark

            await api.demarkArticleById(payload).then(res => {
                anchor.setState({
                    marked: false
                })
            }).catch(error => {
                //Todo error
            })
        } else {
            //mark

            await api.markArticleById(payload).then(res => {
                anchor.setState({
                    marked: true
                })
            }).catch(error => {
                //Todo error
            })

        }
    }

    doSendReport = async event => {
        const anchor = this;
        const uuid = Userfront.user.userUuid;
        var { report_reason, report_message, id } = this.state;
        const link = window.location.href;
        const report_reference = Constants.REPORT_ARTICLE;

        if (report_reason === Constants.REPORT_TYPE_4) {
            if (report_message === '') {
                this.setState({ valueReportMessageError: true })
                return;
            }
        }

        if (report_message === '') {
            report_message = ' - '
        }

        this.setState({
            modalReportState: Constants.STATE_PENDING
        })

        const payload = { uuid, id, report_reason, report_message, link, report_reference }

        await api.sendReport(payload).then(res => {
            anchor.setState({
                modalReportState: Constants.STATE_SUCCESS
            })
        }).catch(error => {
            anchor.setState({
                modalReportState: Constants.STATE_ERROR
            })
        })
    }

    handleBtnBack = async () => {
        this.setState({
            modalReportOpen: false
        })
    }

    handleBtnBackOnError = async () => {
        this.setState({
            modalReportState: Constants.STATE_INITIAL
        })
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        const anchor = this;


        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const { id } = this.state

        var view = 'true';
        var uuid = 'anonymous';
        if (Userfront.accessToken()) {
            uuid = Userfront.user.userUuid;
        }

        const article = await api.getArticleById(id, view, uuid)
            .catch(error => {
                anchor.setState({
                    pageState: Constants.STATE_NODATA
                })

            })

        this.setState({
            marked: article.data.data.marked,
        })

        const { t } = this.props

        function p(value, unit) {
            if (value > 1) {
                return unit + '_p'
            } else {
                return unit + '_s'
            }
        }

        function n(value, def) {
            if (value === Constants.GEN_NULL) {
                return def
            } else {
                return value
            }
        }

        function b(min, max) {
            var text = ''
            if (min !== -1 && max !== -1) {
                text = min + ' - ' + max
            }
            if (min === -1 && max !== -1) {
                text = 'max. ' + max
            }
            if (min !== -1 && max === -1) {
                text = 'min. ' + min
            }
            return text
        }
        function v(min, max) {
            var text = ''
            text = min + '-' + max
            return text;
        }

        //Article
        this.setState({
            dateCreated: format(new Date(article.data.data.dateCreated), 'dd.MM.yyyy'),
            title: article.data.data.title,
            description: article.data.data.description,
            price: article.data.data.price,
            category: article.data.data.category,
            delivery: article.data.data.delivery,
            seller_name: article.data.data.seller_name,
            seller_type: article.data.data.seller_type,
            owner_uuid: article.data.data.owner_uuid,
            taker_uuid: article.data.data.taker_uuid,
            current_uuid: uuid,
            views: article.data.data.views,
            status: article.data.data.status,
            status_display: article.data.data.status_display
        })

        if (article.data.data.delivery === Constants.ART_DEL_3) {
            this.setState({
                delivery_option: Constants.ART_DEL_3
            })

        }

        switch (article.data.data.category) {
            case Constants.ART_CAT_SKI:
                this.state.properties1Title = t('label_ski');
                this.state.properties2Title = t('label_binding');
                this.state.condition1 = article.data.data.ski_condition;
                this.state.condition2 = article.data.data.binding_condition;
                this.state.condition1_tooltip = Constants.ML_TT_SKI_CONDITION;
                this.state.condition2_tooltip = Constants.ML_TT_BINDING_CONDITION;
                if (article.data.data.ski_climbingskin === Constants.GEN_TRUE) {
                    this.state.properties3Title = t('label_climbingskin');
                    this.state.condition3 = article.data.data.climbingskin_condition;
                    this.state.condition3_tooltip = Constants.ML_TT_CLIMBINGSKIN_CONDITION;
                }
                if (article.data.data.binding_skicrampon === Constants.GEN_TRUE) {
                    this.state.properties4Title = t('label_skicrampon');
                    this.state.condition4 = article.data.data.skicrampon_condition;
                    this.state.condition4_tooltip = Constants.ML_TT_SKICRAMPON_CONDITION;
                }
                break;
            /*
            case Constants.ART_CAT_BOOT:
                this.state.properties1Title = t('label_boot');
                break;*/
            default:
                this.state.properties1Title = t('label_properties');
                break;
        }

        if (article.data.data.delivery === Constants.ART_DEL_1 || article.data.data.delivery === Constants.ART_DEL_3) {
            this.setState({
                delivery_location: article.data.data.delivery_country + " - " + article.data.data.delivery_postalcode + " " + article.data.data.delivery_city
            })
        }

        //Article        
        if (typeof (article.data.data.price_original) !== "undefined" && article.data.data.price_original !== -1) { this.state.price_original = article.data.data.price_original; }
        if (typeof (article.data.data.article_weight) !== "undefined" && article.data.data.article_weight !== -1) { this.state.properties1.push({ key: 'article_weight', label: t('label_article_weight'), value: article.data.data.article_weight + ' ' + t('label_article_weight_unit') }); }
        if (typeof (article.data.data.article_age) !== "undefined" && article.data.data.article_age !== -1) { this.state.properties1.push({ key: 'article_age', label: t('label_article_age'), value: article.data.data.article_age + ' ' + t(p(article.data.data.article_age, 'label_article_age_unit')) }); }
        if (typeof (article.data.data.article_daysontour) !== "undefined" && article.data.data.article_daysontour !== -1) { this.state.properties1.push({ key: 'article_daysontour', label: t('label_article_daysontour'), value: article.data.data.article_daysontour }); }

        //Ski
        if (typeof (article.data.data.ski_brand) !== "undefined" && article.data.data.ski_brand !== '') { this.state.properties1.push({ key: 'ski_brand', label: t('label_ski_brand'), value: n(article.data.data.ski_brand, t('other_brand')) }); }
        if (typeof (article.data.data.ski_model) !== "undefined" && article.data.data.ski_model !== '') { this.state.properties1.push({ key: 'ski_model', label: t('label_ski_model'), value: article.data.data.ski_model }); }
        if (typeof (article.data.data.ski_aptitude) !== "undefined" && article.data.data.ski_aptitude !== '') { this.state.properties1.push({ key: 'ski_aptitude', label: t('label_ski_aptitude'), value: t((article.data.data.ski_aptitude).toString()) }); }
        if (typeof (article.data.data.ski_length) !== "undefined" && article.data.data.ski_length !== -1) { this.state.properties1.push({ key: 'ski_length', label: t('label_ski_length'), value: article.data.data.ski_length + ' ' + t('label_ski_length_unit') }); }
        if (typeof (article.data.data.ski_width) !== "undefined" && article.data.data.ski_width !== -1) { this.state.properties1.push({ key: 'ski_width', label: t('label_ski_width'), value: article.data.data.ski_width + ' ' + t('label_ski_width_unit') }); }
        if (typeof (article.data.data.ski_radius) !== "undefined" && article.data.data.ski_radius !== -1) { this.state.properties1.push({ key: 'ski_radius', label: t('label_ski_radius'), value: article.data.data.ski_radius + ' ' + t('label_ski_radius_unit') }); }
        if (typeof (article.data.data.ski_rocker) !== "undefined" && article.data.data.ski_rocker !== '') { this.state.properties1.push({ key: 'ski_rocker', label: t('label_ski_rocker'), value: t((article.data.data.ski_rocker).toString()) }); }

        //Binding
        if (typeof (article.data.data.binding_brand) !== "undefined" && article.data.data.binding_brand !== '') { this.state.properties2.push({ key: 'binding_brand', label: t('label_binding_brand'), value: n(article.data.data.binding_brand, t('other_brand')) }); }
        if (typeof (article.data.data.binding_model) !== "undefined" && article.data.data.binding_model !== '') { this.state.properties2.push({ key: 'binding_model', label: t('label_binding_model'), value: article.data.data.binding_model }); }
        if (typeof (article.data.data.binding_aptitude) !== "undefined" && article.data.data.binding_aptitude !== '') { this.state.properties2.push({ key: 'binding_aptitude', label: t('label_binding_aptitude'), value: t((article.data.data.binding_aptitude).toString()) }); }
        if (typeof (article.data.data.binding_pin) !== "undefined" && article.data.data.binding_pin !== '') { this.state.properties2.push({ key: 'binding_pin', label: t('label_binding_pin'), value: t((article.data.data.binding_pin).toString()) }); }
        if (typeof (article.data.data.binding_stopper) !== "undefined" && article.data.data.binding_stopper !== '') { this.state.properties2.push({ key: 'binding_stopper', label: t('label_binding_stopper'), value: t((article.data.data.binding_stopper).toString()) }); }
        if (typeof (article.data.data.binding_length) !== "undefined" && article.data.data.binding_length !== -1) { this.state.properties2.push({ key: 'binding_length', label: t('label_binding_length'), value: article.data.data.binding_length + ' ' + t('label_binding_length_unit') }); }
        if (typeof (article.data.data.binding_length) !== "undefined" && article.data.data.binding_length !== -1) { if ((typeof (article.data.data.binding_length_min) !== "undefined" && article.data.data.binding_length_min !== -1) || (typeof (article.data.data.binding_length_max) !== "undefined" && article.data.data.binding_length_max !== -1)) { this.state.properties2.push({ key: 'binding_length_adjust', label: t('label_binding_length_adjust'), value: v(article.data.data.binding_length, article.data.data.binding_length_min, article.data.data.binding_length_max) + ' ' + t('label_binding_length_unit') }); } }
        if (typeof (article.data.data.binding_release_rear) !== "undefined") {
            if (article.data.data.binding_release_rear === Constants.GEN_TRUE) {
                this.state.properties2.push({ key: 'binding_release_rear', label: t('label_binding_release_rear'), value: t((article.data.data.binding_release_rear).toString()) });
                if ((typeof (article.data.data.binding_release_rear_min) !== "undefined" && article.data.data.binding_release_rear_min !== -1) || (typeof (article.data.data.binding_release_rear_max) !== "undefined" && article.data.data.binding_release_rear_max !== -1)) { this.state.properties2.push({ key: 'binding_release_rear_din', label: t('label_binding_release_rear_din'), value: b(article.data.data.binding_release_rear_min, article.data.data.binding_release_rear_max) }); }
            }
            else { this.state.properties2.push({ key: 'binding_release_rear', label: t('label_binding_release_rear'), value: t((article.data.data.binding_release_rear).toString()) }); }
        }

        if (typeof (article.data.data.binding_release_front) !== "undefined") {
            if (article.data.data.binding_release_front === Constants.GEN_TRUE) {
                this.state.properties2.push({ key: 'binding_release_front', label: t('label_binding_release_front'), value: t((article.data.data.binding_release_front).toString()) });
                if ((typeof (article.data.data.binding_release_front_min) !== "undefined" && article.data.data.binding_release_front_min !== -1) || (typeof (article.data.data.binding_release_front_miax) !== "undefined" && article.data.data.binding_release_front_max !== -1)) { this.state.properties2.push({ key: 'binding_release_front_din', label: t('label_binding_release_front_din'), value: b(article.data.data.binding_release_front_min, article.data.data.binding_release_front_max) }); }
            }
            else { this.state.properties2.push({ key: 'binding_release_front', label: t('label_binding_release_front'), value: t((article.data.data.binding_release_front).toString()) }); }
        }

        //Skicrampons
        if (typeof (article.data.data.climbingskin_brand) !== "undefined" && article.data.data.climbingskin_brand !== '') { this.state.properties3.push({ key: 'climbingskin_brand', label: t('label_climbingskin_brand'), value: n(article.data.data.climbingskin_brand, t('other_brand')) }); }
        if (typeof (article.data.data.climbingskin_model) !== "undefined" && article.data.data.climbingskin_model !== '') { this.state.properties3.push({ key: 'climbingskin_model', label: t('label_climbingskin_model'), value: article.data.data.climbingskin_model }); }
        if (typeof (article.data.data.climbingskin_adhesion) !== "undefined" && article.data.data.climbingskin_adhesion !== '') { this.state.properties3.push({ key: 'climbingskin_adhesion', label: t('label_climbingskin_adhesion'), value: t((article.data.data.climbingskin_adhesion).toString()) }); }
        if (typeof (article.data.data.climbingskin_material) !== "undefined" && article.data.data.climbingskin_material !== '') { this.state.properties3.push({ key: 'climbingskinclimbingskin_material_adhesion', label: t('label_climbingskin_material'), value: t((article.data.data.climbingskin_material).toString()) }); }


        //Skicrampons
        if (typeof (article.data.data.skicrampon_brand) !== "undefined" && article.data.data.skicrampon_brand !== '') { this.state.properties4.push({ key: 'skicrampon_brand', label: t('label_skicrampon_brand'), value: n(article.data.data.skicrampon_brand, t('other_brand')) }); }
        if (typeof (article.data.data.skicrampon_model) !== "undefined" && article.data.data.skicrampon_model !== '') { this.state.properties4.push({ key: 'skicrampon_model', label: t('label_skicrampon_model'), value: article.data.data.skicrampon_model }); }
        if (typeof (article.data.data.skicrampon_width) !== "undefined" && article.data.data.skicrampon_width !== -1) { this.state.properties4.push({ key: 'skicrampon_width', label: t('label_skicrampon_width'), value: article.data.data.skicrampon_width + ' ' + t('label_skicrampon_width_unit') }); }

        //Boot
        /*
        if (typeof (article.data.data.boot_brand) !== "undefined" && article.data.data.boot_brand !== '') { this.state.properties1.push({ key: 'boot_brand', label: t('label_boot_brand'), value: article.data.data.boot_brand }); }
        if (typeof (article.data.data.boot_model) !== "undefined" && article.data.data.boot_model !== '') { this.state.properties1.push({ key: 'boot_model', label: t('label_boot_model'), value: article.data.data.boot_model }); }
        if (typeof (article.data.data.boot_aptitude) !== "undefined" && article.data.data.boot_aptitude !== '') { this.state.properties1.push({ key: 'boot_aptitude', label: t('label_boot_aptitude'), value: t((article.data.data.boot_aptitude).toString()) }); }
        if (typeof (article.data.data.boot_pin) !== "undefined" && article.data.data.boot_pin !== '') { this.state.properties1.push({ key: 'boot_pin', label: t('label_boot_pin'), value: t((article.data.data.boot_pin).toString()) }); }
        if (typeof (article.data.data.boot_length) !== "undefined" && article.data.data.boot_length !== -1) { this.state.properties1.push({ key: 'boot_length', label: t('label_boot_length'), value: article.data.data.boot_length + ' ' + t('label_boot_length_unit') }); }
        if (typeof (article.data.data.boot_size) !== "undefined" && article.data.data.boot_size !== -1) { this.state.properties1.push({ key: 'boot_size', label: t('label_boot_size'), value: article.data.data.boot_size }); }
        if (typeof (article.data.data.boot_gender) !== "undefined" && article.data.data.boot_gender !== '') { this.state.properties1.push({ key: 'boot_gender', label: t('label_boot_gender'), value: t((article.data.data.boot_gender).toString()) }); }
        
        */


        this.setState({
            condition_color1: getConditionColor(this.state.condition1),
            condition_color2: getConditionColor(this.state.condition2),
            condition_color3: getConditionColor(this.state.condition3),
            condition_color4: getConditionColor(this.state.condition4)
        })
    }

    render() {
        const { id, dateCreated, title, description, price, price_original, category, delivery, delivery_location, seller_name, seller_type, views, marked, status_display, status, taker_uuid, current_uuid, owner_uuid } = this.state
        const { condition1, condition_color1, condition2, condition_color2, condition3, condition_color3, condition4, condition_color4, condition1_tooltip, condition2_tooltip, condition3_tooltip, condition4_tooltip } = this.state
        const { properties1Title, properties2Title, properties3Title, properties4Title, properties1, properties2, properties3, properties4 } = this.state
        const { modalReportOpen, modalLoginOpen, report_reason, report_message, char_left, valueReportMessageError, valueReportMessageRequired, modalReportState, isMobile } = this.state

        const { pageState } = this.state

        const { t } = this.props

        const getArticleWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "65%";
        }
        const getActionsWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "35%";
        }
        const getLayoutOrientation = () => {
            if (isMobile === true) {
                return "column";
            }
            return "row";
        }
        const getButtonOrientation = () => {
            if (isMobile === true) {
                return "row";
            }
            return "column";
        }

        const OptionButtons =
            <WrapperButtons style={{ "flex-direction": getButtonOrientation() }}>

                {isMobile === true && <SpacerHori5 ></SpacerHori5>}

                <BtnMark onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.doMark}>
                    <WrapperBtnTextLine>
                        <WrapperBtnTextLineItem>{marked ? <Icon alt={'...'} src={icon_star_f} /> : <Icon alt={'...'} src={icon_star} />}</WrapperBtnTextLineItem>
                        <WrapperBtnTextLineItem>{t("ui.view.tx_mark")}</WrapperBtnTextLineItem>
                    </WrapperBtnTextLine>
                </BtnMark>
                {isMobile === false && <SpacerVert15 ></SpacerVert15>}
                {isMobile === true && <SpacerHori5 ></SpacerHori5>}

                {status_display === Constants.STATUS_AVAILABLE && owner_uuid !== current_uuid &&
                    <ContainerBtn>
                        {!Userfront.accessToken() ?

                            <BtnCheckout onClick={this.handleModalLoginOpen}>{t("ui.view.bt_checkout")}</BtnCheckout>
                            :
                            <StyledLink to={"/purchase/checkout/" + id}>
                                <BtnCheckout >{t("ui.view.bt_checkout")}</BtnCheckout>
                            </StyledLink>
                        }
                    </ContainerBtn>
                }
                {status_display === Constants.STATUS_AVAILABLE && owner_uuid === current_uuid &&
                    <ContainerBtn>
                        <StyledLink to={"/advert/update/" + id} >
                            <BtnNoCheckout><Icon alt={'...'} src={icon_edit} /> {t('ui.adverts.bt_list_edit')}</BtnNoCheckout>
                        </StyledLink>
                    </ContainerBtn>
                }
                {status_display === Constants.STATUS_RESERVED &&
                    <ContainerBtn>
                        {(taker_uuid === current_uuid && status === Constants.STATUS_LOCKED) ?
                            <ContainerBtn>
                                <StyledLink to={"/purchase/checkout/" + id}>
                                    <BtnCheckout >{t("ui.view.bt_checkout")}</BtnCheckout>
                                </StyledLink>
                            </ContainerBtn>
                            :
                            <ContainerBtn>
                                <BtnNoCheckout>{t("ui.view.tx_currently_reservated")}</BtnNoCheckout>
                            </ContainerBtn>
                        }
                    </ContainerBtn>
                }
                {status_display === Constants.STATUS_UNAVAILABLE &&
                    <BtnNoCheckout>{t("ui.view.tx_currently_unavailable")}</BtnNoCheckout>
                }
                {status_display === Constants.STATUS_IN_TIMEOUT &&
                    <BtnNoCheckout>{t("ui.view.tx_soon_available")}</BtnNoCheckout>
                }
                {isMobile === true && <SpacerHori5 ></SpacerHori5>}
            </WrapperButtons>;

        return (
            <Container>
                {
                    pageState === Constants.STATE_INITIAL &&
                    <ContainerMain>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={modalReportOpen}
                            onClose={this.handleModalReportClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={modalReportOpen}>
                                <Box sx={isMobile === true ? m_style_report : style_report}>
                                    {modalReportState === Constants.STATE_INITIAL &&
                                        <Container>
                                            <WrapperModalHeader>
                                                <H5TitleModal>{t("ui.view.tx_mod_report_title")}</H5TitleModal>
                                                <BtnModalClose onClick={this.handleModalReportClose}>
                                                    <IconModalClose alt={'...'} src={icon_close} />
                                                </BtnModalClose>
                                            </WrapperModalHeader>
                                            <Paragraph><Bold>{t("ui.view.tx_mod_report_text1")}</Bold></Paragraph>
                                            <Paragraph>{t("ui.view.tx_mod_report_text2")}</Paragraph>
                                            <Bold>{t("ui.view.tx_mod_report_text3")}</Bold>
                                            <WrapperRadiogroup>
                                                <FormControl>
                                                    <RadioGroup
                                                        name="controlled-radio-buttons-group"
                                                        value={report_reason}
                                                        onChange={this.handleReportChange}
                                                    >
                                                        <FormControlLabel value={Constants.REPORT_TYPE_1} control={<Radio />} label={t("ui.view.tx_mod_report_reason_1")} />
                                                        <FormControlLabel value={Constants.REPORT_TYPE_2} control={<Radio />} label={t("ui.view.tx_mod_report_reason_2")} />
                                                        <FormControlLabel value={Constants.REPORT_TYPE_3} control={<Radio />} label={t("ui.view.tx_mod_report_reason_3")} />
                                                        <FormControlLabel value={Constants.REPORT_TYPE_4} control={<Radio />} label={t("ui.view.tx_mod_report_reason_4")} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </WrapperRadiogroup>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-message"
                                                    label={t('ui.view.tx_mod_report_message')}
                                                    value={report_message}
                                                    onChange={this.handleReportMessageChange}
                                                    inputProps={{ maxLength: 500 }}
                                                    multiline
                                                    error={valueReportMessageError}
                                                    required={valueReportMessageRequired}
                                                    rows={3}
                                                    size={'small'}
                                                />
                                            </FormControl>
                                            <Paragraph>
                                                {t('ui.contact.tx_message_ts')} <Italic>{char_left}</Italic> {t('ui.contact.tx_message_te')}
                                            </Paragraph>
                                            <WrapperButtonLine>
                                                <BtnSendReport onClick={this.doSendReport}> {t('ui.view.bt_sendreport')}</BtnSendReport>
                                            </WrapperButtonLine>
                                        </Container>
                                    }
                                    {modalReportState === Constants.STATE_PENDING &&
                                        <Container>
                                            <WrapperCenter>
                                                <Paragraph>{t("ui.view.tx_mod_report_pending_text")}</Paragraph>
                                                <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                            </WrapperCenter>
                                        </Container>
                                    }
                                    {modalReportState === Constants.STATE_SUCCESS &&
                                        <Container>
                                            <WrapperModalHeader>
                                                <H5TitleModal>{t("ui.view.tx_mod_report_successTitle")}</H5TitleModal>
                                                <BtnModalClose onClick={this.handleModalReportClose}>
                                                    <IconModalClose alt={'...'} src={icon_close} />
                                                </BtnModalClose>
                                            </WrapperModalHeader>
                                            <Paragraph>{t("ui.view.tx_mod_report_success_text")}</Paragraph>
                                            <WrapperCenter>
                                                <BtnBack onClick={this.handleBtnBack}> {t('ui.view.bt_back')}</BtnBack>
                                            </WrapperCenter>
                                        </Container>
                                    }
                                    {modalReportState === Constants.STATE_ERROR &&
                                        <Container>
                                            <WrapperModalHeader>
                                                <H5TitleModal>{t("ui.view.tx_mod_report_errorTitle")}</H5TitleModal>
                                                <BtnModalClose onClick={this.handleModalReportClose}>
                                                    <IconModalClose alt={'...'} src={icon_close} />
                                                </BtnModalClose>
                                            </WrapperModalHeader>
                                            <Paragraph>{t("ui.view.tx_mod_report_error_text")}</Paragraph>
                                            <WrapperCenter>
                                                <BtnBackOnError onClick={this.handleBtnBackOnError}> {t('ui.view.bt_backOnError')}</BtnBackOnError>
                                            </WrapperCenter>
                                        </Container>
                                    }
                                </Box>
                            </Fade>
                        </Modal>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={modalLoginOpen}
                            onClose={this.handleModalLoginClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={modalLoginOpen}>
                                <Box sx={isMobile === true ? m_style_login : style_login}>
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.view.tx_mod_login_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalLoginClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.view.tx_mod_login_text")}</Paragraph>
                                        <StyledLink to={"/auth/login/"} state={{ from: window.location.pathname }}>
                                            <WrapperCenter>
                                                <BtnToLogin >{t("ui.view.bt_mod_toLogin")}</BtnToLogin>
                                            </WrapperCenter>
                                        </StyledLink>
                                    </Container>
                                </Box>
                            </Fade>
                        </Modal>
                        <WrapperMainLayout style={{ "flex-direction": getLayoutOrientation() }}>
                            <WrapperArticle style={{ "width": getArticleWidth() }}>
                                <Container34>
                                    <Wrapper34>
                                        <PhotoCarousel params={{ id }} />
                                    </Wrapper34>
                                </Container34>
                                <WrapperText>
                                    <SubTitle>{title}</SubTitle>


                                    <WrapperGrid>
                                        <Grid container
                                            width={1}
                                            height={1}
                                            direction="row"
                                            justifyContent="left"
                                            alignItems="left"
                                            spacing={{ sm: 0, md: 0 }} >
                                            <Grid sm={10} md={5.5} key={'g-v-price'}
                                                sx={{
                                                    boxShadow: 0,
                                                    width: '100%',
                                                    p: 0,
                                                    m: 0,
                                                    borderRadius: 0
                                                }}>
                                                <WrapperPanel>
                                                    <WrapperLine>
                                                        <WrapperLineItem>
                                                            <SubsubTitle><font color="#229954">{price} €</font></SubsubTitle>
                                                        </WrapperLineItem>
                                                        {price_original != '' &&
                                                            <WrapperLineItem>
                                                                <H5Title><font color="#DB0000"><s><font color="#000000">{price_original} €</font></s></font></H5Title>
                                                            </WrapperLineItem>
                                                        }
                                                    </WrapperLine>
                                                </WrapperPanel>
                                            </Grid>
                                            <Grid sm={10} md={5.5} key={'g-v-delivery'}
                                                sx={{
                                                    boxShadow: 0,
                                                    width: '100%',
                                                    p: 0,
                                                    m: 0,
                                                    borderRadius: 0,
                                                }}>
                                                <WrapperPanel>
                                                    {t(delivery)}
                                                </WrapperPanel>
                                            </Grid>
                                        </Grid>
                                    </WrapperGrid>

                                    {delivery_location !== '' &&

                                        <Container><Icon alt={'...'} src={icon_pos} /> {delivery_location}</Container>
                                    }
                                    <Icon alt={'...'} src={icon_cal} /> {dateCreated}   <Icon alt={'...'} src={icon_view} /> {views}

                                </WrapperText>
                                {properties1Title !== '' ?
                                    <WrapperText>
                                        <WrapperSplit>
                                            <WrapperSplit2Left><Container><H5Title>{properties1Title}</H5Title></Container></WrapperSplit2Left>
                                            <WrapperSplit2Right>
                                                <Container>
                                                    <font color={condition_color1} ><Bold> {t(condition1)}</Bold></font>
                                                    <MultilineTooltip type={condition1_tooltip} />
                                                </Container>
                                            </WrapperSplit2Right>
                                        </WrapperSplit>
                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ xs: 0, md: 0.5, lg: 0.5 }} >

                                                {properties1.map((obj, index) => {
                                                    return (
                                                        <Grid xs={10} md={5.5} lg={3.5} key={'p1_' + index.toString()}
                                                            sx={{
                                                                boxShadow: 0,
                                                                width: '100%',
                                                                p: 0,
                                                                m: 0,
                                                                borderRadius: 0
                                                            }}>
                                                            <WrapperPanel>
                                                                <WrapperSplit key={index.toString()}>
                                                                    <WrapperSplit2Left>
                                                                        <Bold>{properties1[index].label}:</Bold>
                                                                    </WrapperSplit2Left>
                                                                    <WrapperSplit2Right>
                                                                        {properties1[index].value}
                                                                    </WrapperSplit2Right>
                                                                </WrapperSplit>
                                                            </WrapperPanel>
                                                        </Grid>
                                                    )
                                                })}
                                                {properties1.length % 2 === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p1_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </WrapperGrid>
                                    </WrapperText>
                                    : <Container />}

                                {properties2Title !== '' ?
                                    <WrapperText>
                                        <WrapperSplit>
                                            <WrapperSplit2Left><Container><H5Title>{properties2Title}</H5Title></Container></WrapperSplit2Left>
                                            <WrapperSplit2Right>
                                                <Container>
                                                    <font color={condition_color2} ><Bold> {t(condition2)}</Bold></font>
                                                    <MultilineTooltip type={condition2_tooltip} />
                                                </Container>
                                            </WrapperSplit2Right>
                                        </WrapperSplit>
                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ xs: 0, md: 0.5, lg: 0.5 }} >

                                                {properties2.map((obj, index) => {
                                                    return (
                                                        <Grid xs={10} md={5.5} lg={3.5} key={'p2_' + index.toString()}
                                                            sx={{
                                                                boxShadow: 0,
                                                                width: '100%',
                                                                p: 0,
                                                                m: 0,
                                                                borderRadius: 0
                                                            }}>
                                                            <WrapperPanel>
                                                                <WrapperSplit key={index.toString()}>
                                                                    <WrapperSplit2Left>
                                                                        <Bold>{properties2[index].label}:</Bold>
                                                                    </WrapperSplit2Left>
                                                                    <WrapperSplit2Right>
                                                                        {properties2[index].value}
                                                                    </WrapperSplit2Right>
                                                                </WrapperSplit>
                                                            </WrapperPanel>
                                                        </Grid>
                                                    )
                                                })}
                                                {properties2.length % 2 === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p2_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </WrapperGrid>
                                    </WrapperText>
                                    : <Container />}

                                {properties3Title !== '' ?
                                    <WrapperText>
                                        <WrapperSplit>
                                            <WrapperSplit2Left><Container><H5Title>{properties3Title}</H5Title></Container></WrapperSplit2Left>
                                            <WrapperSplit2Right>
                                                <Container><font color={condition_color3} ><Bold> {t(condition3)}</Bold></font>
                                                    <MultilineTooltip type={condition3_tooltip} />
                                                </Container>
                                            </WrapperSplit2Right>
                                        </WrapperSplit>
                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ xs: 0, md: 0.5, lg: 0.5 }} >

                                                {properties3.map((obj, index) => {
                                                    return (
                                                        <Grid xs={10} md={5.5} lg={3.5} key={'p3_' + index.toString()}
                                                            sx={{
                                                                boxShadow: 0,
                                                                width: '100%',
                                                                p: 0,
                                                                m: 0,
                                                                borderRadius: 0
                                                            }}>
                                                            <WrapperPanel>
                                                                <WrapperSplit key={index.toString()}>
                                                                    <WrapperSplit2Left>
                                                                        <Bold>{properties3[index].label}:</Bold>
                                                                    </WrapperSplit2Left>
                                                                    <WrapperSplit2Right>
                                                                        {properties3[index].value}
                                                                    </WrapperSplit2Right>
                                                                </WrapperSplit>
                                                            </WrapperPanel>
                                                        </Grid>
                                                    )
                                                })}
                                                {properties3.length % 2 === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p3_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                                {properties3.length === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p32_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </WrapperGrid>
                                    </WrapperText>
                                    : <Container />}
                                {properties4Title !== '' ?
                                    <WrapperText>
                                        <WrapperSplit>
                                            <WrapperSplit2Left><Container><H5Title>{properties4Title}</H5Title></Container></WrapperSplit2Left>
                                            <WrapperSplit2Right>
                                                <Container>
                                                    <font color={condition_color4} ><Bold> {t(condition4)}</Bold></font>
                                                    <MultilineTooltip type={condition4_tooltip} />
                                                </Container>
                                            </WrapperSplit2Right>
                                        </WrapperSplit>

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ xs: 0, md: 0.5, lg: 0.5 }} >

                                                {properties4.map((obj, index) => {
                                                    return (
                                                        <Grid xs={10} md={5.5} lg={3.5} key={'p4_' + index.toString()}
                                                            sx={{
                                                                boxShadow: 0,
                                                                width: '100%',
                                                                p: 0,
                                                                m: 0,
                                                                borderRadius: 0
                                                            }}>
                                                            <WrapperPanel>
                                                                <WrapperSplit key={index.toString()}>
                                                                    <WrapperSplit2Left>
                                                                        <Bold>{properties4[index].label}:</Bold>
                                                                    </WrapperSplit2Left>
                                                                    <WrapperSplit2Right>
                                                                        {properties4[index].value}
                                                                    </WrapperSplit2Right>
                                                                </WrapperSplit>
                                                            </WrapperPanel>
                                                        </Grid>
                                                    )
                                                })}
                                                {properties4.length % 2 === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p4_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                                {properties4.length === 1 &&
                                                    <Grid xs={10} md={5.5} lg={3.5} key={'p42_filler'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                        </WrapperPanel>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </WrapperGrid>
                                    </WrapperText>
                                    : <Container />}
                                <WrapperText>

                                    <H5Title>{t("ui.view.tx_description")}</H5Title>
                                    <Paragraph>{description}</Paragraph>
                                </WrapperText>
                            </WrapperArticle>
                            <WrapperActions style={{ "width": getActionsWidth(), "height": "80%" }}>
                                {isMobile === false &&
                                    <Container style={{ "margin": "15px" }}>
                                        {OptionButtons}
                                    </Container>
                                }
                                <WrapperText>
                                    <Bold>{t("ui.view.tx_seller")}:</Bold> {seller_name}
                                    {' ('}
                                    {seller_type === Constants.USER_SELLER_TYPE_1 && t('user_private')}
                                    {seller_type === Constants.USER_SELLER_TYPE_2 && t('user_commercial')}
                                    {')'}
                                </WrapperText>
                                <WrapperText>
                                    <Bold>{t("ui.view.tx_adid")}:</Bold> {id}
                                </WrapperText>
                                <WrapperBtnReport >
                                    <BtnReport onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.handleModalReportOpen}><Icon alt={'...'} src={icon_warn} /> {t("ui.view.tx_report")}</BtnReport>
                                </WrapperBtnReport>
                            </WrapperActions>
                        </WrapperMainLayout>
                        {isMobile === true &&
                            <BottomNavigation style={{ "background-color": "#212529" }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                                <ContainerBottomNavigation >
                                    {OptionButtons}
                                </ContainerBottomNavigation>
                            </BottomNavigation>
                        }
                    </ContainerMain>
                }
                {
                    pageState === Constants.STATE_NODATA &&
                    <ContainerMain>
                        <WrapperMain>
                            <WrapperCenter>
                                <WrapperText>
                                    <SubTitle>{t('ui.view.tx_nodataTitle')}</SubTitle>
                                </WrapperText>
                                <Paragraph>{t('ui.view.tx_nodata_text')}</Paragraph>
                            </WrapperCenter>
                        </WrapperMain>
                    </ContainerMain >

                }
            </Container >
        )
    }
}

export default withTranslation()(withParams(Article_View));