import styled from 'styled-components'

const BtnBlue = styled.button.attrs({
    className: `btn btn-primary`,
})`
`

const BtnHoloBlue = styled.button.attrs({
    className: `btn btn-primary`,
})`
color: #000000;
background-color: #EBEBEB;
&:hover {
    color: #FFFFFF;
    cursor: default;
  }
  `

const BtnRed = styled.button.attrs({
    className: `btn btn-secundary`,
})`
color: #FFFFFF;
background-color: #FC0F0F;
    &:hover {
        color: #FFFFFF;
        background-color: #C90A0A;
      }
`

const BtnWhite = styled.button.attrs({
    className: `btn btn-secundary`,
})`
    color: #000000;
    background-color: #FFFFFF;
    &:hover {
        background-color: #D1D1D1;
      }
    
`

const BtnGrey = styled.button.attrs({
    className: `btn btn-secundary`,
})`
    color: #000000;
    background-color: #EBEBEB;
    &:hover {
        background-color: #D1D1D1;
      }
    
`

const BtnGreen = styled.button.attrs({
    className: `btn btn-secundary`,
})`
color: #FFFFFF;
    background-color: #00960F;
    &:hover {
        color: #FFFFFF;
        background-color: #007F0C;
      }
    
`

export { BtnBlue, BtnWhite, BtnGrey, BtnRed, BtnGreen, BtnHoloBlue }