import React, { Component } from 'react'

import styled from 'styled-components'
import LinksNav from './LinksNav'

import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

import banner from '../res/images/reski_banner_v6.webp'
const banner_backup = 'https://ik.imagekit.io/reski/admin/reski_banner_v6_png.png'

const Spacer = styled.div.attrs({
})`
`

const Nav = styled.nav.attrs({
    className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
    margin-bottom: 20 px;
    width: 100%;
`

class Header extends Component {
    constructor(props) {
        super(props)

        this.bannerRef = React.createRef();
        this.barnavRef = React.createRef();
        this.spacerRef = React.createRef();
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        const that = this;
        window.addEventListener('scroll', this.handleScroll(that));
        return () => window.removeEventListener('scroll', this.handleScroll(that));
    }

    handleScroll = that => () => {

        if (window.scrollY >= that.bannerRef.current.offsetHeight) {
            that.spacerRef.current.style.marginBottom = that.barnavRef.current.offsetHeight + 'px';

            that.barnavRef.current.style.position = 'fixed';
            that.barnavRef.current.style.top = 0;
            that.barnavRef.current.style.left = 0;
            that.barnavRef.current.style.zIndex = 99;

        } else {
            that.spacerRef.current.style.marginBottom = '0px';

            that.barnavRef.current.style.removeProperty('position');
            that.barnavRef.current.style.removeProperty('top');
            that.barnavRef.current.style.removeProperty('left');
            that.barnavRef.current.style.removeProperty('zIndex');
        }
    }

    render() {
        return (
            <Container>
                <img id="banner" ref={this.bannerRef}
                    src={banner}
                    width="100%"
                    hight="100%"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = banner_backup;
                    }}
                />
                <Nav id="navbar" ref={this.barnavRef}>
                    <LinksNav />
                </Nav>
                <Spacer ref={this.spacerRef} />
            </Container>
        )
    }
}

export default Header