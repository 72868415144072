import React, { Component } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
min-width: 300px;
max-width: 600px;
width: 30%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnReset = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnProfile = styled(BtnWhite)`
margin: 5px 0px;
width: 100%;
`
const BtnHome = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

class Auth_Change_Password extends Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordOld: '',
            password: '',
            passwordVerify: '',
            passwordError: false,
            pageState: Constants.STATE_INITIAL,
            showPasswordOld: false,
            showPassword: false,
            showPasswordVerify: false,
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);
    }

    handleChangeInputPasswordOld = async event => {
        const passwordOld = event.target.value
        this.setState({ passwordOld })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({ password })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangeInputPasswordVerify = async event => {
        const passwordVerify = event.target.value
        this.setState({ passwordVerify })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangePasswordOldVisibility = async event => {
        this.setState({
            showPasswordOld: !this.state.showPasswordOld
        })
    }
    handleChangePasswordVisibility = async event => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    handleChangePasswordVerifyVisibility = async event => {
        this.setState({
            showPasswordVerify: !this.state.showPasswordVerify
        })
    }

    doChange = async () => {
        const anchor = this;

        if (this.state.password !== this.state.passwordVerify) {
            this.setState({ passwordError: true })
            return;
        }

        if (this.state.password.length < 16) {
            if (this.state.password.length < 8) {
                this.setState({ passwordError: true })
                return;
            } else {
                var regularExpression = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
                if (!regularExpression.test(this.state.password)) {
                    this.setState({ passwordError: true })
                    return;
                }
            }
        }

        await Userfront.resetPassword({
            password: this.state.password,
            existingPassword: this.state.passwordOld,
        }).then(function () {
            anchor.setState({ pageState: Constants.STATE_SUCCESS })
        }).catch(function (error) {
            anchor.setState({ passwordError: true })
        });

    }

    render() {
        const { t } = this.props

        const { passwordOld, password, passwordVerify, passwordError, pageState, showPasswordOld, showPassword, showPasswordVerify, isMobile } = this.state

        /* Prebuild Login
        const PasswordResetForm = Userfront.build({
            toolId: "kobobk"
        });
        */

        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.change_password.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        {pageState === Constants.STATE_INITIAL &&
                            <Container>
                                <WrapperCenter>
                                    <ContainerFields>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-passwordOld"
                                                    label={t('ui.change_password.tx_password_old')}
                                                    value={passwordOld}
                                                    error={passwordError}
                                                    required={true}
                                                    onChange={this.handleChangeInputPasswordOld}
                                                    size={'small'}
                                                    type={showPasswordOld ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleChangePasswordOldVisibility}
                                                                    onMouseDown={this.handleChangePasswordOldVisibility}
                                                                    edge="end"
                                                                >
                                                                    {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>,
                                                    }}
                                                    autoComplete={'current-password'}

                                                />
                                            </FormControl>
                                        </WrapperField>
                                    </ContainerFields>
                                    <WrapperText>
                                        {t('ui.change_password.tx_text_pwcrit')}
                                    </WrapperText>
                                    <ContainerFields>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-password"
                                                    label={t('ui.change_password.tx_password')}
                                                    value={password}
                                                    error={passwordError}
                                                    required={true}
                                                    onChange={this.handleChangeInputPassword}
                                                    size={'small'}
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleChangePasswordVisibility}
                                                                    onMouseDown={this.handleChangePasswordVisibility}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>,
                                                    }}
                                                    autoComplete={'new-password'}
                                                    inputProps={{ maxLength: 50 }}
                                                />
                                            </FormControl>
                                        </WrapperField>
                                        <WrapperField>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-password_verify"
                                                    label={t('ui.change_password.tx_password_verify')}
                                                    value={passwordVerify}
                                                    error={passwordError}
                                                    required={true}
                                                    onChange={this.handleChangeInputPasswordVerify}
                                                    size={'small'}
                                                    type={showPasswordVerify ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleChangePasswordVerifyVisibility}
                                                                    onMouseDown={this.handleChangePasswordVerifyVisibility}
                                                                    edge="end"
                                                                >
                                                                    {showPasswordVerify ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>,
                                                    }}
                                                    autoComplete={'new-password'}
                                                    inputProps={{ maxLength: 50 }}
                                                />
                                            </FormControl>
                                        </WrapperField>
                                    </ContainerFields>
                                </WrapperCenter>

                                <WrapperCenter>
                                    <BtnReset style={{ "width": getButtonWidth(isMobile) }} onClick={this.doChange}>{t('ui.change_password.bt_change')}</BtnReset>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/user/profile"}>
                                        <BtnProfile>{t('ui.change_password.bt_toprofile')}</BtnProfile>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }
                        {pageState === Constants.STATE_SUCCESS &&
                            <Container>
                                <WrapperCenter>
                                    <WrapperText>
                                        {t('ui.change_password.tx_text_pwchg_success')}
                                    </WrapperText>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/user/profile"}>
                                        <BtnHome>{t('ui.change_password.bt_toprofile')}</BtnHome>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }

                        {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                        <PasswordResetForm />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}
                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Change_Password));