import styled from 'styled-components'
import IconButton from '@mui/material/IconButton';

const H5TitleModal = styled.h5.attrs({
    className: 'h5',
})`
width: 100%;
`

const BtnModalClose = styled(IconButton)`
`
const WrapperModalHeader = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`
const IconModalClose = styled.img.attrs({
    hight: '15',
    width: '15'
})``


export { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose}