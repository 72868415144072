import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI.jsx'


import * as Constants from '../constants'

import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import icon_info from '../res/icons/icon_info_d_v1.png'
import altimage from '../res/images/reski_altimage_sq_v1.png'

const ContainerTooltip = styled.div.attrs({
})`
margin: 10px;
`

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        maxWidth: 1200,
        fontSize: '15px',
        border: '2px solid #D1D1D1',
        borderRadius: '5px',
    },
}));

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class MultilineTooltip extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: this.props.type,
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
    }

    render() {
        const { type } = this.state
        const { t } = this.props

        return (
            <HtmlTooltip title={
                <ContainerTooltip >
                    {type === Constants.ML_TT_SKI_CONDITION &&
                        <React.Fragment>
                            <H5Title>{t('tt_ski_art_con_title')}</H5Title>
                            <Line ><Bold>{t('art_con_1')}:</Bold> {t('tt_ski_art_con_text_1')}</Line>
                            <Line><Bold>{t('art_con_2')}:</Bold> {t('tt_ski_art_con_text_2')}</Line>
                            <Line><Bold>{t('art_con_3')}:</Bold> {t('tt_ski_art_con_text_3')}</Line>
                            <Line><Bold>{t('art_con_4')}:</Bold> {t('tt_ski_art_con_text_4')}</Line>
                            <Line><Bold>{t('art_con_5')}:</Bold> {t('tt_ski_art_con_text_5')}</Line>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_BINDING_CONDITION &&
                        <React.Fragment>
                            <H5Title>{t('tt_binding_art_con_title')}</H5Title>
                            <Line><Bold>{t('art_con_1')}:</Bold> {t('tt_binding_art_con_text_1')}</Line>
                            <Line><Bold>{t('art_con_2')}:</Bold> {t('tt_binding_art_con_text_2')}</Line>
                            <Line><Bold>{t('art_con_3')}:</Bold> {t('tt_binding_art_con_text_3')}</Line>
                            <Line><Bold>{t('art_con_4')}:</Bold> {t('tt_binding_art_con_text_4')}</Line>
                            <Line><Bold>{t('art_con_5')}:</Bold> {t('tt_binding_art_con_text_5')}</Line>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_CLIMBINGSKIN_CONDITION &&
                        <React.Fragment>
                            <H5Title>{t('tt_climbingskin_art_con_title')}</H5Title>
                            <Line><Bold>{t('art_con_1')}:</Bold> {t('tt_climbingskin_art_con_text_1')}</Line>
                            <Line><Bold>{t('art_con_2')}:</Bold> {t('tt_climbingskin_art_con_text_2')}</Line>
                            <Line><Bold>{t('art_con_3')}:</Bold> {t('tt_climbingskin_art_con_text_3')}</Line>
                            <Line><Bold>{t('art_con_4')}:</Bold> {t('tt_climbingskin_art_con_text_4')}</Line>
                            <Line><Bold>{t('art_con_5')}:</Bold> {t('tt_climbingskin_art_con_text_5')}</Line>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_SKICRAMPON_CONDITION &&
                        <React.Fragment>
                            <H5Title>{t('tt_skicrampon_art_con_title')}</H5Title>
                            <Line><Bold>{t('art_con_1')}:</Bold> {t('tt_skicrampon_art_con_text_1')}</Line>
                            <Line><Bold>{t('art_con_2')}:</Bold> {t('tt_skicrampon_art_con_text_2')}</Line>
                            <Line><Bold>{t('art_con_3')}:</Bold> {t('tt_skicrampon_art_con_text_3')}</Line>
                            <Line><Bold>{t('art_con_4')}:</Bold> {t('tt_skicrampon_art_con_text_4')}</Line>
                            <Line><Bold>{t('art_con_5')}:</Bold> {t('tt_skicrampon_art_con_text_5')}</Line>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_PHOTO_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_photo_upload_title')}</H5Title>
                            <Paragraph>{t('tt_photo_upload_text')}</Paragraph>
                            <img src={'https://ik.imagekit.io/reski/admin/photos_example_ski.jpg'} alt={'...'} width="100%" />
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_BINDING_LENGTH_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_binding_length_title')}</H5Title>
                            <Paragraph>{t('tt_binding_length_text')}</Paragraph>
                            <img src={'https://ik.imagekit.io/reski/admin/photos_example_binding.jpg'} alt={'...'} width="100%" />
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_ROCKER_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_rocker_title')}</H5Title>
                            <Paragraph>{t('tt_rocker_text')}</Paragraph>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_WEIGHT_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_weight_title')}</H5Title>
                            <Paragraph>{t('tt_weight_text')}</Paragraph>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_SELLER_NAME_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_seller_name_title')}</H5Title>
                            <Paragraph>{t('tt_seller_name_text')}</Paragraph>
                        </React.Fragment>
                    }
                    {type === Constants.ML_TT_BRAND_HELP &&
                        <React.Fragment>
                            <H5Title>{t('tt_brand_title')}</H5Title>
                            <Paragraph>{t('tt_brand_text')}</Paragraph>
                        </React.Fragment>
                    }
                    
                </ContainerTooltip>
            } arrow>
                <IconButton title="Info">
                    <Icon src={icon_info} />
                </IconButton>
            </HtmlTooltip >
        )
    };
}

export default withTranslation()(withParams(MultilineTooltip));