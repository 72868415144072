import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'
import Grid from '@mui/material/Unstable_Grid2';

import altimage from '../res/images/reski_altimage_sq_v1.png'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemL = styled.div.attrs({
})`
    width: 50%;
  padding-right: 15px;
  justify-content: left;
  align-items: left;
`
const WrapperLineItemR = styled.div.attrs({
})`
    width: 50%;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemLL = styled.div.attrs({
})`
    width: 33%;
  justify-content: center;
  align-items: center;
  margin: 0px 5px 5px 0px;
`
const WrapperLineItemRR = styled.div.attrs({
})`
    width: 66%;
  padding-right: 15px;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 5px 0px;
`


const WrapperLineItem = styled.div.attrs({
})`
    width: 33%
  padding-right: 15px;
  justify-content: center;
  align-items: center;
`

const Splitter = styled.hr.attrs({
    color: '#FFFFFF',
    width: 'auto'
})`
margin: 50px 25px;
`

const WrapperGrid = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`


class Website_About extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { t } = this.props
        return (
            <Container>

                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <ContainerText>
                                <WrapperCenter>
                                    <Title>{t('ui.about.tx_title')}</Title>
                                </WrapperCenter>
                                <Paragraph></Paragraph>
                                <Paragraph>
                                    reski.net ist ein 2023 gegründetes Startup mit dem Ziel, DIE Plattform für gebrauchte Skitourenausrüstung im Alpenraum zu werden. Dahinter stehen zwei Persönlichkeiten, die sich genau das zur Aufgabe gemacht haben.
                                </Paragraph>

                                <Splitter></Splitter>

                                <WrapperGrid>
                                    <Grid container
                                        width={1}
                                        height={1}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        spacing={{ md: 0, lg: 1 }} >
                                        <Grid md={10} lg={5} key={'part_1'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0
                                            }}>
                                            <WrapperPanel>
                                                <Paragraph></Paragraph>
                                                <Paragraph style={{ fontSize: 'large' }}>
                                                    <Bold>Michael Franz</Bold><br />
                                                    IT & Web
                                                </Paragraph>
                                                <Paragraph>
                                                    <ul>
                                                        <Line>M. Sc. Wirtschaftsinformatik Universität Regensburg</Line>
                                                        <Line>SAP Entwickler & Berater</Line>
                                                        <Line>(Sport-)klettern, Bergsteigen, MTB, Skitour</Line>
                                                    </ul>
                                                </Paragraph>
                                                <Paragraph>
                                                    <Italic>„reski.net bietet mir die Möglichkeit, gebrauchte Skitourenausrüstung unkompliziert zu kaufen und verkaufen.“</Italic>
                                                </Paragraph>
                                            </WrapperPanel>
                                        </Grid>
                                        <Grid md={10} lg={6} key={'part_2'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0,
                                            }}>
                                            <WrapperPanel>
                                                <img src={'https://ik.imagekit.io/reski/admin/mf_04_lq_v2.jpg'} alt={'...'} width="100%" />
                                                <img src={'https://ik.imagekit.io/reski/admin/mf_03_lq.jpg'} alt={'...'} width="100%" />
                                            </WrapperPanel>
                                        </Grid>
                                    </Grid>
                                </WrapperGrid>

                                <Splitter></Splitter>

                                <WrapperGrid>
                                    <Grid container
                                        width={1}
                                        height={1}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        spacing={{ md: 0, lg: 1 }} >
                                        <Grid md={10} lg={6} key={'part_3'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0
                                            }}>
                                            <WrapperPanel>
                                                <img src={'https://ik.imagekit.io/reski/admin/lb_01_lq.jpg'} alt={'...'} width="100%" />
                                                <img src={'https://ik.imagekit.io/reski/admin/lb_02_lq.jpg'} alt={'...'} width="100%" />

                                            </WrapperPanel>
                                        </Grid>
                                        <Grid md={10} lg={5} key={'part_4'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0,
                                            }}>
                                            <WrapperPanel>
                                                <Paragraph></Paragraph>
                                                <Paragraph style={{ fontSize: 'large' }}>
                                                    <Bold>Lukas Brexler</Bold><br />
                                                    Strategic & Operations
                                                </Paragraph>
                                                <Paragraph>
                                                    <ul>
                                                        <Line>M. Eng. Business & Systems Engineering - HSHL Lippstadt</Line>
                                                        <Line>Manager Operational Excellence</Line>
                                                        <Line>Bergsteigen, Skitour, Sportklettern, Bergwacht</Line>
                                                    </ul>
                                                </Paragraph>
                                                <Paragraph>
                                                    <Italic>„reski.net ist für mich eine Plattform für mehr Nachhaltigkeit von Skitourenausrüstung.“</Italic>
                                                </Paragraph>

                                            </WrapperPanel>
                                        </Grid>
                                    </Grid>
                                </WrapperGrid>

                            </ContainerText>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_About));