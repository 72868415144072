import React, { Component } from "react";
import { useParams, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import{ getButtonWidth } from '../util/index.js';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const BtnLogout = styled(BtnBlue)`
margin: 5px 0px;
`

class Auth_Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);
    }

    doLogout = async () => {
        Userfront.logout({ redirect: false }).then(function (response) {
            window.location.reload();
        }
        );
    }

    render() {
        const { t } = this.props

        const { isMobile } = this.state

    
        /* Prebuild Logout
        const LogoutButton = Userfront.build({
            toolId: "lmrmrl"
        });*/


        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.logout.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <BtnLogout style={{ "width": getButtonWidth(isMobile ) }} onClick={this.doLogout}>{t('ui.logout.bt_logout')}</BtnLogout>
                        </WrapperCenter>

                        {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                            <LogoutButton />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}

                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Logout));