import { Component } from 'react';

import styled from 'styled-components'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

const BtnReload = styled(BtnGrey)`
    margin-bottom: 15px;
`

let captcha_value = '';
let captcha_number = '';
let captcha_btn_text = '';
let backgroundColor_value = '';
let fontColor_value = '';
let charMap_value = '';

export const loadCaptchaEnginge = (numberOfCharacters, btnText, backgroundColor = 'white', fontColor = 'black', charMap = '') => {

    backgroundColor_value = backgroundColor;
    fontColor_value = fontColor;
    charMap_value = charMap;
    captcha_number = numberOfCharacters;
    captcha_btn_text = btnText;

    let retVal = "";
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    switch(charMap){
        case "upper":
            charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        break;
        case "lower":
            charset = "abcdefghijklmnopqrstuvwxyz0123456789";
        break;
        case "numbers":
            charset = "0123456789";
        break;
        case "special_char":
            charset = "~`!@#$%^&*()_+-=[]{}\|:'<>,.?/";
        break;
    }

    let length = parseInt(numberOfCharacters);

    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let captcha = retVal;

    captcha_value = captcha;

    let length_height_canvas = Math.round(parseInt(length) / 3);

    let canvas = document.getElementById('canv'),
        ctx = canvas.getContext('2d'),
        img = document.getElementById('image');
    let text = captcha;
    let x = 12.5;
    let y = 15;
    let lineheight = 30;

    let canvas_height = (parseInt(length) - parseInt(length_height_canvas)) * 20;
    let lines = text.split('\n');
    let lineLengthOrder = lines.slice(0).sort(function (a, b) {
        return b.length - a.length;
    });
    ctx.canvas.width = parseInt(length) * 25;
    ctx.canvas.height = (lines.length * lineheight);

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    ctx.textBaseline = "middle";
    ctx.font = "italic 20px Arial";
    ctx.fillStyle = fontColor;


    let num = 0;
    for (let i = 0; i < parseInt(length); i++) {
        num = parseInt(num) + 1;
        let heigt_num = 20 * num;
        ctx.fillText(retVal[i], heigt_num, Math.round(Math.random() * (15 - 12) + 12));
    }

    document.getElementById("btn_reload_captcha").onclick = function () {
        loadCaptchaEnginge(captcha_number, captcha_btn_text, backgroundColor, fontColor, charMap);
    }

    document.getElementById("btn_reload_captcha").textContent = captcha_btn_text;
};

export const validateCaptcha = (userValue, reload = true) => {
    if (userValue != captcha_value) {
        if (reload == true) {
            loadCaptchaEnginge(captcha_number, captcha_btn_text, backgroundColor_value, fontColor_value, charMap_value);
        }

        return false;
    }

    else {
        return true;
    }
};


export class LoadCanvasTemplate extends Component {

    render() {

        return (
            <Container>
            <WrapperCenter>
                <canvas id="canv"></canvas>
                    <BtnReload id="btn_reload_captcha">Placeholder</BtnReload>
                </WrapperCenter>
            </Container>
        );
    }

};
