//Colors
export const COLOUR_GREEN = "#229954"
export const COLOUR_RED = "#ED5752"
export const COLOUR_BLUE = "#0C00F2"
export const COLOUR_ORANGE = "#FF6816"
export const COLOUR_YELLOW = "#EAC300"
export const COLOUR_BLACK = "#000000"
export const COLOUR_WHITE = "#FFFFFF"
export const COLOUR_GREY = "#7F7F7F"
export const COLOUR_BACKGROUND = "#EBEBEB"
