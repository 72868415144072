//.env for local testing
export const DEV_BASE_URL = 'http://localhost:9000'

export const DEV_USERFRONT_TENANT = "jb7w5zrb"

export const DEV_IMAGEKIT_UPLOAD_URL = 'https://upload.imagekit.io/api/v1/files/upload'
export const DEV_IMAGEKIT_PUBLIC_KEY = 'public_ZHn3VRnlARIvvLOnsObkQjQ/ivM='
export const DEV_IMAGEKIT_FOLDER = 'reski'

export const DEV_USERFRONT_SELF_URL = 'https://api.userfront.com/v0/self'
export const DEV_USERFRONT_VERIFY_EMAIL_URL = 'https://api.userfront.com/v0/auth/auth/verify/email'
export const DEV_USERFRONT_SIGNUP_URL = 'https://api.userfront.com/v0/auth/create'

export const DEV_MAINTAINENCE = 'false'

export const DEV_STRIPE_PRICE_SHIPMENT = 'price_1McEvYJdzNmTN3xKt3FWlXtz'
export const DEV_STRIPE_PRICE_SERVICEFEE = 'price_1McEwWJdzNmTN3xKDCmtMwjP'


//Website maintainence developer & tester key
export const DEVELOPER_KEY = 'Km1kirMWuyO3Ed7gOop2'
export const TESTER_KEY = 'hcZy28Vhvn0KQxUzTnJz'
export const DEVELOPER = 'developer'
export const TESTER = 'tester'


//Constants
export const GEN_TRUE = "gen_true"
export const GEN_FALSE = "gen_false"
export const GEN_NULL = "gen_null"

export const CAPTCHA_LENGTH = 6

export const PHOTO_SIZE_LIMIT = 10e6
export const PHOTO_AMOUNT_LIMIT = 8

export const PHOTO_HEIGHT = 840
export const PHOTO_WIDTH = 1120
export const PHOTO_QUALITY = 0.80
export const TITLEPHOTO_HEIGHT = 250
export const TITLEPHOTO_WIDTH = 250

export const ART_PRICE_MAX = 3000

export const PATH_TO_ARTIKLES = '/advert/ski'

export const ERROR_IMAGE_SIZE = "error_image_size"
export const ERROR_IMAGE_AMOUNT = "error_image_amount"

export const USER_SELLER_TYPE_1 = "user_seller_type_1"
export const USER_SELLER_TYPE_2 = "user_seller_type_2"

export const UNIT_GRAMM = "unit_gramm"
export const UNIT_KILOGRAMM = "unit_kilogramm"

export const STATE_SUCCESS = "state_success"
export const STATE_ERROR = "state_error"
export const STATE_PENDING = "state_pending"
export const STATE_INITIAL = "state_initial"
export const STATE_NODATA = "state_nodata"
export const STATE_LOCKED = "state_locked"


export const STATUS_AVAILABLE = "status_available"
export const STATUS_UNAVAILABLE = "status_unavailable"
export const STATUS_RESERVED = "status_reserved"
export const STATUS_LOCKED = "status_locked"
export const STATUS_IN_SHIPPING_1 = "status_in_shipping_1"
export const STATUS_IN_SHIPPING_2 = "status_in_shipping_2"
export const STATUS_IN_SHIPPING_3 = "status_in_shipping_3"
export const STATUS_IN_HANDOVER = "status_in_handover"
export const STATUS_IN_TIMEOUT = "status_in_timeout"
export const STATUS_DISPUTED = "status_disputed"
export const STATUS_SOLD = "status_sold"
export const STATUS_RESOLVED = "status_resolved"

export const STATE_PENDING_PAYMENT = "STATE_PENDING_PAYMENT"
export const STATE_PAYMENT_SUCCESS = "STATE_PAYMENT_SUCCESS"
export const STATE_PAYMENT_FAILURE = "STATE_PAYMENT_FAILURE"
export const STATE_PURCHASE_ACCEPTED = "STATE_PURCHASE_ACCEPTED"
export const STATE_PURCHASE_DECLINED = "STATE_PURCHASE_DECLINED"
export const STATE_PURCHASE_TIMEOUT = "STATE_PURCHASE_TIMEOUT"
export const STATE_REFUND_PENDING = "STATE_REFUND_PENDING"
export const STATE_SHIPPING_ORGANIZED = "STATE_SHIPPING_ORGANIZED"
export const STATE_SHIPPING_RESCHEDULED = "STATE_SHIPPING_RESCHEDULED"
export const STATE_HANDOVER_ORGANIZED = "STATE_HANDOVER_ORGANIZED"
export const STATE_PURCHASE_REFUNDED = "STATE_PURCHASE_REFUNDED"
export const STATE_DELIVERY_DISPUTED = "STATE_DELIVERY_DISPUTED"
export const STATE_DELIVERY_CONFIRMED = "STATE_DELIVERY_CONFIRMED"
export const STATE_DISPUTE_RESOLVED = "STATE_DISPUTE_RESOLVED"
export const STATE_INVALIDATED = "STATE_INVALIDATED"
export const STATE_DELETED = "STATE_DELETED"
export const STATE_COMPLETED = "STATE_COMPLETED"
export const STATE_CONCLUDED = "STATE_CONCLUDED"


export const SERVICE_OPTION_1 = "service_option_1"
export const SERVICE_OPTION_2 = "service_option_2"

export const COUNTRYCODE_GERMANY = "DE"
export const COUNTRYCODE_AUSTRIA = "AT"

export const REPORT_REVIEW = "report_review"
export const REPORT_ARTICLE = "report_article"

export const REPORT_TYPE_1 = "report_type_1"
export const REPORT_TYPE_2 = "report_type_2"
export const REPORT_TYPE_3 = "report_type_3"
export const REPORT_TYPE_4 = "report_type_4"

export const ART_CAT_SKI = "art_cat_ski"
export const ART_CAT_BOOT = "art_cat_boot"
export const ART_CAT_BINDING = "art_cat_binding"
export const ART_CAT_CLIMBINGSKIN = "art_cat_climbingskin"
export const ART_CAT_SKICRAMPON = "art_cat_skicrampon"

export const ART_CON_1 = "art_con_1"
export const ART_CON_2 = "art_con_2"
export const ART_CON_3 = "art_con_3"
export const ART_CON_4 = "art_con_4"
export const ART_CON_5 = "art_con_5"

export const ART_DEL_1 = "art_del_1"
export const ART_DEL_2 = "art_del_2"
export const ART_DEL_3 = "art_del_3"

export const ART_GEN_1 = "art_gen_1"
export const ART_GEN_2 = "art_gen_2"
export const ART_GEN_3 = "art_gen_3"

export const ROCKER_TYPE_1 = "rocker_type_1"
export const ROCKER_TYPE_2 = "rocker_type_2"
export const ROCKER_TYPE_3 = "rocker_type_3"
export const ROCKER_TYPE_4 = "rocker_type_4"
export const ROCKER_TYPE_5 = "rocker_type_5"

export const ADHESION_TYPE_1 = "adhesion_type_1"
export const ADHESION_TYPE_2 = "adhesion_type_2"

export const SKI_TYPE_1 = "ski_type_1"
export const BINDING_TYPE_1 = "binding_type_1"
export const BOOT_TYPE_1 = "boot_type_1"

export const SKI_APTITUDE_1 = "ski_aptitude_1"
export const SKI_APTITUDE_2 = "ski_aptitude_2"
export const SKI_APTITUDE_3 = "ski_aptitude_3"
export const SKI_APTITUDE_4 = "ski_aptitude_4"

export const BINDING_APTITUDE_1 = "binding_aptitude_1"
export const BINDING_APTITUDE_2 = "binding_aptitude_2"
export const BINDING_APTITUDE_3 = "binding_aptitude_3"
export const BINDING_APTITUDE_4 = "binding_aptitude_4"

export const BOOT_APTITUDE_1 = "boot_aptitude_1"
export const BOOT_APTITUDE_2 = "boot_aptitude_2"
export const BOOT_APTITUDE_3 = "boot_aptitude_3"
export const BOOT_APTITUDE_4 = "boot_aptitude_4"


export const ML_TT_SKI_CONDITION = "ml_tt_ski_condition"
export const ML_TT_BINDING_CONDITION = "ml_tt_binding_condition"
export const ML_TT_CLIMBINGSKIN_CONDITION = "ml_tt_climbingskin_condition"
export const ML_TT_SKICRAMPON_CONDITION = "ml_tt_skicrampon_condition"
export const ML_TT_PHOTO_HELP = "ml_tt_photo_help"
export const ML_TT_BINDING_LENGTH_HELP = "ml_tt_binding_length_help"
export const ML_TT_ROCKER_HELP = "ml_tt_rocker_help"
export const ML_TT_WEIGHT_HELP = "ml_tt_weight_help"
export const ML_TT_SELLER_NAME_HELP = "ml_tt_seller_name_help"


export const ML_TT_BRAND_HELP = "ml_tt_brand_help"


export const SORT_ORDER_PRICE_ASC = "price&1"
export const SORT_ORDER_PRICE_DSC = "price&-1"
export const SORT_ORDER_DATE_ASC = "dateCreated&1"
export const SORT_ORDER_DATE_DSC = "dateCreated&-1"