import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import { isMobile } from 'react-device-detect';
import{ getButtonWidth} from '../util/index.js';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnArticles = styled(BtnWhite)`
    margin: 5px 0px;
    width: 100%;
`
const BtnPurchases = styled(BtnBlue)`
    margin: 5px 0px;
    width: 100%;
`
const BtnHome = styled(BtnGrey)`
    margin: 5px 0px;
    width: 100%;
`

class Purchase_Success extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { isMobile } = this.state
        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.pur_success.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <Paragraph>{t('ui.pur_success.tx_text')}</Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/user/purchases"}>
                                <BtnPurchases >{t('ui.pur_success.bt_purchases')}</BtnPurchases>
                            </StyledLink>
                            <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={Constants.PATH_TO_ARTIKLES}>
                                <BtnArticles >{t('ui.pur_success.bt_articles')}</BtnArticles>
                            </StyledLink>
                            <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/"}>
                                <BtnHome >{t('ui.pur_success.bt_home')}</BtnHome>
                            </StyledLink>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Purchase_Success));