import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth, getContainerFieldWidth } from '../util/index.js';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`

const WrapperFieldButton = styled.div.attrs({

})`
    width: 100%;
    margin: 5px;
`

const ContainerFields = styled.div.attrs({
})`
width: 60%;
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperSplitStreet = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplitStreetnr = styled.div.attrs({
})`
  display: flex;
  width: 30%;
`

const WrapperSplitAddress1 = styled.div.attrs({
})`
  display: flex;
  width: 70%;
  margin-Left: 10px;
`
const WrapperSplitCountry = styled.div.attrs({
})`
  display: flex;
  width: 30%;
`
const WrapperSplitPostalcode = styled.div.attrs({
})`
  display: flex;
  width: 70%;
  margin-Left: 10px;
`

const WrapperSplitCity = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperSplitPhonenumber = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`

const WrapperLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
`

const WrapperButtonLine = styled.div.attrs({
})`
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const WrapperAntiEdit = styled.div.attrs({
})`
  width: 75%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 5px;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;


const WrapperGrid = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`

const WrapperFields = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`




const BtnChangePassword = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`
const BtnChangeEmail = styled(BtnGrey)`
margin: 5px 0px;
    width: 100%;
`
const BtnEdit = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`
const BtnUpdate = styled(BtnBlue)`
margin: 5px 10px;
`
const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`
const BtnBackOnError = styled(BtnGrey)`
margin: 5px 0px;
`

class User_Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            placeholder: '',
            pageState: Constants.STATE_INITIAL,

            editPersonalInfo: false,
            editSellerInfo: false,

            email: '',
            emailDisabled: true,
            password: '********',
            passwordDisabled: true,


            sirname: '',
            sirnameOriginal: '',
            sirnameDisabled: true,
            sirnameError: false,
            name: '',
            nameOriginal: '',
            nameDisabled: true,
            nameError: false,
            isSeller: false,
            isSellerOriginal: false,
            isSellerDisabled: true,

            phonenumber: '',
            phonenumberOriginal: '',
            phonenumberError: false,
            phonenumberDisabled: true,

            street: '',
            streetOriginal: '',
            streetDisabled: true,
            streetError: false,
            streetnr: '',
            streetnrOriginal: '',
            streetnrDisabled: true,
            streetnrError: false,
            address1: '',
            address1Original: '',
            address1Disabled: true,
            address1Error: false,
            postalcode: '',
            postalcodeOriginal: '',
            postalcodeDisabled: true,
            postalcodeError: false,
            city: '',
            cityOriginal: '',
            cityDisabled: true,
            cityError: false,
            country: '',
            countryOriginal: '',
            countryDisabled: true,
            countryError: false,

            iban: '',
            ibanOriginal: '',
            ibanDisabled: true,
            ibanError: false,
            ibanSuccess: '',

            share_phonenumber: false,
            isMobile
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        const anchor = this;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const userData = Userfront.user;
        this.setState({
            email: userData.email,
            sirname: decodeURIComponent(userData.data.sirname),
            name: decodeURIComponent(userData.data.name),
            phonenumber: userData.data.phonenumber,
            street: decodeURIComponent(userData.data.street),
            streetnr: userData.data.streetnr,
            address1: decodeURIComponent(userData.data.address1),
            country: userData.data.country,
            postalcode: userData.data.postalcode,
            city: decodeURIComponent(userData.data.city),
            isSeller: userData.data.seller,
            iban: userData.data.iban,

            sirnameOriginal: decodeURIComponent(userData.data.sirname),
            nameOriginal: decodeURIComponent(userData.data.name),
            phonenumberOriginal: userData.data.phonenumber,
            streetOriginal: decodeURIComponent(userData.data.street),
            streetnrOriginal: userData.data.streetnr,
            address1Original: decodeURIComponent(userData.data.address1),
            countryOriginal: userData.data.country,
            postalcodeOriginal: userData.data.postalcode,
            cityOriginal: decodeURIComponent(userData.data.city),
            isSellerOriginal: userData.data.seller,
            ibanOriginal: userData.data.iban,
        })

        await api.getSettingsByUser().then(res => {
            anchor.setState({
                share_phonenumber: res.data.data.share_phonenumber
            })
        }).catch(error => {

        })
        /*
                let config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    }
                }

                var self_url = '';
                if (process.env.NODE_ENV !== 'production') {
                    self_url = Constants.DEV_USERFRONT_SELF_URL;
                } else {
                    self_url = process.env.REACT_APP_USERFRONT_SELF_URL;
                }
        
                await axios.get(self_url, config)
                    .then(function (response) {
                        anchor.setState({
                            email: response.data.email,
                            sirname: decodeURIComponent(response.data.data.sirname),
                            name: decodeURIComponent(response.data.data.name),
                            phonenumber: response.data.data.phonenumber,
                            street: decodeURIComponent(response.data.data.street),
                            streetnr: response.data.data.streetnr,
                            address1: decodeURIComponent(response.data.data.address1),
                            country: response.data.data.country,
                            postalcode: response.data.data.postalcode,
                            city: decodeURIComponent(response.data.data.city),
                            isSeller: response.data.data.seller,
                            iban: response.data.data.iban,
        
                            sirnameOriginal: decodeURIComponent(response.data.data.sirname),
                            nameOriginal: decodeURIComponent(response.data.data.name),
                            phonenumberOriginal: response.data.data.phonenumber,
                            streetOriginal: decodeURIComponent(response.data.data.street),
                            streetnrOriginal: response.data.data.streetnr,
                            address1Original: decodeURIComponent(response.data.data.address1),
                            countryOriginal: response.data.data.country,
                            postalcodeOriginal: response.data.data.postalcode,
                            cityOriginal: decodeURIComponent(response.data.data.city),
                            isSellerOriginal: response.data.data.seller,
                            ibanOriginal: response.data.data.iban,
                        })
        
                        anchor.setState({
                            placeholder: 'holding'
                        })
                    })
                    .catch(function (error) {
                    })
        */
    }

    handleChangeInputSharePhonenumber = async event => {
        const setting = "share_phonenumber"
        const value = !this.state.share_phonenumber
        const payload = { setting, value }

        if (this.state.share_phonenumber) {
            await api.updateSettingsByUser(payload).then(res => {
                this.setState({ share_phonenumber: false })
            }).catch(error => {
                //Todo error handeling
            })

        } else {
            await api.updateSettingsByUser(payload).then(res => {
                this.setState({ share_phonenumber: true })
            }).catch(error => {
                //Todo error handeling
            })
        }
    }

    handleChangeInputSirname = async event => {
        const sirname = event.target.value
        this.setState({ sirname })
        if (this.state.sirnameError) {
            this.setState({ sirnameError: false })
        }
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
        if (this.state.nameError) {
            this.setState({ nameError: false })
        }
    }

    handleChangeInputPhonenumber = async event => {
        const phonenumber = event.target.value
        this.setState({ phonenumber })
        if (this.state.phonenumberError) {
            this.setState({ phonenumberError: false })
        }
    }

    handleChangeInputStreet = async event => {
        const street = event.target.value
        this.setState({ street })
        if (this.state.streetError) {
            this.setState({ streetError: false })
        }
    }
    handleChangeInputStreetnr = async event => {
        const streetnr = event.target.value
        this.setState({ streetnr })
        if (this.state.streetnrError) {
            this.setState({ streetnrError: false })
        }
    }
    handleChangeInputAddress1 = async event => {
        const address1 = event.target.value
        this.setState({ address1 })
        if (this.state.address1Error) {
            this.setState({ address1Error: false })
        }
    }
    handleChangeInputPostalcode = async event => {
        const postalcode = event.target.value
        this.setState({ postalcode })
        if (this.state.postalcodeError) {
            this.setState({ postalcodeError: false })
        }
    }
    handleChangeInputCity = async event => {
        const city = event.target.value
        this.setState({ city })
        if (this.state.cityError) {
            this.setState({ cityError: false })
        }
    }
    handleChangeInputCountry = async event => {
        const country = event.target.value
        this.setState({ country })
        if (this.state.countryError) {
            this.setState({ countryError: false })
        }
    }
    handleChangeInputIsSeller = async event => {
        if (this.state.isSeller) {
            this.setState({ isSeller: false })
        } else {
            this.setState({ isSeller: true })
        }
    }
    handleChangeInputIban = async event => {
        var iban = event.target.value;

        this.setState({ iban })

        if (this.state.ibanSuccess != '') {
            this.setState({ ibanSuccess: '' })
        }
        if (this.state.ibanError) {
            this.setState({ ibanError: false })
        }
        if (this.validateIBAN(iban)) {
            this.setState({ ibanSuccess: 'success' })
        } else {
            this.setState({ ibanSuccess: '' })
        }
    }

    handleEditPersonalInfo = async event => {
        if (!this.state.editSellerInfo) {
            this.setState({
                editPersonalInfo: true
            })
            this.enablePeronelInfo();
        }
    }
    handleUpdatePersonalInfo = async event => {
        this.doUpdatePeronelInfo();
    }
    handleCancelPersonalInfo = async event => {
        this.setState({
            editPersonalInfo: false
        })
        this.disablePeronelInfo();
        this.setState({
            sirname: this.state.sirnameOriginal,
            name: this.state.nameOriginal,
            phonenumber: this.state.phonenumberOriginal,
            street: this.state.streetOriginal,
            streetnr: this.state.streetnrOriginal,
            address1: this.state.address1Original,
            postalcode: this.state.postalcodeOriginal,
            city: this.state.cityOriginal,
            country: this.state.countryOriginal,
        })
    }

    handleEditSellerInfo = async event => {
        if (!this.state.editPersonalInfo) {
            this.setState({
                editSellerInfo: true
            })
            this.enableSellerInfo();
        }
    }
    handleUpdateSellerInfo = async event => {
        this.doUpdateSellerInfo();
    }
    handleCancelSellerInfo = async event => {
        this.setState({
            editSellerInfo: false
        })
        this.disableSellerInfo();
        this.setState({
            isSeller: this.state.isSellerOriginal,
            iban: this.state.ibanOriginal
        })
    }

    disableSellerInfo = async event => {
        this.setState({
            isSellerDisabled: true,
            ibanDisabled: true
        })

    }
    enableSellerInfo = async event => {
        this.setState({
            isSellerDisabled: false,
            ibanDisabled: false
        })

    }
    disablePeronelInfo = async event => {
        this.setState({
            sirnameDisabled: true,
            nameDisabled: true,
            phonenumberDisabled: true,
            streetDisabled: true,
            streetnrDisabled: true,
            address1Disabled: true,
            postalcodeDisabled: true,
            cityDisabled: true,
            countryDisabled: true,
        })

    }
    enablePeronelInfo = async event => {
        this.setState({
            sirnameDisabled: false,
            nameDisabled: false,
            phonenumberDisabled: false,
            streetDisabled: false,
            streetnrDisabled: false,
            address1Disabled: false,
            postalcodeDisabled: false,
            cityDisabled: false,
            countryDisabled: false,
        })

    }


    handleBackOnError = async => {
        this.setState({ pageState: Constants.STATE_INITIAL })
    }

    validateIBAN = (iban) => {
        var newIban = iban.toUpperCase(),
            modulo = function (divident, divisor) {
                var cDivident = '';
                var cRest = '';

                for (var i in divident) {
                    var cChar = divident[i];
                    var cOperator = cRest + '' + cDivident + '' + cChar;

                    if (cOperator < parseInt(divisor)) {
                        cDivident += '' + cChar;
                    } else {
                        cRest = cOperator % divisor;
                        if (cRest == 0) {
                            cRest = '';
                        }
                        cDivident = '';
                    }

                }
                cRest += '' + cDivident;
                if (cRest == '') {
                    cRest = 0;
                }
                return cRest;
            };

        if (newIban.search(/^[A-Z]{2}/gi) < 0) {
            return false;
        }

        newIban = newIban.substring(4) + newIban.substring(0, 4);

        newIban = newIban.replace(/[A-Z]/g, function (match) {
            return match.charCodeAt(0) - 55;
        });

        return parseInt(modulo(newIban, 97), 10) === 1;
    }

    doUpdatePeronelInfo = async () => {
        const anchor = this;
        var missingFields = false;

        if (this.state.sirname === '') {
            this.setState({ sirnameError: true })
            missingFields = true;
        }
        if (this.state.name === '') {
            this.setState({ nameError: true })
            missingFields = true;
        }
        if (this.state.street === '') {
            this.setState({ streetError: true })
            missingFields = true;
        }
        if (this.state.streetnr === '') {
            this.setState({ streetnrError: true })
            missingFields = true;
        }
        if (this.state.country === '') {
            this.setState({ countryError: true })
            missingFields = true;
        }
        if (this.state.postalcode === '') {
            this.setState({ postalcodeError: true })
            missingFields = true;
        }
        if (this.state.city === '') {
            this.setState({ cityError: true })
            missingFields = true;
        }

        if (missingFields) {
            return;
        }

        this.setState({
            editPersonalInfo: false
        })
        this.disablePeronelInfo();

        var payload = {};
        payload.data = {};
        var dataChanged = false;

        if (this.state.name !== this.state.nameOriginal) { dataChanged = true; }
        if (this.state.sirname !== this.state.sirnameOriginal) { dataChanged = true; }
        if (this.state.phonenumber !== this.state.phonenumberOriginal) { dataChanged = true; }
        if (this.state.isSestreetller !== this.state.streetOriginal) { dataChanged = true; }
        if (this.state.streetnr !== this.state.streetnrOriginal) { dataChanged = true; }
        if (this.state.address1 !== this.state.address1Original) { dataChanged = true; }
        if (this.state.country !== this.state.countryOriginal) { dataChanged = true; }
        if (this.state.postalcode !== this.state.postalcodeOriginal) { dataChanged = true; }
        if (this.state.city !== this.state.cityOriginal) { dataChanged = true; }

        if (!dataChanged) {
            return;
        }

        payload.data.name = encodeURIComponent(this.state.name);
        payload.data.sirname = encodeURIComponent(this.state.sirname);
        payload.data.phonenumber = this.state.phonenumber;
        payload.data.street = encodeURIComponent(this.state.street);
        payload.data.streetnr = this.state.streetnr;
        payload.data.address1 = encodeURIComponent(this.state.address1);
        payload.data.country = this.state.country;
        payload.data.postalcode = this.state.postalcode;
        payload.data.city = encodeURIComponent(this.state.city);

        if (this.state.isSeller !== this.state.isSellerOriginal) { return; }
        if (this.state.iban !== this.state.ibanOriginal) { return; }

        payload.data.seller = this.state.isSeller;
        payload.data.iban = this.state.iban;

        Userfront.user.update(payload).catch(function (error) {
            anchor.setState({ pageState: Constants.STATE_ERROR })
        });
    }

    doUpdateSellerInfo = async () => {
        const anchor = this;
        var missingFields = false;

        if (this.state.isSeller === true && this.state.iban === '') {
            this.setState({ ibanError: true })
            missingFields = true;
        }

        if (this.state.iban != '') {
            if (!this.validateIBAN(this.state.iban)) {
                this.setState({ ibanError: true })
                missingFields = true;
            }
        }

        if (missingFields) {
            return;
        }

        this.setState({
            editSellerInfo: false
        })
        this.disableSellerInfo();

        var payload = {};
        payload.data = {};
        var dataChanged = false;

        if (this.state.isSeller !== this.state.isSellerOriginal) { dataChanged = true; }
        if (this.state.iban !== this.state.ibanOriginal) { dataChanged = true; }

        if (!dataChanged) {
            return;
        }

        payload.data.seller = this.state.isSeller;
        payload.data.iban = this.state.iban;


        if (this.state.name !== this.state.nameOriginal) { return; }
        if (this.state.sirname !== this.state.sirnameOriginal) { return; }
        if (this.state.phonenumber !== this.state.phonenumberOriginal) { return; }
        if (this.state.street !== this.state.streetOriginal) { return; }
        if (this.state.streetnr !== this.state.streetnrOriginal) { return; }
        if (this.state.address1 !== this.state.address1Original) { return; }
        if (this.state.country !== this.state.countryOriginal) { return; }
        if (this.state.postalcode !== this.state.postalcodeOriginal) { return; }
        if (this.state.city !== this.state.cityOriginal) { return; }

        payload.data.name = encodeURIComponent(this.state.name);
        payload.data.sirname = encodeURIComponent(this.state.sirname);
        payload.data.phonenumber = this.state.phonenumber;
        payload.data.street = encodeURIComponent(this.state.street);
        payload.data.streetnr = this.state.streetnr;
        payload.data.address1 = encodeURIComponent(this.state.address1);
        payload.data.country = this.state.country;
        payload.data.postalcode = this.state.postalcode;
        payload.data.city = encodeURIComponent(this.state.city);

        Userfront.user.update(payload).catch(function (error) {
            anchor.setState({ pageState: Constants.STATE_ERROR })
        });
    }

    render() {
        const { t } = this.props

        const { email, password, editPersonalInfo, editSellerInfo, pageState, isMobile } = this.state
        const { sirname, sirnameError, name, nameError, iban, ibanError, ibanSuccess, isSeller, phonenumber, phonenumberError, phonenumberDisabled } = this.state
        const { street, streetError, streetnr, streetnrError, address1, address1Error, postalcode, postalcodeError, city, cityError, country, countryError } = this.state
        const { emailDisabled, passwordDisabled, sirnameDisabled, nameDisabled, isSellerDisabled, streetDisabled, streetnrDisabled, address1Disabled, postalcodeDisabled,
            cityDisabled, countryDisabled, ibanDisabled, share_phonenumber } = this.state

        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.profile.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        {pageState === Constants.STATE_INITIAL &&
                            <Container>
                                <WrapperCenter>
                                    <ContainerFields>
                                        <H5Title>{t('ui.profile.tx_personalinfo')}</H5Title>
                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-name'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-name"
                                                                    label={t('ui.signup.tx_name')}
                                                                    value={name}
                                                                    error={nameError}
                                                                    disabled={nameDisabled}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputName}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-sirname'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-sirname"
                                                                    label={t('ui.signup.tx_sirname')}
                                                                    value={sirname}
                                                                    error={sirnameError}
                                                                    disabled={sirnameDisabled}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputSirname}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-street'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-street"
                                                                    label={t('ui.signup.tx_street')}
                                                                    value={street}
                                                                    error={streetError}
                                                                    disabled={streetDisabled}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputStreet}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-streetnr-address1'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperFields>
                                                            <WrapperSplit>
                                                                <WrapperSplitStreetnr>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-streetnr"
                                                                            label={t('ui.signup.tx_streetnr')}
                                                                            value={streetnr}
                                                                            error={streetnrError}
                                                                            disabled={streetnrDisabled}
                                                                            required={true}
                                                                            onChange={this.handleChangeInputStreetnr}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitStreetnr>
                                                                <WrapperSplitAddress1>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-address1"
                                                                            label={t('ui.signup.tx_address1')}
                                                                            value={address1}
                                                                            error={address1Error}
                                                                            disabled={address1Disabled}
                                                                            required={false}
                                                                            onChange={this.handleChangeInputAddress1}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitAddress1>
                                                            </WrapperSplit>
                                                        </WrapperFields>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-country-postalcode'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperFields>
                                                            <WrapperSplit>
                                                                <WrapperSplitCountry>
                                                                    <FormControl
                                                                        error={countryError}
                                                                        disabled={countryDisabled}
                                                                        required={true}
                                                                        fullWidth size={'small'}>
                                                                        <InputLabel id="tx-country">{t('ui.signup.tx_country')}</InputLabel>
                                                                        <Select
                                                                            labelId="tx-country"
                                                                            id="ip-country"
                                                                            value={country}
                                                                            label={t('ui.signup.tx_country')}
                                                                            onChange={this.handleChangeInputCountry}>
                                                                            <MenuItem value={Constants.COUNTRYCODE_GERMANY}>{Constants.COUNTRYCODE_GERMANY}</MenuItem>
                                                                            {/*<MenuItem value={Constants.COUNTRYCODE_AUSTRIA}>{Constants.COUNTRYCODE_AUSTRIA}</MenuItem>*/}
                                                                        </Select>
                                                                    </FormControl>
                                                                </WrapperSplitCountry>
                                                                <WrapperSplitPostalcode>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-postalcode"
                                                                            label={t('ui.signup.tx_postalcode')}
                                                                            value={postalcode}
                                                                            error={postalcodeError}
                                                                            disabled={postalcodeDisabled}
                                                                            required={true}
                                                                            onChange={this.handleChangeInputPostalcode}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 5 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitPostalcode>
                                                            </WrapperSplit>
                                                        </WrapperFields>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-city'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-city"
                                                                    label={t('ui.signup.tx_city')}
                                                                    value={city}
                                                                    error={cityError}
                                                                    disabled={cityDisabled}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputCity}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-phonenumber'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-phonenumber"
                                                                    label={t('ui.signup.tx_phonenumber')}
                                                                    value={phonenumber}
                                                                    error={phonenumberError}
                                                                    disabled={phonenumberDisabled}
                                                                    required={false}
                                                                    onChange={this.handleChangeInputPhonenumber}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 25 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>

                                        {editPersonalInfo ?
                                            <WrapperButtonLine>
                                                <BtnUpdate style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleUpdatePersonalInfo}>{t('ui.profile.bt_update')}</BtnUpdate>
                                                <BtnCancel style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleCancelPersonalInfo}>{t('ui.profile.bt_cancel')}</BtnCancel>
                                            </WrapperButtonLine>
                                            :
                                            <WrapperButtonLine>
                                                <BtnEdit style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleEditPersonalInfo}>{t('ui.profile.bt_edit')}</BtnEdit>
                                            </WrapperButtonLine>
                                        }

                                        <Paragraph> </Paragraph>

                                        <H5Title>{t('ui.profile.tx_kontoinfo')}</H5Title>

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-ch-email'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-email"
                                                                    label={t('ui.profile.tx_email')}
                                                                    value={email}
                                                                    disabled={emailDisabled}
                                                                    required={true}
                                                                    size={'small'}
                                                                    autoComplete={'current-email'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-btnch-email'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        {isMobile == false &&
                                                            <Container>
                                                                {true === false &&
                                                                    <StyledLink to={"/auth/change_email"}>
                                                                        <BtnChangeEmail>{t('ui.profile.bt_tochem')}</BtnChangeEmail>
                                                                    </StyledLink>
                                                                }
                                                            </Container>
                                                        }
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-ch-pw'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-password"
                                                                    label={t('ui.profile.tx_password')}
                                                                    value={password}
                                                                    disabled={passwordDisabled}
                                                                    required={true}
                                                                    size={'small'}
                                                                    type={'password'}
                                                                    autoComplete={'current-password'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-btnch-pw'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <StyledLink to={"/auth/change_password"}>
                                                            <BtnChangePassword>{t('ui.profile.bt_tochpw')}</BtnChangePassword>
                                                        </StyledLink>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>

                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>


                                <WrapperCenter>
                                    <ContainerFields>
                                        <H5Title>{t('ui.profile.tx_sellerinfo')}</H5Title>

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-ch-cb_seller'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <Container>
                                                            {t('ui.profile.cb_seller')}
                                                            <Switch
                                                                checked={isSeller}
                                                                disabled={isSellerDisabled}
                                                                onChange={this.handleChangeInputIsSeller}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Container>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-ch-iban'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="ip-iban"
                                                                color={ibanSuccess}
                                                                label={t('ui.profile.tx_iban')}
                                                                value={iban}
                                                                disabled={ibanDisabled}
                                                                error={ibanError}
                                                                required={true}
                                                                onChange={this.handleChangeInputIban}
                                                                inputProps={{ maxLength: 30 }}
                                                                size={'small'}
                                                            />
                                                        </FormControl>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>

                                        {editSellerInfo ?
                                            <WrapperButtonLine >
                                                <BtnUpdate style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleUpdateSellerInfo}>{t('ui.profile.bt_update')}</BtnUpdate>
                                                <BtnCancel style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleCancelSellerInfo}>{t('ui.profile.bt_cancel')}</BtnCancel>
                                            </WrapperButtonLine>
                                            :
                                            <WrapperButtonLine>
                                                <BtnEdit style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleEditSellerInfo}>{t('ui.profile.bt_edit')}</BtnEdit>
                                            </WrapperButtonLine>
                                        }
                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>

                                <WrapperCenter>
                                    <ContainerFields>
                                        <H5Title>{t('ui.profile.tx_contact')}</H5Title>
                                        <WrapperText>
                                            {t('ui.profile.tx_share_phonenumber')}
                                            <Switch
                                                checked={share_phonenumber}
                                                onChange={this.handleChangeInputSharePhonenumber}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </WrapperText>
                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>
                            </Container>
                        }
                        {pageState === Constants.STATE_ERROR &&
                            <Container>
                                <WrapperCenter>
                                    <Paragraph>{t('ui.profile.tx_error')}</Paragraph>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <BtnBackOnError style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleBackOnError}>{t('ui.profile.bt_toprofile')}</BtnBackOnError>
                                </WrapperCenter>
                            </Container>
                        }
                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(User_Profile));