import React, { Component } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import { format } from 'date-fns'
import LoadingIcons from 'react-loading-icons'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
min-width: 300px;
max-width: 600px;
width: 30%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnReset = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnSend = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnLogin = styled(BtnWhite)`
margin: 5px 0px;
width: 100%;
`
const BtnHome = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`
const BtnRetry = styled(BtnGrey)`
margin: 5px 0px;
`

class Auth_Reset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            emailError: false,
            password: '',
            passwordVerify: '',
            passwordError: false,
            emailState: '',
            resetState: '',
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;


        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const queryParameters = new URLSearchParams(window.location.search)
        const uuid = queryParameters.get("uuid")
        const token = queryParameters.get("token")
        const type = queryParameters.get("type")

        if (typeof (token) != 'undefined' && token != '' && token != null) {
            this.setState({ resetState: Constants.STATE_INITIAL })
        } else {
            this.setState({ emailState: Constants.STATE_INITIAL })
        }
        /*
                if (uuid !== null && token !== null && type !== null) {
                    this.setState({ resetState: Constants.STATE_INITIAL })
                } else {
                    this.setState({ emailState: Constants.STATE_INITIAL })
                }*/
    }

    handleChangeInputEmail = async event => {
        const email = event.target.value
        this.setState({ email })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({ password })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangeInputPasswordVerify = async event => {
        const passwordVerify = event.target.value
        this.setState({ passwordVerify })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleBtnRetry = async event => {
        event.preventDefault()
        window.location.href = `/auth/reset`
    }

    doReset = async () => {
        const anchor = this;

        if (this.state.password !== this.state.passwordVerify) {
            this.setState({ passwordError: true })
            return;
        }

        if (this.state.password.length < 16) {
            if (this.state.password.length < 8) {
                this.setState({ passwordError: true })
                return;
            } else {
                var regularExpression = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
                if (!regularExpression.test(this.state.password)) {
                    this.setState({ passwordError: true })
                    return;
                }
            }
        }

        await Userfront.updatePassword({
            password: this.state.password,
        }).then(function () {
            anchor.setState({ resetState: Constants.STATE_SUCCESS })
        }).catch(function (error) {
            anchor.setState({ resetState: Constants.STATE_ERROR })
        });
    }

    doSendMail = async () => {
        const anchor = this;

        let email = this.state.email;
        let date = format(new Date(), 'dd.MM.yyyy hh:mm');
        var payload = { email, date }


        this.setState({ emailState: Constants.STATE_PENDING })

        await api.resetUserPassword(payload).then(res => {
            anchor.setState({ emailState: Constants.STATE_SUCCESS })
        }).catch(error => {
            if (error.response.status === 503) {
                anchor.setState({ emailState: Constants.STATE_LOCKED })
            } else {
                anchor.setState({ emailState: Constants.STATE_ERROR })
            }
        })

    }

    render() {
        const { t } = this.props

        const { email, emailError, password, passwordVerify, passwordError, emailState, resetState, isMobile } = this.state

        /* Prebuild Login
        const PasswordResetForm = Userfront.build({
            toolId: "kobobk"
        });
        */

        return (
            <ContainerMain>
                <WrapperMain>
                    <WrapperCenter>
                        <Title>{t('ui.reset.tx_title')}</Title>
                        <Paragraph></Paragraph>
                    </WrapperCenter>
                    {emailState === Constants.STATE_INITIAL && resetState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_sndml')}
                                </WrapperText>
                                <ContainerFields>
                                    <WrapperField>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-email"
                                                label={t('ui.reset.tx_email')}
                                                value={email}
                                                error={emailError}
                                                required={true}
                                                onChange={this.handleChangeInputEmail}
                                                size={'small'}
                                                autoComplete={'current-email'}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </WrapperField>
                                </ContainerFields>
                                <WrapperCenter>
                                    <BtnSend style={{ "width": getButtonWidth(isMobile) }} onClick={this.doSendMail}>{t('ui.reset.bt_send')}</BtnSend>
                                </WrapperCenter>

                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/auth/login"}>
                                        <BtnLogin>{t('ui.reset.bt_tologin')}</BtnLogin>
                                    </StyledLink>
                                </WrapperCenter>
                            </WrapperCenter>
                        </Container>
                    }
                    {emailState === Constants.STATE_SUCCESS && resetState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_sndml_s')}
                                </WrapperText>
                            </WrapperCenter>
                        </Container>
                    }
                    {emailState === Constants.STATE_ERROR && resetState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_sndml_e')}
                                </WrapperText>
                            </WrapperCenter>
                            <WrapperCenter>
                                <BtnRetry style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleBtnRetry}>{t('ui.reset.tb_retry')}</BtnRetry>
                            </WrapperCenter>
                        </Container>
                    }
                    {emailState === Constants.STATE_PENDING && resetState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_sndml_p')}
                                </WrapperText>
                                <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                            </WrapperCenter>
                        </Container>
                    }
                    {emailState === Constants.STATE_LOCKED && resetState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_sndml_l')}
                                </WrapperText>
                            </WrapperCenter>
                        </Container>
                    }
                    {resetState === Constants.STATE_INITIAL && emailState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_pwcrit')}
                                </WrapperText>
                                <ContainerFields>
                                    <WrapperField>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-password"
                                                label={t('ui.reset.tx_password')}
                                                value={password}
                                                error={passwordError}
                                                required={true}
                                                onChange={this.handleChangeInputPassword}
                                                size={'small'}
                                                type={'password'}
                                                autoComplete={'new-password'}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </WrapperField>
                                    <WrapperField>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-password_verify"
                                                label={t('ui.reset.tx_password_verify')}
                                                value={passwordVerify}
                                                error={passwordError}
                                                required={true}
                                                onChange={this.handleChangeInputPasswordVerify}
                                                size={'small'}
                                                type={'password'}
                                                autoComplete={'new-password'}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </FormControl>
                                    </WrapperField>
                                </ContainerFields>
                            </WrapperCenter>

                            <WrapperCenter>
                                <BtnReset style={{ "width": getButtonWidth(isMobile) }} onClick={this.doReset}>{t('ui.reset.bt_reset')}</BtnReset>
                            </WrapperCenter>
                        </Container>
                    }
                    {resetState === Constants.STATE_SUCCESS && emailState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_pwrst_s')}
                                </WrapperText>
                            </WrapperCenter>
                            <WrapperCenter>
                                <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/advert/all"}>
                                    <BtnHome>{t('ui.reset.bt_articles')}</BtnHome>
                                </StyledLink>
                            </WrapperCenter>
                        </Container>
                    }
                    {resetState === Constants.STATE_ERROR && emailState === '' &&
                        <Container>
                            <WrapperCenter>
                                <WrapperText>
                                    {t('ui.reset.tx_text_pwrst_e')}
                                </WrapperText>
                            </WrapperCenter>
                            <WrapperCenter>
                                <BtnRetry style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleBtnRetry}>{t('ui.reset.tb_retry')}</BtnRetry>
                            </WrapperCenter>
                        </Container>
                    }

                    {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                        <PasswordResetForm />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}
                </WrapperMain>
            </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Reset));