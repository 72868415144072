import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";
import { format, parseISO } from 'date-fns'
import { convertToLocalTime } from 'date-fns-timezone';



import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const WrapperDate = styled.div.attrs({
})`
  margin: 5px;
`

const WrapperLine = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BtnGenFIData = styled(BtnBlue)`
    margin: 5px 0px;
`

class Admin_Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articles_total_adverts: 0,
            articles_total_completed: 0,
            articles_total_concluded: 0,
            articles_in_process: 0,
            articles_total_deleted: 0,
            articles_current_online: 0,
            articles_total_views: 0,
            articles_average_tts: 0,
            articles_total_service_option_1: 0,
            articles_total_service_option_2: 0,
            fromDate: null,
            toDate: null,

        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const stats = await api.getKPIs()
            .catch(error => {
                //Todo error handeling
            })
        this.setState({
            articles_total_adverts: stats.data.data.articles_total_adverts,
            articles_total_completed: stats.data.data.articles_total_completed,
            articles_total_concluded: stats.data.data.articles_total_concluded,
            articles_in_process: stats.data.data.articles_in_process,
            articles_total_deleted: stats.data.data.articles_total_deleted,
            articles_current_online: stats.data.data.articles_current_online,
            articles_total_views: stats.data.data.articles_total_views,
            articles_average_tts: stats.data.data.articles_average_tts,
            articles_total_service_option_1: stats.data.data.articles_total_service_option_1,
            articles_total_service_option_2: stats.data.data.articles_total_service_option_2,
        })
    }

    handleChangeInputFromDate = async (newValue) => {
        const fromDate = newValue;
        this.setState({ fromDate })
    }
    handleChangeInputToDate = async (newValue) => {
        const toDate = newValue
        this.setState({ toDate })
    }

    genFIData = async () => {
        if (this.state.toDate === null || this.state.fromDate === null) {
            alert('Zeitbereich auswählen!')
            return;
        }

        let fromDate = new Date(this.state.fromDate)
        fromDate.setDate(fromDate.getDate() + 1)

        let toDate = new Date(this.state.toDate)
        toDate.setDate(toDate.getDate() + 1)

        const payload = {
            fromDate: fromDate,
            toDate: toDate
        };
        const fidata = await api.getFIData(payload)
            .catch(error => {
                //Todo error handeling
                alert('Keine Daten gefunden!')
                return;
            })

        

        var purchases = [];

        fidata.data.data.forEach(purchase => {
            switch (purchase.state) {
                case Constants.STATE_COMPLETED:
                    purchase.fi_state = 'Abgeschlossen'
                    purchases.push(purchase);
                    break;
                case Constants.STATE_CONCLUDED:
                    purchase.fi_state = 'Storniert'
                    purchases.push(purchase);
                    break;
                default:
                    purchase.fi_state = 'Offen'
                    purchases.push(purchase);
                    break;
            }
        });

        const rows = [];
        let fmt_fromDate = format(new Date(fromDate), 'dd.MM.yyyy');
        let fmt_toDate = format(new Date(toDate), 'dd.MM.yyyy');
        rows.push(["FI Auswertung"]);
        rows.push([fmt_fromDate + " - "+ fmt_toDate]);
        rows.push([""]);

        if (purchases.length > 0) {
            rows.push(["Abgeschlossen:"])
            rows.push(["Datum", "Bestellungs Nr.", "Pos.", "Item", "Beschreibung", "Status", "Preis in €"])
            purchases.forEach(purchase => {
                let i = 0;
                purchase.fi_prices.forEach(fi_price => {
                    i++;
                    let fmt_price = (fi_price.price / 100).toString().replace(".", ",");
                    let fmt_date = format(new Date(purchase.fi_date), 'dd.MM.yyyy');
                    rows.push([fmt_date, purchase.order_no, i, fi_price.item, fi_price.name, purchase.fi_state, fmt_price])
                });
            });
            rows.push([""])
        }

        let universalBOM = "\uFEFF";
        let csvContent = "data:text/csv;charset=utf-8," + universalBOM + rows.map(e => e.join(";")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        let dat = format(new Date(), 'yyyyMMdd_HHmmss')
        link.setAttribute("download", "FI_Export_" + dat + ".csv");
        document.body.appendChild(link);
        link.click();
    }

    render() {
        const { t } = this.props

        const {
            fromDate, toDate
        } = this.state

        const {
            articles_total_adverts,
            articles_total_completed,
            articles_total_concluded,
            articles_in_process,
            articles_total_deleted,
            articles_current_online,
            articles_total_views,
            articles_average_tts,
            articles_total_service_option_1,
            articles_total_service_option_2
        } = this.state

        return (
            <Container>
                {Userfront.accessToken() && Userfront.user.hasRole("admin") ?
                    <ContainerMain>
                        <WrapperMain>
                            <WrapperCenter>
                                <ContainerText>
                                    <WrapperCenter>
                                        <Title>{t('ui.admin.tx_title')}</Title>
                                        <Paragraph>
                                            {t('ui.admin.tx_text')}
                                        </Paragraph>
                                    </WrapperCenter>
                                    <H5Title>Statistiken:</H5Title>
                                    <WrapperText>
                                        <Paragraph>
                                            Anzeigen online: <Bold><font color={Style.COLOUR_GREEN}>{articles_current_online}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Artikel verkauft: <Bold><font color={Style.COLOUR_GREEN}>{articles_total_completed}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Artikel returniert: <Bold><font color={Style.COLOUR_RED}>{articles_total_concluded}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Artikel im Prozess: <Bold><font color={Style.COLOUR_ORANGE}>{articles_in_process}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Anzeigen gesammt: <Bold><font color={Style.COLOUR_BLACK}>{articles_total_adverts}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Anzeigen gelöscht: <Bold><font color={Style.COLOUR_RED}>{articles_total_deleted}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Anzeigen Ø online: <Bold><font color={Style.COLOUR_BLACK}>{articles_average_tts}</font></Bold> Tage
                                        </Paragraph>
                                        <Paragraph>
                                            Anzeigen views gesammt: <Bold><font color={Style.COLOUR_BLACK}>{articles_total_views}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Versandverkauf: <Bold><font color={Style.COLOUR_BLACK}>{articles_total_service_option_1}</font></Bold>
                                        </Paragraph>
                                        <Paragraph>
                                            Selbstabholungsverkauf: <Bold><font color={Style.COLOUR_BLACK}>{articles_total_service_option_2}</font></Bold>
                                        </Paragraph>
                                    </WrapperText>
                                    <H5Title>FI Auswertung</H5Title>
                                    <WrapperLine>
                                        <WrapperDate>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={"Von (inkl.):"}
                                                    size={'small'}
                                                    value={fromDate}
                                                    onChange={(newValue) => this.handleChangeInputFromDate(newValue)}
                                                    format="DD.MM.YYYY"
                                                />
                                            </LocalizationProvider>
                                        </WrapperDate>
                                        <WrapperDate>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={"Bis (inkl.):"}
                                                    size={'small'}
                                                    value={toDate}
                                                    onChange={(newValue) => this.handleChangeInputToDate(newValue)}
                                                    format="DD.MM.YYYY"
                                                />
                                            </LocalizationProvider>
                                        </WrapperDate>
                                        <BtnGenFIData onClick={this.genFIData}>Auswertung generieren</BtnGenFIData>
                                    </WrapperLine>
                                </ContainerText>
                            </WrapperCenter>
                        </WrapperMain>
                    </ContainerMain>
                    :
                    <Container>
                        <WrapperCenter>
                            <Paragraph>
                                {t('ui.admin.tx_page_error')} {';)'}
                            </Paragraph>
                        </WrapperCenter>
                    </Container>
                }
            </Container>

        )
    }
}

export default withTranslation()(withParams(Admin_Dashboard));