import React, { Component } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth, getContainerFieldWidth } from '../util/index.js';

import LoadingIcons from 'react-loading-icons'
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperField = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`
const WrapperFields = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperSplitStreet = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplitStreetnr = styled.div.attrs({
})`
  width: 30%;
`

const WrapperSplitAddress1 = styled.div.attrs({
})`
  width: 70%;
  margin-Left: 10px;
`
const WrapperSplitCountry = styled.div.attrs({
})`
  width: 30%;
`
const WrapperSplitPostalcode = styled.div.attrs({
})`
  width: 70%;
  margin-Left: 10px;
`

const WrapperSplitCity = styled.div.attrs({
})`
  display: flex;
  width: 50%;
`

const WrapperSplitPhonenumber = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`

const WrapperLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
`

const ContainerTaC = styled.div.attrs({
})`
 border-radius: 5px;
padding-right: 10px;
white-space: pre-wrap;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const WrapperGrid = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const BtnSignup = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnLogin = styled(BtnWhite)`
margin: 5px 0px;
width: 100%;
`
const BtnBackOnError = styled(BtnGrey)`
margin: 5px 0px;
`

class Auth_Signup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageState: Constants.STATE_INITIAL,
            isMobile,

            email: '',
            emailVerify: '',
            emailError: false,
            password: '',
            passwordVerify: '',
            passwordError: false,

            sirname: '',
            sirnameError: false,
            name: '',
            nameError: false,
            isSeller: false,
            isTaC: false,

            phonenumber: '',
            phonenumberError: false,

            street: '',
            streetError: false,
            streetnr: '',
            streetnrError: false,
            address1: '',
            address1Error: false,
            postalcode: '',
            postalcodeError: false,
            city: '',
            cityError: false,
            country: '',
            countryError: false,

            iban: '',
            ibanError: false,
            ibanSuccess: '',

            tenant: ''
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        this.setState({ tenant: tenant })
        Userfront.init(tenant);
    }

    handleChangeInputEmail = async event => {
        const email = event.target.value
        this.setState({ email })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    handleChangeInputEmailVerify = async event => {
        const emailVerify = event.target.value
        this.setState({ emailVerify })
        if (this.state.emailError) {
            this.setState({ emailError: false })
        }
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({ password })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangeInputPasswordVerify = async event => {
        const passwordVerify = event.target.value
        this.setState({ passwordVerify })
        if (this.state.passwordError) {
            this.setState({ passwordError: false })
        }
    }

    handleChangeInputSirname = async event => {
        const sirname = event.target.value
        this.setState({ sirname })
        if (this.state.sirnameError) {
            this.setState({ sirnameError: false })
        }
    }

    handleChangeInputPhonenumber = async event => {
        const phonenumber = event.target.value
        this.setState({ phonenumber })
        if (this.state.phonenumberError) {
            this.setState({ phonenumberError: false })
        }
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
        if (this.state.nameError) {
            this.setState({ nameError: false })
        }
    }

    handleChangeInputStreet = async event => {
        const street = event.target.value
        this.setState({ street })
        if (this.state.streetError) {
            this.setState({ streetError: false })
        }
    }
    handleChangeInputStreetnr = async event => {
        const streetnr = event.target.value
        this.setState({ streetnr })
        if (this.state.streetnrError) {
            this.setState({ streetnrError: false })
        }
    }
    handleChangeInputAddress1 = async event => {
        const address1 = event.target.value
        this.setState({ address1 })
        if (this.state.address1Error) {
            this.setState({ address1Error: false })
        }
    }
    handleChangeInputPostalcode = async event => {
        const postalcode = event.target.value
        this.setState({ postalcode })
        if (this.state.postalcodeError) {
            this.setState({ postalcodeError: false })
        }
    }
    handleChangeInputCity = async event => {
        const city = event.target.value
        this.setState({ city })
        if (this.state.cityError) {
            this.setState({ cityError: false })
        }
    }
    handleChangeInputCountry = async event => {
        const country = event.target.value
        this.setState({ country })
        if (this.state.countryError) {
            this.setState({ countryError: false })
        }
    }
    handleChangeInputIsSeller = async event => {
        if (this.state.isSeller) {
            this.setState({ isSeller: false })
        } else {
            this.setState({ isSeller: true })
        }
    }
    handleChangeInputIsTac = async event => {
        if (this.state.isTaC) {
            this.setState({ isTaC: false })
        } else {
            this.setState({ isTaC: true })
            document.getElementById("elementTaC").style.border = ''
        }
    }
    handleChangeInputIban = async event => {
        var iban = event.target.value;

        this.setState({ iban })

        if (this.state.ibanSuccess != '') {
            this.setState({ ibanSuccess: '' })
        }
        if (this.state.ibanError) {
            this.setState({ ibanError: false })
        }
        if (this.validateIBAN(iban)) {
            this.setState({ ibanSuccess: 'success' })
        } else {
            this.setState({ ibanSuccess: '' })
        }
    }
    handleBackOnError = async => {
        this.setState({ pageState: Constants.STATE_INITIAL })
    }

    validateIBAN = (iban) => {
        var newIban = iban.toUpperCase(),
            modulo = function (divident, divisor) {
                var cDivident = '';
                var cRest = '';

                for (var i in divident) {
                    var cChar = divident[i];
                    var cOperator = cRest + '' + cDivident + '' + cChar;

                    if (cOperator < parseInt(divisor)) {
                        cDivident += '' + cChar;
                    } else {
                        cRest = cOperator % divisor;
                        if (cRest == 0) {
                            cRest = '';
                        }
                        cDivident = '';
                    }

                }
                cRest += '' + cDivident;
                if (cRest == '') {
                    cRest = 0;
                }
                return cRest;
            };

        if (newIban.search(/^[A-Z]{2}/gi) < 0) {
            return false;
        }

        newIban = newIban.substring(4) + newIban.substring(0, 4);

        newIban = newIban.replace(/[A-Z]/g, function (match) {
            return match.charCodeAt(0) - 55;
        });

        return parseInt(modulo(newIban, 97), 10) === 1;
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    doSignup = async () => {
        const anchor = this;

        //Check for empty fields
        var missingFields = false;
        if (this.state.email === '' || this.state.emailVerify === '') {
            this.setState({ emailError: true })
            missingFields = true;
        }
        if (this.state.password === '' || this.state.passwordVerify === '') {
            this.setState({ passwordError: true })
            missingFields = true;
        }
        if (this.state.sirname === '') {
            this.setState({ sirnameError: true })
            missingFields = true;
        }
        if (this.state.name === '') {
            this.setState({ nameError: true })
            missingFields = true;
        }
        if (this.state.street === '') {
            this.setState({ streetError: true })
            missingFields = true;
        }
        if (this.state.streetnr === '') {
            this.setState({ streetnrError: true })
            missingFields = true;
        }
        if (this.state.country === '') {
            this.setState({ countryError: true })
            missingFields = true;
        }
        if (this.state.postalcode === '') {
            this.setState({ postalcodeError: true })
            missingFields = true;
        }
        if (this.state.city === '') {
            this.setState({ cityError: true })
            missingFields = true;
        }

        if (this.state.isSeller === true) {
            if (this.state.iban === '' || !this.validateIBAN(this.state.iban)) {
                this.setState({ ibanError: true })
                missingFields = true;
            }
        } else {
            this.state.iban = '';
        }

        if (missingFields) {
            return;
        }

        //Check rules
        if (this.state.email !== this.state.emailVerify) {
            this.setState({ emailError: true })
            return;
        }

        if (!this.validateEmail(this.state.email)) {
            this.setState({ emailError: true })
            return;
        }

        if (this.state.password !== this.state.passwordVerify) {
            this.setState({ passwordError: true })
            return;
        }

        if (this.state.password.length < 16) {
            if (this.state.password.length < 8) {
                this.setState({ passwordError: true })
                return;
            } else {
                var regularExpression = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
                if (!regularExpression.test(this.state.password)) {
                    this.setState({ passwordError: true })
                    return;
                }
            }
        }

        if (this.state.isTaC === false) {
            document.getElementById("elementTaC").style.border = '2px solid red'
            return;
        }


        var payload = {
            email: this.state.email,
            password: this.state.password,
            tenantId: this.state.tenant,
            name: encodeURIComponent(this.state.name) + ' ' + encodeURIComponent(this.state.sirname),
            data: {
                name: encodeURIComponent(this.state.name),
                sirname: encodeURIComponent(this.state.sirname),
                phonenumber: this.state.phonenumber,
                street: encodeURIComponent(this.state.street),
                streetnr: this.state.streetnr,
                address1: encodeURIComponent(this.state.address1),
                country: this.state.country,
                postalcode: this.state.postalcode,
                city: encodeURIComponent(this.state.city),
                seller: this.state.isSeller,
                iban: this.state.iban,
                tac: this.state.isTaC
            },
            options: { noSignupEmail: "true" }
        }

        anchor.setState({ pageState: Constants.STATE_PENDING })

        await api.signupUser(payload).then(res => {
            window.location.href = `/auth/registered`
        }).catch(error => {
            anchor.setState({ pageState: Constants.STATE_ERROR })
        })

    }

    render() {
        const { t } = this.props

        const { email, emailVerify, emailError, password, passwordVerify, passwordError, pageState, isTaC, isMobile } = this.state
        const { sirname, sirnameError, name, nameError, iban, ibanError, ibanSuccess, isSeller, phonenumber, phonenumberError } = this.state
        const { street, streetError, streetnr, streetnrError, address1, address1Error, postalcode, postalcodeError, city, cityError, country, countryError } = this.state

        /*const SignupForm = Userfront.build({
            toolId: "bbkbkm"
        });*/

        return (
            Userfront.accessToken() ? <Navigate to={{ pathname: "/", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.signup.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        {pageState === Constants.STATE_INITIAL &&
                            <Container>
                                <WrapperCenter>
                                    <ContainerFields style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <H5Title>{t('ui.signup.tx_personalinfo')}</H5Title>
                                        {t('ui.signup.tx_text_loc')}

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-email'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-email"
                                                                    label={t('ui.signup.tx_email')}
                                                                    value={email}
                                                                    error={emailError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputEmail}
                                                                    size={'small'}
                                                                    autoComplete={'current-email'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-email_verify'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-email_verify"
                                                                    label={t('ui.signup.tx_email_verify')}
                                                                    value={emailVerify}
                                                                    error={emailError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputEmailVerify}
                                                                    size={'small'}
                                                                    autoComplete={'current-email'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-name'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-name"
                                                                    label={t('ui.signup.tx_name')}
                                                                    value={name}
                                                                    error={nameError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputName}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-sirname'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-sirname"
                                                                    label={t('ui.signup.tx_sirname')}
                                                                    value={sirname}
                                                                    error={sirnameError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputSirname}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-street'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-street"
                                                                    label={t('ui.signup.tx_street')}
                                                                    value={street}
                                                                    error={streetError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputStreet}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-streetnr-address1'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperFields>
                                                            <WrapperSplit>
                                                                <WrapperSplitStreetnr>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-streetnr"
                                                                            label={t('ui.signup.tx_streetnr')}
                                                                            value={streetnr}
                                                                            error={streetnrError}
                                                                            required={true}
                                                                            onChange={this.handleChangeInputStreetnr}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitStreetnr>
                                                                <WrapperSplitAddress1>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-address1"
                                                                            label={t('ui.signup.tx_address1')}
                                                                            value={address1}
                                                                            error={address1Error}
                                                                            required={false}
                                                                            onChange={this.handleChangeInputAddress1}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitAddress1>
                                                            </WrapperSplit>
                                                        </WrapperFields>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-country-postalcode'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperFields>
                                                            <WrapperSplit>
                                                                <WrapperSplitCountry>
                                                                    <FormControl
                                                                        error={countryError}
                                                                        required={true}
                                                                        fullWidth size={'small'}>
                                                                        <InputLabel id="tx-country">{t('ui.signup.tx_country')}</InputLabel>
                                                                        <Select
                                                                            labelId="tx-country"
                                                                            id="ip-country"
                                                                            value={country}
                                                                            label={t('ui.signup.tx_country')}
                                                                            onChange={this.handleChangeInputCountry}>
                                                                            <MenuItem value={Constants.COUNTRYCODE_GERMANY}>{Constants.COUNTRYCODE_GERMANY}</MenuItem>
                                                                            {/*<MenuItem value={Constants.COUNTRYCODE_AUSTRIA}>{Constants.COUNTRYCODE_AUSTRIA}</MenuItem>*/}
                                                                        </Select>
                                                                    </FormControl>
                                                                </WrapperSplitCountry>
                                                                <WrapperSplitPostalcode>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="ip-postalcode"
                                                                            label={t('ui.signup.tx_postalcode')}
                                                                            value={postalcode}
                                                                            error={postalcodeError}
                                                                            required={true}
                                                                            onChange={this.handleChangeInputPostalcode}
                                                                            size={'small'}
                                                                            inputProps={{ maxLength: 5 }}
                                                                        />
                                                                    </FormControl>
                                                                </WrapperSplitPostalcode>
                                                            </WrapperSplit>
                                                        </WrapperFields>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-city'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-city"
                                                                    label={t('ui.signup.tx_city')}
                                                                    value={city}
                                                                    error={cityError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputCity}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-phonenumber'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-phonenumber"
                                                                    label={t('ui.signup.tx_phonenumber')}
                                                                    value={phonenumber}
                                                                    error={phonenumberError}
                                                                    required={false}
                                                                    onChange={this.handleChangeInputPhonenumber}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 25 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>
                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>

                                <WrapperCenter>
                                    <ContainerFields style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <H5Title>{t('ui.signup.tx_sellerinfo')}</H5Title>
                                        {t('ui.signup.tx_text_slcrit')}

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={4} key={'g-cb_seller'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <Container>
                                                            <Bold>{t('ui.signup.cb_seller')}</Bold>
                                                            <Checkbox
                                                                checked={isSeller}
                                                                onChange={this.handleChangeInputIsSeller}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Container>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={7} key={'g-iban'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-iban"
                                                                    color={ibanSuccess}
                                                                    label={t('ui.signup.tx_iban')}
                                                                    value={iban}
                                                                    error={ibanError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputIban}
                                                                    size={'small'}
                                                                    inputProps={{ maxLength: 22 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>
                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>

                                <WrapperCenter>
                                    <ContainerFields style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <H5Title>{t('ui.signup.tx_password')}</H5Title>
                                        {t('ui.signup.tx_text_pwcrit')}

                                        <WrapperGrid>
                                            <Grid container
                                                width={1}
                                                height={1}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={{ sm: 0, md: 1 }} >
                                                <Grid sm={10} md={5.5} key={'g-password'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-password"
                                                                    label={t('ui.signup.tx_password')}
                                                                    value={password}
                                                                    error={passwordError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputPassword}
                                                                    size={'small'}
                                                                    type={'password'}
                                                                    autoComplete={'current-password'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                                <Grid sm={10} md={5.5} key={'g-password_verify'}
                                                    sx={{
                                                        boxShadow: 0,
                                                        width: '100%',
                                                        p: 0,
                                                        m: 0,
                                                        borderRadius: 0,
                                                    }}>
                                                    <WrapperPanel>
                                                        <WrapperField>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="ip-password_verify"
                                                                    label={t('ui.signup.tx_password_verify')}
                                                                    value={passwordVerify}
                                                                    error={passwordError}
                                                                    required={true}
                                                                    onChange={this.handleChangeInputPasswordVerify}
                                                                    size={'small'}
                                                                    type={'password'}
                                                                    autoComplete={'current-password'}
                                                                    inputProps={{ maxLength: 50 }}
                                                                />
                                                            </FormControl>
                                                        </WrapperField>
                                                    </WrapperPanel>
                                                </Grid>
                                            </Grid>
                                        </WrapperGrid>
                                    </ContainerFields>
                                </WrapperCenter>
                                <Paragraph> </Paragraph>

                                <WrapperCenter>
                                    <ContainerFields style={{ "width": getContainerFieldWidth(isMobile) }}>

                                        <WrapperLine>
                                            <Paragraph>
                                                <ContainerTaC id="elementTaC" >
                                                    <Checkbox
                                                        checked={isTaC}
                                                        onChange={this.handleChangeInputIsTac}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                    {t('ui.signup.tx_signupTaCText1')} <a href={'/info/termsandconditions'} target="_blank" rel="noreferrer noopener">{t('ui.signup.tx_signupTaCText2')}</a> {t('ui.signup.tx_signupTaCText3')} {t('ui.signup.tx_signupPPText1')} <a href={'/info/privacypolicy'} target="_blank" rel="noreferrer noopener">{t('ui.signup.tx_signupPPText2')}</a> {t('ui.signup.tx_signupPPText3')}

                                                </ContainerTaC>
                                            </Paragraph>
                                        </WrapperLine>

                                    </ContainerFields>
                                </WrapperCenter>

                                <WrapperCenter>
                                    <BtnSignup style={{ "width": getButtonWidth(isMobile) }} onClick={this.doSignup}>{t('ui.signup.bt_signup')}</BtnSignup>
                                </WrapperCenter>

                                {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                            <SignupForm />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}

                                <WrapperCenter>
                                    <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/auth/login"}>
                                        <BtnLogin>{t('ui.signup.bt_tologin')}</BtnLogin>
                                    </StyledLink>
                                </WrapperCenter>
                            </Container>
                        }
                        {pageState === Constants.STATE_PENDING &&
                            <Container>
                                <WrapperCenter>
                                    <Paragraph>{t('ui.signup.tx_pending')}</Paragraph>
                                    <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                </WrapperCenter>
                            </Container>
                        }
                        {pageState === Constants.STATE_ERROR &&
                            <Container>
                                <WrapperCenter>
                                    <Paragraph>{t('ui.signup.tx_error')}</Paragraph>
                                </WrapperCenter>
                                <WrapperCenter>
                                    <BtnBackOnError style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleBackOnError}>{t('ui.signup.bt_tosignup')}</BtnBackOnError>
                                </WrapperCenter>
                            </Container>
                        }
                    </WrapperMain>
                </ContainerMain >
        )
    }
}

export default withTranslation()(withParams(Auth_Signup));