import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`

const ContainerFields = styled.div.attrs({
})`
min-width: 300px;
width: 80%;
`

const ContainerItem = styled.div.attrs({

})`
margin: 5px;
background-color: #FFFFFF;
`
const ContainerLines = styled.div.attrs({

})`

`

const WrapperOptions = styled.div.attrs({
})`
margin: 10px 10px;
`


const WrapperFilter = styled.div.attrs({
})`
width: 75%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`


class Admin_Purchases extends Component {
    constructor(props) {
        super(props)

        var filter = 'ActionRequired'
        if (typeof (this.props.params.filter) !== "undefined") {
            filter = this.props.params.filter
        }
        var sort = 'updatedAtAsc'
        if (typeof (this.props.params.sort) !== "undefined") {
            sort = this.props.params.sort
        }
        var top = '20'
        if (typeof (this.props.params.top) !== "undefined") {
            top = this.props.params.top
        }

        this.state = {
            purchases: [],
            filter: filter,
            sort: sort,
            top: top
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const { filter, sort, top } = this.state;
        await api.getPurchasesByFilter(filter, sort, top).then(res => {
            this.setState({
                purchases: res.data.data
            })
        }).catch(error => {
            this.setState({
                purchases: []
            })
        })
    }

    handleChangeInputSort = async event => {
        const sort = event.target.value
        await this.setState({ sort })
        this.doRefresh();
    }
    handleChangeInputFilter = async event => {
        const filter = event.target.value
        await this.setState({ filter })
        this.doRefresh();
    }
    handleChangeInputTop = async event => {
        const top = event.target.value
        await this.setState({ top })
        this.doRefresh();
    }

    doRefresh = async event => {
        const { filter, sort, top } = this.state;
        const { origin } = window.location;
        const newURL = `${origin}/admin/purchases/${filter}/${sort}/${top}`;
        window.history.replaceState({}, '', newURL);
        await api.getPurchasesByFilter(filter, sort, top).then(res => {
            this.setState({
                purchases: res.data.data
            })
        }).catch(error => {
            if (error.response.status === 404) {
                this.setState({
                    purchases: []
                })
            }
        })
        window.scrollTo(0, document.getElementById('banner').offsetHeight);
    }

    render() {
        const { t } = this.props

        const {
            purchases, filter, sort, top
        } = this.state

        return (
            <ContainerMain>
                <WrapperMain>
                    {Userfront.accessToken() && Userfront.user.hasRole("admin") ?
                        <Container>
                            <WrapperCenter>
                                <Title>{t('ui.admin_purchases.tx_title')}</Title>
                                <Paragraph>
                                    {t('ui.admin_purchases.tx_text')}
                                </Paragraph>
                            </WrapperCenter>

                            <WrapperCenter>

                                <WrapperFilter>
                                    <Grid container
                                        width={1}
                                        height={1}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        spacing={{ sm: 0, md: 0 }} >
                                        <Grid sm={10} md={4} key={'adm-filter'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0
                                            }}>
                                            <WrapperPanel>
                                            <WrapperOptions>
                                    <FormControl fullWidth>
                                        <InputLabel id="tx-filter">Filter</InputLabel>
                                        <Select
                                            labelId="tx-filter"
                                            id="ip-filter"
                                            value={filter}
                                            label="Filter"
                                            onChange={this.handleChangeInputFilter}
                                            size={'small'}>
                                            <MenuItem value="ActionRequired">Action required</MenuItem>
                                            <MenuItem value="none">Alle Käufe</MenuItem>
                                            <MenuItem value="Completed">Completed</MenuItem>
                                            <MenuItem value="Concluded">Concluded</MenuItem>
                                            <MenuItem value="Deleted">Deleted</MenuItem>
                                            <MenuItem value="PaymentPending">Payment pending</MenuItem>
                                            <MenuItem value="PaymentSuccess">Payment success</MenuItem>
                                            <MenuItem value="PurchaseAccepted">Purchase accepted</MenuItem>
                                            <MenuItem value="ShippingOrganized">Shipping organized</MenuItem>
                                            <MenuItem value="HandoverOrganized">Handover organized</MenuItem>
                                            <MenuItem value="RefundPending">Refund pending</MenuItem>
                                            <MenuItem value="DeliveryDisputed">Delivery disputed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </WrapperOptions>
                                            </WrapperPanel>
                                        </Grid>
                                        <Grid sm={10} md={5} key={'adm-sort'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0,
                                            }}>
                                            <WrapperPanel>
                                            <WrapperOptions>
                                    <FormControl fullWidth>
                                        <InputLabel id="tx-sort">Sortierung</InputLabel>
                                        <Select
                                            labelId="tx-sort"
                                            id="ip-sort"
                                            value={sort}
                                            label="Sortierung"
                                            onChange={this.handleChangeInputSort}
                                            size={'small'}>
                                            <MenuItem value="createdAtDsc">Erstellt absteigend</MenuItem>
                                            <MenuItem value="createdAtAsc">Erstellt aufsteigend</MenuItem>
                                            <MenuItem value="updatedAtDsc">Aktualisiert absteigend</MenuItem>
                                            <MenuItem value="updatedAtAsc">Aktualisiert aufsteigend</MenuItem>
                                        </Select>
                                    </FormControl>
                                </WrapperOptions>
                                            </WrapperPanel>
                                        </Grid>
                                        <Grid sm={10} md={2} key={'adm-top'}
                                            sx={{
                                                boxShadow: 0,
                                                width: '100%',
                                                p: 0,
                                                m: 0,
                                                borderRadius: 0,
                                            }}>
                                            <WrapperPanel>
                                            <WrapperOptions>
                                    <FormControl fullWidth>
                                        <InputLabel id="tx-top">Ergebnisse</InputLabel>
                                        <Select
                                            labelId="tx-top"
                                            id="ip-top"
                                            value={top}
                                            label="Ergebnisse"
                                            onChange={this.handleChangeInputTop}
                                            size={'small'}>
                                            <MenuItem value="20">Top 20</MenuItem>
                                            <MenuItem value="all">Alle</MenuItem>
                                        </Select>
                                    </FormControl>
                                </WrapperOptions>
                                            </WrapperPanel>
                                        </Grid>
                                    </Grid>
                                </WrapperFilter>

</WrapperCenter>

                            <WrapperCenter>
                                <ContainerFields>
                                    {purchases.length > 0 ?
                                        <Container>
                                            <WrapperGrid>
                                                <Grid container spacing={{ xs: 0, md: 0 }} >
                                                    {purchases.map((obj, index) => {
                                                        return (
                                                            <Grid xs={12} key={index}
                                                                sx={{
                                                                    boxShadow: 3,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    p: 0,
                                                                    m: 1,
                                                                    borderRadius: 0,
                                                                }}>
                                                                <StyledLink to={"/admin/purchase/" + purchases[index]._id}>
                                                                    <ContainerItem key={purchases[index]._id}>
                                                                        <ContainerLines><Bold>Bestellnummer: {purchases[index].order_no}</Bold></ContainerLines>
                                                                        <ContainerItem>
                                                                            <ContainerLines><Bold>Date:</Bold> {purchases[index].createdAt}</ContainerLines>
                                                                            <ContainerLines><Bold>CheckOut:</Bold> {purchases[index].session.substring(0, 20) + '...'}</ContainerLines>
                                                                            <ContainerLines><Bold>Artikel:</Bold> {purchases[index].article}</ContainerLines>
                                                                            <ContainerLines><Bold>Verkäufer:</Bold> {purchases[index].seller}</ContainerLines>
                                                                            <ContainerLines><Bold>Käufer:</Bold> {purchases[index].buyer}</ContainerLines>
                                                                            <ContainerLines><Bold>Status: </Bold>
                                                                                {purchases[index].state === Constants.STATE_PENDING_PAYMENT &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PAYMENT_SUCCESS &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PAYMENT_FAILURE &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PURCHASE_ACCEPTED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PURCHASE_DECLINED &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PURCHASE_TIMEOUT &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_SHIPPING_ORGANIZED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_HANDOVER_ORGANIZED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_REFUND_PENDING &&
                                                                                    <font color={Style.COLOUR_RED}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_PURCHASE_REFUNDED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_DELIVERY_DISPUTED &&
                                                                                    <font color={Style.COLOUR_RED}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_DELIVERY_CONFIRMED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_DISPUTE_RESOLVED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_INVALIDATED &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_DELETED &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_COMPLETED && purchases[index].payout_status === true &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_COMPLETED && purchases[index].payout_status === false &&
                                                                                    <font color={Style.COLOUR_ORANGE}>{purchases[index].state}</font>
                                                                                }
                                                                                {purchases[index].state === Constants.STATE_CONCLUDED &&
                                                                                    <font color={Style.COLOUR_GREEN}>{purchases[index].state}</font>
                                                                                }
                                                                                {" ("}{purchases[index].date}{")"}
                                                                            </ContainerLines>
                                                                        </ContainerItem>
                                                                    </ContainerItem>
                                                                </StyledLink>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </WrapperGrid>
                                        </Container>
                                        :
                                        <Container>
                                            <WrapperCenter>
                                                <Paragraph></Paragraph>
                                                <Paragraph>Keine Käufe gefunden!</Paragraph>
                                            </WrapperCenter>
                                        </Container>
                                    }
                                </ContainerFields>
                            </WrapperCenter>
                        </Container>
                        :
                        <Container>
                            <WrapperCenter>
                                <Paragraph>
                                    {t('ui.admin.tx_page_error')} {';)'}
                                </Paragraph>
                            </WrapperCenter>
                        </Container>
                    }
                </WrapperMain>
            </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Admin_Purchases));