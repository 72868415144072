import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnHoloBlue } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'
import { BarArticle } from '../components'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import LoadingIcons from 'react-loading-icons'
import { format } from 'date-fns'
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Drawer from '@mui/material/Drawer';

import altimage from '../res/images/reski_altimage_sq_v1.png'
import icon_cal from '../res/icons/icon_cal_d_v1.png'
import icon_cnt from '../res/icons/icon_cnt_l_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_pos from '../res/icons/icon_pos_d_v1.png'
import icon_star from '../res/icons/icon_star_d_v1.png'
import icon_star_f from '../res/icons/icon_star_d_f_v1.png'
import icon_menu from '../res/icons/icon_menu_g_v1.png'

const style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const ContainerText = styled.div.attrs({
})`
    width: 100%;
    display: flex;
    flex-flow: column;
    margin: 10px 15px;
`

const ContainerItem = styled.div.attrs({

})`

border-radius: 5px;
background-color: #FFFFFF;
&:hover {
    cursor: pointer;
  }
`

const ContainerItemImage = styled.div.attrs({

})`
position: relative;
margin: 15px;
`

const WrapperArticles = styled.div.attrs({
})`
`

const WrapperFilters = styled.div.attrs({
})`
    width: 35%;
`
const WrapperSort = styled.div.attrs({
})`
padding: 10px;
margin: 10px;
`

const WrapperFilterBar = styled.nav.attrs({
    className: 'navbar',
})`
    width: 100%;
    background-color: #EBEBEB; 
`

const WrapperTop = styled.div.attrs({
})`
`
const WrapperTitle = styled.div.attrs({
})`
    margin: 5px 0px;
`
const ContainerDescription = styled.div.attrs({
})`
width: 100%;
`
const WrapperRight = styled.div.attrs({
})`
display: flex;
width: 95%;
flex-direction: row;
justify-content: right;
align-items: right;
`

const WrapperDescription = styled.div.attrs({
})`
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
`

const WrapperPrice = styled.div.attrs({
})`
margin: 5px 0px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`
const WrapperTags = styled.div.attrs({
})`
    white-space: nowrap;
`

const WrapperSplit = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperSplit2Location = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 65%;
`
const WrapperSplit2Date = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 35%;
  
  white-space: nowrap;
`

const ContainerFilter = styled.div.attrs({
})`
margin-left: 10px;
margin-right: 10px;
margin-bottom: 10px;
`

const WrapperFilterTitle = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`
const WrapperFilter = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  flex-direction: row;
`


const WrapperFilterLeft = styled.div.attrs({
})`
display: flex;
flex-direction: row;
justify-content: left;
align-items: center;
width: 45%;
`

const WrapperFilterCenter = styled.div.attrs({
})`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 10%;
`

const WrapperFilterRight = styled.div.attrs({
})`
display: flex;
flex-direction: row;
justify-content: right;
align-items: center;
width: 45%;
`


const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperLineTag = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
`

const WrapperLineMark = styled.div.attrs({
})`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
`
const Spacer = styled.div.attrs({
})`
`

const WrapperLineItem = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperTag = styled.div.attrs({
})`
padding: 0px 5px;
margin: 0px 5px;
background-color: #EBEBEB;
border-radius: 7px;
`

const WrapperLineItemPhoto = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const WrapperLineItemText = styled.div.attrs({
})`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperFlexTop = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperFlexMid = styled.div.attrs({
})`
flex: 1 1 auto;
`
const WrapperFlexBottom = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`
const WrapperMark = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: row;
justify-content: right;
align-items: right;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const WrapperFloatingButton = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  right: 0;
  &.hide {
    display: none;   
  }
  
`

const BtnFilter = styled(BtnBlue)`
    margin: 5px 15px;
`
const BtnLoadmore = styled(BtnWhite)`
`

const BtnToLogin = styled(BtnBlue)`
    width: 50%;
`

const IconGo = styled(Icon)`
margin-right: 5px;
`

const IconList = styled(Icon)`
margin-right: 5px;
`

const IconMark = styled(Icon)`
margin: 5px;
width: 25px;
height: 25px;
`
const IconFilter = styled(Icon)`
margin: 5px;
width: 25px;
height: 25px;
`
const IconClose = styled(Icon)`
margin: 5px;
width: 25px;
height: 25px;
`

class Article_List_Ski extends Component {
    constructor(props) {
        super(props)

        this.filterRef = React.createRef();
        this.spacerRef = React.createRef();

        var limit = 20
        if (typeof (this.props.params.limit) !== "undefined") {
            limit = Number(this.props.params.limit)
        }
        var filter = 'filter'
        if (typeof (this.props.params.filter) !== "undefined") {
            filter = this.props.params.filter
        }
        var sort = 'sort'
        if (typeof (this.props.params.sort) !== "undefined") {
            sort = this.props.params.sort
        }

        this.state = {
            isMobile,
            filterBarOffsetTop: 0,
            sb_right: false,
            articles: [],
            loading: false,
            end: false,
            notfound: false,
            skip: 0,
            limit: limit,
            filter: filter,
            sort: sort,
            articleMarkerId: '',
            jump_back: false,
            modalLoginOpen: false,
            holder: '',
            uuid: 'anonymous',

            sort_order: Constants.SORT_ORDER_DATE_DSC,

            //Base + Article
            filter_searchString: '',
            filter_price_low: '',
            filter_price_high: '',
            filter_article_weight_low: '',
            filter_article_weight_high: '',
            filter_article_age_low: '',
            filter_article_age_high: '',
            filter_article_daysontour_low: '',
            filter_article_daysontour_high: '',

            //Ski
            filter_ski_brand: [],
            filter_ski_model: '',
            filter_ski_condition: [],
            filter_ski_aptitude: [],
            filter_ski_length_low: '',
            filter_ski_length_high: '',
            filter_ski_width_low: '',
            filter_ski_width_high: '',
            filter_ski_radius_low: '',
            filter_ski_radius_high: '',
            filter_ski_rocker: [],
            filter_ski_climbingskin: '',

            //Binding
            filter_binding_brand: [],
            filter_binding_model: '',
            filter_binding_condition: [],
            filter_binding_aptitude: [],
            filter_binding_pin: '',
            filter_binding_length: '',
            filter_binding_length_adjustable: false,
            filter_binding_stopper: '',
            filter_binding_release_rear: '',
            filter_binding_release_front: '',
            filter_binding_skicrampon: '',

            //Valuehelp
            ski_brand_vh: [],
            ski_condition_vh: [],
            ski_aptitude_vh: [],
            ski_rocker_vh: [],
            binding_brand_vh: [],
            binding_condition_vh: [],
            binding_aptitude_vh: [],

        }
        this.restorationRef = React.createRef();
    }

    scrollToElement = () => this.restorationRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });

    componentDidUpdate = () => {
        try {
            if (this.state.jump_back) {
                this.scrollToElement();
                this.state.jump_back = false;
            }
        } catch { }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        this.hideFab();

        if (this.filterRef.current != null) {
            this.state.filterBarOffsetTop = this.filterRef.current.offsetTop;
        }

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        var uuid = 'anonymous';
        if (Userfront.accessToken()) {
            uuid = Userfront.user.userUuid;
        }
        this.setState({
            uuid: uuid
        })

        try {
            await this.load_vhbrands_ski();
            await this.load_vhbrands_binding();
        } catch {
            //Todo Error handeling
        }


        this.deconstructFilter()
        this.deconstructSort()

        const { skip, limit, filter, sort } = this.state
        const category = Constants.ART_CAT_SKI
        await api.getArticlesByFilter(category, skip, limit, filter, sort, uuid).then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })

        this.state.ski_condition_vh = [Constants.ART_CON_1, Constants.ART_CON_2, Constants.ART_CON_3, Constants.ART_CON_4, Constants.ART_CON_5]
        this.state.ski_aptitude_vh = [Constants.SKI_APTITUDE_1, Constants.SKI_APTITUDE_2, Constants.SKI_APTITUDE_3, Constants.SKI_APTITUDE_4]
        this.state.ski_rocker_vh = [Constants.ROCKER_TYPE_1, Constants.ROCKER_TYPE_2, Constants.ROCKER_TYPE_3, Constants.ROCKER_TYPE_4, Constants.ROCKER_TYPE_5]
        this.state.binding_condition_vh = [Constants.ART_CON_1, Constants.ART_CON_2, Constants.ART_CON_3, Constants.ART_CON_4, Constants.ART_CON_5]
        this.state.binding_aptitude_vh = [Constants.BINDING_APTITUDE_1, Constants.BINDING_APTITUDE_2, Constants.BINDING_APTITUDE_3, Constants.BINDING_APTITUDE_4]

        const persistedId = sessionStorage.getItem(
            "scroll-position-article_list_ski-id-marker"
        );
        sessionStorage.removeItem("scroll-position-article_list_ski-id-marker");
        this.setState({
            articleMarkerId: persistedId,
            jump_back: true
        })

        const that = this;
        window.addEventListener('scroll', this.handleScroll(that));
        return () => window.removeEventListener('scroll', this.handleScroll(that));
    }


    load_vhbrands_ski = async event => {
        const vhbrands_ski = await api.getBrandsByCategory('ski')
        var ski_brand_vh = []
        vhbrands_ski.data.data.forEach(element => {
            ski_brand_vh.push(element.brand)
        });
        this.setState({
            ski_brand_vh: ski_brand_vh
        })
    }
    load_vhbrands_binding = async event => {
        const vhbrands_binding = await api.getBrandsByCategory('binding')
        var binding_brand_vh = []
        vhbrands_binding.data.data.forEach(element => {
            binding_brand_vh.push(element.brand)
        });
        this.setState({
            binding_brand_vh: binding_brand_vh
        })
    }

    handleOnClickArticle = id => () => {
        sessionStorage.setItem("scroll-position-article_list_ski-id-marker", id);
    }

    handleOnClickMarkArticle = index => async event => {
        event.preventDefault();

        const anchor = this;

        const id = this.state.articles[index]._id;
        const marked = this.state.articles[index].marked;
        const payload = { id };

        if (marked) {
            //demark

            await api.demarkArticleById(payload).then(res => {
                this.state.articles[index].marked = false;
                anchor.setState({
                    holder: 'holding'
                })
            }).catch(error => {
                //Todo error
            })
        } else {
            //mark

            await api.markArticleById(payload).then(res => {
                this.state.articles[index].marked = true;
                anchor.setState({
                    holder: 'holdingup'
                })
            }).catch(error => {
                //Todo error
            })

        }
    }

    handleOnClickLoadmore = async event => {
        this.state.loading = true
        const limit = this.state.limit + 20
        this.setState({ limit })
        var articlesArray = this.state.articles;
        const skip = articlesArray.length;
        const { filter, sort, uuid } = this.state;
        const { origin } = window.location;
        const newURL = `${origin}/advert/ski/${limit}/${filter}/${sort}`;
        window.history.replaceState({}, '', newURL);

        const category = Constants.ART_CAT_SKI
        await api.getArticlesByFilter(category, skip, limit, filter, sort, uuid).then(res => {
            res.data.data.forEach(element => {
                articlesArray.push(element)
            });
            if (res.data.data.length < 20) {
                const end = true
                this.setState({ end })
            }
        }).catch(error => {
            if (error.response.status === 404) {
                const end = true
                this.setState({ end })
            }
            //Todo error handeling
        })
        this.setState({
            articles: articlesArray
        })

        this.state.loading = false
    }


    handleChangeInputSortorder = async event => {
        const sort_order = event.target.value
        this.setState({ sort_order })
        this.handleOnClickRefresh()
    }

    //Base + Article
    handleChangeInputFilter_searchString = async event => {
        this.showFab();
        const filter_searchString = event.target.value
        this.setState({ filter_searchString })
    }
    handleChangeInputFilter_price_low = async event => {
        this.showFab();
        const filter_price_low = event.target.value
        this.setState({ filter_price_low })
    }
    handleChangeInputFilter_price_high = async event => {
        this.showFab();
        const filter_price_high = event.target.value
        this.setState({ filter_price_high })
    }
    handleChangeInputFilter_article_weight_low = async event => {
        this.showFab();
        const filter_article_weight_low = event.target.value
        this.setState({ filter_article_weight_low })
    }
    handleChangeInputFilter_article_weight_high = async event => {
        this.showFab();
        const filter_article_weight_high = event.target.value
        this.setState({ filter_article_weight_high })
    }
    handleChangeInputFilter_article_age_low = async event => {
        this.showFab();
        const filter_article_age_low = event.target.value
        this.setState({ filter_article_age_low })
    }
    handleChangeInputFilter_article_age_high = async event => {
        this.showFab();
        const filter_article_age_high = event.target.value
        this.setState({ filter_article_age_high })
    }
    handleChangeInputFilter_article_daysontour_low = async event => {
        this.showFab();
        const filter_article_daysontour_low = event.target.value
        this.setState({ filter_article_daysontour_low })
    }
    handleChangeInputFilter_article_daysontour_high = async event => {
        this.showFab();
        const filter_article_daysontour_high = event.target.value
        this.setState({ filter_article_daysontour_high })
    }
    //Ski
    handleChangeInputFilter_ski_brand = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_ski_brand = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_ski_brand })
    }
    handleChangeInputFilter_ski_model = async event => {
        this.showFab();
        const filter_ski_model = event.target.value
        this.setState({ filter_ski_model })
    }
    handleChangeInputFilter_ski_condition = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_ski_condition = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_ski_condition })
    }
    handleChangeInputFilter_ski_aptitude = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_ski_aptitude = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_ski_aptitude })
    }
    handleChangeInputFilter_ski_length_low = async event => {
        this.showFab();
        const filter_ski_length_low = event.target.value
        this.setState({ filter_ski_length_low })
    }
    handleChangeInputFilter_ski_length_high = async event => {
        this.showFab();
        const filter_ski_length_high = event.target.value
        this.setState({ filter_ski_length_high })
    }
    handleChangeInputFilter_ski_width_low = async event => {
        this.showFab();
        const filter_ski_width_low = event.target.value
        this.setState({ filter_ski_width_low })
    }
    handleChangeInputFilter_ski_width_high = async event => {
        this.showFab();
        const filter_ski_width_high = event.target.value
        this.setState({ filter_ski_width_high })
    }
    handleChangeInputFilter_ski_radius_low = async event => {
        this.showFab();
        const filter_ski_radius_low = event.target.value
        this.setState({ filter_ski_radius_low })
    }
    handleChangeInputFilter_ski_radius_high = async event => {
        this.showFab();
        const filter_ski_radius_high = event.target.value
        this.setState({ filter_ski_radius_high })
    }
    handleChangeInputFilter_ski_rocker = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_ski_rocker = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_ski_rocker })
    }
    handleChangeInputFilter_ski_climbingskin = async event => {
        this.showFab();
        const filter_ski_climbingskin = event.target.value
        this.setState({ filter_ski_climbingskin })
    }
    //Binding
    handleChangeInputFilter_binding_brand = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_binding_brand = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_binding_brand })
    }
    handleChangeInputFilter_binding_model = async event => {
        this.showFab();
        const filter_binding_model = event.target.value
        this.setState({ filter_binding_model })
    }
    handleChangeInputFilter_binding_condition = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_binding_condition = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_binding_condition })
    }
    handleChangeInputFilter_binding_aptitude = async event => {
        this.showFab();
        const {
            target: { value },
        } = event;
        var filter_binding_aptitude = typeof value === 'string' ? value.split(',') : value
        this.setState({ filter_binding_aptitude })
    }
    handleChangeInputFilter_binding_pin = async event => {
        this.showFab();
        const filter_binding_pin = event.target.value
        this.setState({ filter_binding_pin })
    }
    handleChangeInputFilter_binding_stopper = async event => {
        this.showFab();
        const filter_binding_stopper = event.target.value
        this.setState({ filter_binding_stopper })
    }
    handleChangeInputFilter_binding_length = async event => {
        this.showFab();
        const filter_binding_length = event.target.value
        this.setState({ filter_binding_length })
    }
    handleChangeInputFilter_binding_length_adjustable = async event => {
        this.showFab();
        if (this.state.filter_binding_length_adjustable) {
            this.setState({ filter_binding_length_adjustable: false })
        } else {
            this.setState({ filter_binding_length_adjustable: true })
        }
    }
    handleChangeInputFilter_binding_release_rear = async event => {
        this.showFab();
        const filter_binding_release_rear = event.target.value
        this.setState({ filter_binding_release_rear })
    }
    handleChangeInputFilter_binding_release_front = async event => {
        this.showFab();
        const filter_binding_release_front = event.target.value
        this.setState({ filter_binding_release_front })
    }
    handleChangeInputFilter_binding_skicrampon = async event => {
        this.showFab();
        const filter_binding_skicrampon = event.target.value
        this.setState({ filter_binding_skicrampon })
    }

    handleModalLoginOpen = async event => {
        event.preventDefault()
        const modalLoginOpen = true;
        this.setState({ modalLoginOpen })
    }

    handleModalLoginClose = async event => {
        const modalLoginOpen = false
        this.setState({ modalLoginOpen })

    }

    constructFilter = async event => {
        const { filter_searchString, filter_price_low, filter_price_high, filter_article_weight_low, filter_article_weight_high, filter_article_age_low, filter_article_age_high, filter_article_daysontour_low, filter_article_daysontour_high } = this.state
        const { filter_ski_brand, filter_ski_model, filter_ski_condition, filter_ski_aptitude, filter_ski_length_low, filter_ski_length_high, filter_ski_width_low, filter_ski_width_high, filter_ski_radius_low, filter_ski_radius_high, filter_ski_rocker, filter_ski_climbingskin } = this.state
        const { filter_binding_brand, filter_binding_model, filter_binding_condition, filter_binding_aptitude, filter_binding_pin, filter_binding_stopper, filter_binding_length, filter_binding_length_adjustable, filter_binding_release_rear, filter_binding_release_front, filter_binding_skicrampon } = this.state

        var filter = ''
        //Base + Article
        if (filter_searchString !== '') {
            filter += 'searchString' + '&lk&' + filter_searchString + '&&'
        }
        if (filter_price_low !== '') {
            filter += 'price' + '&gte&' + filter_price_low + '&&'
        }
        if (filter_price_high !== '') {
            filter += 'price' + '&lte&' + filter_price_high + '&&'
        }
        if (filter_article_weight_low !== '') {
            filter += 'article_weight' + '&gte&' + filter_article_weight_low + '&&'
        }
        if (filter_article_weight_high !== '') {
            filter += 'article_weight' + '&lte&' + filter_article_weight_high + '&&'
        }
        if (filter_article_age_low !== '') {
            filter += 'article_age' + '&gte&' + filter_article_age_low + '&&'
        }
        if (filter_article_age_high !== '') {
            filter += 'article_age' + '&lte&' + filter_article_age_high + '&&'
        }
        if (filter_article_daysontour_low !== '') {
            filter += 'article_daysontour' + '&gte&' + filter_article_daysontour_low + '&&'
        }
        if (filter_article_daysontour_high !== '') {
            filter += 'article_daysontour' + '&lte&' + filter_article_daysontour_high + '&&'
        }


        //Ski
        if (filter_ski_brand.length > 0) {
            var brands = ''
            filter_ski_brand.forEach(brand => {
                brands += brand + ','
            });
            brands = brands.substring(0, brands.length - 1);

            filter += 'ski_brand' + '&in&' + brands + '&&'
        }
        if (filter_ski_model !== '') {
            filter += 'ski_model' + '&lk&' + filter_ski_model + '&&'
        }
        if (filter_ski_condition.length > 0) {
            var conditions = ''
            filter_ski_condition.forEach(condition => {
                conditions += condition + ','
            });
            conditions = conditions.substring(0, conditions.length - 1);

            filter += 'ski_condition' + '&in&' + conditions + '&&'
        }
        if (filter_ski_aptitude.length > 0) {
            var aptitudes = ''
            filter_ski_aptitude.forEach(aptitude => {
                aptitudes += aptitude + ','
            });
            aptitudes = aptitudes.substring(0, aptitudes.length - 1);

            filter += 'ski_aptitude' + '&in&' + aptitudes + '&&'
        }
        if (filter_ski_length_low !== '') {
            filter += 'ski_length' + '&gte&' + filter_ski_length_low + '&&'
        }
        if (filter_ski_length_high !== '') {
            filter += 'ski_length' + '&lte&' + filter_ski_length_high + '&&'
        }
        if (filter_ski_width_low !== '') {
            filter += 'ski_width' + '&gte&' + filter_ski_width_low + '&&'
        }
        if (filter_ski_width_high !== '') {
            filter += 'ski_width' + '&lte&' + filter_ski_width_high + '&&'
        }
        if (filter_ski_radius_low !== '') {
            filter += 'ski_radius' + '&gte&' + filter_ski_radius_low + '&&'
        }
        if (filter_ski_radius_high !== '') {
            filter += 'ski_radius' + '&lte&' + filter_ski_radius_high + '&&'
        }
        if (filter_ski_rocker.length > 0) {
            var rockers = ''
            filter_ski_rocker.forEach(rocker => {
                rockers += rocker + ','
            });
            rockers = rockers.substring(0, rockers.length - 1);

            filter += 'ski_rocker' + '&in&' + rockers + '&&'
        }
        if (filter_ski_climbingskin !== '') {
            filter += 'ski_climbingskin' + '&eq&' + filter_ski_climbingskin + '&&'
        }

        //Binding
        if (filter_binding_brand.length > 0) {
            var brands = ''
            filter_binding_brand.forEach(brand => {
                brands += brand + ','
            });
            brands = brands.substring(0, brands.length - 1);

            filter += 'binding_brand' + '&in&' + brands + '&&'
        }
        if (filter_binding_model !== '') {
            filter += 'binding_model' + '&lk&' + filter_binding_model + '&&'
        }
        if (filter_binding_condition.length > 0) {
            var conditions = ''
            filter_binding_condition.forEach(condition => {
                conditions += condition + ','
            });
            conditions = conditions.substring(0, conditions.length - 1);

            filter += 'binding_condition' + '&in&' + conditions + '&&'
        }
        if (filter_binding_aptitude.length > 0) {
            var aptitudes = ''
            filter_binding_aptitude.forEach(aptitude => {
                aptitudes += aptitude + ','
            });
            aptitudes = aptitudes.substring(0, aptitudes.length - 1);

            filter += 'binding_aptitude' + '&in&' + aptitudes + '&&'
        }
        if (filter_binding_pin !== '') {
            filter += 'binding_pin' + '&eq&' + filter_binding_pin + '&&'
        }
        if (filter_binding_stopper !== '') {
            filter += 'binding_stopper' + '&eq&' + filter_binding_stopper + '&&'
        }


        if (filter_binding_length_adjustable === true) {
            filter += 'binding_length_adjustable' + '&eq&' + Constants.GEN_TRUE + '&&'
            if (filter_binding_length !== '') {
                filter += 'binding_length_min' + '&lte&' + filter_binding_length + '&&'
                filter += 'binding_length_max' + '&gte&' + filter_binding_length + '&&'
            }
        } else {
            if (filter_binding_length !== '') {
                filter += 'binding_length' + '&eq&' + filter_binding_length + '&&'
            }
        }


        if (filter_binding_release_rear !== '') {
            filter += 'binding_release_rear_min' + '&lte&' + filter_binding_release_rear + '&&'
            filter += 'binding_release_rear_max' + '&gte&' + filter_binding_release_rear + '&&'
        }
        if (filter_binding_release_front !== '') {
            filter += 'binding_release_front' + '&eq&' + filter_binding_release_front + '&&'
        }
        if (filter_binding_skicrampon !== '') {
            filter += 'binding_skicrampon' + '&eq&' + filter_binding_skicrampon + '&&'
        }


        if (filter === '') {
            filter = 'filter'
        } else {
            filter = filter.substring(0, filter.length - 2);
        }

        this.setState({ filter })
    }

    deconstructFilter = async event => {
        if (this.state.filter !== 'filter') {
            let filter_urls = this.state.filter.split('&&');
            filter_urls.forEach(element => {
                let filter_url = element.split('&');
                switch (filter_url[0]) {
                    //Article + Base
                    case 'searchString':
                        this.state.filter_searchString = filter_url[2];
                        break;
                    case 'price':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_price_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_price_high = Number(filter_url[2]);
                        }
                        break;
                    case 'article_weight':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_article_weight_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_article_weight_high = Number(filter_url[2]);
                        }
                        break;
                    case 'article_age':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_article_age_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_article_age_high = Number(filter_url[2]);
                        }
                        break;
                    case 'article_daysontour':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_article_daysontour_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_article_daysontour_high = Number(filter_url[2]);
                        }
                        break;


                    //Ski
                    case 'ski_brand':
                        this.state.filter_ski_brand = filter_url[2].split(',');
                        break;
                    case 'ski_model':
                        this.state.filter_ski_model = filter_url[2];
                        break;
                    case 'ski_condition':
                        this.state.filter_ski_condition = filter_url[2].split(',');
                        break;
                    case 'ski_aptitude':
                        this.state.filter_ski_aptitude = filter_url[2].split(',');
                        break;
                    case 'ski_length':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_ski_length_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_ski_length_high = Number(filter_url[2]);
                        }
                        break;
                    case 'ski_width':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_ski_width_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_ski_width_high = Number(filter_url[2]);
                        }
                        break;
                    case 'ski_radius':
                        if (filter_url[1] === 'gte') {
                            this.state.filter_ski_radius_low = Number(filter_url[2]);
                        } else {
                            this.state.filter_ski_radius_high = Number(filter_url[2]);
                        }
                        break;
                    case 'ski_rocker':
                        this.state.filter_ski_rocker = filter_url[2].split(',');
                        break;
                    case 'ski_climbingskin':
                        this.state.filter_ski_climbingskin = filter_url[2];
                        break;

                    //Binding
                    case 'binding_brand':
                        this.state.filter_binding_brand = filter_url[2].split(',');
                        break;
                    case 'binding_model':
                        this.state.filter_binding_model = filter_url[2];
                        break;
                    case 'binding_condition':
                        this.state.filter_binding_condition = filter_url[2].split(',');
                        break;
                    case 'binding_aptitude':
                        this.state.filter_binding_aptitude = filter_url[2].split(',');
                        break;
                    case 'binding_release_rear_min':
                        break;
                    case 'binding_release_rear_max':
                        this.state.filter_binding_release_rear = Number(filter_url[2]);
                        break;
                    case 'binding_pin':
                        this.state.filter_binding_pin = filter_url[2];
                        break;
                    case 'binding_stopper':
                        this.state.filter_binding_stopper = filter_url[2];
                        break;
                    case 'binding_length_adjustable':
                        this.state.filter_binding_length_adjustable = true;
                        break;
                    case 'binding_length_min':
                        this.state.filter_binding_length = filter_url[2];
                        break;
                    /*case 'binding_length_max':
                        this.state.filter_binding_length = filter_url[2];
                        break;*/
                    case 'binding_release_front':
                        this.state.filter_binding_release_front = filter_url[2];
                        break;
                    case 'binding_skicrampon':
                        this.state.filter_binding_skicrampon = filter_url[2];
                        break;

                    default:
                        break;
                }
            });
        }
    }

    constructSort = async event => {
        var sort = this.state.sort_order
        this.setState({ sort })
    }

    deconstructSort = async event => {
        if (this.state.sort !== 'sort') {
            let sort_url = this.state.sort.split('&');
            if (sort_url[0] === 'dateCreated') {
                if (sort_url[1] === '1') {
                    const sort_order = Constants.SORT_ORDER_DATE_ASC;
                    this.setState({ sort_order: sort_order });
                } else {
                    const sort_order = Constants.SORT_ORDER_DATE_DSC;
                    this.setState({ sort_order: sort_order });
                }
            }
            if (sort_url[0] === 'price') {
                if (sort_url[1] === '1') {
                    const sort_order = Constants.SORT_ORDER_PRICE_ASC;
                    this.setState({ sort_order: sort_order });
                } else {
                    const sort_order = Constants.SORT_ORDER_PRICE_DSC;
                    this.setState({ sort_order: sort_order });
                }
            }
        }
    }

    doProgClick = async () => {
        try {
            document.getElementById("bt_filter").style.backgroundColor = '#0B5ED7';
            setTimeout(() => {
                document.getElementById("bt_filter").style.backgroundColor = '';
                setTimeout(() => {
                    this.hideFab();
                }, 100)
            }, 100)
            this.handleOnClickRefresh();
        } catch {

        }
    }

    handleOnClickRefresh = async event => {

        if (this.state.isMobile === true) {
            this.hideDrawer();
        }

        const end = false
        this.setState({ end })
        const notfound = false
        this.setState({ notfound })
        await this.constructFilter()
        await this.constructSort()

        this.state.skip = 0
        this.state.limit = 20

        const { skip, limit, filter, sort, uuid } = this.state
        const { origin } = window.location;
        const newURL = `${origin}/advert/ski/${limit}/${filter}/${sort}`;
        window.history.replaceState({}, '', newURL);

        const category = Constants.ART_CAT_SKI
        await api.getArticlesByFilter(category, skip, limit, filter, sort, uuid).then(res => {
            this.setState({
                articles: res.data.data
            })
        }).catch(error => {
            if (error.response.status === 404) {
                this.setState({
                    articles: []
                })
                const end = true
                this.setState({ end })
                const notfound = true
                this.setState({ notfound })

            }
            //Todo error handeling
        })
        window.scrollTo(0, document.getElementById('banner').offsetHeight);
    }

    showFab = async event => {
        var fab = document.getElementById("fab_filter");
        if (fab != null) {
            if (fab.classList.contains("hide")) {
                fab.classList.remove("hide");
            }
        }
    }
    hideFab = async event => {
        var fab = document.getElementById("fab_filter");
        if (fab != null) {
            if (fab.classList.contains("hide")) {
            } else {
                fab.classList.add("hide");
            }
        }
    }

    handleScroll = that => () => {
        if (that.filterRef.current === null || that.spacerRef.current === null) {
            return;
        }

        if (window.scrollY >= (that.state.filterBarOffsetTop - that.filterRef.current.offsetHeight)) {
            that.spacerRef.current.style.marginBottom = (that.filterRef.current.offsetHeight + 10) + 'px';

            that.filterRef.current.style.position = 'fixed';
            that.filterRef.current.style.top = (that.filterRef.current.offsetHeight - 5) + 'px';
            that.filterRef.current.style.left = 0;
            that.filterRef.current.style.zIndex = 99;

        } else {
            that.spacerRef.current.style.marginBottom = '0px';

            that.filterRef.current.style.removeProperty('position');
            that.filterRef.current.style.removeProperty('top');
            that.filterRef.current.style.removeProperty('left');
            that.filterRef.current.style.removeProperty('zIndex');
        }
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ sb_right: open });
    }
    hideDrawer = () => {
        this.setState({ sb_right: false });
    }

    render() {
        const { articles, loading, end, notfound, sort_order, isMobile, sb_right } = this.state
        const { articleMarkerId } = this.state

        const { ski_brand_vh, ski_condition_vh, ski_aptitude_vh, ski_rocker_vh, binding_brand_vh, binding_condition_vh, binding_aptitude_vh } = this.state

        const { filter_searchString, filter_price_low, filter_price_high, filter_article_weight_low, filter_article_weight_high, filter_article_age_low, filter_article_age_high, filter_article_daysontour_low, filter_article_daysontour_high } = this.state
        const { filter_ski_brand, filter_ski_model, filter_ski_condition, filter_ski_aptitude, filter_ski_length_low, filter_ski_length_high, filter_ski_width_low, filter_ski_width_high, filter_ski_radius_low, filter_ski_radius_high, filter_ski_rocker, filter_ski_climbingskin } = this.state
        const { filter_binding_brand, filter_binding_model, filter_binding_condition, filter_binding_aptitude, filter_binding_pin, filter_binding_stopper, filter_binding_length, filter_binding_length_adjustable, filter_binding_release_rear, filter_binding_release_front, filter_binding_skicrampon } = this.state

        const { modalLoginOpen } = this.state

        const { t } = this.props

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        const getArticleWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "65%";
        }
        const getFotoWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "25%";
        }
        const getButtonOffset = () => {
            if (isMobile === true) {
                return "30px";
            }
            return "0";
        }

        const Filters =
            <Container>
                <WrapperText>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth>
                                <TextField
                                    id="ip-filter_searchString"
                                    label={t('ui.list.tx_filter_article_search')}
                                    value={filter_searchString}
                                    onChange={this.handleChangeInputFilter_searchString}
                                    size={'small'}
                                    inputProps={{ maxLength: 50 }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.doProgClick()
                                        }
                                    }}
                                />
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                </WrapperText>
                <WrapperText>
                    <SubsubTitle>{t('label_article')}</SubsubTitle>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_article_price')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_price_low"
                                        value={filter_price_low}
                                        onChange={this.handleChangeInputFilter_price_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_price_high"
                                        value={filter_price_high}
                                        onChange={this.handleChangeInputFilter_price_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_article_weight')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_weight_low"
                                        value={filter_article_weight_low}
                                        onChange={this.handleChangeInputFilter_article_weight_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_weight_high"
                                        value={filter_article_weight_high}
                                        onChange={this.handleChangeInputFilter_article_weight_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_article_age')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_age_low"
                                        value={filter_article_age_low}
                                        onChange={this.handleChangeInputFilter_article_age_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Jahre</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_age_high"
                                        value={filter_article_age_high}
                                        onChange={this.handleChangeInputFilter_article_age_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Jahre</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_article_daysontour')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_daysontour_low"
                                        value={filter_article_daysontour_low}
                                        onChange={this.handleChangeInputFilter_article_daysontour_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_article_daysontour_high"
                                        value={filter_article_daysontour_high}
                                        onChange={this.handleChangeInputFilter_article_daysontour_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                </WrapperText>

                <WrapperText>
                    <SubsubTitle>{t('label_ski')}</SubsubTitle>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_ski_brand">{t('ui.list.tx_filter_ski_brand')}</InputLabel>
                                <Select
                                    labelId="tx-filter_ski_brand"
                                    id="ip-filter_ski_brand"
                                    value={filter_ski_brand}
                                    label={t('ui.list.tx_filter_ski_brand')}
                                    onChange={this.handleChangeInputFilter_ski_brand}
                                    multiple
                                    renderValue={(selected) => {
                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {ski_brand_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_ski_brand.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth>
                                <TextField
                                    id="ip-filter_ski_model"
                                    label={t('ui.list.tx_filter_ski_model')}
                                    value={filter_ski_model}
                                    onChange={this.handleChangeInputFilter_ski_model}
                                    size={'small'}
                                    inputProps={{ maxLength: 50 }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.doProgClick()
                                        }
                                    }}
                                />
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_ski_condition">{t('ui.list.tx_filter_ski_condition')}</InputLabel>
                                <Select
                                    labelId="tx-filter_ski_condition"
                                    id="ip-filter_ski_condition"
                                    value={filter_ski_condition}
                                    label={t('ui.list.tx_filter_ski_condition')}
                                    onChange={this.handleChangeInputFilter_ski_condition}
                                    multiple
                                    renderValue={(selected) => {
                                        var transformed = []
                                        selected.forEach(element => {
                                            transformed.push(t(element))
                                        });
                                        return transformed.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {ski_condition_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_ski_condition.indexOf(name) > -1} />
                                            <ListItemText primary={t(name)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_ski_aptitude">{t('ui.list.tx_filter_ski_aptitude')}</InputLabel>
                                <Select
                                    labelId="tx-filter_ski_aptitude"
                                    id="ip-filter_ski_aptitude"
                                    value={filter_ski_aptitude}
                                    label={t('ui.list.tx_filter_ski_aptitude')}
                                    onChange={this.handleChangeInputFilter_ski_aptitude}
                                    multiple
                                    renderValue={(selected) => {
                                        var transformed = []
                                        selected.forEach(element => {
                                            transformed.push(t(element))
                                        });
                                        return transformed.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {ski_aptitude_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_ski_aptitude.indexOf(name) > -1} />
                                            <ListItemText primary={t(name)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_ski_length')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_length_low"
                                        value={filter_ski_length_low}
                                        onChange={this.handleChangeInputFilter_ski_length_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_length_high"
                                        value={filter_ski_length_high}
                                        onChange={this.handleChangeInputFilter_ski_length_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_ski_width')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_width_low"
                                        value={filter_ski_width_low}
                                        onChange={this.handleChangeInputFilter_ski_width_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_width_high"
                                        value={filter_ski_width_high}
                                        onChange={this.handleChangeInputFilter_ski_width_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilterTitle>
                            <H5Title>{t('ui.list.tx_filter_ski_radius')}</H5Title>
                        </WrapperFilterTitle>
                        <WrapperFilter>
                            <WrapperFilterLeft>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_radius_low"
                                        value={filter_ski_radius_low}
                                        onChange={this.handleChangeInputFilter_ski_radius_low}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterLeft>
                            <WrapperFilterCenter>
                                -
                            </WrapperFilterCenter>
                            <WrapperFilterRight>
                                <FormControl fullWidth>
                                    <TextField
                                        id="ip-filter_ski_radius_high"
                                        value={filter_ski_radius_high}
                                        onChange={this.handleChangeInputFilter_ski_radius_high}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                        }}
                                        size={'small'}
                                        inputProps={{ maxLength: 5 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.doProgClick()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </WrapperFilterRight>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_ski_rocker">{t('ui.list.tx_filter_ski_rocker')}</InputLabel>
                                <Select
                                    labelId="tx-filter_ski_rocker"
                                    id="ip-filter_ski_rocker"
                                    value={filter_ski_rocker}
                                    label={t('ui.list.tx_filter_ski_rocker')}
                                    onChange={this.handleChangeInputFilter_ski_rocker}
                                    multiple
                                    renderValue={(selected) => {
                                        var transformed = []
                                        selected.forEach(element => {
                                            transformed.push(t(element))
                                        });
                                        return transformed.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {ski_rocker_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_ski_rocker.indexOf(name) > -1} />
                                            <ListItemText primary={t(name)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_ski_climbingskin">{t('ui.list.tx_filter_ski_climbingskin')}</InputLabel>
                                <Select
                                    labelId="tx-filter_ski_climbingskin"
                                    id="ip-filter_ski_climbingskin"
                                    value={filter_ski_climbingskin}
                                    label={t('ui.list.tx_filter_ski_climbingskin')}
                                    onChange={this.handleChangeInputFilter_ski_climbingskin}>
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Constants.GEN_TRUE}>{t('gen_true')}</MenuItem>
                                    <MenuItem value={Constants.GEN_FALSE}>{t('gen_false')}</MenuItem>
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                </WrapperText>

                <WrapperText>
                    <SubsubTitle>{t('label_binding')}</SubsubTitle>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_brand">{t('ui.list.tx_filter_binding_brand')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_brand"
                                    id="ip-filter_binding_brand"
                                    value={filter_binding_brand}
                                    label={t('ui.list.tx_filter_binding_brand')}
                                    onChange={this.handleChangeInputFilter_binding_brand}
                                    multiple
                                    renderValue={(selected) => {
                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {binding_brand_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_binding_brand.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth>
                                <TextField
                                    id="ip-filter_binding_model"
                                    label={t('ui.list.tx_filter_binding_model')}
                                    value={filter_binding_model}
                                    onChange={this.handleChangeInputFilter_binding_model}
                                    size={'small'}
                                    inputProps={{ maxLength: 50 }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.doProgClick()
                                        }
                                    }}
                                />
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_condition">{t('ui.list.tx_filter_binding_condition')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_condition"
                                    id="ip-filter_binding_condition"
                                    value={filter_binding_condition}
                                    label={t('ui.list.tx_filter_binding_condition')}
                                    onChange={this.handleChangeInputFilter_binding_condition}
                                    multiple
                                    renderValue={(selected) => {
                                        var transformed = []
                                        selected.forEach(element => {
                                            transformed.push(t(element))
                                        });
                                        return transformed.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {binding_condition_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_binding_condition.indexOf(name) > -1} />
                                            <ListItemText primary={t(name)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_aptitude">{t('ui.list.tx_filter_binding_aptitude')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_aptitude"
                                    id="ip-filter_binding_aptitude"
                                    value={filter_binding_aptitude}
                                    label={t('ui.list.tx_filter_binding_aptitude')}
                                    onChange={this.handleChangeInputFilter_binding_aptitude}
                                    multiple
                                    renderValue={(selected) => {
                                        var transformed = []
                                        selected.forEach(element => {
                                            transformed.push(t(element))
                                        });
                                        return transformed.join(', ');
                                    }}
                                    MenuProps={MenuProps}>
                                    {binding_aptitude_vh.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={filter_binding_aptitude.indexOf(name) > -1} />
                                            <ListItemText primary={t(name)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth>
                                <TextField
                                    id="ip-filter_binding_length"
                                    label={t('ui.list.tx_filter_binding_length')}
                                    value={filter_binding_length}
                                    onChange={this.handleChangeInputFilter_binding_length}
                                    size={'small'}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.doProgClick()
                                        }
                                    }}
                                />
                            </FormControl>
                        </WrapperFilter>
                        <WrapperFilter>
                            {t('ui.list.tx_filter_binding_length_adjustable')}
                            <Checkbox
                                checked={filter_binding_length_adjustable}
                                onChange={this.handleChangeInputFilter_binding_length_adjustable}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth>
                                <TextField
                                    id="ip-filter_binding_release_rear"
                                    label={t('ui.list.tx_filter_binding_release_rear')}
                                    value={filter_binding_release_rear}
                                    onChange={this.handleChangeInputFilter_binding_release_rear}
                                    size={'small'}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.doProgClick()
                                        }
                                    }}
                                />
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_pin">{t('ui.list.tx_filter_binding_pin')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_pin"
                                    id="ip-filter_binding_pin"
                                    value={filter_binding_pin}
                                    label={t('ui.list.tx_filter_binding_pin')}
                                    onChange={this.handleChangeInputFilter_binding_pin}>
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Constants.GEN_TRUE}>{t('gen_true')}</MenuItem>
                                    <MenuItem value={Constants.GEN_FALSE}>{t('gen_false')}</MenuItem>
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_stopper">{t('ui.list.tx_filter_binding_stopper')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_stopper"
                                    id="ip-filter_binding_stopper"
                                    value={filter_binding_stopper}
                                    label={t('ui.list.tx_filter_binding_stopper')}
                                    onChange={this.handleChangeInputFilter_binding_stopper}>
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Constants.GEN_TRUE}>{t('gen_true')}</MenuItem>
                                    <MenuItem value={Constants.GEN_FALSE}>{t('gen_false')}</MenuItem>
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_release_front">{t('ui.list.tx_filter_binding_release_front')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_release_front"
                                    id="ip-filter_binding_release_front"
                                    value={filter_binding_release_front}
                                    label={t('ui.list.tx_filter_binding_release_front')}
                                    onChange={this.handleChangeInputFilter_binding_release_front}>
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Constants.GEN_TRUE}>{t('gen_true')}</MenuItem>
                                    <MenuItem value={Constants.GEN_FALSE}>{t('gen_false')}</MenuItem>
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                    <ContainerFilter>
                        <WrapperFilter>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="tx-filter_binding_skicrampon">{t('ui.list.tx_filter_binding_skicrampon')}</InputLabel>
                                <Select
                                    labelId="tx-filter_binding_skicrampon"
                                    id="ip-filter_binding_skicrampon"
                                    value={filter_binding_skicrampon}
                                    label={t('ui.list.tx_filter_binding_skicrampon')}
                                    onChange={this.handleChangeInputFilter_binding_skicrampon}>
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Constants.GEN_TRUE}>{t('gen_true')}</MenuItem>
                                    <MenuItem value={Constants.GEN_FALSE}>{t('gen_false')}</MenuItem>
                                </Select>
                            </FormControl>
                        </WrapperFilter>
                    </ContainerFilter>
                </WrapperText>

                <WrapperFloatingButton id="fab_filter" style={{ "bottom": getButtonOffset() }}>
                    <BtnFilter id="bt_filter" onClick={this.handleOnClickRefresh}>{t('ui.list.bt_filter')} <IconGo alt={'...'} src={icon_cnt}></IconGo></BtnFilter>
                </WrapperFloatingButton>

            </Container>;

        return (
            <Container>
                {isMobile === false &&
                    <BarArticle />
                }
                <ContainerMain>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalLoginOpen}
                        onClose={this.handleModalLoginClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalLoginOpen}>
                            <Box sx={isMobile === true ? m_style_login : style_login}>
                                <Container>
                                    <WrapperModalHeader>
                                        <H5TitleModal>{t("ui.view.tx_mod_login_title")}</H5TitleModal>
                                        <BtnModalClose onClick={this.handleModalLoginClose}>
                                            <IconModalClose alt={'...'} src={icon_close} />
                                        </BtnModalClose>
                                    </WrapperModalHeader>
                                    <Paragraph>{t("ui.view.tx_mod_login_text")}</Paragraph>
                                    <StyledLink to={"/auth/login/"} state={{ from: window.location.pathname }}>
                                        <WrapperCenter>
                                            <BtnToLogin >{t("ui.view.bt_mod_toLogin")}</BtnToLogin>
                                        </WrapperCenter>
                                    </StyledLink>
                                </Container>
                            </Box>
                        </Fade>
                    </Modal>
                    <WrapperLine>

                        {isMobile === false &&

                            <WrapperFilters>

                                {Filters}

                            </WrapperFilters>

                        }


                        <WrapperArticles style={{ "width": getArticleWidth() }}>
                            <WrapperSort>
                                <WrapperSplit>
                                    <WrapperSplit2Left>
                                        <Title>{t('label_ski')}</Title>
                                    </WrapperSplit2Left>
                                    <WrapperSplit2Right>
                                        <FormControl>
                                            <InputLabel id="tx-sort_order">{t('ui.list.tx_filter_article_sort')}</InputLabel>
                                            <Select
                                                labelId="tx-sort_order"
                                                id="ip-sort_order"
                                                value={sort_order}
                                                label={t('ui.list.tx_filter_article_sort')}
                                                onChange={this.handleChangeInputSortorder}
                                                size={'small'}>
                                                <MenuItem value={Constants.SORT_ORDER_DATE_DSC}>{t('ui.list.tx_filter_article_sort_date_dsc')}</MenuItem>
                                                <MenuItem value={Constants.SORT_ORDER_DATE_ASC}>{t('ui.list.tx_filter_article_sort_date_asc')}</MenuItem>
                                                <MenuItem value={Constants.SORT_ORDER_PRICE_ASC}>{t('ui.list.tx_filter_article_sort_price_asc')}</MenuItem>
                                                <MenuItem value={Constants.SORT_ORDER_PRICE_DSC}>{t('ui.list.tx_filter_article_sort_price_dsc')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </WrapperSplit2Right>
                                </WrapperSplit>
                            </WrapperSort>

                            {isMobile === true &&

                                <React.Fragment>
                                    <WrapperFilterBar ref={this.filterRef}>
                                        <WrapperRight>
                                            <WrapperLine onClick={this.toggleDrawer(true)}>
                                                <WrapperLineItem>
                                                    <H5Title>{t('label_filter')}</H5Title>
                                                </WrapperLineItem>
                                                <WrapperLineItem>
                                                    <IconFilter alt={'...'} src={icon_menu} />
                                                </WrapperLineItem>
                                            </WrapperLine>
                                            <Drawer
                                                anchor={'right'}
                                                open={sb_right}
                                                onClose={this.toggleDrawer(false)}
                                            >
                                                <Box
                                                    height={'100%'}
                                                    bgcolor={'#212529'}
                                                    role="presentation"
                                                >
                                                    <WrapperText>
                                                        <WrapperLine>
                                                            <H5Title>{t('label_filter')}</H5Title>
                                                            <WrapperRight>
                                                                <IconClose alt={'...'} src={icon_close} onClick={this.hideDrawer} />
                                                            </WrapperRight>
                                                        </WrapperLine>
                                                    </WrapperText>
                                                    {Filters}
                                                </Box>
                                            </Drawer>
                                        </WrapperRight>
                                    </WrapperFilterBar>
                                    <Spacer ref={this.spacerRef} />
                                </React.Fragment>

                            }

                            {articles.length > 0 ?
                                <Container>
                                    <WrapperGrid>
                                        <Grid container spacing={{ xs: 0, md: 0 }} >
                                            {articles.map((obj, index) => {
                                                return (
                                                    <Grid xs={12} key={index}
                                                        sx={{
                                                            boxShadow: 3,
                                                            width: '100%',
                                                            height: '100%',
                                                            p: 0,
                                                            m: 2,
                                                            borderRadius: 0,
                                                        }}>

                                                        <StyledLink to={"/advert/view/" + articles[index]._id} onClick={this.handleOnClickArticle(articles[index]._id)}>
                                                            <ContainerItem key={articles[index]._id}
                                                                ref={articleMarkerId === articles[index]._id ? this.restorationRef : null}>
                                                                <WrapperLine>
                                                                    <WrapperLineItemPhoto style={{ "width": getFotoWidth() }}>
                                                                        <ContainerItemImage>
                                                                            {articles[index].photo_elements[0] ?
                                                                                <img src={articles[index].titlephoto_url} loading="lazy" alt={'...'} width="100%" />
                                                                                :
                                                                                <img src={altimage} alt={'...'} width="100%" />
                                                                            }
                                                                        </ContainerItemImage>
                                                                    </WrapperLineItemPhoto>
                                                                    <WrapperLineItemText>
                                                                        <ContainerText>
                                                                            <WrapperFlexTop>
                                                                                <WrapperTop>

                                                                                    {isMobile === false &&
                                                                                        <WrapperSplit>
                                                                                            <WrapperSplit2Location>
                                                                                                {(articles[index].delivery === Constants.ART_DEL_1 || articles[index].delivery === Constants.ART_DEL_3) &&
                                                                                                    <Container>
                                                                                                        <IconList alt={'...'} src={icon_pos} />{articles[index].delivery_country + " - " + articles[index].delivery_postalcode + " " + articles[index].delivery_city}
                                                                                                    </Container>
                                                                                                }
                                                                                            </WrapperSplit2Location>
                                                                                            <WrapperSplit2Date>
                                                                                                <Container>
                                                                                                    <IconList alt={'...'} src={icon_cal} />{format(new Date(articles[index].dateCreated), 'dd.MM.yyyy')}
                                                                                                </Container>
                                                                                            </WrapperSplit2Date>

                                                                                        </WrapperSplit>
                                                                                    }
                                                                                </WrapperTop>
                                                                                <WrapperTitle>
                                                                                    <Bold>{articles[index].title}</Bold>
                                                                                </WrapperTitle>
                                                                                {isMobile === false &&
                                                                                    <ContainerDescription>
                                                                                        <WrapperDescription>
                                                                                            {articles[index].description}
                                                                                        </WrapperDescription>
                                                                                    </ContainerDescription>
                                                                                }
                                                                            </WrapperFlexTop>
                                                                            <WrapperFlexMid>
                                                                                <WrapperPrice>
                                                                                    <SubsubTitle><font color="#229954">{articles[index].price} €</font></SubsubTitle>
                                                                                    {isMobile === true &&
                                                                                        <WrapperMark>
                                                                                            <IconMark alt={'...'} src={articles[index].marked ? icon_star_f : icon_star} onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.handleOnClickMarkArticle(index)} />
                                                                                        </WrapperMark>
                                                                                    }
                                                                                </WrapperPrice>
                                                                            </WrapperFlexMid>
                                                                            <WrapperFlexBottom>
                                                                                <WrapperTags>
                                                                                    <WrapperLineTag>
                                                                                        {isMobile === false && articles[index].ski_brand !== -1 && articles[index].ski_brand !== '' && articles[index].ski_brand !== Constants.GEN_NULL &&
                                                                                            <WrapperLineItem>
                                                                                                <WrapperTag>
                                                                                                    {articles[index].ski_brand}
                                                                                                </WrapperTag>
                                                                                            </WrapperLineItem>
                                                                                        }
                                                                                        {articles[index].ski_length !== -1 && articles[index].ski_length !== '' &&
                                                                                            <WrapperLineItem>
                                                                                                <WrapperTag> {isMobile === true ?
                                                                                                    <small>
                                                                                                        {articles[index].ski_length + ' cm'}
                                                                                                    </small>
                                                                                                    :
                                                                                                    <Container>
                                                                                                        {articles[index].ski_length + ' cm'}
                                                                                                    </Container>
                                                                                                }
                                                                                                </WrapperTag>
                                                                                            </WrapperLineItem>
                                                                                        }
                                                                                        {articles[index].ski_width !== -1 && articles[index].ski_width !== '' &&
                                                                                            <WrapperLineItem>
                                                                                                <WrapperTag> {isMobile === true ?
                                                                                                    <small>
                                                                                                        {articles[index].ski_width + ' mm'}
                                                                                                    </small>
                                                                                                    :
                                                                                                    <Container>
                                                                                                        {articles[index].ski_width + ' mm'}
                                                                                                    </Container>
                                                                                                }
                                                                                                </WrapperTag>
                                                                                            </WrapperLineItem>

                                                                                        }
                                                                                        <WrapperLineMark>
                                                                                            {isMobile === false &&
                                                                                                <WrapperMark>
                                                                                                    <IconMark alt={'...'} src={articles[index].marked ? icon_star_f : icon_star} onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.handleOnClickMarkArticle(index)} />
                                                                                                </WrapperMark>
                                                                                            }
                                                                                        </WrapperLineMark>
                                                                                    </WrapperLineTag>
                                                                                </WrapperTags>
                                                                            </WrapperFlexBottom>
                                                                        </ContainerText>
                                                                    </WrapperLineItemText>
                                                                </WrapperLine>
                                                            </ContainerItem>
                                                        </StyledLink>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </WrapperGrid>
                                </Container>
                                :
                                <Container>
                                    <WrapperCenter>
                                        <Paragraph></Paragraph>
                                        <Paragraph>{t('ui.list.tx_noarticles')}</Paragraph>
                                    </WrapperCenter>
                                </Container>
                            }
                            <WrapperCenter>
                                {loading ?
                                    <Wrapper>
                                        <LoadingIcons.ThreeDots width="5em" stroke="#0D6EFD" />
                                    </Wrapper>
                                    :
                                    <Container>
                                        {notfound &&
                                            <Wrapper>
                                                {t('ui.list.tx_noresults')}
                                            </Wrapper>
                                        }
                                        {!end && articles.length > 19 &&
                                            <Wrapper>
                                                <BtnLoadmore onClick={this.handleOnClickLoadmore}>{t('ui.list.tx_loadmore')}</BtnLoadmore>
                                            </Wrapper>
                                        }
                                    </Container>
                                }
                            </WrapperCenter>
                        </WrapperArticles>
                    </WrapperLine>
                </ContainerMain>
            </Container >
        )
    }
}

export default withTranslation()(withParams(Article_List_Ski));