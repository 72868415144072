import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Grid from '@mui/material/Unstable_Grid2';

import background from '../res/images/reski_background_v2.webp'
import background_backup from '../res/images/reski_background_lq_v2.png'
import icon_arrow_right from '../res/icons/icon_aup_r_v1.png';
//const background_backup = 'https://ik.imagekit.io/reski/admin/reski_background_v2_png.png'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const ContainerBackground = styled.div.attrs({
})`

`

const WrapperMain = styled.div.attrs({
})`
padding: 15px;
margin: 0px;
`

const WrapperGrid = styled.div.attrs({
})`
width: 100%;
height: 75px;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Panel = styled.div.attrs({
})`
width: 100%;
padding: 15px;
background-color: #FFFFFF;
border-radius: 15px;
`

const WrapperTitle = styled.div.attrs({
})`
width: 100%;
height: 300px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const WrapperSplit = styled.div.attrs({
})`
width: 100%;
height: 100%;
  display: flex;
  flex-direction: row;
`
const WrapperSplitLeft = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
`
const WrapperSplitRight = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 10%;
  `

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const IconArrowRight = styled.img.attrs({
    hight: '15',
    width: '15'
})``

class Website_Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            backgroundHeight: 0,
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        this.setState({
            backgroundHeight: this.getBackgroundHeight()
        })

        document.getElementById('banner').onload = function () {
            this.setState({
                backgroundHeight: this.getBackgroundHeight()
            })
        }.bind(this);
    }

    getBackgroundHeight = () => {

        if (document.getElementById('banner').offsetHeight === 0) {
            return;
        }

        var frameHeight;

        const bannerHeight = document.getElementById('banner').offsetHeight;
        const navbarHeight = document.getElementById('navbar').offsetHeight;
        const infobarHeight = document.getElementById('infobar').offsetHeight;

        try {
            frameHeight = bannerHeight + navbarHeight;

            if ( Math.abs(navbarHeight - infobarHeight) < 20) {
                frameHeight += infobarHeight;
            }

        } catch {
            frameHeight = window.innerHeight;
        }

        return window.innerHeight - frameHeight;
    }

    render() {
        const { backgroundHeight } = this.state
        const { t } = this.props

        return (

            <ContainerBackground style={{
                width: '100%',
                height: `${backgroundHeight}px`,
                backgroundImage: ` url(${background}), url(${background_backup})`,
                backgroundSize: 'cover'
            }}>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperTitle>
                            <WrapperCenter>
                                <Title>{t('ui.home.tx_title')}</Title>
                                <Paragraph></Paragraph>
                            </WrapperCenter>
                            <Paragraph></Paragraph>
                            <WrapperCenter>
                                <SubsubTitle>
                                    {t('ui.home.tx_text')}
                                </SubsubTitle>
                            </WrapperCenter>
                        </WrapperTitle>
                        <WrapperGrid>
                            <Grid container
                                width={1}
                                height={1}
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                                spacing={{ md: 0, lg: 1 }} >
                                <Grid md={7} lg={3} key={'link_1'}
                                    sx={{
                                        boxShadow: 0,
                                        width: '100%',
                                        height: '100%',
                                        p: 0,
                                        m: 0,
                                        borderRadius: 0
                                    }}>
                                    <WrapperPanel>
                                        <StyledLink to={"/info/benefits"}>
                                            <Panel>
                                                <WrapperCenter>
                                                    <WrapperSplit>
                                                        <WrapperSplitLeft>
                                                            <Bold>
                                                                {t('ui.home.tx_benefits')}
                                                            </Bold>
                                                        </WrapperSplitLeft>
                                                        <WrapperSplitRight>
                                                            <Container>
                                                                <IconArrowRight src={icon_arrow_right} alt={'...'} />
                                                            </Container>
                                                        </WrapperSplitRight>
                                                    </WrapperSplit>
                                                </WrapperCenter>
                                            </Panel>
                                        </StyledLink>
                                    </WrapperPanel>
                                </Grid>
                                <Grid md={7} lg={3} key={'link_2'}
                                    sx={{
                                        boxShadow: 0,
                                        width: '100%',
                                        height: '100%',
                                        p: 0,
                                        m: 0,
                                        borderRadius: 0,
                                    }}>
                                    <WrapperPanel>
                                        <StyledLink to={"/info/about"}>
                                            <Panel>
                                                <WrapperCenter>
                                                    <WrapperSplit>
                                                        <WrapperSplitLeft>
                                                            <Bold>
                                                                {t('ui.home.tx_about')}
                                                            </Bold>
                                                        </WrapperSplitLeft>
                                                        <WrapperSplitRight>
                                                            <Container>
                                                                <IconArrowRight src={icon_arrow_right} alt={'...'} />
                                                            </Container>
                                                        </WrapperSplitRight>
                                                    </WrapperSplit>
                                                </WrapperCenter>
                                            </Panel>
                                        </StyledLink>
                                    </WrapperPanel>
                                </Grid>
                                <Grid md={7} lg={3} key={'link_3'}
                                    sx={{
                                        boxShadow: 30,
                                        width: '100%',
                                        height: '100%',
                                        p: 0,
                                        m: 0,
                                        borderRadius: 0,
                                    }}>
                                    <WrapperPanel>
                                        <StyledLink to={Constants.PATH_TO_ARTIKLES}>
                                            <Panel>
                                                <WrapperCenter>
                                                    <WrapperSplit>
                                                        <WrapperSplitLeft>
                                                            <Bold>
                                                                {t('ui.home.tx_articles')}
                                                            </Bold>
                                                        </WrapperSplitLeft>
                                                        <WrapperSplitRight>
                                                            <Container>
                                                                <IconArrowRight src={icon_arrow_right} alt={'...'} />
                                                            </Container>
                                                        </WrapperSplitRight>
                                                    </WrapperSplit>
                                                </WrapperCenter>
                                            </Panel>
                                        </StyledLink>
                                    </WrapperPanel>
                                </Grid>
                            </Grid>
                        </WrapperGrid>
                    </WrapperMain>
                </ContainerMain>
            </ContainerBackground>
        )
    }
}

export default withTranslation()(withParams(Website_Home));