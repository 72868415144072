import styled from 'styled-components'

const ContainerMain = styled.div.attrs({
    className: 'container',
})`
`

const WrapperMain = styled.div.attrs({
})`
    padding: 15px;
    margin: 15px;
    background-color: #FFFFFF;
    white-space: pre-wrap;
    border-radius: 20px;
`

const Container = styled.div.attrs({
})`

`

const ContainerText = styled.div.attrs({
})`
width: 75%;
`

const Wrapper = styled.div.attrs({
})`
    margin: 15px;
`

const WrapperText = styled.div.attrs({
})`
    padding: 15px;
    margin: 15px;
    background-color: #FFFFFF;
    white-space: pre-wrap;
    border-radius: 5px;
`

const WrapperCenter = styled.div.attrs({
})`
display: flex;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
`

const WrapperCenterH = styled.div.attrs({
})`
display: flex;
width: 100%;
flex-direction: row;
justify-content: center;
align-items: center;
`

export { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText }