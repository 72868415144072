import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'

import { BarArticle } from '../components'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';

import LoadingIcons from 'react-loading-icons'
import Grid from '@mui/material/Unstable_Grid2';

import altimage from '../res/images/reski_altimage_sq_v1.png'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const ContainerItem = styled.div.attrs({

})`
border-radius: 5px;
background-color: #FFFFFF;
&:hover {
    cursor: pointer;
  }
`

const ContainerItemImage = styled.div.attrs({

})`
position: relative;
`

const ContainerItemText = styled.div.attrs({

})`
padding: 2.5%;
overflow: hidden; 
text-overflow: ellipsis;
white-space: nowrap;
width: 100%;
`

const ContainerItemPrice = styled.div.attrs({

})`
padding: 0px 7px;
margin: 0px 7px;
white-space: nowrap;
background-color: #229954;
color: #ffffff;
border-radius: 7px;
position: absolute;
  top: 96%;
  left: 94%;
  transform: translate(-96%, -94%);

`

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
width: 95%
`
const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`


const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnLoadmore = styled(BtnWhite)`
`

class Article_Grid extends Component {
    constructor(props) {
        super(props)

        var limit = 20
        if (typeof (this.props.params.limit) !== "undefined") {
            limit = Number(this.props.params.limit)
        }

        this.state = {
            isMobile,
            articles: [],
            loading: false,
            end: false,
            skip: 0,
            limit: limit,
            articleMarkerId: '',

        }

        this.restorationRef = React.createRef();
    }

    scrollToElement = () => this.restorationRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });

    componentDidUpdate = () => {
        try {
            this.scrollToElement();
        } catch { }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        const { skip, limit } = this.state
        await api.getArticles(skip, limit).then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })

        const persistedId = sessionStorage.getItem(
            "scroll-position-article_grid-id-marker"
        );
        sessionStorage.removeItem("scroll-position-article_grid-id-marker");
        this.setState({
            articleMarkerId: persistedId
        })
    }

    handleOnClickArticle = id => () => {
        sessionStorage.setItem("scroll-position-article_grid-id-marker", id);
    }

    handleOnClickLoadmore = async event => {
        this.state.loading = true
        const limit = this.state.limit + 20
        this.setState({ limit })
        var articlesArray = this.state.articles;
        const skip = articlesArray.length
        const { origin } = window.location;
        const newURL = `${origin}/advert/all/${limit}`;
        window.history.replaceState({}, '', newURL);

        await api.getArticles(skip, limit).then(res => {
            res.data.data.forEach(element => {
                articlesArray.push(element)
            });
            if (res.data.data.length < 20) {
                const end = true
                this.setState({ end })
            }
        }).catch(error => {
            if (error.response.status === 404) {
                const end = true
                this.setState({ end })
            }
            //Todo error handeling
        })
        this.setState({
            articles: articlesArray
        })

        this.state.loading = false
    }

    render() {
        const { articles, loading, end, articleMarkerId, isMobile } = this.state
        const { t } = this.props

        return (
            <Container>
                {isMobile === false &&
                    <BarArticle />
                }
                <ContainerMain>
                    {articles.length > 0 ?
                        <Container>
                            <WrapperGrid>
                                <Grid container spacing={{ xs: 0.5, sm: 0, md: 0, lg: 0 }} >
                                    {articles.map((obj, index) => {
                                        return (
                                            <Grid xs={4.5} sm={3.21} md={2.42} lg={2.04} key={index}
                                                sx={{
                                                    boxShadow: 3,
                                                    height: '100%',
                                                    p: 0,
                                                    m: 2,
                                                    borderRadius: 0,
                                                }}>
                                                <StyledLink to={"/advert/view/" + articles[index]._id} onClick={this.handleOnClickArticle(articles[index]._id)}>
                                                    {/* <StyledLink onClick={this.handleOnClickArticle(articles[index]._id)}>*/}
                                                    <ContainerItem key={articles[index]._id}
                                                        ref={articleMarkerId === articles[index]._id ? this.restorationRef : null}>

                                                        <ContainerItemImage>
                                                            {articles[index].photo_elements[0] ?
                                                                <img src={articles[index].titlephoto_url} loading="lazy" alt={'...'} width="100%" />
                                                                :
                                                                <img src={altimage} alt={'...'} width="100%" />
                                                            }
                                                            <ContainerItemPrice>
                                                                <Bold>{articles[index].price} €</Bold>
                                                            </ContainerItemPrice>
                                                        </ContainerItemImage>

                                                        <ContainerItemText>
                                                            <Bold>{articles[index].title}</Bold>
                                                        </ContainerItemText>
                                                    </ContainerItem>
                                                </StyledLink>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </WrapperGrid>
                            <WrapperCenter>
                                {loading ?
                                    <Wrapper>
                                        <LoadingIcons.ThreeDots width="5em" stroke="#0D6EFD" />
                                    </Wrapper>
                                    :
                                    <Container>
                                        {!end && articles.length > 19 &&
                                            <Wrapper>
                                                <BtnLoadmore onClick={this.handleOnClickLoadmore}>{t('ui.grid.tx_loadmore')}</BtnLoadmore>
                                            </Wrapper>
                                        }
                                    </Container>
                                }
                            </WrapperCenter>
                        </Container>
                        :
                        <Container>
                            <WrapperCenter>
                                <Paragraph></Paragraph>
                                <Paragraph>{t('ui.grid.tx_noarticles')}</Paragraph>
                            </WrapperCenter>
                        </Container>
                    }
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Article_Grid));