import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import LoadingIcons from 'react-loading-icons'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import altimage from '../res/images/reski_altimage_sq_v1.png'
import paymentsimage from '../res/images/reski_payments_v3.webp'
const paymentsimage_backup = 'https://ik.imagekit.io/reski/admin/reski_payments_v3_png.png'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


const WrapperRadiogroup = styled.div.attrs({
})`
    margin: 5px;
`

const WrapperLineMain = styled.div.attrs({
})`
  display: flex;
  justify-content: left;
  align-items: left;
`

const WrapperForm = styled.div.attrs({
})`
`
const WrapperFormblock = styled.div.attrs({
})`
padding: 15px;
margin: 15px;
background-color: #FFFFFF;
white-space: pre-wrap;
`
const WrapperFormblockAccordion = styled.div.attrs({
})`
margin: 15px;
white-space: pre-wrap;
`


const WrapperBasket = styled.div.attrs({
})`
`

const WrapperBasketblock = styled.div.attrs({
})`
padding: 15px;
margin: 15px;
background-color: #FFFFFF;
white-space: pre-wrap;
`
const WrapperAcceptedPayments = styled.div.attrs({
})`
padding: 15px;
margin: 15px;
white-space: pre-wrap;
`

const WrapperBasketItem = styled.div.attrs({
})`
padding: 5px;
margin: 5px;
display: flex;
flex-direction: row;
`

const WrapperItemPhoto = styled.div.attrs({
})`
height: 100px;
width: 100px;
`

const WrapperItemText = styled.div.attrs({
})`
margin 0px 5px;
`

const WrapperBasketItemLine = styled.div.attrs({
})`
display: flex;
flex-direction: row;
`
const WrapperBasketItemLinePhoto = styled.div.attrs({
})`
margin-top 5px;
flex: 0 1 auto;
`
const WrapperBasketItemLineSplit = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperBasketItemLineText = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperBasketItemInfo = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperBasketItemSplit = styled.div.attrs({
})`
flex: 1 1 auto;
`

const WrapperBasketItemPrice = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperSereviceItem = styled.div.attrs({
})`
display: flex;
flex-direction: row;
width: 100%
`

const WrapperSereviceItemInfo = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperSereviceItemSplit = styled.div.attrs({
})`
flex: 1 1 auto;
`

const WrapperSereviceItemPrice = styled.div.attrs({
})`
flex: 0 1 auto;
`

const ContainerService = styled.div.attrs({
})`
padding: 5px 0px;
width: 100%
display: flex;
flex-direction: row;
`

const ContainerTaC = styled.div.attrs({
})`
margin: 5px;
`

const Splitter = styled.hr.attrs({
    color: '#FFFFFF',
    width: 'auto'
})`
margin: 10px 5px;
`

const WrapperFields = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`



const WrapperSplitStreet = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplitStreetnr = styled.div.attrs({
})`
  display: flex;
  width: 30%;
`

const WrapperSplitAddress1 = styled.div.attrs({
})`
  display: flex;
  width: 70%;
  margin-Left: 10px;
`
const WrapperSplitCountry = styled.div.attrs({
})`
  display: flex;
  width: 30%;
`
const WrapperSplitPostalcode = styled.div.attrs({
})`
  display: flex;
  width: 70%;
  margin-Left: 10px;
`

const WrapperSplitCity = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`

const WrapperSplitPhonenumber = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`
const WrapperSplitEmail = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`

const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`
const WrapperGrid = styled.div.attrs({
})`
width: 100%;
padding-right: 5px;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperField = styled.div.attrs({

})`
    width: 100%;
    margin: 10px 10px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnBuy = styled(BtnBlue)`
    width: 60%;
`
const BtnBackOnError = styled(BtnBlue)`
    width: 100%;
`

class Purchase_Checkout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.params.id,
            pageState: Constants.STATE_INITIAL,
            service_option: Constants.SERVICE_OPTION_1,
            acceptedTaC: false,
            jump_back: false,
            articleReservated: false,
            isMobile,

            services: [],
            article: {},
            itemtext: [],
            priceitem_delivery_option: {},
            priceitem_service_fee: {},

            email: '',
            phonenumber: '',

            sirname: '',
            sirnameError: false,
            name: '',
            nameError: false,

            street: '',
            streetError: false,
            streetnr: '',
            streetnrError: false,
            address1: '',
            postalcode: '',
            postalcodeError: false,
            city: '',
            cityError: false,
            country: '',
            countryError: false,
        }
    }

    handleServiceOptionChange = (event) => {
        const service_option = event.target.value
        this.setState({ service_option })
    }

    handleChangeInputSirname = async event => {
        const sirname = event.target.value
        this.setState({ sirname })
        if (this.state.sirnameError) {
            this.setState({ sirnameError: false })
        }
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
        if (this.state.nameError) {
            this.setState({ nameError: false })
        }
    }

    handleChangeInputStreet = async event => {
        const street = event.target.value
        this.setState({ street })
        if (this.state.streetError) {
            this.setState({ streetError: false })
        }
    }
    handleChangeInputStreetnr = async event => {
        const streetnr = event.target.value
        this.setState({ streetnr })
        if (this.state.streetnrError) {
            this.setState({ streetnrError: false })
        }
    }
    handleChangeInputAddress1 = async event => {
        const address1 = event.target.value
        this.setState({ address1 })
        if (this.state.address1Error) {
            this.setState({ address1Error: false })
        }
    }
    handleChangeInputPostalcode = async event => {
        const postalcode = event.target.value
        this.setState({ postalcode })
        if (this.state.postalcodeError) {
            this.setState({ postalcodeError: false })
        }
    }
    handleChangeInputCity = async event => {
        const city = event.target.value
        this.setState({ city })
        if (this.state.cityError) {
            this.setState({ cityError: false })
        }
    }
    handleChangeInputCountry = async event => {
        const country = event.target.value
        this.setState({ country })
        if (this.state.countryError) {
            this.setState({ countryError: false })
        }
    }

    handleChangeInputPhonenumber = async event => {
        const phonenumber = event.target.value
        this.setState({ phonenumber })
        if (this.state.phonenumberError) {
            this.setState({ phonenumberError: false })
        }
    }

    handleChangeInputAcceptedTaC = async event => {
        if (this.state.acceptedTaC) {
            this.setState({ acceptedTaC: false })
        } else {
            this.setState({ acceptedTaC: true })
            document.getElementById("containerTaC").style.border = ''
        }
    }

    componentDidUpdate = () => {
        try {
            if (this.state.jump_back) {
                this.setState({
                    pageState: Constants.STATE_INITIAL
                })
                this.state.jump_back = false;
            }
        } catch { }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        const anchor = this;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }

        Userfront.init(tenant);
        const userData = Userfront.user;
        this.setState({
            email: userData.email,
            sirname: decodeURIComponent(userData.data.sirname),
            name: decodeURIComponent(userData.data.name),
            phonenumber: userData.data.phonenumber,
            street: decodeURIComponent(userData.data.street),
            streetnr: userData.data.streetnr,
            address1: decodeURIComponent(userData.data.address1),
            country: userData.data.country,
            postalcode: userData.data.postalcode,
            city: decodeURIComponent(userData.data.city),
        })

        const { id } = this.state

        var view = 'false';
        var uuid = 'anonymous';
        if (Userfront.accessToken()) {
            uuid = Userfront.user.userUuid;
        }

        api.getArticleById(id, view, uuid).then(res => {

            this.setState({
                article: res.data.data
            })

            const { t } = this.props

            switch (res.data.data.category) {
                case Constants.ART_CAT_SKI:
                    this.state.itemtext.push({ size: 'n', text: t('label_ski') + ': ' });
                    this.state.itemtext.push({ size: 's', text: res.data.data.ski_brand + ' - ' + res.data.data.ski_model + ' ' });
                    this.state.itemtext.push({ size: 'n', text: t('label_binding') + ': ' });
                    this.state.itemtext.push({ size: 's', text: res.data.data.binding_brand + ' - ' + res.data.data.binding_model + ' ' });
                    break;
            }

            if (res.data.data.status != Constants.STATUS_AVAILABLE) {
                if (res.data.data.status === Constants.STATUS_LOCKED && uuid === res.data.data.taker_uuid) {
                } else {
                    this.setState({
                        articleReservated: true
                    })
                    return;
                }
            }

            if (res.data.data.delivery === Constants.ART_DEL_3) {
                this.setState({
                    service_option: Constants.SERVICE_OPTION_2
                })
            }


        }).catch(error => {
            anchor.setState({
                pageState: Constants.STATE_NODATA
            })
        })





        var shipment_price_id;
        if (process.env.NODE_ENV !== 'production') {
            shipment_price_id = Constants.DEV_STRIPE_PRICE_SHIPMENT;
        } else {
            shipment_price_id = process.env.REACT_APP_STRIPE_PRICE_SHIPMENT;
        }
        api.getPriceItemById(shipment_price_id).then(res => {

            this.setState({
                priceitem_delivery_option: res.data.data
            })
        }).catch(error => {
            anchor.setState({
                pageState: Constants.STATE_ERROR
            })
        })

        var servicefee_price_id;
        if (process.env.NODE_ENV !== 'production') {
            servicefee_price_id = Constants.DEV_STRIPE_PRICE_SERVICEFEE;
        } else {
            servicefee_price_id = process.env.REACT_APP_STRIPE_PRICE_SERVICEFEE;
        }
        api.getPriceItemById(servicefee_price_id).then(res => {

            this.setState({
                priceitem_service_fee: res.data.data
            })

        }).catch(error => {
            anchor.setState({
                pageState: Constants.STATE_ERROR
            })
        })
    }

    doPurchase = async event => {
        const { id, service_option, services, acceptedTaC, itemtext, phonenumber, email } = this.state;
        const { sirname, name, street, streetnr, country, postalcode, city, address1 } = this.state;

        //Check required
        var missing_values = false;
        if (acceptedTaC === false) {
            missing_values = true;
            document.getElementById("containerTaC").style.border = '2px solid red';
        }
        if (sirname === '') {
            this.setState({ sirnameError: true })
            missing_values = true;
        }
        if (name === '') {
            this.setState({ nameError: true })
            missing_values = true;
        }
        if (street === '') {
            this.setState({ streetError: true })
            missing_values = true;
        }
        if (streetnr === '') {
            this.setState({ streetnrError: true })
            missing_values = true;
        }
        if (country === '') {
            this.setState({ countryError: true })
            missing_values = true;
        }
        if (postalcode === '') {
            this.setState({ postalcodeError: true })
            missing_values = true;
        }
        if (city === '') {
            this.setState({ cityError: true })
            missing_values = true;
        }
        if (missing_values === true) {
            return;
        }

        var address = {
            sirname, name, street, streetnr, country, postalcode, city, address1
        }

        var contact = {
            phonenumber, email
        }

        this.setState({
            pageState: Constants.STATE_PENDING
        })


        const payload = { id, service_option, services, itemtext, address, contact }

        await api.purchaseArticles(payload).then(res => {

            //Redirect to Stripe
            window.location.href = res.data.redirect;

        }).catch(error => {

            this.setState({
                pageState: Constants.STATE_ERROR
            })

        })
    }

    render() {

        const { id, article, priceitem_delivery_option, priceitem_service_fee, service_option, itemtext, acceptedTaC } = this.state
        const { pageState, articleReservated, isMobile } = this.state

        const { sirname, sirnameError, name, nameError, email, phonenumber, phonenumberError } = this.state
        const { street, streetError, streetnr, streetnrError, address1, address1Error, postalcode, postalcodeError, city, cityError, country, countryError } = this.state

        const { t } = this.props

        var total_fee =
            Math.floor((
                Number(article.price)
                + Number(priceitem_service_fee.unit_amount) / 100
                + (service_option === Constants.SERVICE_OPTION_1 ? Number(priceitem_delivery_option.unit_amount) / 100 : 0)
            ) * 1.5) / 100


        var total_fee_label = (total_fee + "").replace('.', ',');
        if (total_fee_label[total_fee_label.length - 3] !== ',') {
            total_fee_label = total_fee_label + '0';
        }

        var total_price = Number(article.price)
            + total_fee
            + Number(priceitem_service_fee.unit_amount) / 100
            + (service_option === Constants.SERVICE_OPTION_1 ? Number(priceitem_delivery_option.unit_amount) / 100 : 0);

        var total_price_label = (total_price + "").replace('.', ',');
        if (total_price_label[total_price_label.length - 3] !== ',') {
            total_price_label = total_price_label + '0';
        }

        const serevice_1 =
            <ContainerService>
                <WrapperSereviceItem>
                    <WrapperSereviceItemInfo>
                        <Bold>{t("ui.pur_checkout.tx_service_1")}</Bold>
                        <Container>{t("ui.pur_checkout.tx_service_desc_1")}</Container>
                    </WrapperSereviceItemInfo>
                    <WrapperSereviceItemSplit></WrapperSereviceItemSplit>
                    <WrapperSereviceItemPrice>
                        <Bold>{Number(priceitem_delivery_option.unit_amount) / 100},00€</Bold>
                    </WrapperSereviceItemPrice>
                </WrapperSereviceItem>
            </ContainerService>;
        const serevice_2 =
            <ContainerService>
                <WrapperSereviceItem>
                    <WrapperSereviceItemInfo>
                        <Bold>{t("ui.pur_checkout.tx_service_2")}</Bold>
                        <Container>{t("ui.pur_checkout.tx_service_desc_2")} {article.delivery_city}                                                                                                                                                                                        </Container>
                    </WrapperSereviceItemInfo>
                    <WrapperSereviceItemSplit></WrapperSereviceItemSplit>
                    <WrapperSereviceItemPrice>
                        <Bold>0,00€</Bold>
                    </WrapperSereviceItemPrice>
                </WrapperSereviceItem>
            </ContainerService>;

        const useStyles = ({
            fullWidth: {
                width: "100%"
            }
        });

        const getFormWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "65%";
        }
        const getBasketWidth = () => {
            if (isMobile === true) {
                return "100%";
            }
            return "35%";
        }
        const getLayoutOrientation = () => {
            if (isMobile === true) {
                return "column";
            }
            return "row";
        }

        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login" }} /> :
                articleReservated ? <Navigate to={{ pathname: "/advert/view/" + id }} /> :
                    <Container>
                        {
                            pageState === Constants.STATE_INITIAL &&
                            <ContainerMain>
                                <WrapperMain>
                                    <WrapperCenter>
                                        <Title>{t('ui.pur_checkout.tx_title')}</Title>
                                        <Paragraph></Paragraph>
                                    </WrapperCenter>
                                </WrapperMain>
                                <WrapperLineMain style={{ "flex-direction": getLayoutOrientation() }}>
                                    <WrapperForm style={{ "width": getFormWidth() }}>
                                        <WrapperFormblock>
                                            <H5Title>{t('ui.pur_checkout.tx_service_title')}</H5Title>
                                            <WrapperRadiogroup>
                                                <FormControl fullWidth>
                                                    <RadioGroup
                                                        name="controlled-radio-buttons-group"
                                                        value={service_option}
                                                        onChange={this.handleServiceOptionChange}
                                                    >
                                                        {(article.delivery === Constants.ART_DEL_1 || article.delivery === Constants.ART_DEL_2) &&
                                                            <FormControlLabel
                                                                value={Constants.SERVICE_OPTION_1}
                                                                control={<Radio />}
                                                                label={serevice_1}
                                                            />
                                                        }
                                                        {(article.delivery === Constants.ART_DEL_1 || article.delivery === Constants.ART_DEL_3) &&
                                                            <FormControlLabel
                                                                value={Constants.SERVICE_OPTION_2}
                                                                control={<Radio />}
                                                                label={serevice_2}
                                                            />
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </WrapperRadiogroup>
                                        </WrapperFormblock>
                                        <WrapperFormblock>
                                            <H5Title>{t('ui.pur_checkout.tx_delivery_title')}</H5Title>
                                            <WrapperGrid>
                                                <Grid container
                                                    width={1}
                                                    height={1}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    spacing={{ sm: 0, md: 1 }} >
                                                    <Grid sm={10} md={5.5} key={'g-out-name'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-name"
                                                                        label={t('ui.profile.tx_name')}
                                                                        value={name}
                                                                        error={nameError}
                                                                        required={true}
                                                                        onChange={this.handleChangeInputName}
                                                                        inputProps={{ maxLength: 50 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-out-sirname'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-sirname"
                                                                        label={t('ui.profile.tx_sirname')}
                                                                        value={sirname}
                                                                        error={sirnameError}
                                                                        required={true}
                                                                        onChange={this.handleChangeInputSirname}
                                                                        inputProps={{ maxLength: 50 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-out-street'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-street"
                                                                        label={t('ui.profile.tx_street')}
                                                                        value={street}
                                                                        error={streetError}
                                                                        required={true}
                                                                        onChange={this.handleChangeInputStreet}
                                                                        inputProps={{ maxLength: 50 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>

                                                    <Grid sm={10} md={5.5} key={'g-out-streetnr-address1'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperFields>
                                                                <WrapperSplit>
                                                                    <WrapperSplitStreetnr>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id="ip2-streetnr"
                                                                                label={t('ui.profile.tx_streetnr')}
                                                                                value={streetnr}
                                                                                error={streetnrError}
                                                                                required={true}
                                                                                onChange={this.handleChangeInputStreetnr}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                size={'small'}
                                                                            />
                                                                        </FormControl>
                                                                    </WrapperSplitStreetnr>
                                                                    <WrapperSplitAddress1>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id="ip2-address1"
                                                                                label={t('ui.profile.tx_address1')}
                                                                                value={address1}
                                                                                error={address1Error}
                                                                                required={false}
                                                                                onChange={this.handleChangeInputAddress1}
                                                                                inputProps={{ maxLength: 50 }}
                                                                                size={'small'}
                                                                            />
                                                                        </FormControl>
                                                                    </WrapperSplitAddress1>
                                                                </WrapperSplit>
                                                            </WrapperFields>
                                                        </WrapperPanel>
                                                    </Grid>

                                                    <Grid sm={10} md={5.5} key={'g-out-country-postalcode'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperFields>
                                                                <WrapperSplit>
                                                                    <WrapperSplitCountry>
                                                                        <FormControl
                                                                            error={countryError}
                                                                            required={true}
                                                                            fullWidth size={'small'}>
                                                                            <InputLabel id="tx-country">{t('ui.profile.tx_country')}</InputLabel>
                                                                            <Select
                                                                                labelId="tx-country"
                                                                                id="ip2-country"
                                                                                value={country}
                                                                                label={t('ui.profile.tx_country')}
                                                                                onChange={this.handleChangeInputCountry}>
                                                                                <MenuItem value={Constants.COUNTRYCODE_GERMANY}>{Constants.COUNTRYCODE_GERMANY}</MenuItem>
                                                                                <MenuItem value={Constants.COUNTRYCODE_AUSTRIA}>{Constants.COUNTRYCODE_AUSTRIA}</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </WrapperSplitCountry>
                                                                    <WrapperSplitPostalcode>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id="ip2-postalcode"
                                                                                label={t('ui.profile.tx_postalcode')}
                                                                                value={postalcode}
                                                                                error={postalcodeError}
                                                                                required={true}
                                                                                onChange={this.handleChangeInputPostalcode}
                                                                                inputProps={{ maxLength: 5 }}
                                                                                size={'small'}
                                                                            />
                                                                        </FormControl>
                                                                    </WrapperSplitPostalcode>
                                                                </WrapperSplit>
                                                            </WrapperFields>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-out-city'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-city"
                                                                        label={t('ui.profile.tx_city')}
                                                                        value={city}
                                                                        error={cityError}
                                                                        required={true}
                                                                        onChange={this.handleChangeInputCity}
                                                                        inputProps={{ maxLength: 50 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>
                                                    <Grid sm={10} md={5.5} key={'g-out-email'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-email"
                                                                        label={t('ui.profile.tx_email')}
                                                                        value={email}
                                                                        disabled={true}
                                                                        required={true}
                                                                        inputProps={{ maxLength: 25 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>

                                                    <Grid sm={10} md={5.5} key={'g-out-phonenumber'}
                                                        sx={{
                                                            boxShadow: 0,
                                                            width: '100%',
                                                            p: 0,
                                                            m: 0,
                                                            borderRadius: 0,
                                                        }}>
                                                        <WrapperPanel>
                                                            <WrapperField>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="ip2-phonenumber"
                                                                        label={t('ui.profile.tx_phonenumber')}
                                                                        value={phonenumber}
                                                                        error={phonenumberError}
                                                                        required={false}
                                                                        onChange={this.handleChangeInputPhonenumber}
                                                                        inputProps={{ maxLength: 25 }}
                                                                        size={'small'}
                                                                    />
                                                                </FormControl>
                                                            </WrapperField>
                                                        </WrapperPanel>
                                                    </Grid>
                                                </Grid>
                                            </WrapperGrid>
                                        </WrapperFormblock>
                                    </WrapperForm>
                                    <WrapperBasket style={{ "width": getBasketWidth() }}>
                                        <WrapperBasketblock>
                                            <H5Title>{t('ui.pur_checkout.tx_basket_title')}</H5Title>
                                            <WrapperBasketItem>
                                                <WrapperBasketItemInfo>
                                                    <Bold>{article.title}</Bold>
                                                </WrapperBasketItemInfo>
                                                <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                <WrapperBasketItemPrice>
                                                    <Bold>{Number(article.price)},00€</Bold>
                                                </WrapperBasketItemPrice>
                                            </WrapperBasketItem>
                                            <WrapperBasketItem>

                                                <WrapperBasketItemInfo>
                                                    <WrapperBasketItemLine>
                                                        <WrapperBasketItemLinePhoto>
                                                            {article.titlephoto_url === 'null' ?
                                                                <WrapperItemPhoto>
                                                                    <img src={altimage} alt={'...'} height="100%" />
                                                                </WrapperItemPhoto>
                                                                :
                                                                <WrapperItemPhoto>
                                                                    <img src={article.titlephoto_url} loading="lazy" alt={'...'} height="100%" />
                                                                </WrapperItemPhoto>
                                                            }
                                                        </WrapperBasketItemLinePhoto>
                                                        <WrapperBasketItemLineSplit></WrapperBasketItemLineSplit>
                                                        <WrapperBasketItemLineText>
                                                            <WrapperItemText>
                                                                {itemtext.map((obj, index) => {
                                                                    switch (obj.size) {
                                                                        case 'n':
                                                                            return (
                                                                                <Container>{obj.text}</Container>
                                                                            )
                                                                        case 's':
                                                                            return (
                                                                                <Container style={{ fontSize: 'smaller' }}>{obj.text}</Container>
                                                                            )
                                                                    }
                                                                })}
                                                            </WrapperItemText>
                                                        </WrapperBasketItemLineText>
                                                    </WrapperBasketItemLine>
                                                </WrapperBasketItemInfo>
                                                <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                <WrapperBasketItemPrice>
                                                </WrapperBasketItemPrice>
                                            </WrapperBasketItem>
                                            <WrapperBasketItem>
                                                <WrapperBasketItemInfo>
                                                    {priceitem_service_fee.product_name}
                                                </WrapperBasketItemInfo>
                                                <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                <WrapperBasketItemPrice>
                                                    {Number(priceitem_service_fee.unit_amount) / 100},00€
                                                </WrapperBasketItemPrice>
                                            </WrapperBasketItem>
                                            {service_option === Constants.SERVICE_OPTION_1 ?
                                                <WrapperBasketItem>
                                                    <WrapperBasketItemInfo>
                                                        {priceitem_delivery_option.product_name}
                                                    </WrapperBasketItemInfo>
                                                    <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                    <WrapperBasketItemPrice>
                                                        {Math.floor(Number(priceitem_delivery_option.unit_amount) / 100)},00€
                                                    </WrapperBasketItemPrice>
                                                </WrapperBasketItem>
                                                :
                                                <WrapperBasketItem>
                                                    <WrapperBasketItemInfo>
                                                        {t('ui.pur_checkout.tx_basket_pickup')}
                                                    </WrapperBasketItemInfo>
                                                    <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                    <WrapperBasketItemPrice>
                                                        0,00€
                                                    </WrapperBasketItemPrice>
                                                </WrapperBasketItem>
                                            }

                                            <Splitter></Splitter>
                                            <WrapperBasketItem>
                                                <WrapperBasketItemInfo>
                                                    <Container>{t('ui.pur_checkout.tx_basket_transactionfee')}</Container>
                                                    <Container style={{ fontSize: 'smaller' }}>{t('ui.pur_checkout.tx_basket_transactionfee_desc')}</Container>
                                                </WrapperBasketItemInfo>
                                                <WrapperBasketItemSplit></WrapperBasketItemSplit>


                                                <WrapperBasketItemPrice>
                                                    {total_fee_label}€
                                                </WrapperBasketItemPrice>
                                            </WrapperBasketItem>
                                            <Splitter></Splitter>
                                            <WrapperBasketItem>
                                                <WrapperBasketItemInfo>
                                                    {t('ui.pur_checkout.tx_basket_sum')}
                                                </WrapperBasketItemInfo>
                                                <WrapperBasketItemSplit></WrapperBasketItemSplit>
                                                <WrapperBasketItemPrice>
                                                    <Bold>{total_price_label}€</Bold>
                                                </WrapperBasketItemPrice>
                                            </WrapperBasketItem>
                                            <ContainerTaC id="containerTaC">
                                                <Checkbox
                                                    checked={acceptedTaC}
                                                    onChange={this.handleChangeInputAcceptedTaC}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                {t('ui.pur_checkout.tx_tac_1')} <a href={'/info/termsandconditions'} target="_blank" rel="noreferrer noopener">{t('ui.pur_checkout.tx_tac_2')}</a> {t('ui.pur_checkout.tx_tac_3')}
                                            </ContainerTaC>
                                            <WrapperCenter>
                                                <BtnBuy onClick={this.doPurchase}>{t('ui.pur_checkout.bt_buy')}</BtnBuy>
                                            </WrapperCenter>
                                        </WrapperBasketblock>
                                        <WrapperAcceptedPayments>
                                            <Paragraph>{t('ui.pur_checkout.tx_payments')}</Paragraph>
                                            <img src={paymentsimage} width="100%"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = paymentsimage_backup;
                                                }} />
                                        </WrapperAcceptedPayments>
                                        <WrapperFormblockAccordion>
                                            <Accordion sx={{ boxShadow: 0, backgroundColor: '#EBEBEB' }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    {t('ui.pur_checkout.tx_info_1_title')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {t('ui.pur_checkout.tx_info_1_text')}
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion sx={{ boxShadow: 0, backgroundColor: '#EBEBEB' }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    {t('ui.pur_checkout.tx_info_2_title')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {t('ui.pur_checkout.tx_info_2_text')}
                                                </AccordionDetails>
                                            </Accordion>
                                        </WrapperFormblockAccordion>
                                    </WrapperBasket>
                                </WrapperLineMain>
                            </ContainerMain>
                        }
                        {
                            pageState === Constants.STATE_PENDING &&
                            <ContainerMain>
                                <WrapperMain>
                                    <WrapperCenter>
                                        <H5Title>{t('ui.pur_checkout.tx_pendingTitle')}</H5Title>
                                        <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                    </WrapperCenter>
                                    <WrapperText>{t('ui.pur_checkout.tx_pending_text')}</WrapperText>
                                </WrapperMain>
                            </ContainerMain >

                        }
                        {
                            pageState === Constants.STATE_ERROR &&
                            <ContainerMain>
                                <WrapperMain>
                                    <WrapperCenter>
                                        <H5Title>{t('ui.pur_checkout.tx_errorTitle')}</H5Title>
                                    </WrapperCenter>
                                    <WrapperText>{t('ui.pur_checkout.tx_error_text')}</WrapperText>
                                    <WrapperCenter>
                                        <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/advert/view/" + id}>
                                            <BtnBackOnError >{t("ui.pur_checkout.bt_backOnError")}</BtnBackOnError>
                                        </StyledLink>
                                    </WrapperCenter>
                                </WrapperMain>
                            </ContainerMain >

                        }
                        {
                            pageState === Constants.STATE_NODATA &&
                            <ContainerMain>
                                <WrapperMain>
                                    <WrapperCenter>
                                        <H5Title>{t('ui.pur_checkout.tx_nodataTitle')}</H5Title>
                                    </WrapperCenter>
                                    <WrapperText>{t('ui.pur_checkout.tx_nodata_text')}</WrapperText>
                                </WrapperMain>
                            </ContainerMain >

                        }
                    </Container>
        )
    }
}

export default withTranslation()(withParams(Purchase_Checkout));