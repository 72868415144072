import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'

import banner from '../res/images/reski_banner_v6.webp'
import logo from '../res/images/reski_logo_3_v3.webp'
const logo_backup = 'https://ik.imagekit.io/reski/admin/reski_logo_3_v3_png.png'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const OfflineContainer = styled.div.attrs({
})`
    background-color: #EBEBEB;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`

const WrapperLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
`
const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
    background-color: #EBEBEB;
`

const InputSelect = styled.select.attrs({
    className: 'form-control',
})`
    margin: 5px;
    width: 200px;
    background-color: #EBEBEB;
    &:hover {
        cursor: pointer;
      }
`
const SelectOption = styled.option.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const BtnKey = styled(BtnGrey)`
    margin: 5px;
    width: 100%;
`

const HeaderContainer = styled.div.attrs({
})`
flex: 0 1 auto;
`
const ContentContainer = styled.div.attrs({
})`
flex: 1 1 auto;
`
const FooterContainer = styled.div.attrs({
})`
flex: 0 1 auto;
`
const Nav = styled.nav.attrs({
    className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
    margin-bottom: 20 px;
`
const WrapperNav = styled.a.attrs({
    className: 'navbar-brand',
})`
`

class Website_Offline extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }

    tryKey = () => {

        let type = document.getElementById("ipOfflineType").value;
        let key = document.getElementById("ipOfflineKey").value;

        localStorage.removeItem("dev_key");
        localStorage.removeItem("test_key");

        switch (type) {
            case Constants.TESTER:
                localStorage.setItem("test_key", key);
                break;
            case Constants.DEVELOPER:
                localStorage.setItem("dev_key", key);
                break;
        }

        window.location.href = `/`

    }

    render() {
        const { t } = this.props

        return (
            <OfflineContainer>
                <HeaderContainer>
                    <img id="banner" ref={this.bannerRef} src={banner} width="100%" hight="100%" alt="Banner" />
                    <Nav>
                        <WrapperNav>
                            <img src={logo} style={{ marginLeft: "15px" }} height="35"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = logo_backup;
                                }} />
                        </WrapperNav>
                    </Nav>
                </HeaderContainer>
                <ContentContainer>
                    <Container>
                        <ContainerMain>
                            <WrapperMain>
                                <WrapperCenter>
                                    <ContainerText>
                                        <WrapperCenter>
                                            <Title>{t('ui.offline.tx_title')}</Title>
                                            <Paragraph></Paragraph>
                                        </WrapperCenter>
                                        <WrapperCenter>
                                            <Paragraph>
                                                {t('ui.offline.tx_text')}
                                            </Paragraph>
                                            <Paragraph>
                                                {t('ui.offline.tx_signatur_greetings')}
                                            </Paragraph>
                                            <Paragraph>
                                                {t('ui.offline.tx_signatur_sender')}
                                            </Paragraph>
                                        </WrapperCenter>
                                    </ContainerText>
                                </WrapperCenter>
                            </WrapperMain>
                            <WrapperMain>
                                <WrapperCenter>
                                    <Paragraph>
                                        {t('ui.offline.tx_devtext')}
                                    </Paragraph>
                                    <WrapperLine>
                                        <InputSelect id="ipOfflineType" defaultValue={Constants.TESTER}>
                                            <SelectOption type="text" value={Constants.TESTER} > {t('ui.offline.tx_sel_test')} </SelectOption>
                                            <SelectOption type="text" value={Constants.DEVELOPER} > {t('ui.offline.tx_sel_dev')} </SelectOption>
                                        </InputSelect>
                                        <InputText
                                            id="ipOfflineKey"
                                            type="text"
                                            maxLength={25}
                                        />
                                        <BtnKey onClick={this.tryKey}>
                                            {t('ui.offline.bt_devtext')}
                                        </BtnKey>
                                    </WrapperLine>
                                </WrapperCenter>

                            </WrapperMain>
                        </ContainerMain>
                    </Container>

                </ContentContainer>
                <FooterContainer>
                    <Nav>
                        .
                    </Nav>
                </FooterContainer>
            </OfflineContainer>
        )
    }
}

export default withTranslation()(withParams(Website_Offline));