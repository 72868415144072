import React, { Component } from 'react'

import styled from 'styled-components'
import BarInfo from './BarInfo'

import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

class Footer extends Component {
    render() {
        return (
            <Container>
                    <BarInfo />
            </Container>
        )
    }
}

export default Footer