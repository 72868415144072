import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class Website_Benefits extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.benefits.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <Paragraph>
                                Drei gute Gründe, warum du deinen Tourenski gebraucht über reski.net kaufen solltest:<br/>
                                <br/>
                                <H5Title>Alleinstellungsmerkmal reski.net:</H5Title> 
                                Hast du schon mal auf einschlägigen Online-Marktplätzen eine wirklich vollständige und transparente Beschreibung eines Tourenskis gefunden? Wenn ja: sehr cool! Falls nicht: Schau hier. <br/>
                                <br/>
                                <H5Title>Transparente Zahlungsabwicklung und fairer Käuferschutz:</H5Title>
                                Bei uns erhält der Verkäufer erst dann sein Geld, wenn der Käufer zufrieden ist. Und wenn mal etwas nicht klappt, vermitteln wir gerne!<br/>
                                <br/>
                                <H5Title>Versandabwicklung: </H5Title>
                                Wir kümmern uns auch um den Versand deines Tourenskis von deiner Haustür weg bis hin zur Haustür des Käufers. Und mal ehrlich: Wer hat schon einen passenden Karton daheim, um Tourenski zu versenden?<br/>
                            </Paragraph>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>

        )
    }
}

export default withTranslation()(withParams(Website_Benefits));