import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class Website_Imprint extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <ContainerText>
                                <WrapperCenter>
                                    <Title>{t('ui.imprint.tx_title')}</Title>
                                    <Paragraph></Paragraph>
                                </WrapperCenter>
                                <Paragraph>
                                    <Bold>Angabengemäß §5 TMG</Bold>
                                </Paragraph>
                                <Paragraph>
                                    reski.online GbR <br />
                                    Michael Franz & Lukas Brexler
                                </Paragraph>
                                <Paragraph>
                                    Am Kleinfeld 13 <br />
                                    82431 Kochel am See <br />
                                    Deutschland
                                </Paragraph>
                                <Paragraph>
                                    USt.-ID: DE361649683
                                </Paragraph>
                                <Paragraph>
                                    Kontakt: info@reski.net
                                </Paragraph>
                                <Paragraph>
                                    <Bold>Vertretungsberechtigte Gesellschafter</Bold><br />
                                    Michael Franz, Lukas Brexler
                                </Paragraph>
                                <Paragraph>
                                    <Bold>Zweck der Unternehmung</Bold><br />
                                    Die Vermittlung von gebrauchter Skitourenausrüstung zwischen jeweils einem privaten Käufer und Verkäufer.
                                </Paragraph>
                                <Paragraph>
                                    <Bold>EU-Streitschlichtung</Bold><br />
                                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/consumers/odr</a>. <br />
                                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle oder Universalschlichtungsstelle teilzunehmen.
                                </Paragraph>
                                <Paragraph>
                                    <Bold>Redaktionelle Verantwortung</Bold><br />
                                    Lukas Brexler<br />
                                    Am Kleinfeld 13<br />
                                    82431 Kochel am See
                                </Paragraph>
                            </ContainerText>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_Imprint));