import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const ContainerFields = styled.div.attrs({
})`
width: 60%;
`

const BtnProfile = styled.button.attrs({
    className: `btn btn-secundary`,
})`
margin: 5px 0px;
color: #000000;
background-color: #EBEBEB;
&:hover {
    background-color: #D1D1D1;
  }
  `

class User_Noseller extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);
    }


    render() {
        const { t } = this.props
        return (
            !Userfront.accessToken() ? <Navigate to={{ pathname: "/auth/login", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.seller.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <ContainerFields>
                                <Paragraph>{t('ui.seller.tx_text')}</Paragraph>
                                <WrapperCenter>
                                    <StyledLink to={"/user/profile"}>
                                        <BtnProfile >{t('ui.seller.bt_profile')}</BtnProfile>
                                    </StyledLink>
                                </WrapperCenter>
                            </ContainerFields>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(User_Noseller));