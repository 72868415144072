import axios from 'axios'
import * as Constants from '../constants'
import Userfront from "@userfront/react";

var tenant = '';
if (process.env.NODE_ENV !== 'production') {
    tenant = Constants.DEV_USERFRONT_TENANT;
} else {
    tenant = process.env.REACT_APP_USERFRONT_TENANT;
}
Userfront.init(tenant);

var base_url = '';
if (process.env.NODE_ENV !== 'production') {
    base_url = Constants.DEV_BASE_URL;
} else {
    base_url = process.env.REACT_APP_BASE_URL;
}

var imagekit_upload_url = '';
if (process.env.NODE_ENV !== 'production') {
    imagekit_upload_url = Constants.DEV_IMAGEKIT_UPLOAD_URL;
} else {
    imagekit_upload_url = process.env.REACT_APP_IMAGEKIT_UPLOAD_URL;
}

var userfront_signup_url = '';
if (process.env.NODE_ENV !== 'production') {
    userfront_signup_url = Constants.DEV_USERFRONT_SIGNUP_URL;
} else {
    userfront_signup_url = process.env.REACT_APP_USERFRONT_SIGNUP_URL;
}


const api = axios.create({
    baseURL: base_url + '/api',
})

const config_default = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Userfront.accessToken()}`,
    },
};

const config_put = {
    headers: {
        Authorization: `Bearer ${Userfront.accessToken()}`,
    },
};

const config_webhook = {
    headers: {
        Authorization: `Bearer uf_test_webhook_jb7w5zrb_c2c5433715095907f38bc08f869cf04f`,
    },
};

//Protected - admin
export const getKPIs = () => api.get(`/report/kpis`, config_default)
export const getFIData = payload => api.post(`/report/fidata`, payload, config_default)
export const getPurchasesByFilter = (filter, sort, top) => api.get(`/report/purchases/filter/${filter}/sort/${sort}/top/${top}`, config_default)
export const getPurchaseById = (id) => api.get(`/report/purchase/${id}`, config_default)
export const answerPurchaseDispute = payload => api.post(`/purchase/answer/dispute`, payload, config_default)
export const refundArticles = payload => api.post(`/purchase/refund`, payload, config_default)
export const payoutArticles = payload => api.post(`/purchase/payout`, payload, config_default)
export const organizeShipping = payload => api.post(`/purchase/shipping/organize`, payload, config_default)
export const updateReviewAnswer = payload => api.post(`/review/answer`, payload, config_default)
export const getUserPayoutinfo = (uuid) => api.get(`/user/payoutinfo/${uuid}`, config_default)

//Protected - user
export const insertArticle = payload => api.post(`/article`, payload, config_default)
export const updateArticleById = (id, payload) => api.put(`/article/${id}`, payload, config_put)
export const deleteArticleById = id => api.delete(`/article/${id}`, config_default)

export const markArticleById = payload => api.put(`/user/mark`, payload, config_put)
export const demarkArticleById = payload => api.put(`/user/demark`, payload, config_put)
export const getSettingsByUser = () => api.get(`/user/settings`, config_default)
export const updateSettingsByUser = payload => api.put(`/user/settings`, payload, config_put)

export const getUploadToken = () => api.get(`/uploadToken`, config_default)
export const uploadPhoto = payload => axios.post(imagekit_upload_url, payload) //Protected via Uploadtoken

export const sendReport = payload => api.post(`/notification/report`, payload, config_default)

export const purchaseArticles = payload => api.post(`/purchase/checkout`, payload, config_default)

export const insertReview = payload => api.post(`/review`, payload, config_default)
export const updateReviewById = (id, payload) => api.put(`/review/${id}`, payload, config_put)
export const deleteReviewById = id => api.delete(`/review/${id}`, config_default)

export const answerPurchaseRequest = payload => api.post(`/purchase/answer/request`, payload, config_default)
export const answerPurchaseDelivery = payload => api.post(`/purchase/answer/delivery`, payload, config_default)
export const getPurchasesByUser = () => api.get(`/purchase/purchases`, config_default)
export const confirmPurchaseHandover = payload => api.post(`/purchase/handover/confirm`, payload, config_default)
export const rescheduleShipping = payload => api.post(`/purchase/shipping/reschedule`, payload, config_default)



//Unprotected
export const getArticleById = (id, view, uuid) => api.get(`/article/${id}/view/${view}/uuid/${uuid}`)
export const getArticles = (skip, limit) => api.get(`/articles/skip/${skip}/limit/${limit}`)
export const getArticlesByFilter = (category, skip, limit, filter, sort, uuid) => api.get(`/articles/category/${category}/skip/${skip}/limit/${limit}/filter/${filter}/sort/${sort}/uuid/${uuid}`)
export const getArticlesByUser = uuid => api.get(`/articles/user/${uuid}`)
export const getMarkedArticlesByUser = uuid => api.get(`/articles/mark/${uuid}`)

export const getBrandsByCategory = category => api.get(`/valuehelp/brands/${category}`)

export const getPriceItemById = id => api.get(`/purchase/priceitem/${id}`)

export const sendContact = payload => api.post(`/notification/contact`, payload)

export const getReviews = (skip, limit) => api.get(`/reviews/skip/${skip}/limit/${limit}`)
export const getReviewStats = () => api.get(`/reviews/stats`)

export const signupUser = payload => axios.post(userfront_signup_url, payload)
export const resetUserPassword = payload => api.post('/user/resetpassword', payload)

//Pseudo unprotected
export const simulateWebhookCreateUser = payload => api.post(`/user`, payload, config_webhook)


const apis = {
    getKPIs,
    getFIData,
    getPurchaseById,
    getPurchasesByFilter,
    
    getArticles,
    insertArticle,
    updateArticleById,
    getArticleById,
    getArticlesByUser,
    deleteArticleById,
    uploadPhoto,
    getBrandsByCategory,
    getArticlesByFilter,
    sendContact,
    sendReport,
    markArticleById,
    demarkArticleById,
    getMarkedArticlesByUser,
    getSettingsByUser,
    updateSettingsByUser,
    getUploadToken,
    getUserPayoutinfo,
    
    purchaseArticles,
    getPurchasesByUser,
    answerPurchaseRequest,
    answerPurchaseDelivery,
    answerPurchaseDispute,
    confirmPurchaseHandover,
    refundArticles,
    payoutArticles,
    rescheduleShipping,
    
    insertReview,
    getReviews,
    getReviewStats,
    deleteReviewById,
    updateReviewById,
    updateReviewAnswer,

    organizeShipping,

    getPriceItemById,

    simulateWebhookCreateUser,
    signupUser,
    resetUserPassword
}

export default apis