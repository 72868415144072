import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed, BtnGreen } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth, getListWidth } from '../util/index.js';

import { format } from 'date-fns'
import Grid from '@mui/material/Unstable_Grid2';
import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Countdown from 'react-countdown';
import HelpView from '../components/HelpView';

import Dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import altimage from '../res/images/reski_altimage_sq_v1.png'
import icon_cal from '../res/icons/icon_cal_d_v1.png'
import icon_view from '../res/icons/icon_view_d_v1.png'
import icon_star from '../res/icons/icon_star_d_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_warn from '../res/icons/icon_warn_d_v1.png'
import icon_done from '../res/icons/icon_done_g_v1.png'


const style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_reschedule = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
}; const m_style_reschedule = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const StyledLinkHelp = styled(Link)`
    text-decoration: none;
    width: 100%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const ContainerText = styled.div.attrs({
})`
    width: 90%;
    display: flex;
    flex-flow: column;
    margin: 10px 15px;
`

const ContainerItem = styled.div.attrs({

})`
border-radius: 5px;
background-color: #FFFFFF;
`

const ContainerBtn = styled.div.attrs({
})`
width: 100%

`

const ContainerItemImage = styled.div.attrs({

})`
position: relative;
margin: 15px;
`


const WrapperGridList = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`


const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperPrice = styled.div.attrs({
})`
margin: 5px 0px;
    background-color: #FFFFFF;
    white-space: nowrap;
`
const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperLineItemPhoto = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemDetails = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemStatus = styled.div.attrs({
})`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemButtons = styled.div.attrs({
})`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperSplit2LeftSM = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 10%;
    margin: 0px 10px 10px 0px ;
`

const WrapperFlexTop = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperFlexMid = styled.div.attrs({
})`
flex: 1 1 auto;
`
const WrapperFlexBottom = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperStatusDescription = styled.div.attrs({
})`
display: flex;
flex-direction: column;
justify-content: left;
align-items: left;
white-space: normal;
overflow-wrap: break-word;
`

const WrapperStrike = styled.div.attrs({
})`
display: flex;
flex-direction: row;
justify-content: left;
align-items: center;
`


const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`
const ContainerFields = styled.div.attrs({
})`
`

const WrapperButtonLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const BtnView = styled(BtnBlue)`
margin: 5px 0px;
    width: 100%;
`
const BtnEdit = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

const BtnTrack = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`
const BtnReschedule = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

const BtnReschedule2 = styled(BtnGreen)`
margin: 5px 10px;
justify-content: right;
`

const BtnDelete = styled(BtnWhite)`
margin: 5px 0px;
    width: 100%;
`

const BtnAccept = styled(BtnGreen)`
margin: 5px 0px;
    width: 100%;
`

const BtnBack = styled(BtnGrey)`
`

const BtnBackOnError = styled(BtnGrey)`
`

const BtnDelete2 = styled(BtnRed)`
margin: 5px 10px;
justify-content: right;
`

const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const BtnHelp = styled(BtnWhite)`
    margin: 5px;
    width: 100%;
`

const BtnConfirm = styled(BtnGreen)`
margin: 5px 0px;
    width: 100%;
`


const Icon = styled.img.attrs({
    hight: '15',
    width: '15'
})`
margin-right: 5px;
`

class User_Adverts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pickupDate: null,
            pichupMinDate: null,
            pichupMaxDate: null,
            modalDeleteId: '',
            modalDeleteOpen: false,
            modalDeleteState: Constants.STATE_INITIAL,
            modalRescheduleId: '',
            modalRescheduleOpen: false,
            modalRescheduleState: Constants.STATE_INITIAL,
            isMobile
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const userData = Userfront.user;

        const uuid = userData.userUuid;

        await api.getArticlesByUser(uuid).then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })
    }

    handleBtnBackOnError = async () => {
        this.setState({
            modalDeleteState: Constants.STATE_INITIAL,
            modalRescheduleState: Constants.STATE_INITIAL
        })
    }

    handleBtnDelete = async () => {
        const anchor = this;

        this.setState({
            modalDeleteState: Constants.STATE_PENDING
        })

        await api.deleteArticleById(this.state.modalDeleteId).then(res => {
            anchor.setState({
                modalDeleteState: Constants.STATE_SUCCESS
            })

            const uuid = Userfront.user.userUuid;
            api.getArticlesByUser(uuid).then(res => {
                anchor.setState({
                    articles: res.data.data
                })

            }).catch(error => {
                //Todo error handeling
            })

        }).catch(error => {
            anchor.setState({
                modalDeleteState: Constants.STATE_ERROR
            })
        })
    }

    handleBtReschedule = async () => {
        const anchor = this;

        this.setState({
            modalRescheduleState: Constants.STATE_PENDING
        })

        var payload = {};
        payload.id = this.state.modalRescheduleId;
        payload.pickupDate = format(new Date(this.state.pickupDate), 'dd.MM.yyyy');
        payload.pickupDateCMP = format(new Date(this.state.pickupDate), 'yyyyMMdd');

        await api.rescheduleShipping(payload).then(res => {
            anchor.setState({
                modalRescheduleState: Constants.STATE_SUCCESS
            })

            const uuid = Userfront.user.userUuid;
            api.getArticlesByUser(uuid).then(res => {
                anchor.setState({
                    articles: res.data.data
                })

            }).catch(error => {
                //Todo error handeling
            })

        }).catch(error => {
            anchor.setState({
                modalRescheduleState: Constants.STATE_ERROR
            })
        })
    }

    doAccept = id => async event => {

        event.preventDefault();
        const accepted = true;
        const payload = { id, accepted }

        await api.answerPurchaseRequest(payload).then(res => {

            //Todo make popup

            this.doRefresh();

        }).catch(error => {
            switch (error.response.status) {
                case 409:
                    this.doRefresh();
                    break;
                default:
                    //Todo error
                    break;
            }
        })
    }

    doNavExt = url => async event => {
        event.preventDefault();
        window.open(url, "_blank");
    }

    doRefresh = async event => {

        const uuid = Userfront.user.userUuid;

        await api.getArticlesByUser(uuid).then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })
    }

    handleModalDeleteOpen = id => async event => {
        event.preventDefault()

        const modalDeleteOpen = true;
        const modalDeleteId = id;
        this.setState({ modalDeleteOpen, modalDeleteId })
    }

    handleModalDeleteClose = async event => {
        const modalDeleteOpen = false
        this.setState({ modalDeleteOpen })
        await this.delay(250);
        this.setState({
            modalDeleteState: Constants.STATE_INITIAL
        })
    }

    handleModalRescheduleOpen = (id, date) => async event => {
        event.preventDefault()

        const modalRescheduleOpen = true;
        const modalRescheduleId = id;
        var date_char = date.toString();
        const pickupDate = Dayjs(date_char.substring(0, 4) + '-' + date_char.substring(4, 6) + '-' + date_char.substring(6, 8)).add(1, 'd');
        const pichupMinDate = Dayjs(date_char.substring(0, 4) + '-' + date_char.substring(4, 6) + '-' + date_char.substring(6, 8)).add(1, 'd');
        var pichupMaxDate;
        if (pickupDate.day() === 6 || pickupDate.day() === 3 || pickupDate.day() === 4 || pickupDate.day() === 5) {
            pichupMaxDate = Dayjs(date_char.substring(0, 4) + '-' + date_char.substring(4, 6) + '-' + date_char.substring(6, 8)).add(5, 'd');
        }
        if (pickupDate.day() === 0 || pickupDate.day() === 1 || pickupDate.day() === 2) {
            pichupMaxDate = Dayjs(date_char.substring(0, 4) + '-' + date_char.substring(4, 6) + '-' + date_char.substring(6, 8)).add(3, 'd');
        }
        this.setState({ modalRescheduleOpen, modalRescheduleId, pickupDate, pichupMinDate, pichupMaxDate })
    }

    handleModalRescheduleClose = async event => {
        const modalRescheduleOpen = false
        this.setState({ modalRescheduleOpen })
        await this.delay(250);
        this.setState({
            modalRescheduleState: Constants.STATE_INITIAL
        })
    }

    doConfirmHandover = id => async event => {

        event.preventDefault();

        const confirmed = true;

        const payload = { id, confirmed }

        await api.confirmPurchaseHandover(payload).then(res => {

            //Todo make popup

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })

    }

    delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    handleChangeInputDate = async (newValue) => {
        const pickupDate = newValue
        this.setState({ pickupDate })
    }


    render() {
        const { articles, modalDeleteOpen, modalDeleteState, modalRescheduleOpen, modalRescheduleState, isMobile } = this.state
        const { pickupDate, pichupMinDate, pichupMaxDate } = this.state
        const { t } = this.props

        const Completionist = () => <span>{t('ui.adverts.tx_list_accept_timeout')}</span>;
        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            var h = days * 24 + hours;
            var m = minutes;
            var s = seconds;

            if (h < 10) { h = '0' + h }
            if (m < 10) { m = '0' + m }
            if (s < 10) { s = '0' + s }

            if (completed) {

                return <Completionist />;
            } else {
                return <span>{t('ui.adverts.tx_list_accept_timeremaining_1')} {h}:{m}:{s} {t('ui.adverts.tx_list_accept_timeremaining_2')}</span>;
            }
        };

        const reorderDate = (date) => {
            if (typeof (date) !== "undefined") {
                var date_char = date.toString();
                return date_char.substring(6, 8) + '.' + date_char.substring(4, 6) + '.' + date_char.substring(0, 4);
            }
            return "01.01.1970"
        };
        const reorderDate2 = (date) => {
            if (typeof (date) !== "undefined") {
                var date_char = date.toString();
                return date_char.substring(0, 4) + '-' + date_char.substring(4, 6) + '-' + date_char.substring(6, 8);
            }
            return "1970-01-01"
        };

        const getReschedulabe = (date) => {
            var reschedulabe = false;
            if (typeof (date) !== "undefined") {
                var curr_date = new Date();
                curr_date.setDate(curr_date.getDate() + 3)
                var new_date = new Date(reorderDate2(date));
                if (curr_date < new_date) {
                    reschedulabe = true;
                }
            }
            return reschedulabe;
        }

        const isWeekend = (date) => {
            const day = date.day();
            return day === 0 || day === 6;
        };

        return (
            !Userfront.accessToken() ?
                <Navigate
                    to={{
                        pathname: "/auth/login",
                    }}
                />
                :
                <Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalDeleteOpen}
                        onClose={this.handleModalDeleteClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalDeleteOpen}>
                            <Box sx={isMobile === true ? m_style_delete : style_delete}>
                                {modalDeleteState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_delete_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_delete_text")}</Paragraph>
                                        <WrapperButtonLine>
                                            <BtnDelete2 onClick={this.handleBtnDelete}> {t('ui.adverts.bt_delete')}</BtnDelete2>
                                            <BtnCancel onClick={this.handleModalDeleteClose}> {t('ui.adverts.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.adverts.tx_mod_delete_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_delete_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_delete_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleModalDeleteClose}> {t('ui.adverts.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_delete_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_delete_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleBtnBackOnError}> {t('ui.adverts.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalRescheduleOpen}
                        onClose={this.handleModalRescheduleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalRescheduleOpen}>
                            <Box sx={isMobile === true ? m_style_reschedule : style_reschedule}>
                                {modalRescheduleState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_reschedule_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalRescheduleClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_reschedule_text")}</Paragraph>
                                        <WrapperCenter>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={"Abholung am:"}
                                                    size={'small'}
                                                    value={pickupDate}
                                                    onChange={(newValue) => this.handleChangeInputDate(newValue)}
                                                    format="DD.MM.YYYY"
                                                    shouldDisableDate={isWeekend}
                                                    minDate={pichupMinDate}
                                                    maxDate={pichupMaxDate}
                                                />
                                            </LocalizationProvider>
                                        </WrapperCenter>
                                        <Paragraph></Paragraph>
                                        <WrapperButtonLine>
                                            <BtnReschedule2 onClick={this.handleBtReschedule}> {t('ui.adverts.bt_reschedule')}</BtnReschedule2>
                                            <BtnCancel onClick={this.handleModalRescheduleClose}> {t('ui.adverts.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalRescheduleState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.adverts.tx_mod_reschedule_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalRescheduleState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_reschedule_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalRescheduleClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_reschedule_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleModalRescheduleClose}> {t('ui.adverts.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalRescheduleState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.adverts.tx_mod_reschedule_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalRescheduleClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.adverts.tx_mod_reschedule_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleBtnBackOnError}> {t('ui.adverts.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <ContainerMain>
                        <WrapperMain>
                            <WrapperCenter>
                                <Title>{t('ui.adverts.tx_title')}</Title>
                                <Paragraph></Paragraph>
                            </WrapperCenter>
                        </WrapperMain>
                        <WrapperCenter>
                            <ContainerFields style={{ "width": getListWidth(isMobile) }}>
                                {articles.length > 0 ?
                                    <Container>
                                        <WrapperGrid>
                                            <Grid container spacing={{ xs: 0, md: 0 }} >
                                                {articles.map((obj, index) => {
                                                    return (
                                                        <Grid xs={12} key={index}
                                                            sx={{
                                                                boxShadow: 3,
                                                                width: '100%',
                                                                height: '100%',
                                                                p: 0,
                                                                m: 2,
                                                                borderRadius: 0,
                                                            }}>

                                                            <ContainerItem key={articles[index]._id}>
                                                                <StyledLink to={"/advert/view/" + articles[index]._id} >

                                                                    <WrapperGridList>
                                                                        <Grid container
                                                                            width={1}
                                                                            height={1}
                                                                            direction="row"
                                                                            justifyContent="space-evenly"
                                                                            alignItems="center"
                                                                            spacing={{ xs: 0, sm: 0.5, lg: 1 }} >
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-advert-photo'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerItemImage>
                                                                                        {articles[index].photo_elements[0] ?
                                                                                            <img src={articles[index].titlephoto_url} loading="lazy" alt={'...'} width="100%" />
                                                                                            :
                                                                                            <img src={altimage} alt={'...'} width="100%" />
                                                                                        }
                                                                                    </ContainerItemImage>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-advert-details'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{articles[index].title}</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>
                                                                                            <Container>
                                                                                                Nr. {articles[index].purchase_order_no}
                                                                                            </Container>
                                                                                            <Container>
                                                                                                <Icon src={icon_cal} />{format(new Date(articles[index].dateCreated), 'dd.MM.yyyy')}
                                                                                            </Container>
                                                                                            <WrapperLine>
                                                                                                <WrapperLineItem>
                                                                                                    <Icon src={icon_view} />{articles[index].views}
                                                                                                </WrapperLineItem>
                                                                                                <WrapperLineItem>
                                                                                                    <Icon src={icon_star} />{articles[index].marks}
                                                                                                </WrapperLineItem>
                                                                                            </WrapperLine>
                                                                                            <Paragraph></Paragraph>
                                                                                            {articles[index].strikes === 1 &&
                                                                                                <WrapperStrike>
                                                                                                    <Icon src={icon_warn} />
                                                                                                    <font color={Style.COLOUR_YELLOW}> {t("ui.adverts.tx_strike")}: {articles[index].strikes}</font>
                                                                                                </WrapperStrike>
                                                                                            }
                                                                                            {articles[index].strikes === 2 &&
                                                                                                <WrapperStrike>
                                                                                                    <Icon src={icon_warn} />
                                                                                                    <font color={Style.COLOUR_ORANGE}> {t("ui.adverts.tx_strike")}: {articles[index].strikes}</font>
                                                                                                </WrapperStrike>
                                                                                            }
                                                                                            {articles[index].strikes === 3 &&
                                                                                                <WrapperStrike>
                                                                                                    <Icon src={icon_warn} />
                                                                                                    <font color={Style.COLOUR_RED}> {t("ui.adverts.tx_strike")}: {articles[index].strikes}</font>
                                                                                                </WrapperStrike>
                                                                                            }
                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>
                                                                                            <WrapperPrice>
                                                                                                {articles[index].status != Constants.STATUS_RESOLVED &&
                                                                                                    <SubsubTitle><font color="#229954">{articles[index].price} €</font></SubsubTitle>
                                                                                                }
                                                                                            </WrapperPrice>
                                                                                        </WrapperFlexBottom>

                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-advert-status'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{t('ui.marks.tx_list_status')}:</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>

                                                                                            <WrapperPrice>
                                                                                                {articles[index].status === Constants.STATUS_AVAILABLE &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_LOCKED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_BLUE}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_RESERVED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_BLUE}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_1 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>

                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_2 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_ORANGE}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_HANDOVER &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_ORANGE}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_SOLD &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_DISPUTED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_RED}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_RESOLVED &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_GREY}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_TIMEOUT &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_RED}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <Countdown
                                                                                                            date={new Date(new Date(articles[index].article_timeout) - (60 * 1000))}
                                                                                                            renderer={renderer}
                                                                                                        />
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_UNAVAILABLE &&
                                                                                                    <Container>
                                                                                                        <SubsubTitle><font color={Style.COLOUR_RED}>{t("advert_" + articles[index].status)}</font></SubsubTitle>
                                                                                                        <WrapperStatusDescription>
                                                                                                            {t("advert_desc_" + articles[index].status)}
                                                                                                        </WrapperStatusDescription>
                                                                                                    </Container>
                                                                                                }
                                                                                            </WrapperPrice>

                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>
                                                                                            {articles[index].status === Constants.STATUS_IN_SHIPPING_2 &&
                                                                                                <Paragraph>
                                                                                                    {t('ui.adverts.tx_list_reschedule_1')} {reorderDate(articles[index].shipping_pickup)} {t('ui.adverts.tx_list_reschedule_2')}.
                                                                                                </Paragraph>
                                                                                            }
                                                                                            {articles[index].status === Constants.STATUS_IN_HANDOVER && typeof (articles[index].handover_pickup) !== 'undefined' &&
                                                                                                <Paragraph>
                                                                                                    {t('ui.adverts.advert_desc_status_in_handover_2')} {format(new Date(articles[index].handover_pickup), 'dd.MM.yyyy')} {t('ui.adverts.advert_desc_status_in_handover_3')}.
                                                                                                </Paragraph>
                                                                                            }
                                                                                            {articles[index].status != Constants.STATUS_AVAILABLE && articles[index].status != Constants.STATUS_LOCKED && articles[index].status != Constants.STATUS_SOLD &&
                                                                                                <WrapperLine>
                                                                                                    <WrapperSplit2LeftSM>
                                                                                                    </WrapperSplit2LeftSM>
                                                                                                    <Container>
                                                                                                        <StyledLinkHelp to={"/info/contact/" + articles[index].purchase_order_no} >
                                                                                                            <BtnHelp>
                                                                                                                <HelpView />
                                                                                                            </BtnHelp>
                                                                                                        </StyledLinkHelp>
                                                                                                    </Container>
                                                                                                </WrapperLine>
                                                                                            }
                                                                                            {articles[index].status === Constants.STATUS_SOLD && articles[index].payout_status === true &&
                                                                                                <Paragraph>
                                                                                                    <Icon src={icon_done} />{t('ui.adverts.tx_list_paidout')}
                                                                                                </Paragraph>
                                                                                            }
                                                                                        </WrapperFlexBottom>
                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-advert-actions'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperCenter>
                                                                                        <ContainerText>
                                                                                            <WrapperFlexTop>
                                                                                                <StyledLink to={"/advert/view/" + articles[index]._id} >
                                                                                                    <BtnView>{t('ui.adverts.bt_list_view')}</BtnView>
                                                                                                </StyledLink>
                                                                                            </WrapperFlexTop>
                                                                                            <WrapperFlexMid>
                                                                                                {articles[index].status === Constants.STATUS_AVAILABLE &&
                                                                                                    <StyledLink to={"/advert/update/" + articles[index]._id} >
                                                                                                        <BtnEdit>{t('ui.adverts.bt_list_edit')}</BtnEdit>
                                                                                                    </StyledLink>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_2 &&
                                                                                                    <BtnTrack onClick={this.doNavExt(articles[index].shipping_tracking_box)}>{t('ui.adverts.bt_list_track_box')}</BtnTrack>

                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_2 && getReschedulabe(articles[index].shipping_pickup) && articles[index].shipping_pickup_rescheduled != true &&
                                                                                                    <BtnReschedule onClick={this.handleModalRescheduleOpen(articles[index]._id, articles[index].shipping_pickup)}>{t('ui.adverts.bt_list_reschedule')}</BtnReschedule>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_SHIPPING_3 &&
                                                                                                    <BtnTrack onClick={this.doNavExt(articles[index].shipping_tracking_article)}>{t('ui.adverts.bt_list_track_article')}</BtnTrack>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_RESERVED &&
                                                                                                    <ContainerBtn>
                                                                                                        <Countdown
                                                                                                            date={new Date(new Date(articles[index].taker_offer_deadline) - (60 * 1000))}
                                                                                                            renderer={
                                                                                                                ({ completed }) => {
                                                                                                                    if (completed) {
                                                                                                                        return <Container />;
                                                                                                                    } else {
                                                                                                                        return <BtnAccept onClick={this.doAccept(articles[index]._id)}>{t('ui.adverts.bt_list_accept')}</BtnAccept>;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        />
                                                                                                        <WrapperCenter>
                                                                                                            <Countdown
                                                                                                                date={new Date(new Date(articles[index].taker_offer_deadline) - (60 * 1000))}
                                                                                                                renderer={renderer}
                                                                                                            />
                                                                                                        </WrapperCenter>
                                                                                                    </ContainerBtn>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_HANDOVER && typeof (articles[index].handover_pickup) === 'undefined' &&
                                                                                                    <ContainerBtn >
                                                                                                        <BtnConfirm onClick={this.doConfirmHandover(articles[index]._id)}>{t('ui.adverts.bt_list_confirm_handover')}</BtnConfirm>
                                                                                                    </ContainerBtn>
                                                                                                }
                                                                                            </WrapperFlexMid>
                                                                                            <WrapperFlexBottom>
                                                                                                {articles[index].status === Constants.STATUS_AVAILABLE &&
                                                                                                    <BtnDelete onClick={this.handleModalDeleteOpen(articles[index]._id)}>{t('ui.adverts.bt_list_delete')}</BtnDelete>
                                                                                                }
                                                                                                {articles[index].status === Constants.STATUS_IN_TIMEOUT &&
                                                                                                    <Countdown
                                                                                                        date={new Date(new Date(articles[index].article_timeout) - (60 * 1000))}
                                                                                                        renderer={
                                                                                                            ({ completed }) => {
                                                                                                                if (completed) {
                                                                                                                    return <Container />;
                                                                                                                } else {
                                                                                                                    return <BtnDelete onClick={this.handleModalDeleteOpen(articles[index]._id)}>{t('ui.adverts.bt_list_delete')}</BtnDelete>;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    />

                                                                                                }
                                                                                            </WrapperFlexBottom>
                                                                                        </ContainerText>
                                                                                    </WrapperCenter>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </WrapperGridList>

                                                                </StyledLink>
                                                            </ContainerItem>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </WrapperGrid>
                                    </Container>
                                    :
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph></Paragraph>
                                            <Paragraph>{t('ui.adverts.tx_noarticles')}</Paragraph>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </ContainerFields>
                        </WrapperCenter>
                    </ContainerMain>
                </Container >
        )
    }
}

export default withTranslation()(withParams(User_Adverts));