import styled from 'styled-components'

const Title = styled.h1.attrs({
    className: 'h1',
})``

const SubTitle = styled.h2.attrs({
    className: 'h2',
})``

const SubsubTitle = styled.h3.attrs({
    className: 'h3',
})``

const H5Title = styled.h5.attrs({
    className: 'h5',
})``

const Paragraph = styled.p.attrs({
    className: 'p',
})``

const Line = styled.li.attrs({
    className: 'li',
})``

const Italic = styled.i.attrs({
})``

const Bold = styled.b.attrs({
})``


export { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold }