import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH, ContainerText } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class Website_TaC extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <ContainerText>
                                <WrapperCenter>
                                    <Title>{t('ui.termsandconditions.tx_title')}</Title>
                                    <Paragraph></Paragraph>
                                </WrapperCenter>
                                <H5Title>§1 Allgemeines und Geltungsbereich</H5Title>
                                <Paragraph>
                                    1.1. Die nachfolgenden AGB gelten für alle Verträge zwischen der<br />
                                    reski.online GbR<br />
                                    Michael Franz & Lukas Brexler<br />
                                    Am Kleinfeld 13<br />
                                    82431 Kochel am See<br />
                                    E-Mail: info@reski.net<br />
                                    Internet: https://www.reski.net<br />
                                    USt.-ID: DE361649683<br />
                                    Geschäftsführer: Lukas Brexler, Michael Franz<br />
                                    Sitz der Gesellschaft: Kochel am See<br />
                                    (im Folgenden „reski.online“ genannt) und den Anbieter:innen (im Folgenden geschlechtsneutral „Verkäufer“ genannt) und Interessent:innen (im Folgenden geschlechtsneutral „Käufer“, gemeinsam mit reski auch „Parteien“ genannt). Verkäufer und Käufer werden im Folgenden gemeinsam auch geschlechtsneutral „Nutzer“ des unter dem Link https://www.reski.net abrufbaren Marktplatzes und allen zu den Domains gehörenden Subdomains genannt. <br />
                                    1.2. Diese AGB gelten sowohl gegenüber Verbrauchern als auch gegenüber Unternehmern, es sei denn, in der jeweiligen Klausel wird eine Differenzierung vorgenommen. Verbraucher ist gem. § 13 BGB jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist gem. § 14 BGB eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.<br />
                                    1.3. Die AGB von reski.online gelten ausschließlich. Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen bzw. AGB des Nutzers werden nur dann und insoweit Vertragsbestandteil, als reski.online deren Geltung ausdrücklich zugestimmt hat.<br />
                                </Paragraph>
                                <H5Title>§2 Vertragsgegenstand</H5Title>
                                <Paragraph>
                                    2.1. reski.online stellt den Nutzern für die Vertragslaufzeit einen weltweiten Zugang zum Marktplatz von reski.online zur Verfügung, um als Verkäufer gebrauchte Skitourenausrüstung zahlungspflichtig anzubieten oder als Käufer in einer Datenbank nach Skitourenausrüstung zu suchen und zu erwerben. Der Versand der Skitourenausrüstung erfolgt durch einen von reski.online benannten Versanddienstleister oder durch Selbstabholung durch den Käufer bei einem zwischen Käufer und Verkäufer abgestimmten Übergabeort. Zusätzlich stellt reski.online den Zugang zur einer Plattform zur Zahlungsabwicklung sicher.<br />
                                    2.2. reski.online selbst bietet derzeit keine Skitourenausrüstung auf dem Marktplatz zum Kauf an, behält sich im Einzelfall jedoch vor, selbst Skitourenausrüstung anzukaufen, um diese zu einem späteren Zeitpunkt zu verkaufen und/oder zu verleasen. Hierbei unterwirft sich reski.online jedoch diesen AGB als Käuferin und erwirbt die Skitourenausrüstung zu den gleichen Konditionen wie die „normalen“ Käufer mit der Maßgabe, dass keine Versandpauschalen anfallen.<br />
                                    2.3. Gegenstand dieses Vertrages ist dementsprechend die Bereitstellung eines weltweit zugänglichen Marktplatzes für das Anbieten von bzw. Suchen nach Skitourenausrüstung und deren anschließender Versand und Zahlungsabwicklung.<br />
                                    2.4. Etwaige Anbahnung von Verträgen über den Marktplatz und/oder Vertragsbeziehungen bzgl. des Kaufs gebrauchter Skitourenausrüstung kommen ausschließlich zwischen den Nutzern untereinander zustande. reski.online haftet nicht für die ordnungsgemäße Durchführung etwaiger Kaufverträge zwischen den Nutzern.<br />
                                    2.5. reski.online bietet Käufern und Verkäufern einen Kundenservice und ist Ansprechpartner für alle Dispute, Rückerstattungsanfragen und Fragen zu auf reski.online durchgeführten Käufen.<br />
                                    2.6. Zwischen dem Käufer und reski.online entsteht mit Abschluss des Kaufvertrages ein Vertrag über die Transaktionsabwicklung.<br />
                                </Paragraph>

                                <H5Title>§3 Leistungen und Nutzung des Profils</H5Title>
                                <Paragraph>
                                    3.1. Die Leistungen von reski.online unterscheiden sich danach, ob der Nutzer den Marktplatz als Verkäufer oder als Käufer nutzt. Die Nutzung des Marktplatzes setzt in jedem Fall die erfolgreiche Registrierung und Zulassung des Nutzers voraus.<br />
                                    3.2. Der Nutzer kann als Verkäufer Angebote für den Verkauf von Skitourenausrüstung (im Folgenden „Verkaufsanzeigen“ genannt) auf dem Marktplatz von reski.online erstellen und veröffentlichen. Die Erstellung einer Verkaufsanzeige erfordert die erfolgreiche Registrierung sowie Angaben zu der Marke, dem Model, dem Herstellungsjahr, dem Bindungstyp, der Skilänge, der Skimittelbreite und dem Gebrauchszustand des Tourenskis. Der Verkäufer muss einen Verkaufspreis angeben und kann zusätzlich den Preis angeben, für den er die Ausrüstung erworben hat. Darüber hinaus kann der Verkäufer wahrheitsgetreue und unretuschierte Bilder hochladen sowie detaillierte Angaben über die Beschaffenheit der Skitourenausrüstung machen. Schließlich kann der Verkäufer die Verkaufsanzeigen jederzeit bearbeiten und wieder löschen, außerdem die Verkaufsanzeigen von anderen Nutzern einsehen. Im Falle eines Angebots des Käufers zur Verkaufsanzeige einer Skitourenausrüstung erhält der Verkäufer von reski.online eine Benachrichtigung (sog. „Notification“), auf die er innerhalb von 48 Stunden reagieren und mitteilen kann, ob ein Zwischenverkauf stattfand oder er das Angebot des Käufers annehmen möchte.<br />
                                    3.3. Ist der Nutzer Käufer, kann er nach erfolgreicher Registrierung und Zulassung veröffentlichte Verkaufsanzeigen auf dem Marktplatz von reski.online einsehen. Hierfür steht dem Käufer die Möglichkeit zur Verfügung, sämtliche auf dem Marktplatz von reski.online veröffentlichte Verkaufsanzeigen anzusehen oder eine „strukturierte Suche“ nach bestimmten Kriterien einer Skitourenausrüstung vorzunehmen. Der Käufer kann aus den Verkaufsanzeigen sämtliche Informationen zur Skitourenausrüstung, zu dessen Preis zzgl. etwaiger Zusatzkosten (Versand- und Transportkosten, Vermittlungsgebühr, Zahlungsgebühren) sowie zu den Mängelrechten gegenüber dem Verkäufer entnehmen.<br />
                                    3.4. Für Unternehmer gilt ergänzend: Die Erstellung und Veröffentlichung von Verkaufsanzeigen und die Einhaltung der Rechtskonformität liegen in der alleinigen Verantwortung des Verkäufers. Die Veröffentlichung von Verkaufsanzeigen ist ausschließlich unter Einhaltung der gesetzlichen Informationspflichten für Verkäufer und der Verwendung der eigenen ggf. vorhandenen Verträge bzw. Allgemeinen Geschäftsbedingungen, Gewährleistungen, Widerrufsbelehrung, Datenschutzerklärung und des Impressums des Verkäufers möglich.<br />
                                    3.5. Die konkrete grafische und funktionelle Ausgestaltung der vertragsgegenständlichen Nutzungsmöglichkeiten, die Erweiterung bzw. Anpassung der Nutzungsmöglichkeiten mit zusätzlichen Features bzw. Leistungen oder deren Ergänzung mit entgeltlichen Zusatzleistungen stehen im Ermessen von reski.online. reski.online ist unter Beibehaltung der vertragsgegenständlichen Nutzungsmöglichkeiten, jederzeit berechtigt, die konkrete Ausgestaltung zu modifizieren, erweitern und/oder anzupassen.<br />
                                    3.6. reski.online ist jederzeit berechtigt, den Zugang zu einzelnen Inhalten oder zu dem Marktplatz zu sperren, z.B. wenn der Verdacht besteht, dass die Nutzer gegen geltendes Recht, gegen diese AGB oder Rechte Dritter verstoßen. Der Nutzer kann diese Maßnahmen abwenden, wenn er den Verdacht durch Vorlage geeigneter Nachweise auf eigene Kosten ausräumt. Es besteht kein Anspruch des Nutzers auf Aufrechterhaltung einzelner Funktionalitäten in dem Marktplatz.<br />
                                    3.8. Der Marktplatz steht dem Nutzer zeitlich unbegrenzt zu mindestens 98,5 % im Jahresmittel zur Verfügung. Unvermeidbare, unvorhersehbare und außergewöhnliche Ereignisse, die zur Nichtverfügbarkeit des Marktplatzes führen können, wie z.B. Stromausfälle, Hackingeingriffe, Ausfälle der Telekommunikationsleitungen ab dem Übergabepunkt an das Internet werden auf das Verfügbarkeitsminimum nicht angerechnet.<br />
                                    3.9. reski.online ist berechtigt, für die Erbringung der vertraglich geschuldeten Leistungen Dritte als Subunternehmer einzuschalten. Darüber hinaus kann reski.online Rechte und Pflichten aus diesem Vertrag auf einen oder mehrere Dritte übertragen (Vertragsübernahme). Dem Nutzer steht für den Fall der vollständigen Vertragsübernahme das Recht zu, den Vertrag fristlos zu kündigen.<br />
                                </Paragraph>
                                <H5Title>§4 Registrierung, Vertragsschluss und Benutzerkonto</H5Title>
                                <Paragraph>
                                    4.1. Durch Abschluss des Online-Registrierungsvorgangs (im Folgenden „Registrierung“ genannt) und Erstellung eines Profils kommt ein Nutzungsvertrag zwischen dem Nutzer und reski.online zustande.<br />
                                    4.2. Für die Registrierung und die Erstellung eines Profils ist die Erstellung eines Benutzerkontos erforderlich. Die für das Benutzerkonto erforderlichen Daten (im Folgenden „Log-in-Daten“ genannt) ergeben sich aus der Eingabemaske, die für die Registrierung verwendet wird.<br />
                                    4.3. Für die Nutzung des Marktplatzes können sich natürliche Personen registrieren. Der Kauf ist natürlichen Personen vorbehalten. Ebenso sind juristische Personen mit Sitz in Deutschland zur Nutzung des Marktplatzes als Käufer berechtigt. Juristischen Personen mit Sitz außerhalb von Deutschland ist der Kauf von Skitourenausrüstung über reski.online nicht gestattet. Die Registrierung einer juristischen Person kann nur über eine vertretungsberechtigte Person vorgenommen werden. Als natürliche Person können sich nur Nutzer registrieren, die volljährig und geschäftsfähig sind. Sofern der Nutzer minderjährig ist, darf eine Registrierung nur mit Zustimmung eines gesetzlichen Vertreters erfolgen. reski.online ist berechtigt, die Registrierung von einem entsprechenden Nachweis einer Zustimmung eines gesetzlichen Vertreters abhängig zu machen.<br />
                                    4.4. Der Vertrag mit reski.online über die Nutzung des Marktplatzes kommt durch Abschluss des Online-Registrierungsvorgangs und anschließendem Anklicken der Checkboxen AGB („Hiermit bestätige ich, die Allgemeinen Geschäfts- und Nutzungsbedingungen gelesen zu haben und akzeptiere diese.“) und Datenschutz („Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass meine personenbezogenen Daten zum Zwecke der Registrierung und Erstellung eines Benutzerkontos elektronisch erhoben und gespeichert werden. Meine diesbezüglich erteilte Einwilligung kann ich jederzeit für die Zukunft per E Mail an info@reski.net widerrufen.“) zustande.<br />
                                    4.5. Nach Abschluss des Online-Registrierungsprozesses erhält der Nutzer eine Bestätigungs-E-Mail mit einem Aktivierungslink. Um den Registrierungsvorgang abzuschließen, muss der Nutzer sich durch Anklicken des Links in der Bestätigungs-E-Mail verifizieren. Ein Anspruch auf Abschluss eines Nutzungsvertrages besteht nicht.<br />
                                    4.6. Nachdem sich der Nutzer durch Anklicken des Links in der Bestätigungs-E-Mail verifiziert und das Benutzerkonto von reski freigeschaltet wurde, muss der Nutzer eine gültige Liefer- bzw. Versandadresse angeben und kann weitere Informationen hinzufügen.<br />
                                    4.7. Die Erstellung eines Benutzerkontos ist nur unter Angabe einer aktuellen E-Mail Adresse des Nutzers möglich. Diese E-Mail-Adresse dient zugleich der Kommunikation mit reski.online.<br />
                                    4.8. Der Nutzer sichert zu, dass die bei Erstellung seines Profils verwendeten Daten (im Folgenden „Profil-Daten“ genannt) wahrheitsgemäß und vollständig sind. Er ist verpflichtet seine Daten stets auf dem aktuellen Stand zu halten und im Falle von Änderungen eine Aktualisierung seiner Daten in seinem Benutzerkonto durchzuführen. Die Nutzung von Pseudonymen ist unzulässig. <br />
                                    4.9. Mit der Registrierung erstellt der Nutzer ein Passwort für sein Benutzerkonto. Der Nutzer kann das Passwort jederzeit in seinem Benutzerkonto ändern. Der Nutzer darf das Passwort Dritten nicht mitteilen oder zugänglich machen und hat es sorgfältig zu verwahren, um Missbräuche zu vermeiden.<br />
                                    4.10. Jeder Nutzer darf sich für die private und/oder geschäftliche Nutzung der reski.online Dienste jeweils nur einmal registrieren. Ein Benutzerkonto ist nicht auf andere Nutzer übertragbar.<br />
                                    4.11. Der Nutzer kann seine im Online-Registrierungsvorgang gemachten Eingaben jederzeit vor Abschicken seiner Registrierung über die üblichen Tastatur- und Mausfunktionen berichtigen.<br />
                                    4.12. reski.online speichert die Vertragsbestimmungen einschließlich der AGB bei Vertragsschluss unter Wahrung des Datenschutzes.<br />
                                    4.13. Der Vertragsschluss erfolgt in deutscher Sprache.<br />
                                    4.14. Der Nutzer hat sicherzustellen, dass die von ihm zur Registrierungsabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass unter dieser Adresse, die von reski.online versandten E-Mails empfangen werden können. Insbesondere hat der Nutzer bei dem Einsatz von SPAM-Filtern sicherzustellen, dass alle von reski.online oder von diesem mit der Bestellabwicklung beauftragten Dritten versandten E-Mails zugestellt werden können.<br />
                                </Paragraph>
                                <H5Title>§5 Warenübergabe und Auszahlung des Verkaufspreises an den Verkäufer</H5Title>
                                <Paragraph>
                                    5.1. Grundsätzlich kann der Käufer sich seine Ware durch eine Zustellung per Versanddienstleister oder einer persönlichen Übergabe übergeben bzw. ausliefern lassen. Die Auszahlung des Verkaufsbetrages an den Verkäufer erfolgt nach Warenübergang, spätestens nach einer Frist von 15 Werktagen. Die Frist beginnt mit Vertragsschluss zwischen Käufer und Verkäufer. Der Warenübergang erfolgt bei Paketversand durch die Abholung des Produktes beim Verkäufer, bei Selbstabholung durch die Übergabe der Ware. reski.online verwaltet dabei das Geld treuhändisch. Die Zahlungsabwicklung erfolgt über den Dienstleister Stripe.<br />
                                    5.2. Der Käufer hat die Möglichkeit, die Skitourenausrüstung beim Verkäufer abzuholen, sofern der Verkäufer dies nicht ausdrücklich ausgeschlossen hat. Nach dem Kauf informiert der Verkäufer den Käufer innerhalb von 48h, dass der Verkäufer das Angebot des Käufers akzeptiert oder ablehnt. Bestätigt der Verkäufer innerhalb von 48h das Kaufangebot in seinem Profil, muss dieser die Ware innerhalb von 12 weiteren Tagen an den Käufer übergeben. Dazu werden die jeweiligen Kontaktdaten zur Abstimmung der Warenübergabe bereitgestellt. Im Anschluss kann der Käufer die Skitourenausrüstung nach Absprache vom Verkäufer abholen. In diesem Fall werden keine Versandkosten berechnet. Die Zahlung wird an den Käufer ausgezahlt, sobald dieser die Entgegennahme der Ware in seinem Profil bestätigt hat, spätestens nach der Frist von 15 Werktagen. reski.online behält in diesem Fall die festgelegte Übermittlungsprovision ein und zahlt dem Verkäufer den Netto-Verkaufspreis ohne Vermittlungsprovision aus. Erfolgt nach spätestens 48h keine Kontaktaufnahme durch den Verkäufer, wird die Kaufanfrage storniert und der Käufer erhält den vollen Kaufpreis zurück. Wird die Ware nicht innerhalb der vereinbarten 15 Werktage übergeben, erfolgt nach weiteren 3 Werktagen eine E-Mail über die Stornierung der Vermittlung. Sobald der Käufer die Ware bei Selbstabholung übernommen hat, ist keine Reklamation mehr aufgrund von Sachmängeln möglich.<br />
                                    5.3. Bei Zustellung durch Postversand wird dem Verkäufer für den Versand der verkauften Skitourenausrüstung von reski.online eine Versandbox zur Verfügung gestellt. Der Verkäufer ist verpflichtet, die Skitourenausrüstung innerhalb von fünf (5) Tagen nach Zustellung der Versandbox in diese versandfertig zu verpacken und diese dem Versanddienstleister zum angegebenen Abholtermin zur Verfügung zu stellen. Der Abholtermin wird im Vorfeld durch reski.online festgelegt und kann bis zum Versenden der Versandbox durch den Verkäufer im Profil noch um max. 5 Tage angepasst werden. Die Lieferung der Skitourenausrüstung erfolgt auf dem versicherten Versandweg an die vom Käufer angegebene Lieferanschrift, sofern zwischen den Parteien nichts anderes vereinbart ist. Die während der Bestellung im Marktplatz angegebene Lieferanschrift des Käufers ist maßgeblich. Nach Erhalt der Ware hat der Käufer maximal 5 Werktage Zeit, die erhaltene Ware auszupacken und auf den beschriebenen Zustand hin zu überprüfen. Bestätigt der Käufer den Erhalt der Ware im beschrieben Zustand in seinem Profil, zahlt reski.online den vereinbarten Verkaufspreis abzüglich der Kosten für Provision und Versand auf das Konto des Verkäufers aus. Erfolgt keine Rückmeldung durch den Käufer, wird davon ausgegangen, dass dieser die Ware wie beschrieben erhalten hat. reski.online zahlt in diesem Fall spätestens nach 15 Werktagen den Verkaufspreis abzüglich Vermittlungsprovision und Versandkosten an den Verkäufer aus. Damit entfallen auch Ansprüche gegenüber dem Verkäufer, was einen eventuellen Mangel über den Zustand der Ware angeht.<br />
                                </Paragraph>
                                <H5Title>§6 Preise, Versandkosten, Service-Gebühr und Zahlungsbedingungen</H5Title>
                                <Paragraph>
                                    6.1. Der Käufer ist nach erfolgreicher Vertragsabwicklung mit dem Verkäufer und Kauf einer Skitourenausrüstung über den Marktplatz von reski.online verpflichtet, den vereinbarten Kaufpreis zzgl. der Versand- und Transportkosten (gemäß der Versandart) sowie eine Service-Gebühr für die Nutzung der reski.online-Dienste über das Bezahlsystem des Marktplatzes an reski.online als Einziehenden der Zahlung zu zahlen.<br />
                                    6.2. reski.online erhebt für die Nutzung des Markplatzes eine Servicegebühr sowie eine Transaktionsgebühr und bietet zusätzliche optionale Services an. Sofern zwischen den Parteien nicht etwas anderes vereinbart wird, richtet sich die Höhe der Gebühren nach der im Zeitpunkt des Vertragsschlusses gültigen und im Folgenden aufgeführten Preisliste vonreski.online:<br />
                                    Kosten für Käufer:<br />
                                    Servicegebühr bei Selbstabholung: 25€<br />
                                    Servicegebühr, Verpackung und Versand innerhalb Deutschlands: 50€<br />
                                    Transaktionsgebühr: 1,5% der Gesamtsumme<br />
                                    Kosten für Verkäufer: Keine<br />
                                    6.3. reski.online behält sich vor, zukünftig die Kosten gemäß 6.2 zur Bewahrung des Preis Leistungs-Verhältnisses nach beliebigem Ermessen anzupassen, um auf Kostensteigerungen oder -senkungen und sich dadurch verändernde, nicht anders ausgleichbare Kostensituationen angemessen reagieren zu können. Die für die Gebührenanpassung zu berücksichtigenden Kostenelemente bzw. Anpassungsmaßstäbe können die Modifizierung, Erweiterung und/oder Anpassung der vertragsgegenständlichen Nutzungsmöglichkeiten des Marktplatzes von reski.online, Verwaltungs- und Gemeinkosten (Mietzins, Finanzierungs- und Transaktionskosten, Personal- und Dienstleisterkosten, Energie- und Internetzugangskosten, IT-Entwicklungskosten, usw.) sowie staatlich auferlegte Steuern, Gebühren, Beiträge und sonstige Abgaben sein. Sämtliche Gebührenanpassungen gelten einen (1) Monat nach Bekanntgabe. Das Kündigungsrecht des Käufers gem. Ziffer 17.5. dieser AGB bleibt hiervon unberührt.<br />
                                    6.4. Aufrechnungsrechte stehen dem Käufer nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt oder unbestritten mit der Hauptforderung von reski.online gegenseitig verknüpft oder von diesem anerkannt sind.<br />
                                    6.5. Wird nach Abschluss des Vertrags erkennbar (z.B. durch Antrag auf Eröffnung eines Insolvenzverfahrens), dass der Anspruch von reski.online auf die Service-Gebühr durch mangelnde Leistungsfähigkeit des Käufers gefährdet wird, so ist reski.online nach den gesetzlichen Vorschriften zur Leistungsverweigerung und – gegebenenfalls nach Fristsetzung – zum Rücktritt vom Vertrag berechtigt (§ 321 BGB).<br />
                                </Paragraph>
                                <H5Title>§7 Allgemeine Mitwirkungspflichten des Nutzers</H5Title>
                                <Paragraph>
                                    7.1. Der Nutzer darf ohne ausdrückliche Gestattung von reski.online den Marktplatz nur im Rahmen der vertraglich vorausgesetzten privaten und geschäftlichen Zwecke nutzen. Jede über diese Zweckbindung hinausgehende missbräuchliche Nutzung ist dem Nutzer untersagt.<br />
                                    7.2. Für den Fall, dass die Inhalte Hyperlinks auf Seiten Dritter enthalten, sichert der Nutzer zu, dass er die Berechtigung zur Nutzung des Hyperlinks hat und die Website, auf die verwiesen wird („Landingpage“), mit geltendem Recht und Rechten Dritter vereinbar ist.<br />
                                    7.3. Der Nutzer ist verpflichtet, mit den Log-in-Daten sorgfältig umzugehen. Dem Nutzer ist es ausnahmslos untersagt, die Log-in-Daten Dritten mitzuteilen und/oder Dritten den Zugang zu dem Profil unter Umgehung der Log-in-Daten zu ermöglichen.<br />
                                    7.4. Der Nutzer ist verpflichtet, bei der Aufklärung von Angriffen Dritter auf den Marktplatz mitzuwirken, soweit diese Mitwirkung durch den Nutzer erforderlich ist.<br />
                                    7.5. Der Nutzer muss jedwede Tätigkeit unterlassen, die geeignet ist, den Betrieb des Marktplatzes oder der dahinterstehenden technischen Infrastruktur zu beeinträchtigen und/oder übermäßig zu belasten. Dazu zählen insbesondere:<br />
                                    7.5.1. die Verwendung von Software, Skripten oder Datenbanken in Verbindung mit der Nutzung des Marktplatzes;<br />
                                    7.5.2. das automatische Auslesen, Blockieren, Überschreiben, Modifizieren, Kopieren von Daten und/oder sonstigen Inhalten, soweit dies nicht für die ordnungsgemäße Nutzung des Marktplatzes erforderlich ist.<br />
                                    7.6. Weiterhin muss der Nutzer dafür Sorge tragen, dass seine über den Marktplatz übertragenen Informationen und eingestellten Daten nicht mit Viren, Würmern oder Trojanischen Pferden behaftet sind.<br />
                                    7.7. Ferner sind insbesondere folgende Handlungen des Nutzers untersagt und gelten als missbräuchliche Nutzung des Marktplatzes:<br />
                                    7.7.1. Veröffentlichen von eigenen oder fremden Telefonnummern, Internetadressen oder sonstigen Kontaktmöglichkeiten im Textbereich oder in der Direktkommunikation; <br />
                                    7.7.2. systematisches Auslesen der Kontaktdaten anderer Nutzer zum Zwecke der Weitergabe an Dritte;<br />
                                    7.7.3. unzumutbare Belästigungen anderer Nutzer durch aggressive, obszöne, beleidigende, verleumderische oder aufdringliche Forenbeiträge oder Nachrichten;<br />
                                    7.7.4. Anstiftung anderer Nutzer zur missbräuchlichen Nutzung des Marktplatzes<br />
                                    7.7.5. umfangreiche oder dauerhafte Nutzung des Marktplatzes zur Veröffentlichung und Verbreitung von Inhalten, die sachlich nicht dem Zweck oder Themenbereich des Marktplatzes oder seiner Blogs und Foren entsprechen und die die Attraktivität des Marktplatzes für andere Nutzer beeinträchtigen können;<br />
                                    7.7.6. die Nutzung fremder Identitäten für die Registrierung, das Einstellen von Beiträgen oder das Versenden von Nachrichten.<br />
                                    7.8. Sollte es bei der Nutzung des Marktplatzes oder seiner Funktionalitäten zu Störungen kommen, wird der Nutzer reski.online von dieser Störung unverzüglich in Kenntnis setzen. Gleiches gilt, wenn der Nutzer Informationen über von Dritten veröffentlichte Inhalte erlangt, die offensichtlich gegen geltendes Recht oder Rechte Dritter verstoßen.<br />
                                </Paragraph>
                                <H5Title>§8 Mitwirkungspflichten des Nutzers im Hinblick auf das Einstellen von Inhalten</H5Title>
                                <Paragraph>
                                    8.1. Der Nutzer verpflichtet sich, auf dem zur Verfügung gestellten Speicherplatz keine rechtswidrigen, die Gesetze, behördlichen Auflagen oder Rechte Dritter verletzenden Inhalte abzulegen.<br />
                                    8.2. Der Nutzer verpflichtet sich gegenüber reski.online, dass jedwede Inhalte, die in den Marktplatz eingestellt werden, weder durch ihren Inhalt oder die Form gegen geltendes Recht oder die guten Sitten verstoßen. Ferner ist es dem Nutzer untersagt, Inhalte einzustellen, die Rechte, insbesondere Marken-, Patent-, andere Schutzrechte oder Betriebsgeheimnisse Dritter verletzen. Das Gleiche gilt für das Setzen von externen Links.<br />
                                    8.3. Soweit die vom Nutzer überlassenen Inhalte gegen geltendes Recht, die guten Sitten, gesetzliche oder behördliche Verbote verstoßen, kann reski.online die entsprechenden Verarbeitungsaufträge ablehnen. Ein Verstoß liegt insbesondere bei der Überlassung von Inhalten vor, die folgende Punkte betreffen, darstellen oder beinhalten: Verfassungsfeindlichkeit; Rassismus, Fremdenfeindlichkeit und Diskriminierung; Jugendgefährdung und/oder Gewaltverherrlichung und Extremismus jeder Art; Aufrufe und Anstiftung zu Straftaten und Gesetzesverstößen, Drohungen gegen Leib, Leben oder Eigentum; Hetzen gegen Personen oder Unternehmen; persönlichkeitsverletzende Äußerungen, Verleumdung, Ehrverletzung und üble Nachrede von Nutzern und Dritten; urheberrechtsverletzende Inhalte oder andere Verletzungen von Immaterialgüterrechten sowie Verstöße gegen das Lauterkeitsrecht; sexuelle Belästigung von Nutzern und Dritten; anstößige, sexistische, obszöne, pornografische, vulgäre, abscheuliche oder ekelerregende Materialien und Ausdrucksweisen.<br />
                                    8.4. Urheberrechtlich geschützte Inhalte dürfen ohne Zustimmung des jeweiligen Rechteinhabers nur im Rahmen des anwendbaren Zitatrechts in Beiträge aufgenommen werden. Zitate sind durch Hervorheben mittels Zitatfunktion und Quellenangabe zu kennzeichnen und müssen gedanklich Bezug zum zitierten Werk aufnehmen. Fremdsprachige Zitate sind zusätzlich soweit auf Deutsch zu übersetzen, dass der Inhalt grob ersichtlich ist. Insbesondere nicht korrekt zitierte Beiträge können von den Moderatoren entfernt oder berichtigt werden. Die Verbreitung und/oder öffentliche Wiedergabe von jedweden Inhalten des Marktplatzes ohne Einwilligung von reski.online ist untersagt.<br />
                                    8.5. reski.online behält sich vor, fremde Inhalte zu sperren, wenn diese nach den geltenden Gesetzen strafbar sind oder erkennbar zur Vorbereitung strafbarer Handlungen dienen.<br />
                                    8.6. reski.online ist berechtigt die eingestellte Anzeige oder sonstige vom Nutzer eingestellte Inhalte zu löschen oder die Veröffentlichung von Anzeigen oder sonstigen Inhalten nicht vorzunehmen, sofern konkrete Anhaltspunkte vorliegen, dass Inhalte der Anzeige gegen diese AGB oder gegen gesetzliche Vorgaben verstoßen, oder dass der Nutzer sonst schuldhaft vertragliche Pflichten verletzt hat. reski ist berechtigt den Nutzer im Falle eines Verstoßes gegen die Ziffern 9. und 10. zu verwarnen und/oder zeitweise oder dauerhaft von der Nutzung des Marktplatzes auszuschließen und wird ggf. zivil- und strafrechtliche Maßnahmen einleiten. In keinem Fall stellen Inhalte des Nutzers die Meinung von reski.online dar und reski.online macht sich diese auch nicht zu Eigen.<br />
                                    8.7. Hat ein Nutzer gegen diese AGB verstoßen und wurde der Nutzungsvertrag mit dem Nutzer beendet, so ist dieser nicht mehr berechtigt, den Marktplatz zu nutzen oder sich erneut für diese zu registrieren. Darüber hinaus ist der Nutzer nicht berechtigt, den Marktplatz mit einem anderen Benutzerkonto zu nutzen.<br />
                                    8.8. Ist der Nutzer Verkäufer, gilt ergänzend: Der Verkäufer verpflichtet sich, in den Verkaufsanzeigen vollständige, wahrheitsgemäße und transparente Angaben zu der Beschaffenheit der Skitourenausrüstung zu machen. Der Verkäufer verpflichtet sich außerdem, nur solche Skitourenausrüstung über den Marktplatz von reski.online zum Verkauf anzubieten, deren Eigentümer er ist bzw. er vom jeweiligen Eigentümer zum Verkauf berechtigt wurde und die jeweilige Skitourenausrüstung nicht mit etwaigen Sicherungsrechten belastet ist. reski.online kann vom Verkäufer ggf. Informationen und Unterlagen verlangen, die erforderlich sind, um seine Eigentumsrechte oder sonstigen Berechtigungen zum Verkauf nachzuweisen.<br />
                                    8.9. Ist der Verkäufer Verbraucher bzw. Privatverkäufer, gilt ergänzend: Sofern der Verkäufer in seiner Verkaufsanzeige den „Ausschluss der Gewährleistung“ bestimmt, wird dies keine Auswirkungen auf die Mängelgewährleistungsrechte des Käufers haben, wenn die in der Verkaufsanzeige beschriebene Beschaffenheit der Skitourenausrüstung tatsächlich abweicht.<br />
                                    8.10. Ist der Verkäufer Unternehmer, gilt ergänzend: Der Verkäufer verpflichtet sich im Rahmen der Erstellung und Veröffentlichung von Verkaufsanzeigen, deutlich und vollständig auf die Geltung und Abrufbarkeit seiner ggf. vorhandenen Verträge bzw. Allgemeinen Geschäftsbedingungen, Widerrufsbelehrung und Datenschutzerklärung hinzuweisen sowie sein Impressum anzugeben. Die Veröffentlichung von Verkaufsanzeigen ist ohne Einhaltung der gesetzlichen Informationspflichten für Verkäufer und ohne Angabe der ggf. vorhandenen Verträge bzw. Allgemeinen Geschäftsbedingungen, Widerrufsbelehrung, Datenschutzerklärung und des Impressums nicht zulässig.<br />
                                </Paragraph>
                                <H5Title>§9 Support</H5Title>
                                <Paragraph>
                                    reski.online bietet den Nutzern von Montag bis Freitag (unter Ausnahme gesetzlicher Feiertage am Geschäftssitz von reski) im Zeitraum von 08:00 – 18:00 Uhr einen technischen Support an. reski.online wird Anfragen der Nutzer in Textform (per E-Mail) beantworten.<br />
                                </Paragraph>
                                <H5Title>§10 Nutzungsrechte</H5Title>
                                <Paragraph>
                                    10.1. Der Nutzer räumt reski.online ein räumlich und zeitlich unbeschränktes, unwiderrufliches auf Dritte übertragbares, nicht exklusives, unentgeltliches Recht ein, die eingestellten Inhalte in dem Online-Angebot zu verwerten. reski.online ist jederzeit berechtigt, die Inhalte zu verwenden, zu bearbeiten und zu verwerten. Das schließt insbesondere das Vervielfältigungsrecht, das Verbreitungsrecht und das Recht der öffentlichen Wiedergabe, insbesondere das Recht der öffentlichen Zugänglichmachung mit ein. Der Nutzer verzichtet diesbezüglich auf die Ausübung seines Namensnennungsrecht, ohne damit insgesamt auf sein Recht auf Anerkennung der Urheberschaft zu verzichten. Von dieser Regelung unberührt bleibt die Möglichkeit des Nutzers, Dritten Rechte an eingestellten Inhalten nach bestimmten Lizenzmodellen einzuräumen.<br />
                                    10.2. Sämtliche Rechte an den Inhalten des Marktplatzes liegen bei reski.online. Dem Nutzer ist die Vervielfältigung, Verbreitung und/oder Veröffentlichung von Inhalten untersagt, die reski, andere Nutzer oder Dritte in den Marktplatz eingestellt haben.<br />
                                    10.3. reski.online ist berechtigt, die vom Nutzer eingestellten Inhalte zu löschen oder zu deaktivieren, wenn diese gegen die Rechte Dritter verstoßen oder Dritte wegen einer Rechtsverletzung Ansprüche geltend machen, deren Begründetheit nicht offenkundig auszuschließen ist.<br />
                                    10.4. Erhält reski.online von einer möglichen Rechtsverletzung durch die Inhalte des Nutzers Kenntnis, wird er dem Nutzer hiervon unverzüglich in Textform Mitteilung machen.<br />
                                </Paragraph>
                                <H5Title>§11 Haftung und Freistellung</H5Title>
                                <Paragraph>
                                    11.1. Hinsichtlich der von reski.online erbrachten Leistungen haftet reski.online, seine gesetzlichen Vertreter und seine Erfüllungsgehilfen nur bei Vorsatz oder grober Fahrlässigkeit.<br />
                                    11.2. Bei der Verletzung vertragswesentlicher Pflichten besteht die Haftung auch bei einfacher Fahrlässigkeit, jedoch begrenzt auf den vorhersehbaren, vertragstypischen Schaden.<br />
                                    11.3. Wesentliche Vertragspflichten sind solche Pflichten, die der Vertrag von reski.online nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf (sog. Kardinalpflichten). Ansprüche für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie nach dem Produkthaftungsgesetz bleiben von vorstehenden Beschränkungen unberührt.<br />
                                    11.4. Im Übrigen ist eine Haftung von reski.online ausgeschlossen.<br />
                                    11.5. Der Nutzer stellt reski.online und seine Mitarbeiter bzw. Beauftragten für den Fall der Inanspruchnahme wegen vermeintlicher oder tatsächlicher Rechtsverletzung und/oder Verletzung von Rechten Dritter durch von dem Nutzer im Zusammenhang mit der Nutzung vorgenommenen Handlungen von sämtlichen sich daraus ergebenen Ansprüchen Dritter frei, sofern die Ansprüche und Haftung nicht von reski.online zumindest überwiegend mit zu vertreten sind. Überwiegend zu vertreten hat reski Schäden, die kausal aufgrund seiner Weisungsrechte nach diesem Vertrag zu Stande gekommen sind. Im Übrigen gilt §254 BGB. Der Nutzer wird reski.online unverzüglich informieren, wenn Dritte reski.online gegenüber unter die vorstehende Freistellungsverpflichtung fallende Ansprüche erheben. Der Nutzer ist verpflichtet, reski unverzüglich alle ihm verfügbaren Informationen über den betreffenden Sachverhalt vollständig, wahrheitsgemäß und unverzüglich in Textform mitzuteilen. Eventuelle darüber hinausgehende Ansprüche von reski.online bleiben unberührt.<br />
                                    11.6. Darüber hinaus verpflichtet sich der Nutzer, alle Kosten zu ersetzen, die reski durch die Inanspruchnahme durch Dritte entstehen. Zu den erstattungsfähigen Kosten zählen auch die Kosten einer angemessenen Rechtsverteidigung.<br />
                                </Paragraph>
                                <H5Title>§12 Haftung für Mängel und Garantien</H5Title>
                                <Paragraph>
                                    Präambel: Der Marktplatz stellt überwiegend gebrauchte Skitourenausrüstung zum Verkauf zur Verfügung. Diese weißt im Regelfall mehr oder minder deutliche Gebrauchsspuren auf, die durch den Verkäufer entsprechend beschrieben werden müssen.<br />
                                    12.1. Der Verkäufer haftet für Sach- oder Rechtsmängel der gelieferten Skitourenausrüstung nach den geltenden gesetzlichen Vorschriften, insbesondere §§ 434ff. BGB.<br />
                                    12.2. Die Verjährungsfrist für gesetzliche Mängelansprüche beim Kauf gebrauchter Waren beträgt ein (1) Jahr mit der Ablieferung der Ware.<br />
                                    12.3. Grundlage der Mängelhaftung des Verkäufers ist vor allem die über die Beschaffenheit der Skitourenausrüstung getroffene Vereinbarung. Als Vereinbarung über die Beschaffenheit der Skitourenausrüstung gelten alle Produktbeschreibungen und Herstellerangaben, die Gegenstand des einzelnen Vertrages sind oder vom Verkäufer auf dem Marktplatz von reski.online zum Zeitpunkt des Vertragsschlusses öffentlich bekannt gemacht waren.<br />
                                </Paragraph>
                                <H5Title>§13 Käuferschutz</H5Title>
                                <Paragraph>
                                    13.1. reski.online unterstützt den Käufer im Rahmen des sog. Käuferschutzes bei der Durchsetzung etwaiger Gewährleistungsansprüche gegenüber dem Verkäufer, insbesondere im Falle der Nicht- oder Schlechtleistung bzw. -lieferung, der wesentlichen Abweichung der Beschaffenheit der Skitourenausrüstung von der Produktbeschreibung des Verkäufers oder im Falle des Rücktritts, sofern der Käufer dies reski innerhalb von 48 Stunden seit Nicht- oder Schlechtleistung bzw. -lieferung mitteilt oder der Käufer ein ihm zustehendes Rücktrittsrecht geltend macht. Eine nicht passende Ski- oder Bindungslänge ist kein Grund für eine Rückabwicklung, sofern sie vom Verkäufer richtig angegeben worden ist.<br />
                                    13.2. Gegenstand des Käuferschutzes ist insoweit eine Prüfung von reski.online dahingehend, ob tatsächlich ein Mangel des Vertragsgegenstandes vorliegt. Hierzu kann der Käufer zunächst auf seine Kosten den Vertragsgegenstand an reski.online senden, der dort auf den behaupteten Mangel hin überprüft wird.<br />
                                    13.3. Stellt sich ein Mangel heraus, wird reski.online den Verkäufer kontaktieren und klären, ob ein Rücktritt akzeptiert oder eine – mögliche – Reparatur auf Kosten des Verkäufers durch reski.online vorgenommen werden soll. Entscheidet sich der Verkäufer für eine Rückabwicklung, erhält er den Vertragsgegenstand gegen Zahlung einer Versandpauschale zurück. reski.online wird den von dem Verkäufer geschuldeten Betrag mit etwaigen zukünftigen Auszahlungen verrechnen. reski.online wird im Namen des Verkäufers die Rückerstattung des gesamten oder geminderten Kaufpreises an den Käufer leisten. Sofern zwischen den Parteien nichts anderes vereinbart wird, erstattet reski.online dem Käufer den vom Verkäufer geschuldeten Betrag mit dem gleichen Zahlungsmittel, welches der Käufer bei der Zahlung des vereinbarten Kaufpreises verwendet hat, zurück. <br />
                                    13.4. Alternativ zur Rückabwicklung kann der Verkäufer auch eine Reparatur auf seine Kosten durch reski.online wählen, sofern hierdurch die ursprünglich vereinbarte Beschaffenheit des Vertragsgegenstandes erreicht werden kann.<br />
                                    13.5. Sollte sich nach Prüfung durch reski.online herausstellen, dass kein Mangel vorliegt, teilt reski.online dies dem Käufer mit und sendet ihm den Vertragsgegenstand gegen Zahlung einer Versandpauschale zurück.<br />
                                    13.6. Nach Ablauf von 48 Stunden nach dem tatsächlichen Liefertermin greift der Käuferschutz nicht mehr.<br />
                                </Paragraph>
                                <H5Title>§14 Personenbezogene Daten, Datenschutz und Urheberrechte</H5Title>
                                <Paragraph>
                                    14.1. Der Nutzer willigt hiermit in die Speicherung der von ihm eingegebenen personenbezogenen Daten ein. Dies gilt auch für die Speicherung der IP-Adressen, die bei jeder Nutzung des Marktplatzes übermittelt werden. Der Nutzer willigt insbesondere auch in die Darstellung der von ihm eingegebenen personenbezogenen Daten in seiner Profildarstellung innerhalb des Marktplatzes für andere Nutzer des Marktplatzes und Dritte ein, die nicht Nutzer des Marktplatzes sind. Nähere Einzelheiten ergeben sich aus der Datenschutzerklärung von reski.online.<br />
                                    14.2. Die Nutzung des Marktplatzes macht die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch reski.online unumgänglich. reski.online versichert, alle gespeicherten Daten sorgsam zu behandeln und ausschließlich im Rahmen der datenschutzrechtlichen Einwilligungen des Nutzers zu verarbeiten. Eine darüberhinausgehende Nutzung personenbezogener Daten erfolgt durch reski.online nur, sofern dies gesetzlich zulässig ist oder der Nutzer vorab eingewilligt hat.<br />
                                    14.3. Der Nutzer erklärt sich ferner damit einverstanden, dass reski.online personenbezogene Daten des Nutzers für Direktmarketingzwecke benutzt. Dazu zählt die werbliche Ansprache des Nutzers per E-Mail.<br />
                                    14.4. Im Übrigen gelten die gesetzlichen Datenschutzbestimmungen, insbesondere der Datenschutz-Grundverordnung (DS-GVO), des Bundesdatenschutzgesetzes neue Fassung (BDSG-neu) und des Telemediengesetzes (TMG).<br />
                                </Paragraph>
                                <H5Title>§15 Höhere Gewalt</H5Title>
                                <Paragraph>
                                    15.1. reski.online ist von der Verpflichtung zur Leistung aus diesem Vertrag befreit, wenn und soweit die Nichterfüllung von Leistungen auf das Eintreten von Umständen höherer Gewalt nach Vertragsabschluss zurückzuführen ist.<br />
                                    15.2. Als Umstände höherer Gewalt gelten zum Beispiel Krieg, Streik, Unruhen, Enteignungen, Verfügungen von höherer Hand, Pandemien, kardinale Rechtsänderungen, Sturm, Überschwemmungen und sonstige Naturkatastrophen sowie sonstige von reski nicht zu vertretende Umstände, insbesondere Wassereinbrüche, Stromausfälle und Unterbrechungen oder Zerstörung datenführender Leitungen.<br />
                                    15.3. reski wird den Nutzer über den Eintritt eines Falles von höherer Gewalt unverzüglich in geeigneter Form in Kenntnis setzen.<br />
                                </Paragraph>
                                <H5Title>§16 Vertragslaufzeit und Kündigung</H5Title>
                                <Paragraph>
                                    16.1. Der Nutzungsvertrag zwischen Euter des Marktplatzes und reski.online wird für eine unbestimmte Zeit geschlossen und kann von beiden Parteien mit einer Frist von zwei (2) Wochen ohne Angabe von Gründen gekündigt werden. <br />
                                    16.2. Die Kündigung bedarf der Textform (per E-Mail) oder kann konkludent durch Deaktivierung des Benutzerkontos durch den Nutzer erklärt werden. Im Falle der Kündigung wird das Profil des Nutzers gelöscht. Der Nutzer ist vor Vertragsbeendigung zur selbständigen Datensicherung und -export (insbesondere Kundendaten, Rechnungen, etc.) verpflichtet.<br />
                                    16.3. Darüber hinaus wird reski.online von seinem Recht zur ordentlichen Kündigung und zur Löschung des Benutzerkontos Gebrauch machen, wenn der Nutzer sich mindestens ein (1) Jahr lang nicht mehr eingeloggt und auf eine Erinnerungsnachricht nicht reagiert hat.<br />
                                    16.4. Unberührt bleibt das Recht jeder Vertragspartei, den Vertrag aus wichtigem Grunde fristlos zu kündigen. Ein wichtiger Grund liegt vor, wenn dem kündigenden Teil unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung der beiderseitigen Interessen die Fortsetzung des Vertragsverhältnisses bis zur vereinbarten Beendigung oder bis zum Ablauf einer Kündigungsfrist nicht zugemutet werden kann.reski ist neben der fristgebundenen Kündigung berechtigt, den Nutzungsvertrag ohne Einhaltung einer Frist zu kündigen, wenn ein wichtiger Grund vorliegt. Ein wichtiger Grund liegt insbesondere dann vor, wenn der Nutzer nachhaltig und schwerwiegend gegen seine vertraglichen Pflichten verstößt (Ziffer 9. und Ziffer 10.) oder bei der Nutzung des Marktplatzes schuldhaft und schwerwiegend gegen gesetzliche Vorschriften verstößt.<br />
                                </Paragraph>
                                <H5Title>§17 Alternative Streitbeilegung</H5Title>
                                <Paragraph>
                                    17.1. Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online Streitbeilegung bereit: https://ec.europa.eu/consumers/odr. Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.<br />
                                    17.2. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle / Universalschlichtungsstelle ist reski.online nicht verpflichtet und nicht bereit.<br />
                                </Paragraph>
                                <H5Title>§18 Änderung der AGB</H5Title>
                                <Paragraph>
                                    18.1. reski.online behält sich vor, diese AGB jederzeit ohne Angabe von Gründen zu ändern, es sei denn, dies ist für den Nutzer nicht zumutbar. reski.online wird den Nutzer über Änderungen der AGB rechtzeitig in Textform benachrichtigen. Widerspricht der Nutzer der Geltung der neuen AGB nicht innerhalb einer Frist von vier (4) Wochen nach der Benachrichtigung, gelten die geänderten AGB als vom Nutzer angenommen. reski.online wird den Nutzer in der Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen. Widerspricht der Nutzer den Änderungen innerhalb der vorgenannten Frist, so besteht das Vertragsverhältnis zu den ursprünglichen Bedingungen fort.<br />
                                    18.2. reski.online behält sich darüber hinaus vor, diese AGB zu ändern, soweit er hierzu aufgrund einer Änderung der Rechtslage verpflichtet ist oder soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer Behördenentscheidung nachkommt oder soweit er zusätzliche, gänzlich neue Dienstleistungen, Dienste oder Dienstelemente einführt, die einer Leistungsbeschreibung in den AGB bedürfen, es sei denn, dass bisherige Nutzungsverhältnis wird dadurch nachteilig verändert oder wenn die Änderung lediglich vorteilhaft für den Nutzer ist oder wenn die Änderung rein technisch oder prozessual bedingt ist, es sei denn, sie hat wesentliche Auswirkungen für den Nutzer.<br />
                                    18.3. Das Kündigungsrecht der Parteien bleibt hiervon unberührt.<br />
                                </Paragraph>
                                <H5Title>§19 Schlussbestimmungen</H5Title>
                                <Paragraph>
                                    19.1. Für diese AGB und die Vertragsbeziehung zwischen den Parteien gilt das Recht der Bundesrepublik Deutschland unter Ausschluss internationalen Einheitsrechts, insbesondere des UN-Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.<br />
                                    19.2. Ist der Nutzer Verbraucher und hat dieser keinen allgemeinen Gerichtsstand in Deutschland oder in einem anderen EU-Mitgliedsstaat, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertrag der Geschäftssitz von reski.online in Kochel mit dem Gerichtssitz in Weilheim.<br />
                                    19.3. Ist der Nutzer Kaufmann i.S.d. Handelsgesetzbuchs, Unternehmer i.S.v. § 14 BGB, juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, ist ausschließlicher – auch internationaler Gerichtsstand für alle sich aus dem Vertragsverhältnis unmittelbar oder mittelbar ergebenden Streitigkeiten der Geschäftssitz von reski.online in Kochel am See. reski.online ist in allen Fällen auch berechtigt, Klage am Erfüllungsort der Leistungsverpflichtung gemäß dieser AGB bzw. einer vorrangigen Individualabrede oder am allgemeinen Gerichtsstand des Nutzers zu erheben. Vorrangige gesetzliche Vorschriften, insbesondere zu ausschließlichen Zuständigkeiten, bleiben unberührt.<br />
                                </Paragraph>
                            </ContainerText>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_TaC));