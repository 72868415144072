import React, { Component } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnRed } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth, getListWidth } from '../util/index.js';

import { format } from 'date-fns'
import Grid from '@mui/material/Unstable_Grid2';
import LoadingIcons from 'react-loading-icons'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import altimage from '../res/images/reski_altimage_sq_v1.png'
import icon_cal from '../res/icons/icon_cal_d_v1.png'
import icon_view from '../res/icons/icon_view_d_v1.png'
import icon_star from '../res/icons/icon_star_d_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'

const style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const ContainerText = styled.div.attrs({
})`
    width: 90%;
    display: flex;
    flex-flow: column;
    margin: 10px 15px;
`

const ContainerItem = styled.div.attrs({

})`
border-radius: 5px;
background-color: #FFFFFF;
`

const ContainerItemImage = styled.div.attrs({

})`
position: relative;
margin: 15px;
`

const WrapperPrice = styled.div.attrs({
})`
margin: 5px 0px;
    background-color: #FFFFFF;
    white-space: nowrap;
`

const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`
const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemPhoto = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const WrapperLineItemDetails = styled.div.attrs({
})`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemStatus = styled.div.attrs({
})`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  overflow: hidden; 
`

const WrapperLineItemButtons = styled.div.attrs({
})`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`

const WrapperGridList = styled.div.attrs({
})`
width: 100%;
`

const WrapperPanel = styled.div.attrs({
})`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const WrapperFlexTop = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperFlexMid = styled.div.attrs({
})`
flex: 1 1 auto;
`
const WrapperFlexBottom = styled.div.attrs({
})`
flex: 0 1 auto;
`

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`

const WrapperButtonLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const ContainerFields = styled.div.attrs({
})`
`

const BtnView = styled(BtnBlue)`
    width: 100%;
    margin: 5px;
`

const BtnDelete = styled(BtnWhite)`
    width: 100%;
    margin: 5px;
`

const BtnBack = styled(BtnGrey)`
`

const BtnBackOnError = styled(BtnGrey)`
`

const BtnDelete2 = styled(BtnRed)`
margin: 5px 10px;
justify-content: right;
`

const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const Icon = styled.img.attrs({
    hight: '15',
    width: '15'
})`
margin-right: 5px;
`


class User_Marks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            modalDeleteId: '',
            modalDeleteOpen: false,
            modalDeleteState: Constants.STATE_INITIAL,
            isMobile
        }

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;


        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const uuid = Userfront.user.userUuid;

        await api.getMarkedArticlesByUser(uuid).then(res => {
            this.setState({
                articles: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
        })

    }


    handleBtnBackOnError = async () => {

        this.setState({
            modalDeleteState: Constants.STATE_INITIAL
        })
    }

    handleBtnDelete = async () => {

        const anchor = this;

        this.setState({
            modalDeleteState: Constants.STATE_PENDING
        })

        const id = this.state.modalDeleteId
        const payload = { id }

        await api.demarkArticleById(payload).then(res => {
            this.handleModalDeleteClose();

            const uuid = Userfront.user.userUuid;

            api.getMarkedArticlesByUser(uuid).then(res => {
                this.setState({
                    articles: res.data.data
                })

            }).catch(error => {
                //Todo error handeling
            })

        }).catch(error => {
            anchor.setState({
                modalDeleteState: Constants.STATE_ERROR
            })
        })

    }

    handleModalDeleteOpen = id => async event => {

        event.preventDefault()

        const modalDeleteOpen = true;
        const modalDeleteId = id;
        this.setState({ modalDeleteOpen, modalDeleteId })
    }

    handleModalDeleteClose = async event => {

        const modalDeleteOpen = false
        this.setState({ modalDeleteOpen })
        await this.delay(250);
        this.setState({
            modalDeleteState: Constants.STATE_INITIAL
        })
    }

    delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    render() {
        const { articles, modalDeleteOpen, modalDeleteState, isMobile } = this.state
        const { t } = this.props
        return (
            !Userfront.accessToken() ?
                <Navigate
                    to={{
                        pathname: "/auth/login",
                    }}
                />
                :
                <Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalDeleteOpen}
                        onClose={this.handleModalDeleteClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalDeleteOpen}>
<Box sx={isMobile === true ? m_style_delete : style_delete}>
                                {modalDeleteState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.marks.tx_mod_delete_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.marks.tx_mod_delete_text")}</Paragraph>
                                        <WrapperButtonLine>
                                            <BtnDelete2 onClick={this.handleBtnDelete}> {t('ui.marks.bt_delete')}</BtnDelete2>
                                            <BtnCancel onClick={this.handleModalDeleteClose}> {t('ui.marks.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.marks.tx_mod_delete_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.marks.tx_mod_delete_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.marks.tx_mod_delete_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleBtnBackOnError}> {t('ui.marks.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <ContainerMain>
                        <WrapperMain>
                            <WrapperCenter>
                                <Title>{t('ui.marks.tx_title')}</Title>
                                <Paragraph></Paragraph>
                            </WrapperCenter>
                        </WrapperMain>
                        <WrapperCenter>
                            <ContainerFields style={{ "width": getListWidth(isMobile) }} >
                                {articles.length > 0 ?
                                    <Container>
                                        <WrapperGrid>
                                            <Grid container spacing={{ xs: 0, md: 0 }} >
                                                {articles.map((obj, index) => {
                                                    return (
                                                        <Grid xs={12} key={index}
                                                            sx={{
                                                                boxShadow: 3,
                                                                width: '100%',
                                                                height: '100%',
                                                                p: 0,
                                                                m: 2,
                                                                borderRadius: 0,
                                                            }}>

                                                            <ContainerItem key={articles[index]._id}>
                                                                <StyledLink to={"/advert/view/" + articles[index]._id} >

                                                                    <WrapperGridList>
                                                                        <Grid container
                                                                            width={1}
                                                                            height={1}
                                                                            direction="row"
                                                                            justifyContent="space-evenly"
                                                                            alignItems="center"
                                                                            spacing={{ xs: 0, sm: 0.5, lg: 1 }} >
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-mark-photo'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerItemImage>
                                                                                        {articles[index].photo_elements[0] ?
                                                                                            <img src={articles[index].titlephoto_url} loading="lazy" alt={'...'} width="100%" />
                                                                                            :
                                                                                            <img src={altimage} alt={'...'} width="100%" />
                                                                                        }
                                                                                    </ContainerItemImage>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-mark-details'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{articles[index].title}</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>
                                                                                            <Container>
                                                                                                <Icon src={icon_cal} />{format(new Date(articles[index].dateCreated), 'dd.MM.yyyy')}
                                                                                            </Container>
                                                                                            <Container>
                                                                                                <Icon src={icon_view} />{articles[index].views}
                                                                                            </Container>

                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>
                                                                                            <WrapperPrice>
                                                                                                <WrapperLine>
                                                                                                    <WrapperLineItem>
                                                                                                        <SubsubTitle><font color="#229954">{articles[index].price} €</font></SubsubTitle>
                                                                                                    </WrapperLineItem>
                                                                                                    <WrapperLineItem>
                                                                                                        {articles[index].price_original != '-1' &&
                                                                                                            <H5Title><font color="#DB0000"><s><font color="#000000">{articles[index].price_original} €</font></s></font></H5Title>
                                                                                                        }
                                                                                                    </WrapperLineItem>
                                                                                                </WrapperLine>
                                                                                            </WrapperPrice>
                                                                                        </WrapperFlexBottom>

                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-mark-status'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <ContainerText>
                                                                                        <WrapperFlexTop>
                                                                                            <Bold>{t('ui.marks.tx_list_status')}:</Bold>
                                                                                        </WrapperFlexTop>
                                                                                        <WrapperFlexMid>

                                                                                            <WrapperPrice>
                                                                                                {articles[index].status_display === Constants.STATUS_AVAILABLE &&
                                                                                                    <SubsubTitle><font color={Style.COLOUR_GREEN}>{t("display_" + articles[index].status_display)}</font></SubsubTitle>
                                                                                                }
                                                                                                {articles[index].status_display === Constants.STATUS_RESERVED &&
                                                                                                    <SubsubTitle><font color={Style.COLOUR_GREY}>{t("display_" + articles[index].status_display)}</font></SubsubTitle>
                                                                                                }
                                                                                                {articles[index].status_display === Constants.STATUS_UNAVAILABLE &&
                                                                                                    <SubsubTitle><font color={Style.COLOUR_RED}>{t("display_" + articles[index].status_display)}</font></SubsubTitle>
                                                                                                }
                                                                                                {articles[index].status_display === Constants.STATUS_IN_TIMEOUT &&
                                                                                                    <SubsubTitle><font color={Style.COLOUR_BLUE}>{t("display_" + articles[index].status_display)}</font></SubsubTitle>
                                                                                                }
                                                                                            </WrapperPrice>

                                                                                        </WrapperFlexMid>
                                                                                        <WrapperFlexBottom>

                                                                                        </WrapperFlexBottom>

                                                                                    </ContainerText>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                            <Grid xs={10} sm={5} lg={2} key={'g-mark-actions'}
                                                                                sx={{
                                                                                    boxShadow: 0,
                                                                                    width: '100%',
                                                                                    p: 0,
                                                                                    m: 0,
                                                                                    borderRadius: 0,
                                                                                }}>
                                                                                <WrapperPanel>
                                                                                    <WrapperCenter>
                                                                                        <ContainerText>
                                                                                            <WrapperFlexTop>
                                                                                                <StyledLink to={"/advert/view/" + articles[index]._id} >
                                                                                                    <BtnView>{t('ui.marks.bt_list_view')}</BtnView>
                                                                                                </StyledLink>
                                                                                            </WrapperFlexTop>

                                                                                            <WrapperFlexBottom>
                                                                                                <BtnDelete onClick={this.handleModalDeleteOpen(articles[index]._id)}>{t('ui.marks.bt_list_delete')}</BtnDelete>
                                                                                            </WrapperFlexBottom>
                                                                                        </ContainerText>
                                                                                    </WrapperCenter>
                                                                                </WrapperPanel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </WrapperGridList>
                                                                </StyledLink>
                                                            </ContainerItem>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </WrapperGrid>
                                    </Container>
                                    :
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph></Paragraph>
                                            <Paragraph>{t('ui.marks.tx_noarticles')}</Paragraph>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </ContainerFields>
                        </WrapperCenter>
                    </ContainerMain>
                </Container>
        )
    }
}

export default withTranslation()(withParams(User_Marks));