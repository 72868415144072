import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnHoloBlue, BtnRed } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'


import * as Constants from '../constants/index.js'
import api from '../api'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';

import { format } from 'date-fns'
import LoadingIcons from 'react-loading-icons'
import Grid from '@mui/material/Unstable_Grid2';
import Rating from '@mui/material/Rating';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_user from '../res/icons/icon_user_g_v1.png'
import icon_rec from '../res/icons/icon_rec_g_v1.png'
import icon_warn from '../res/icons/icon_warn_g_v1.png'
import icon_admin from '../res/icons/icon_admin_g_v1.png'

const style_report = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_report = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_login = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_answer = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_answer = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_delete = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

const style_review = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_review = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const WrapperGrid = styled.div.attrs({
})`
margin: 15px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const ContainerItem = styled.div.attrs({

})`
position: relative;
border-radius: 5px;
background-color: #FFFFFF;
`

const WrapperFlexTop = styled.div.attrs({
})`
flex: 0 1 auto;
`
const WrapperFlexMid = styled.div.attrs({
})`
flex: 1 1 auto;
margin: 0px 5px 0px 0px;
`
const WrapperFlexBottom = styled.div.attrs({
})`
flex: 0 1 auto;
margin: 0px 5px 0px 0px;
`
const ContainerText = styled.div.attrs({
})`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 10px 15px;
`

const WrapperSegment = styled.div.attrs({
})`
    width: 100%;
    padding-left: 5px;
`

const WrapperLine = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`
const WrapperLineItem = styled.div.attrs({
})`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const Splitter = styled.hr.attrs({
    color: '#FFFFFF',
    width: 'auto'
})`
margin: 10px 5px;
`

const WrapperComment = styled.div.attrs({
})`
  padding: 0px 15px ;
`

const WrapperReviewText = styled.div.attrs({
})` 
margin: 5px 10px;
white-space: pre-wrap;
`

const WrapperSplit = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
`
const WrapperSplit2Left = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 50%;
`
const WrapperSplit2Right = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 50%;
`
const WrapperSplit2Left95 = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 95%;
`
const WrapperSplit2Right5 = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  width: 5%;
`
const ContainerReportBtn = styled.div.attrs({

})`
padding: 0px 7px;
margin: 0px 7px;
white-space: nowrap;
position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
`
const WrapperButtonLine = styled.div.attrs({
})`
margin-bottom: 5px;
display: flex;
flex-direction: row;
justify-content: right;
align-items: center;
white-space: nowrap;
width: 100%;
`

const WrapperRadiogroup = styled.div.attrs({
})`
    margin: 5px;
`

const ContainerRating = styled.div.attrs({
})`
margin: 5px;
`

const BtnLoadmore = styled(BtnWhite)`
`

const BtnSendReview = styled(BtnBlue)`
    justify-content: right;
`
const BtnCancel = styled(BtnGrey)`
    justify-content: right;
    margin-left: 5px;
`

const BtnBack = styled(BtnGrey)`
`

const BtnBackOnError = styled(BtnGrey)`
`

const IconUser = styled(Icon)`
margin-right: 5px;
margin-bottom: 2px;
`
const IconReport = styled(Icon)`
width: 20px;
height: 20px;
margin: 5px;
&:hover {
    cursor: pointer;
  }
`

const IconRecommend = styled(Icon)`
width: 35px;
height: 35px;
`

const BtnToLogin = styled(BtnBlue)`
    width: 50%;
`
const BtnReview = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const BtnEdit = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const BtnDelete = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const BtnDelete2 = styled(BtnRed)`
margin: 5px 10px;
justify-content: right;
`

const BtnSendReport = styled(BtnBlue)`
    justify-content: right;
`

const BtnAnswer = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

class Website_Reviews extends Component {
    constructor(props) {
        super(props)

        var limit = 20
        if (typeof (this.props.params.limit) !== "undefined") {
            limit = Number(this.props.params.limit)
        }

        this.state = {
            modalReportOpen: false,
            modalLoginOpen: false,
            modalReviewOpen: false,
            modalDeleteOpen: false,
            modalAnswerOpen: false,
            modalReportState: Constants.STATE_INITIAL,
            modalReviewState: Constants.STATE_INITIAL,
            modalDeleteState: Constants.STATE_INITIAL,
            modalAnswerState: Constants.STATE_INITIAL,
            isMobile,

            reviews: [],
            skip: 0,
            limit: limit,
            uuid: '',
            loading: false,
            end: false,
            rating_avg: 0,
            rating_count: 0,
            rating_total: 0,

            review_id: '',
            review_new: true,

            modalReviewTitle: '',
            modalReviewTitleError: false,
            modalReviewRating: 0,
            modalReviewReviewer_name: '',
            modalReviewReviewer_nameError: false,
            modalReviewPro: '',
            modalReviewCon: '',
            modalReviewRecommendation: true,

            modalAnswerText: '',

            report_reason: Constants.REPORT_TYPE_1,
            report_message: '',
            valueReportMessageError: false,
            valueReportMessageRequired: false,
            report_char_left: 500,
            reported_reviews: []
        }

    }

    doSendReview = async () => {
        const anchor = this;

        const { modalReviewTitle, modalReviewRating, modalReviewReviewer_name, modalReviewPro, modalReviewCon, modalReviewRecommendation } = this.state;

        var required = true;
        if (modalReviewTitle === "") {
            this.setState({
                modalReviewTitleError: true
            })
            required = false;
        }
        if (modalReviewReviewer_name === "") {
            this.setState({
                modalReviewReviewer_nameError: true
            })
            required = false;
        }
        if (modalReviewRating === 0) {
            document.getElementById("ip-review-rating").style.border = '1px solid red';
            required = false;
        }
        if (required === false) {
            return;
        }

        var payload = {
            title: modalReviewTitle,
            rating: modalReviewRating,
            reviewer_name: modalReviewReviewer_name,
            pro: modalReviewPro,
            con: modalReviewCon,
            recommendation: modalReviewRecommendation
        }

        this.setState({
            modalReviewState: Constants.STATE_PENDING
        })

        await api.insertReview(payload).then(res => {
            anchor.setState({
                modalReviewState: Constants.STATE_SUCCESS
            })

            anchor.refreshReviews();
        }).catch(error => {
            anchor.setState({
                modalReviewState: Constants.STATE_ERROR

            })
        })
    }

    doUpdateReview = async () => {
        const anchor = this;

        const { modalReviewTitle, modalReviewRating, modalReviewReviewer_name, modalReviewPro, modalReviewCon, modalReviewRecommendation } = this.state;

        var required = true;
        if (modalReviewTitle === "") {
            this.setState({
                modalReviewTitleError: true
            })
            required = false;
        }
        if (modalReviewReviewer_name === "") {
            this.setState({
                modalReviewReviewer_nameError: true
            })
            required = false;
        }
        if (modalReviewRating === 0) {
            document.getElementById("ip-review-rating").style.border = '1px solid red';
            required = false;
        }
        if (required === false) {
            return;
        }

        var payload = {
            title: modalReviewTitle,
            rating: modalReviewRating,
            reviewer_name: modalReviewReviewer_name,
            pro: modalReviewPro,
            con: modalReviewCon,
            recommendation: modalReviewRecommendation
        }

        this.setState({
            modalReviewState: Constants.STATE_PENDING
        })

        const id = this.state.review_id;
        await api.updateReviewById(id, payload).then(res => {
            anchor.setState({
                modalReviewState: Constants.STATE_SUCCESS
            })

            anchor.refreshReviews();
        }).catch(error => {
            anchor.setState({
                modalReviewState: Constants.STATE_ERROR

            })
        })
    }

    doDeleteReview = async () => {

        const anchor = this;
        const id = this.state.review_id;
        this.setState({
            modalDeleteState: Constants.STATE_PENDING
        })

        await api.deleteReviewById(id).then(res => {
            anchor.setState({
                modalDeleteOpen: false,
                modalDeleteState: Constants.STATE_INITIAL
            })

            anchor.refreshReviews();
        }).catch(error => {
            anchor.setState({
                modalDeleteState: Constants.STATE_ERROR

            })
        })

    }

    doUpdateAnswer = async () => {
        const anchor = this;
        const id = this.state.review_id;
        const admin_comment = this.state.modalAnswerText;

        this.setState({
            modalAnswerState: Constants.STATE_PENDING
        })
        const payload = {
            id, admin_comment
        }
        await api.updateReviewAnswer(payload).then(res => {
            anchor.setState({
                modalAnswerState: Constants.STATE_SUCCESS
            })

            anchor.refreshReviews();
        }).catch(error => {
            anchor.setState({
                modalAnswerState: Constants.STATE_ERROR

            })
        })
    }


    doSendReport = async event => {
        const anchor = this;
        const uuid = Userfront.user.userUuid;
        var { report_reason, report_message } = this.state;
        const link = window.location.href;
        const report_reference = Constants.REPORT_REVIEW;

        const id = this.state.review_id;

        if (report_reason === Constants.REPORT_TYPE_4) {
            if (report_message === '') {
                this.setState({ valueReportMessageError: true })
                return;
            }
        }

        if (report_message === '') {
            report_message = ' - '
        }

        this.setState({
            modalReportState: Constants.STATE_PENDING
        })

        const payload = { uuid, id, report_reason, report_message, link, report_reference }

        await api.sendReport(payload).then(res => {
            this.state.reported_reviews.push(id);
            anchor.setState({
                modalReportState: Constants.STATE_SUCCESS
            })
        }).catch(error => {
            anchor.setState({
                modalReportState: Constants.STATE_ERROR
            })
        })
    }


    handleModalReportOpen = (id) => async event => {

        var found = this.state.reported_reviews.find(e => e === id);
        var state;
        if (typeof (found) != 'undefined') {
            state = Constants.STATE_SUCCESS;
        } else {
            state = Constants.STATE_INITIAL;
        }

        const modalReportOpen = true;
        this.setState({
            modalReportOpen,
            review_id: id,
            modalReportState: state
        })
    }

    handleModalReportClose = async event => {
        const modalReportOpen = false
        this.setState({ modalReportOpen })

    }

    handleModalReviewOpen = (review, review_new) => async event => {

        event.preventDefault();

        const modalReviewOpen = true;
        this.setState({ modalReviewOpen })

        if (review_new != true) {
            this.setState({
                review_id: review._id,
                review_new: review_new,
                modalReviewTitle: review.title,
                modalReviewRating: review.rating,
                modalReviewReviewer_name: review.eviewer_name,
                modalReviewPro: review.pro,
                modalReviewCon: review.con,
                modalReviewRecommendation: review.recommendation
            })
        } else {
            this.setState({
                review_new: review_new,
                modalReviewTitle: '',
                modalReviewRating: 0,
                modalReviewReviewer_name: '',
                modalReviewPro: '',
                modalReviewCon: '',
                modalReviewRecommendation: true
            })
        }
    }
    handleModalReviewClose = async event => {
        const modalReviewOpen = false
        this.setState({ modalReviewOpen })

    }
    handleModalReviewBtnBack = async () => {
        this.setState({
            modalReviewOpen: false
        })
        await this.delay(250);
        this.setState({
            modalReviewState: Constants.STATE_INITIAL
        })
    }
    handleModalReviewBtnBackOnError = async () => {
        this.setState({
            modalReviewState: Constants.STATE_INITIAL
        })
    }

    handleModalDeleteOpen = id => async event => {

        event.preventDefault();

        const modalDeleteOpen = true;
        this.setState({ modalDeleteOpen })
        this.setState({ review_id: id })
    }
    handleModalDeleteClose = async event => {
        const modalDeleteOpen = false
        this.setState({ modalDeleteOpen })

    }
    handleModalDeleteBtnBack = async () => {
        this.setState({
            modalDeleteOpen: false
        })
    }
    handleModalDeleteBtnBackOnError = async () => {
        this.setState({
            modalDeleteState: Constants.STATE_INITIAL
        })
    }

    handleModalLoginOpen = async event => {
        const modalLoginOpen = true;
        this.setState({ modalLoginOpen })
    }
    handleModalLoginClose = async event => {
        const modalLoginOpen = false
        this.setState({ modalLoginOpen })

    }


    handleModalAnswerOpen = review => async event => {

        event.preventDefault();

        const modalAnswerOpen = true;
        this.setState({ modalAnswerOpen })
        this.setState({
            modalAnswerState: Constants.STATE_INITIAL,
            review_id: review._id,
            modalAnswerText: review.admin_comment
        })

    }
    handleModalAnswerClose = async event => {
        const modalAnswerOpen = false
        this.setState({ modalAnswerOpen })

    }
    handleModalAnswerBtnBack = async () => {
        this.setState({
            modalAnswerOpen: false
        })
    }
    handleModalAnswerBtnBackOnError = async () => {
        this.setState({
            modalAnswerState: Constants.STATE_INITIAL
        })
    }


    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        var uuid = 'anonymous';
        if (Userfront.accessToken()) {
            uuid = Userfront.user.userUuid;
        }
        this.setState({
            uuid: uuid
        })

        await api.getReviewStats().then(res => {
            this.setState({
                rating_avg: Math.round(res.data.data.rating_total / res.data.data.rating_count * 100) / 100,
                rating_count: res.data.data.rating_count,
                rating_total: res.data.data.rating_total
            })
        }).catch(error => {
            //Todo error handeling
        })

        const { skip, limit } = this.state
        await api.getReviews(skip, limit).then(res => {
            this.setState({
                reviews: res.data.data
            })
        }).catch(error => {
            //Todo error handeling
        })
    }

    handleOnClickLoadmore = async event => {
        this.state.loading = true
        const limit = this.state.limit + 20
        this.setState({ limit })
        var reviewsArray = this.state.reviews;
        const skip = reviewsArray.length
        const { origin } = window.location;
        const newURL = `${origin}/info/reviews/${limit}`;
        window.history.replaceState({}, '', newURL);

        await api.getReviews(skip, limit).then(res => {
            res.data.data.forEach(element => {
                reviewsArray.push(element)
            });
            if (res.data.data.length < 20) {
                const end = true
                this.setState({ end })
            }
        }).catch(error => {
            if (error.response.status === 404) {
                const end = true
                this.setState({ end })
            }
            //Todo error handeling
        })
        this.setState({
            reviews: reviewsArray
        })

        this.state.loading = false
    }

    refreshReviews = async event => {
        const { limit } = this.state
        const skip = 0;
        await api.getReviews(skip, limit).then(res => {
            this.setState({
                reviews: res.data.data
            })

        }).catch(error => {
            //Todo error handeling
            this.setState({
                reviews: []
            })
        })

        await api.getReviewStats().then(res => {
            this.setState({
                rating_avg: Math.round(res.data.data.rating_total / res.data.data.rating_count * 100) / 100,
                rating_count: res.data.data.rating_count,
                rating_total: res.data.data.rating_total
            })
        }).catch(error => {
            //Todo error handeling
        })

    }

    handleModalReviewRating = async event => {
        const modalReviewRating = Number(event.target.value)
        this.setState({ modalReviewRating })
        document.getElementById("ip-review-rating").style.border = ''
    }
    handleModalReviewTitle = async event => {
        const modalReviewTitle = event.target.value
        this.setState({ modalReviewTitle })
        this.setState({ modalReviewTitleError: false })
    }
    handleModalReviewReviewer_name = async event => {
        const modalReviewReviewer_name = event.target.value
        this.setState({ modalReviewReviewer_name })
        this.setState({ modalReviewReviewer_nameError: false })
    }
    handleModalReviewPro = async event => {
        const modalReviewPro = event.target.value
        this.setState({ modalReviewPro })
    }
    handleModalReviewCon = async event => {
        const modalReviewCon = event.target.value
        this.setState({ modalReviewCon })
    }
    handleModalReviewRecommendation = async event => {
        if (this.state.modalReviewRecommendation === true) {
            this.setState({ modalReviewRecommendation: false })
        } else {
            this.setState({ modalReviewRecommendation: true })
        }
    }
    handleModalAnswerText = async event => {
        const modalAnswerText = event.target.value
        this.setState({ modalAnswerText })
    }


    handleReportChange = (event) => {
        const report_reason = event.target.value
        if (report_reason === Constants.REPORT_TYPE_4) {
            this.setState({ valueReportMessageRequired: true })
        } else {
            this.setState({ valueReportMessageRequired: false })
            this.setState({ valueReportMessageError: false })
        }
        this.setState({ report_reason })
    }
    handleReportMessageChange = async event => {
        const report_message = event.target.value
        this.setState({ report_message })
        const report_char_left = 500 - report_message.length
        this.setState({ report_char_left })
        this.setState({ valueReportMessageError: false })
    }
    handleReportBtnBack = async () => {
        this.setState({
            modalReportOpen: false
        })
        await this.delay(250);
        this.setState({
            modalReportState: Constants.STATE_INITIAL
        })
    }

    handleReportBtnBackOnError = async () => {
        this.setState({
            modalReportState: Constants.STATE_INITIAL
        })
    }

    delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    render() {
        const { reviews, loading, end, rating_avg, rating_total, rating_count, uuid, review_new, isMobile } = this.state
        const { modalLoginOpen, modalReportOpen, modalReviewOpen, modalReportState, modalReviewState, modalDeleteOpen, modalDeleteState, modalAnswerOpen, modalAnswerState } = this.state
        const { modalReviewTitle, modalReviewTitleError, modalReviewRating, modalReviewReviewer_name, modalReviewReviewer_nameError, modalReviewPro, modalReviewCon, modalReviewRecommendation, modalAnswerText } = this.state
        const { report_reason, report_message, valueReportMessageError, valueReportMessageRequired, report_char_left } = this.state


        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalReportOpen}
                        onClose={this.handleModalReportClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalReportOpen}>
                            <Box sx={isMobile === true ? m_style_report : style_report}>
                                {modalReportState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_report_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalReportClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph><Bold>{t("ui.review.tx_mod_report_text1")}</Bold></Paragraph>
                                        <Paragraph>{t("ui.review.tx_mod_report_text2")}</Paragraph>
                                        <Bold>{t("ui.review.tx_mod_report_text3")}</Bold>
                                        <WrapperRadiogroup>
                                            <FormControl>
                                                <RadioGroup
                                                    name="controlled-radio-buttons-group"
                                                    value={report_reason}
                                                    onChange={this.handleReportChange}
                                                >
                                                    <FormControlLabel value={Constants.REPORT_TYPE_1} control={<Radio />} label={t("ui.review.tx_mod_report_reason_1")} />
                                                    <FormControlLabel value={Constants.REPORT_TYPE_2} control={<Radio />} label={t("ui.review.tx_mod_report_reason_2")} />
                                                    <FormControlLabel value={Constants.REPORT_TYPE_3} control={<Radio />} label={t("ui.review.tx_mod_report_reason_3")} />
                                                    <FormControlLabel value={Constants.REPORT_TYPE_4} control={<Radio />} label={t("ui.review.tx_mod_report_reason_4")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </WrapperRadiogroup>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-message"
                                                label={t('ui.review.tx_mod_report_message')}
                                                value={report_message}
                                                onChange={this.handleReportMessageChange}
                                                inputProps={{ maxLength: 500 }}
                                                multiline
                                                error={valueReportMessageError}
                                                required={valueReportMessageRequired}
                                                rows={3}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph>
                                            {t('ui.contact.tx_message_ts')} <Italic>{report_char_left}</Italic> {t('ui.contact.tx_message_te')}
                                        </Paragraph>
                                        <WrapperButtonLine>
                                            <BtnSendReport onClick={this.doSendReport}> {t('ui.review.bt_sendreport')}</BtnSendReport>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalReportState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.review.tx_mod_report_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalReportState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_report_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalReportClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_report_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleReportBtnBack}> {t('ui.review.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalReportState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_report_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalReportClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_report_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleReportBtnBackOnError}> {t('ui.review.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalLoginOpen}
                        onClose={this.handleModalLoginClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalLoginOpen}>
                            <Box sx={isMobile === true ? m_style_login : style_login}>
                                <Container>
                                    <WrapperModalHeader>
                                        <H5TitleModal>{t("ui.view.tx_mod_login_title")}</H5TitleModal>
                                        <BtnModalClose onClick={this.handleModalLoginClose}>
                                            <IconModalClose alt={'...'} src={icon_close} />
                                        </BtnModalClose>
                                    </WrapperModalHeader>
                                    <Paragraph>{t("ui.view.tx_mod_login_text")}</Paragraph>
                                    <StyledLink to={"/auth/login/"} state={{ from: window.location.pathname }}>
                                        <WrapperCenter>
                                            <BtnToLogin >{t("ui.view.bt_mod_toLogin")}</BtnToLogin>
                                        </WrapperCenter>
                                    </StyledLink>
                                </Container>
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalDeleteOpen}
                        onClose={this.handleModalDeleteClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalDeleteOpen}>
                            <Box sx={isMobile === true ? m_style_delete : style_delete}>
                                {modalDeleteState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_delete_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_delete_text")}</Paragraph>
                                        <WrapperButtonLine>
                                            <BtnDelete2 onClick={this.doDeleteReview}> {t('ui.review.bt_delete')}</BtnDelete2>
                                            <BtnCancel onClick={this.handleModalDeleteClose}> {t('ui.review.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.review.tx_mod_delete_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalDeleteState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_delete_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalDeleteClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_delete_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleModalDeleteBtnBackOnError}> {t('ui.review.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalReviewOpen}
                        onClose={this.handleModalReviewClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalReviewOpen}>
                            <Box sx={isMobile === true ? m_style_review : style_review}>
                                {modalReviewState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            {review_new ?
                                                <H5TitleModal>{t("ui.review.tx_mod_review_title")}</H5TitleModal> :
                                                <H5TitleModal>{t("ui.review.tx_mod_review_edit_title")}</H5TitleModal>
                                            }
                                            <BtnModalClose onClick={this.handleModalReviewClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <ContainerRating>
                                            <Rating id="ip-review-rating"
                                                size="large"
                                                value={modalReviewRating}
                                                onChange={(newValue) => this.handleModalReviewRating(newValue)}
                                            />
                                        </ContainerRating>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-review-title"
                                                label={t('ui.reviews.tx_review_title')}
                                                value={modalReviewTitle}
                                                error={modalReviewTitleError}
                                                required={true}
                                                onChange={this.handleModalReviewTitle}
                                                inputProps={{ maxLength: 50 }}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph></Paragraph>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-review-user"
                                                label={t('ui.reviews.tx_review_user')}
                                                value={modalReviewReviewer_name}
                                                error={modalReviewReviewer_nameError}
                                                required={true}
                                                onChange={this.handleModalReviewReviewer_name}
                                                inputProps={{ maxLength: 50 }}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph></Paragraph>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-review-pro"
                                                label={t('ui.reviews.tx_pro')}
                                                value={modalReviewPro}
                                                onChange={this.handleModalReviewPro}
                                                inputProps={{ maxLength: 500 }}
                                                multiline
                                                rows={3}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph></Paragraph>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-review-con"
                                                label={t('ui.reviews.tx_con')}
                                                value={modalReviewCon}
                                                onChange={this.handleModalReviewCon}
                                                inputProps={{ maxLength: 500 }}
                                                multiline
                                                rows={3}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph></Paragraph>
                                        <Container id="ip-review-recommendation">
                                            <Checkbox
                                                checked={modalReviewRecommendation}
                                                onChange={this.handleModalReviewRecommendation}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            {t('ui.reviews.tx_review_recommendation')}
                                        </Container>

                                        <WrapperButtonLine>{review_new ?
                                            <BtnSendReview onClick={this.doSendReview}> {t('ui.review.bt_sendreview')}</BtnSendReview> :
                                            <BtnSendReview onClick={this.doUpdateReview}> {t('ui.review.bt_updatereview')}</BtnSendReview>
                                        }
                                            <BtnCancel onClick={this.handleModalReviewClose}> {t('ui.review.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalReviewState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.review.tx_mod_review_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalReviewState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_review_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalReviewClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_review_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleModalReviewBtnBack}> {t('ui.review.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalReviewState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_review_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalReviewClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_review_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleModalReviewBtnBackOnError}> {t('ui.review.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalAnswerOpen}
                        onClose={this.handleModalAnswerClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalAnswerOpen}>
                            <Box sx={isMobile === true ? m_style_answer : style_answer}>
                                {modalAnswerState === Constants.STATE_INITIAL &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_answer_title")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalAnswerClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="ip-review-con"
                                                label={t('ui.reviews.tx_answer')}
                                                value={modalAnswerText}
                                                onChange={this.handleModalAnswerText}
                                                inputProps={{ maxLength: 500 }}
                                                multiline
                                                rows={3}
                                                size={'small'}
                                            />
                                        </FormControl>
                                        <Paragraph></Paragraph>

                                        <WrapperButtonLine>
                                            <BtnSendReview onClick={this.doUpdateAnswer}> {t('ui.review.bt_updateAnswer')}</BtnSendReview>
                                            <BtnCancel onClick={this.handleModalAnswerClose}> {t('ui.review.bt_cancel')}</BtnCancel>
                                        </WrapperButtonLine>
                                    </Container>
                                }
                                {modalAnswerState === Constants.STATE_PENDING &&
                                    <Container>
                                        <WrapperCenter>
                                            <Paragraph>{t("ui.review.tx_mod_answer_pending_text")}</Paragraph>
                                            <LoadingIcons.Oval width="3em" stroke="#0D6EFD" />
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalAnswerState === Constants.STATE_SUCCESS &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_answer_successTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalAnswerClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_answer_success_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBack onClick={this.handleModalAnswerBtnBack}> {t('ui.review.bt_back')}</BtnBack>
                                        </WrapperCenter>
                                    </Container>
                                }
                                {modalAnswerState === Constants.STATE_ERROR &&
                                    <Container>
                                        <WrapperModalHeader>
                                            <H5TitleModal>{t("ui.review.tx_mod_answer_errorTitle")}</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalAnswerClose}>
                                                <IconModalClose alt={'...'} src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        <Paragraph>{t("ui.review.tx_mod_answer_error_text")}</Paragraph>
                                        <WrapperCenter>
                                            <BtnBackOnError onClick={this.handleModalAnswerBtnBackOnError}> {t('ui.review.bt_backOnError')}</BtnBackOnError>
                                        </WrapperCenter>
                                    </Container>
                                }
                            </Box>
                        </Fade>
                    </Modal>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.reviews.tx_title')}</Title>
                            <Paragraph></Paragraph>
                            <WrapperLine>
                                <WrapperLineItem>
                                    <Rating
                                        readOnly
                                        precision={0.1}
                                        value={(Math.round(rating_total / rating_count * 10) / 10)}
                                        size="large"
                                    />
                                </WrapperLineItem>
                                <WrapperLineItem>
                                    Ø {rating_avg}/5 {t('ui.reviews.tx_reviews_1')} {rating_count} {t('ui.reviews.tx_reviews_2')}
                                </WrapperLineItem>
                            </WrapperLine>
                            <Paragraph></Paragraph>
                            <BtnReview onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.handleModalReviewOpen(null, true)}>
                                {t('ui.reviews.bt_review_now')}
                            </BtnReview>
                        </WrapperCenter>
                    </WrapperMain>
                    {reviews.length > 0 ?
                        <Container>
                            <WrapperGrid>
                                <Grid container spacing={2} >
                                    {reviews.map((obj, index) => {
                                        return (
                                            <Grid xs={2} key={index}
                                                sx={{
                                                    boxShadow: 3,
                                                    width: '100%',
                                                    height: '100%',
                                                    p: 0,
                                                    m: 2,
                                                    borderRadius: 0,
                                                }}>

                                                <ContainerItem key={reviews[index]._id}>
                                                    <ContainerText>
                                                        <WrapperFlexTop>
                                                            <WrapperSegment>
                                                                <WrapperSplit>
                                                                    <WrapperSplit2Left>
                                                                        <Container>
                                                                            <WrapperLine>
                                                                                <WrapperLineItem>
                                                                                    <Rating
                                                                                        readOnly
                                                                                        value={reviews[index].rating}
                                                                                    />
                                                                                </WrapperLineItem>
                                                                                {isMobile == false &&
                                                                                    <WrapperLineItem>
                                                                                        <Bold>{reviews[index].title}</Bold>
                                                                                    </WrapperLineItem>
                                                                                }
                                                                            </WrapperLine>
                                                                            {isMobile == true &&
                                                                                <Bold>{reviews[index].title}</Bold>
                                                                            }
                                                                            <WrapperLine>
                                                                                <WrapperLineItem>
                                                                                    <Container>
                                                                                        <IconUser src={icon_user} />
                                                                                    </Container>
                                                                                    {reviews[index].reviewer_name}
                                                                                </WrapperLineItem>
                                                                                <WrapperLineItem>
                                                                                    {format(new Date(reviews[index].createdAt), 'dd.MM.yyyy')}
                                                                                </WrapperLineItem>
                                                                                {Userfront.user.hasRole("admin") === true &&
                                                                                    <WrapperLineItem>
                                                                                        <IconUser src={icon_admin} alt={'...'} />
                                                                                        {reviews[index]._id}
                                                                                    </WrapperLineItem>
                                                                                }
                                                                            </WrapperLine>
                                                                        </Container>
                                                                    </WrapperSplit2Left>
                                                                    {isMobile == false &&
                                                                        <WrapperSplit2Right>
                                                                            {reviews[index].recommendation === true &&
                                                                                <Container>
                                                                                    {t('ui.reviews.tx_recommendation')}
                                                                                    <IconRecommend src={icon_rec}></IconRecommend>
                                                                                </Container>
                                                                            }
                                                                        </WrapperSplit2Right>
                                                                    }
                                                                </WrapperSplit>
                                                            </WrapperSegment>
                                                        </WrapperFlexTop>
                                                        {isMobile == true &&
                                                            reviews[index].recommendation === true &&
                                                            <Container>
                                                                {t('ui.reviews.tx_recommendation')}
                                                                <IconRecommend src={icon_rec}></IconRecommend>
                                                            </Container>

                                                        }

                                                        <Splitter></Splitter>
                                                        {reviews[index].pro !== "" &&
                                                            <WrapperFlexMid>
                                                                <WrapperSegment>
                                                                    {t('ui.reviews.tx_pro')}
                                                                    <WrapperReviewText>
                                                                        {reviews[index].pro}
                                                                    </WrapperReviewText>
                                                                </WrapperSegment>
                                                            </WrapperFlexMid>
                                                        }
                                                        {reviews[index].con !== "" &&
                                                            <WrapperFlexBottom>
                                                                <WrapperSegment>
                                                                    {t('ui.reviews.tx_con')}
                                                                    <WrapperReviewText>
                                                                        {reviews[index].con}
                                                                    </WrapperReviewText>
                                                                </WrapperSegment>
                                                            </WrapperFlexBottom>
                                                        }
                                                        {reviews[index].admin_comment !== "" &&
                                                            <WrapperComment>
                                                                <Splitter></Splitter>
                                                                <WrapperSegment>
                                                                    <WrapperLine>
                                                                        <WrapperLineItem>
                                                                            <Container>
                                                                                <IconUser src={icon_user} />
                                                                            </Container>
                                                                            reski.net
                                                                        </WrapperLineItem>
                                                                        <WrapperLineItem>
                                                                            {format(new Date(reviews[index].dateCommented), 'dd.MM.yyyy')}
                                                                        </WrapperLineItem>
                                                                    </WrapperLine>
                                                                    <WrapperLine>
                                                                        <WrapperLineItem>
                                                                            <WrapperReviewText>
                                                                                {reviews[index].admin_comment}
                                                                            </WrapperReviewText>
                                                                        </WrapperLineItem>
                                                                    </WrapperLine>
                                                                </WrapperSegment>
                                                            </WrapperComment>
                                                        }
                                                        <WrapperLine>
                                                            {reviews[index].useruuid === uuid &&
                                                                <WrapperLineItem>
                                                                    <BtnEdit onClick={this.handleModalReviewOpen(reviews[index], false)}>{t('ui.review.bt_edit')}</BtnEdit>
                                                                </WrapperLineItem>
                                                            }
                                                            {reviews[index].useruuid === uuid &&
                                                                <WrapperLineItem>
                                                                    <BtnDelete onClick={this.handleModalDeleteOpen(reviews[index]._id)}>{t('ui.review.bt_delete')}</BtnDelete>
                                                                </WrapperLineItem>
                                                            }
                                                            {Userfront.user.hasRole("admin") === true &&
                                                                <WrapperLineItem>
                                                                    <BtnAnswer onClick={this.handleModalAnswerOpen(reviews[index])}>
                                                                        <IconUser src={icon_admin} alt={'...'} />
                                                                        {t('ui.review.bt_answer')}
                                                                    </BtnAnswer>
                                                                </WrapperLineItem>
                                                            }
                                                        </WrapperLine>
                                                    </ContainerText>
                                                    <ContainerReportBtn>
                                                        <IconReport onClick={!Userfront.accessToken() ? this.handleModalLoginOpen : this.handleModalReportOpen(reviews[index]._id)} src={icon_warn} />
                                                    </ContainerReportBtn>
                                                </ContainerItem>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </WrapperGrid>
                            <WrapperCenter>
                                {loading ?
                                    <Wrapper>
                                        <LoadingIcons.ThreeDots width="5em" stroke="#0D6EFD" />
                                    </Wrapper>
                                    :
                                    <Container>
                                        {!end && reviews.length > 19 &&
                                            <Wrapper>
                                                <BtnLoadmore onClick={this.handleOnClickLoadmore}>{t('ui.gridF.tx_loadmore')}</BtnLoadmore>
                                            </Wrapper>
                                        }
                                    </Container>
                                }
                            </WrapperCenter>
                        </Container>
                        :
                        <Container>
                            <WrapperCenter>
                                <Paragraph></Paragraph>
                                <Paragraph>{t('ui.gridF.tx_noreviews')}</Paragraph>
                            </WrapperCenter>
                        </Container>
                    }
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_Reviews));