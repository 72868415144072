import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'
import { BtnBlue, BtnWhite, BtnGrey, BtnGreen, BtnRed } from '../style/Styled_Buttons.jsx'
import { Icon } from '../style/Styled_MiscUI'

import * as Constants from '../constants'
import * as Style from '../style'
import api from '../api'
import Userfront from "@userfront/react";
import { format } from 'date-fns'

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import JsonView from '@uiw/react-json-view';

import { isMobile } from 'react-device-detect';
import { getButtonWidth, getContainerFieldWidth, getContentWidth } from '../util/index.js';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import icon_close from '../res/icons/icon_close_d_v1.png'
import icon_copy from '../res/icons/icon_copy_d_v1.png'

const style_confirm = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '30%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};
const m_style_confirm = {
    position: 'absolute',
    bgcolor: '#FFFFFF',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 2,
};

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


const WrapperButtonLine = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`

const WrapperTextInput = styled.div.attrs({
})`
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const WrapperPayout = styled.div.attrs({
})`
  margin: 5px;
  background-color: #EBEBEB;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
`

const WrapperPayoutText = styled.div.attrs({
})`
  margin-left: 5px;
  margin-bottom: 5px;
`

const BtnResolveCompleted = styled(BtnGreen)`
    margin: 5px;
`

const BtnResolveConcluded = styled(BtnRed)`
    margin: 5px;
`

const BtnResolveRefund = styled(BtnGreen)`
    margin: 5px;
`

const BtnResolvePayout = styled(BtnGreen)`
    margin: 5px;
`

const BtnOrganizeShipping = styled(BtnGreen)`
    margin: 5px;
`
const BtnDownloadCSV = styled(BtnBlue)`
    margin: 5px;
`


const InputTextarea = styled.textarea.attrs({
    className: 'form-control',
})`
    background-color: #EBEBEB;
`

const BtnConfirm = styled(BtnBlue)`
margin: 5px 10px;
justify-content: right;
`

const BtnCancel = styled(BtnGrey)`
margin: 5px 10px;
justify-content: right;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;


const IconCopy = styled(Icon)`
margin: 5px;
width: 15px;
height: 15px;
&:hover {
    cursor: pointer;
  }
`

const PAYOUT = 'PAYOUT';
const IBAN = 'IBAN';
const NAME = 'NAME';
const AMOUNT = 'AMOUNT';
const USAGE = 'USAGE';

class Admin_Purchase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.params.id,
            purchase: {},
            payoutinfo: {},
            comment: '',
            modalConfirmOpen: false,
            modalConfirmCase: '',
            pickupDate: null,
            shippingIdBox: '',
            shippingTrackingBox: '',
            shippingIdArticle: '',
            shippingTrackingArticle: '',
            isMobile
        }
    }

    handleChangeInputComment = async event => {
        const comment = event.target.value
        this.setState({ comment })
    }

    handleChangeInputShippingIdBox = async event => {
        const shippingIdBox = event.target.value
        this.setState({ shippingIdBox })

        const dhl_tracking = 'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=';
        var shippingTrackingBox = dhl_tracking + event.target.value
        this.setState({ shippingTrackingBox })
    }

    handleChangeInputShippingTrackingBox = async event => {
        const shippingTrackingBox = event.target.value
        this.setState({ shippingTrackingBox })
    }

    handleChangeInputShippingIdArticle = async event => {
        const shippingIdArticle = event.target.value
        this.setState({ shippingIdArticle })

        const ilox_tracking = 'https://www.iloxx.de/helpandcontact/tracking.aspx?pid=';
        var shippingTrackingArticle = ilox_tracking + event.target.value
        this.setState({ shippingTrackingArticle })
    }

    handleChangeInputShippingTrackingArticle = async event => {
        const shippingTrackingArticle = event.target.value
        this.setState({ shippingTrackingArticle })
    }

    handleChangeInputDate = async (newValue) => {
        const pickupDate = newValue
        this.setState({ pickupDate })
    }


    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);

        const { id } = this.state;
        var purchase;
        await api.getPurchaseById(id).then(res => {
            purchase = res.data.data;

            this.setState({
                purchase: res.data.data
            })

        }).catch(error => {

        })

        if (purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === false) {
            api.getUserPayoutinfo(purchase.seller).then(res => {
                this.setState({
                    payoutinfo: res.data.data
                })
            }).catch(error => {

            })
        }
    }

    doDownloadCSV = async event => {
        const { purchase, isMobile } = this.state;
        const rows = [
            //["Anrede", "Firma", "Vorname", "Nachname", "Zusatz", "Strasse", "Hausnummer", "Postleitzahl", "Ort", "Land", "Staat", "Telefon", "Fax", "Email", "Kundennummer"],
            ["", "", purchase.data_seller.name, purchase.data_seller.sirname, purchase.data_seller.address1, purchase.data_seller.street, purchase.data_seller.streetnr, purchase.data_seller.postalcode, purchase.data_seller.city, purchase.data_seller.country, "", "", "", "", ""],
            ["", "", purchase.data_buyer.name, purchase.data_buyer.sirname, purchase.data_buyer.address1, purchase.data_buyer.street, purchase.data_buyer.streetnr, purchase.data_buyer.postalcode, purchase.data_buyer.city, purchase.data_buyer.country, "", "", "", "", ""],
        ];

        /*
        sirname: decodeURIComponent(userData.data.sirname),
        name: decodeURIComponent(userData.data.name),
        phonenumber: userData.data.phonenumber,
        street: decodeURIComponent(userData.data.street),
        streetnr: userData.data.streetnr,
        address1: decodeURIComponent(userData.data.address1),
        country: userData.data.country,
        postalcode: userData.data.postalcode,
        city: decodeURIComponent(userData.data.city),
        */

        let csvContent = rows.map(e => e.join(";")).join("\n");

        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });

        var link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.setAttribute("href", url);
        let dat = format(new Date(), 'yyyyMMdd_HHmmss')
        link.setAttribute("download", "Adressdatenimport_" + dat + ".csv");
        document.body.appendChild(link);
        link.click();

    }

    doComplete = async () => {

        const resolved = true;
        const { id, comment } = this.state;
        const payload = { id, resolved, comment }

        await api.answerPurchaseDispute(payload).then(res => {

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })
    }

    doConclude = async () => {

        const resolved = false;
        const { id, comment } = this.state;
        const payload = { id, resolved, comment }

        await api.answerPurchaseDispute(payload).then(res => {

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })
    }

    doRefund = async () => {

        const { id, comment } = this.state;
        const payload = { id, comment }

        await api.refundArticles(payload).then(res => {

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })
    }

    doPayout = async () => {

        const { id, comment } = this.state;
        const payload = { id, comment }

        await api.payoutArticles(payload).then(res => {

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })
    }

    doOrganize = async () => {


        var checkDate = Number(format(new Date(this.state.pickupDate), 'yyyy'))
        if (checkDate < 2000) {
            return;
        }

        const { id, shippingIdBox, shippingIdArticle, shippingTrackingBox, shippingTrackingArticle } = this.state;
        var pickupDate = format(new Date(this.state.pickupDate), 'dd.MM.yyyy')
        var pickupDateCMP = format(new Date(this.state.pickupDate), 'yyyyMMdd')
        const payload = { id, pickupDate, pickupDateCMP, shippingIdBox, shippingIdArticle, shippingTrackingBox, shippingTrackingArticle }

        await api.organizeShipping(payload).then(res => {

            this.doRefresh();

        }).catch(error => {
            //Todo error
        })
    }

    doRefresh = async () => {

        const { id } = this.state;
        await api.getPurchaseById(id).then(res => {
            this.setState({
                purchase: res.data.data
            })
        }).catch(error => {

        })

    }
    handleModalConfirmOpenFromOrganized = async event => {
        const modalConfirmCase = Constants.STATE_PURCHASE_ACCEPTED;
        this.setState({ modalConfirmCase })
        const comment = 'Abholung am: ' + format(new Date(this.state.pickupDate), 'dd.MM.yyyy') + '; ' +
            "Sendungsnummer Karton: '" + this.state.shippingIdBox + "'; " +
            "Sendungsnummer Artikel: '" + this.state.shippingIdArticle + "' Tracking: " +
            this.state.shippingTrackingBox + ' & ' + this.state.shippingTrackingArticle
            ;
        this.setState({ comment })
        this.handleModalConfirmOpen();
    }
    handleModalConfirmOpenFromRefund = async event => {
        const modalConfirmCase = Constants.STATE_PURCHASE_REFUNDED;
        this.setState({ modalConfirmCase })
        this.handleModalConfirmOpen();
    }
    handleModalConfirmOpenFromPayout = async event => {
        const modalConfirmCase = PAYOUT;
        this.setState({ modalConfirmCase })
        this.handleModalConfirmOpen();
    }
    handleModalConfirmOpenFromCompleted = async event => {
        const modalConfirmCase = Constants.STATE_COMPLETED;
        this.setState({ modalConfirmCase })
        this.handleModalConfirmOpen();
    }
    handleModalConfirmOpenFromConcluded = async event => {
        const modalConfirmCase = Constants.STATE_CONCLUDED;
        this.setState({ modalConfirmCase })
        this.handleModalConfirmOpen();
    }

    handleModalConfirmOpen = async event => {
        const modalConfirmOpen = true;
        this.setState({ modalConfirmOpen })
    }

    handleModalConfirmClose = async event => {
        const modalConfirmOpen = false
        this.setState({ modalConfirmOpen })
    }

    handleModalBtnConfirm = async event => {
        switch (this.state.modalConfirmCase) {
            case Constants.STATE_PURCHASE_ACCEPTED:
                this.doOrganize();
                break;
            case Constants.STATE_COMPLETED:
                this.doComplete();
                break;
            case Constants.STATE_CONCLUDED:
                this.doConclude();
                break;
            case Constants.STATE_PURCHASE_REFUNDED:
                this.doRefund();
                break;
            case PAYOUT:
                this.doPayout();
                break;
            default:
                break;
        }
        this.handleModalConfirmClose();
    }

    handleCopyToClipboard = type => () => {
        var text = '';
        switch (type) {
            case IBAN:
                text = this.state.payoutinfo.iban;
                break;
            case NAME:
                text = this.state.payoutinfo.iban;
                break;
            case AMOUNT:
                text = this.state.purchase.payout_amount;
                break;
            case USAGE:
                text = this.state.purchase.data_article.title;
                break;
        }
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }
    fallbackCopyTextToClipboard = text => () => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    render() {
        const { t } = this.props

        const { purchase, payoutinfo, comment, modalConfirmOpen, pickupDate, shippingIdBox, shippingIdArticle, shippingTrackingBox, shippingTrackingArticle, isMobile } = this.state

        return (
            <ContainerMain>
                <WrapperMain>
                    {Userfront.accessToken() && Userfront.user.hasRole("admin") ?
                        <Container>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={modalConfirmOpen}
                                onClose={this.handleModalConfirmClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={modalConfirmOpen}>
                                    <Box sx={isMobile === true ? m_style_confirm : style_confirm}>
                                        <WrapperModalHeader>
                                            <H5TitleModal>Eingabe bestätigen</H5TitleModal>
                                            <BtnModalClose onClick={this.handleModalConfirmClose}>
                                                <IconModalClose src={icon_close} />
                                            </BtnModalClose>
                                        </WrapperModalHeader>
                                        Kommentar:
                                        {comment === '' ?
                                            <Paragraph>Kein Kommentar erfasst.</Paragraph>
                                            :
                                            <Paragraph>{comment}</Paragraph>
                                        }
                                        <WrapperButtonLine>
                                            <BtnConfirm onClick={this.handleModalBtnConfirm}> Bestätigen</BtnConfirm>
                                            <BtnCancel onClick={this.handleModalConfirmClose}> Abbrechen</BtnCancel>
                                        </WrapperButtonLine>
                                    </Box>
                                </Fade>
                            </Modal>
                            <WrapperCenter>
                                <Title>{t('ui.admin_purchase.tx_title')}</Title>
                                <WrapperText>
                                    {t('ui.admin_purchase.tx_text')}
                                </WrapperText>
                                <Paragraph>
                                    Bestellnummer: <Bold>{purchase.order_no}</Bold>
                                </Paragraph>
                                <Paragraph>
                                    Status:{' '}
                                    {purchase.state === Constants.STATE_PENDING_PAYMENT &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PAYMENT_SUCCESS &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PAYMENT_FAILURE &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PURCHASE_ACCEPTED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PURCHASE_DECLINED &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PURCHASE_TIMEOUT &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_SHIPPING_ORGANIZED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_HANDOVER_ORGANIZED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_REFUND_PENDING &&
                                        <font color={Style.COLOUR_RED}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_PURCHASE_REFUNDED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_DELIVERY_DISPUTED &&
                                        <font color={Style.COLOUR_RED}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_DELIVERY_CONFIRMED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_DISPUTE_RESOLVED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_INVALIDATED &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_DELETED &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === true &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === false &&
                                        <font color={Style.COLOUR_ORANGE}>{purchase.state}</font>
                                    }
                                    {purchase.state === Constants.STATE_CONCLUDED &&
                                        <font color={Style.COLOUR_GREEN}>{purchase.state}</font>
                                    }
                                </Paragraph>
                                {purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === false && purchase.accept_timeout === true &&
                                    <WrapperText>
                                        <Bold>Achtung, Käufer hat Kauf nicht bestätigt!</Bold>
                                    </WrapperText>
                                }
                                {purchase.state === Constants.STATE_DELIVERY_DISPUTED &&
                                    <WrapperText>
                                        <Paragraph>Beschwerde: {purchase.user_comment}</Paragraph>
                                    </WrapperText>
                                }
                                {purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === false &&
                                    <WrapperPayout style={{ "width": getContentWidth(isMobile) }}>
                                        <WrapperPayoutText>Auszahlinformation:</WrapperPayoutText>
                                        <WrapperPayoutText>
                                            <Bold>IBAN:</Bold> {payoutinfo.iban}
                                            <IconCopy alt={'...'} src={icon_copy} onClick={this.handleCopyToClipboard(IBAN)} />
                                        </WrapperPayoutText>
                                        <WrapperPayoutText>
                                            <Bold>Name:</Bold> {payoutinfo.name}
                                            <IconCopy alt={'...'} src={icon_copy} onClick={this.handleCopyToClipboard(NAME)} />
                                        </WrapperPayoutText>
                                        <WrapperPayoutText>
                                            <Bold>Summe:</Bold> {purchase.payout_amount}€
                                            <IconCopy alt={'...'} src={icon_copy} onClick={this.handleCopyToClipboard(AMOUNT)} />
                                        </WrapperPayoutText>
                                        <WrapperPayoutText>
                                            <Bold>Zweck:</Bold> Verkauf: {purchase.data_article.title}
                                            <IconCopy alt={'...'} src={icon_copy} onClick={this.handleCopyToClipboard(USAGE)} />
                                        </WrapperPayoutText>
                                    </WrapperPayout>
                                }
                            </WrapperCenter>
                            {purchase.state === Constants.STATE_PURCHASE_ACCEPTED &&
                                <WrapperText>
                                    <WrapperCenter>
                                        <WrapperTextInput style={{ "width": getContainerFieldWidth(isMobile) }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-shippingIdBox"
                                                    value={shippingIdBox}
                                                    onChange={this.handleChangeInputShippingIdBox}
                                                    label={"Versandnummer Verpackung (DHL):"}
                                                    size={'small'}
                                                    inputProps={{ maxLength: 35 }}
                                                />
                                            </FormControl>
                                            <Paragraph></Paragraph>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-shippingTrackingBox"
                                                    value={shippingTrackingBox}
                                                    onChange={this.handleChangeInputShippingTrackingBox}
                                                    label={"Sendeverfolgunglink (DHL):"}
                                                    size={'small'}
                                                    inputProps={{ maxLength: 100 }}
                                                />
                                            </FormControl>
                                            <Paragraph></Paragraph>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-shippingIdArticle"
                                                    value={shippingIdArticle}
                                                    onChange={this.handleChangeInputShippingIdArticle}
                                                    label={"Versandnummer Artikel (Iloxx):"}
                                                    size={'small'}
                                                    inputProps={{ maxLength: 35 }}
                                                />
                                            </FormControl>
                                            <Paragraph></Paragraph>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ip-shippingTrackingArticle"
                                                    value={shippingTrackingArticle}
                                                    onChange={this.handleChangeInputShippingTrackingArticle}
                                                    label={"Sendeverfolgungslink (Iloxx):"}
                                                    size={'small'}
                                                    inputProps={{ maxLength: 100 }}
                                                />
                                            </FormControl>
                                            <Paragraph></Paragraph>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={"Abholung am:"}
                                                    size={'small'}
                                                    value={pickupDate}
                                                    onChange={(newValue) => this.handleChangeInputDate(newValue)}
                                                    format="DD.MM.YYYY"
                                                />
                                            </LocalizationProvider>
                                        </WrapperTextInput>
                                        <WrapperCenterH>
                                            <BtnOrganizeShipping style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleModalConfirmOpenFromOrganized}>Organisiert</BtnOrganizeShipping>
                                            <BtnDownloadCSV style={{ "width": getButtonWidth(isMobile) }} onClick={this.doDownloadCSV}>CSV</BtnDownloadCSV>
                                        </WrapperCenterH>
                                    </WrapperCenter>
                                </WrapperText>
                            }
                            {purchase.state === Constants.STATE_DELIVERY_DISPUTED &&

                                <WrapperCenter>
                                    <Paragraph>Kommentar:</Paragraph>
                                    <Container style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <FormControl fullWidth>
                                            <InputTextarea
                                                id="ipComment"
                                                type="text"
                                                rows="5"
                                                wrap="soft"
                                                maxLength={2000}
                                                value={comment}
                                                onChange={this.handleChangeInputComment}
                                                width="100%"
                                            />
                                        </FormControl>
                                    </Container>
                                    <WrapperCenterH>
                                        <BtnResolveCompleted style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleModalConfirmOpenFromCompleted}>Completed</BtnResolveCompleted>
                                        <BtnResolveConcluded style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleModalConfirmOpenFromConcluded}>Concluded</BtnResolveConcluded>
                                    </WrapperCenterH>
                                </WrapperCenter>
                            }
                            {purchase.state === Constants.STATE_REFUND_PENDING &&
                                <WrapperCenter>
                                    <Paragraph>Kommentar:</Paragraph>
                                    <Container style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <FormControl fullWidth>
                                            <InputTextarea
                                                id="ipComment"
                                                type="text"
                                                rows="5"
                                                wrap="soft"
                                                maxLength={2000}
                                                value={comment}
                                                onChange={this.handleChangeInputComment}
                                                width="100%"
                                            />
                                        </FormControl>
                                    </Container>
                                    <WrapperCenterH>
                                        <BtnResolveRefund style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleModalConfirmOpenFromRefund}>Refund</BtnResolveRefund>
                                    </WrapperCenterH>
                                </WrapperCenter>
                            }
                            {purchase.state === Constants.STATE_COMPLETED && purchase.payout_status === false &&
                                <WrapperCenter>
                                    <Paragraph>Kommentar:</Paragraph>
                                    <Container style={{ "width": getContainerFieldWidth(isMobile) }}>
                                        <FormControl fullWidth>
                                            <InputTextarea
                                                id="ipComment"
                                                type="text"
                                                rows="5"
                                                wrap="soft"
                                                maxLength={2000}
                                                value={comment}
                                                onChange={this.handleChangeInputComment}
                                                width="100%"
                                            />
                                        </FormControl>
                                    </Container>
                                    <WrapperCenterH>
                                        <BtnResolvePayout style={{ "width": getButtonWidth(isMobile) }} onClick={this.handleModalConfirmOpenFromPayout}>Payout</BtnResolvePayout>
                                    </WrapperCenterH>
                                </WrapperCenter>
                            }
                            <WrapperCenter>
                                <Paragraph>JSON-Data:</Paragraph>
                                <JsonView value={purchase} collapsed={true} displayDataTypes={false} />
                            </WrapperCenter>
                        </Container>
                        :
                        <Container>
                            <WrapperCenter>
                                <Paragraph>
                                    {t('ui.admin.tx_page_error')} {';)'}
                                </Paragraph>
                            </WrapperCenter>
                        </Container>
                    }
                </WrapperMain>
            </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Admin_Purchase));