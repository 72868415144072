import React, { Component } from "react";
import { useParams, Link, Navigate, useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'
import { BtnBlue, BtnWhite, BtnGrey } from '../style/Styled_Buttons.jsx'

import * as Constants from '../constants'
import Userfront from "@userfront/react";
import { isMobile } from 'react-device-detect';
import { getButtonWidth } from '../util/index.js';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

const WrapperField = styled.div.attrs({

})`
    margin: 10px 10px;
`

const ContainerFields = styled.div.attrs({
})`
min-width: 300px;
max-width: 600px;
width: 30%;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

const BtnLogin = styled(BtnBlue)`
margin: 5px 0px;
`
const BtnReset = styled(BtnWhite)`
margin: 5px 0px;
width: 100%;
`
const BtnSignup = styled(BtnGrey)`
margin: 5px 0px;
width: 100%;
`

class Auth_Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailOrPasswordError: false,
            showPassword: false,
            redirect: '/',
            isMobile
        }
    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

        if (this.props.location.state != null) {
            if (typeof (this.props.location.state.from) != 'undefined') {
                this.setState({
                    redirect: this.props.location.state.from
                })
            }
        }


        var tenant = '';
        if (process.env.NODE_ENV !== 'production') {
            tenant = Constants.DEV_USERFRONT_TENANT;
        } else {
            tenant = process.env.REACT_APP_USERFRONT_TENANT;
        }
        Userfront.init(tenant);


        // Get token & uuid from URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const uuid = urlParams.get("uuid");
        if (typeof (token) != 'undefined' && token != '' && token != null) {

            // Log in with link
            Userfront.login({
                method: "link",
                token: token,
                uuid: uuid,
            });
        }
    }

    handleChangePasswordVisibility = async event => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    handleChangeInputEmail = async event => {
        const email = event.target.value
        this.setState({ email })
        if (this.state.emailOrPasswordError) {
            this.setState({ emailOrPasswordError: false })
        }
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({ password })
        if (this.state.emailOrPasswordError) {
            this.setState({ emailOrPasswordError: false })
        }
    }

    doProgClick = async () => {
        document.getElementById("bt_login").style.backgroundColor = '#0B5ED7';
        setTimeout(() => {
            document.getElementById("bt_login").style.backgroundColor = '';
        }, 100)
        this.doLogin();
    }

    doLogin = async () => {
        const anchor = this;
        Userfront.login({
            method: "password",
            email: this.state.email,
            password: this.state.password,
            redirect: this.state.redirect
        }).catch(function (error) {
            anchor.setState({ emailOrPasswordError: true })
        });
    }

    render() {
        const { t } = this.props

        const { email, password, emailOrPasswordError, showPassword, isMobile } = this.state

        /* Prebuild Login
        const LoginForm = Userfront.build({
            toolId: "oononm"
        });
        */

        return (
            Userfront.accessToken() ? <Navigate to={{ pathname: "/", }} /> :
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.login.tx_title')}</Title>
                            <Paragraph></Paragraph>
                        </WrapperCenter>

                        <WrapperCenter>
                            <ContainerFields>
                                <WrapperField>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="ip-email"
                                            label={t('ui.login.tx_email')}
                                            value={email}
                                            error={emailOrPasswordError}
                                            required={true}
                                            onChange={this.handleChangeInputEmail}
                                            size={'small'}
                                            autoComplete={'current-email'}
                                            inputProps={{ maxLength: 50 }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    this.doProgClick()
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </WrapperField>
                                <WrapperField>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="ip-password"
                                            label={t('ui.login.tx_password')}
                                            value={password}
                                            error={emailOrPasswordError}
                                            required={true}
                                            onChange={this.handleChangeInputPassword}
                                            size={'small'}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleChangePasswordVisibility}
                                                            onMouseDown={this.handleChangePasswordVisibility}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                            }}
                                            autoComplete={'current-password'}
                                            inputProps={{ maxLength: 50 }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    this.doProgClick()
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </WrapperField>
                            </ContainerFields>
                        </WrapperCenter>

                        <WrapperCenter>
                            <BtnLogin style={{ "width": getButtonWidth(isMobile) }} id="bt_login" onClick={this.doLogin}>{t('ui.login.bt_login')}</BtnLogin>
                        </WrapperCenter>

                        {/* 
                        <WrapperCenter>
                            <Paragraph></Paragraph>
                            ---------- Legacy-form Todo RM ----------
                            <LoginForm />
                            <Paragraph></Paragraph>
                        </WrapperCenter>
                        */}

                        <WrapperCenter>
                            <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/auth/signup"}>
                                <BtnSignup>{t('ui.login.bt_tosignup')}</BtnSignup>
                            </StyledLink>
                        </WrapperCenter>

                        <WrapperCenter>
                            <StyledLink style={{ "width": getButtonWidth(isMobile) }} to={"/auth/reset"}>
                                <BtnReset>{t('ui.login.bt_toreset')}</BtnReset>
                            </StyledLink>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
        )
    }
}

export default withTranslation()(withParams(Auth_Login));