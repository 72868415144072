import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { ContainerMain, WrapperMain, Container, Wrapper, WrapperText, WrapperCenter, WrapperCenterH } from '../style/Styled_Dividers.jsx'

import * as Constants from '../constants'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    width: 20%;
    color: #000000;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #000000;
    }
`;

class Website_Customerinfo extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;

    }


    render() {
        const { t } = this.props
        return (
            <Container>
                <ContainerMain>
                    <WrapperMain>
                        <WrapperCenter>
                            <Title>{t('ui.customerinfo.tx_title')}</Title>
                                    <Paragraph></Paragraph>
                        </WrapperCenter>
                        <WrapperCenter>
                            <Paragraph>
                            <H5Title>Kundeninformation - oder wie funktioniert das Prinzip reski.net?</H5Title>

                                reski.net ist ein Online-Marktplatz, der gebrauchte Skitourenausrüstung vermittelt. So sorgen wir für mehr Nachhaltigkeit in der Branche und ermöglichen Tourenski ein weiteres glückliches Skitourenleben (bei einem/r hoffentlich genau so glücklichen Besitzer/in ;-) <br/>
                                <br/>
                                <H5Title>Was muss ich tun, damit mein nicht mehr benötigter Tourenski inseriert wird?</H5Title>
                                Zuallererst: Richte dir hier ein Benutzerkonto ein und hinterlege deine Daten. Anschließend kannst du deinen Tourenski inserieren. Bitte achte hier auf eine möglichst detaillierte und ehrliche (!) Beschreibung. Wir haben ein einigen Stellen einige Hilfen implementiert, damit du den Zustand deines Tourenskis besser einschätzen kannst.<br/>
                                <br/>
                                <H5Title>Ich als Käufer wohne in Hamburg, der Tourenski meiner Träume steht aber in München? Welche Übergabemöglichkeiten habe ich?</H5Title>
                                Selbstabholung: Im Anschluss an die Zahlung bekommen Käufer und Verkäufer die jeweiligen Kontaktdaten und machen sich die Übergabe selbst aus. Das Geld bekommt der Verkäufer erst dann ausgezahlt, wenn der Käufer ihm die ordnungsgemäße Übergabe des Artikels auf reski.net bestätigt. (In diesem Fall vielleicht etwas unpraktisch ;-) <br/>
                                <br/>
                                Versand: Im Anschluss an den Zahlungsprozess wird dem Verkäufer eine Versandverpackung von reski.net zugesendet. In diese packt der Verkäufer die Tourenski und stellt sie zum angegebenen Abholtermin an ein frei zugänglichen Ort an der Abholadresse. Das Paket wird abgeholt und dem Käufer zugestellt. Der Käufer bestätigt die ordnungsgemäße Zustellung der Tourenski und im Anschluss gibt reski.net die Zahlung an den Verkäufer frei. <br/>
                                <br/>
                                <H5Title>Wie lange dauert es nach dem Zahlungsprozess, bis ich meine Tourenski in den Händen halte?</H5Title> 
                                Nach erfolgreicher Zahlung geben wir die Versandbox für den Verkäufer auf, sodass dieser nach ca. 2-3 Werktagen den Tourenski verpacken kann. Der Abholtermin erfolgt weitere 1-2 Werktage später. Die Zustellung des Tourenskis erfolgt innerhalb Deutschlands binnen 2-3 Werktagen. In Summe kann die Lieferung also max. 8 Werktage in Anspruch nehmen.
                                Solltest du als Übergabeart Selbstabholung gewählt haben, liegt es natürlich am Käufer bzw. Verkäufer ;-) <br/>
                                <br/>
                                <H5Title>Wann bekomme ich als Verkäufer mein Geld ausgezahlt?</H5Title> 
                                Erst wenn der wie beschriebene Zustand der Tourenski durch den Käufer bestätigt wird, wird die Zahlung durch reski.net freigegeben. Erfolgt keine Rückmeldung des Käufers, ist davon auszugehen, dass er mit den Tourenski zufrieden ist und der Käufer erhält sein Geld spätestens nach 15 Werktagen ausgezahlt.<br/>
                                <br/>
                                <H5Title>Was tue ich als Käufer, wenn der zugesendete Tourenski von der Beschreibung abweicht?</H5Title>
                                Wir bitten die Verkäufer um eine ehrliche Beschreibung der zu verkaufenden Tourenski. Wenn doch mal etwas nicht passt: Bitte dokumentiere den Zustand des Skis und nimm mit reski.net Kontakt auf. Wir kontaktieren dann den Verkäufer und versuchen eine Lösung zu finden. Sollte eine Lösung nicht möglich sein bzw. ist der zu verkaufende Tourenski nicht entsprechend der Beschreibung, muss der Käufer diese an den Verkäufer zurücksenden. Die Organisation und Kosten des Rückversandes übernmmt reski.online. Die Zahlung wird im Anschluss an den Käufer zurücküberwiesen. Dem Verkäufer werden die entstanden Kosten (beide Sendungen, Transaktionskosten) in Rechnung gestellt.<br/>

                            </Paragraph>
                        </WrapperCenter>
                    </WrapperMain>
                </ContainerMain>
            </Container>
        )
    }
}

export default withTranslation()(withParams(Website_Customerinfo));