import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { Title, SubTitle, SubsubTitle, H5Title, Paragraph, Line, Italic, Bold } from '../style/Styled_Text.jsx'
import { H5TitleModal, BtnModalClose, WrapperModalHeader, IconModalClose } from '../style/Styled_Modal.jsx'


import api from '../api'

import { Carousel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import altimage from '../res/images/reski_altimage_v1.png'
import icon_close from '../res/icons/icon_close_d_v1.png'

const style = {
    position: 'absolute',
    width: '60%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 2,
};

const Image = styled.img.attrs({
})`
`
const ActionImage = styled.img.attrs({
})`
    &:hover {
    cursor: zoom-in;
  }
`


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PhotoCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.params.id,
            photo_urls: "",
            frame: [],
            controls: true,
            indicators: true,
            modalOpen: false,
            defaultIndex: 0
        }
    }


    componentDidMount = async () => {
        if (this.first) return;
        this.first = true;
        
        const { id, frame } = this.state
        var view = 'false';
        var uuid = 'anonymous';
        const article = await api.getArticleById(id, view, uuid)
        const photo_elements = article.data.data.photo_elements

        if (photo_elements.length < 1) {
            frame.push({ img: altimage })
        } else {
            photo_elements.forEach(element => {
                frame.push({ img: element.url })
            });
        }


        this.setState({
            frame: frame
        })

        if (photo_elements.length < 2) {
            this.setState({
                controls: false,
                indicators: false
            })
        }
    }


    handleOpen = async event => {
        const modalOpen = true
        const defaultIndex = Number(event.currentTarget.dataset.index)
        this.setState({ defaultIndex })
        this.setState({ modalOpen })
    }
    handleClose = async event => {
        const modalOpen = false
        this.setState({ modalOpen })

    }


    render() {
        const { frame, controls, indicators, modalOpen, defaultIndex } = this.state

        const { t } = this.props

        return (
            <Container>
                <Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalOpen}
                        onClose={this.handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalOpen}>
                            <Box sx={style}>
                                <WrapperModalHeader>
                                    <H5TitleModal>{t("ui.view.tx_photos")}</H5TitleModal>
                                    <BtnModalClose onClick={this.handleClose}>
                                        <IconModalClose src={icon_close} />
                                    </BtnModalClose>
                                </WrapperModalHeader>

                                <Carousel id="carousel_modal" controls={controls} defaultActiveIndex={defaultIndex} indicators={indicators} variant="dark" interval={null}>
                                    {frame.map((obj, index) => {
                                        return (
                                            <Carousel.Item key={index.toString() + "_m"}>
                                                <Image
                                                    className="d-block w-100"
                                                    src={frame[index].img}
                                                    loading="lazy"
                                                    alt="..."
                                                />
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel>
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
                <Carousel id="carousel" controls={controls} indicators={indicators} variant="dark" interval={null}>
                    {frame.map((obj, index) => {
                        return (
                            <Carousel.Item key={index.toString()} data-index={index} onClick={this.handleOpen}>
                                <ActionImage
                                    className="d-block w-100"
                                    src={frame[index].img}
                                    loading="lazy"
                                    alt="..."
                                />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Container>
        )
    };
}

export default withTranslation()(withParams(PhotoCarousel));